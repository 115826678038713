
import React from "react";



export default function LoaderLines() {

    return (
        <>
        <div className="loadlines">
            {/* <box className="shine"></box> */}
                <lines className="shine"></lines>
                {/* <lines className="shine"></lines>
                <lines className="shine"></lines> */}
            </div>
        </>

    );
};

