import React from 'react';
import { Container, Typography, Box, Link, CardMedia } from '@material-ui/core';
import { eventMapStyles } from '../../utils/hooks/useApplyStyles';
//import routesimg from '../../assets/images/routes.png'
// import GoogleMapReact from 'google-map-react';



export default function SectionMap({ event_data, refMapProp }) {

    const Map_API_KEY = "AIzaSyCJys05epdm6Oh3rSE1f3ZGBrTCcGWzgOE";
    const map_url = `https://www.google.com/maps/embed/v1/place?q=${event_data.latitude},${event_data.longitude}&key=${Map_API_KEY}`;

    const styles = eventMapStyles();
    const classes = styles();

    return (
        <>
            <Box ref={refMapProp} textAlign="center">
                <Container maxWidth="lg">
                    <div style={{ width: '100%', height: '100%' }}>
                        <CardMedia component="iframe" src={map_url} height="600" loading="lazy" style={{ border: 0 }} />
                    </div>
                    {/* <Box component="div" className={classes.mapInfoBottom}>
                        {event_data &&
                            event_data.app_title &&
                            <Typography variant="h4" gutterBottom>
                                {!!(event_data && event_data.event_format) ? event_data.event_format : "event_format_null"} | {event_data.app_title}
                            </Typography>
                        }

                        {event_data &&
                            event_data.organiser &&
                            <Typography variant="p" component="p" gutterBottom >
                                Organized By:
                                <Link href="#" style={{ marginLeft: 5, }}>
                                    {event_data.organiser}
                                </Link>
                            </Typography>
                        }
                        <Typography variant="p" component="p" gutterBottom>
                            {!!(event_data && event_data.location) ? event_data.location : "location_null"}
                        </Typography>
                        <img src={routesimg} alt="Routes" style={{margin: "auto", width: 187}} />
                    </Box> */}
                </Container>
            </Box>
        </>
    );
};