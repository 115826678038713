import { Box } from '@material-ui/core';
import React from 'react'
import { dashboardStyles } from '../../utils/hooks/useApplyStyles';
import Header from '../Header'
import Sidebar from '../Sidebar';
import Profile from './Profile';

const EditProfile = () => {
    const styles = dashboardStyles();
    const classes = styles();

    return (
        <Box className={classes.main}>
            <Header />
            <div className={classes.wrapper}>
                <Sidebar />
                <Profile />
            </div>
        </Box>
    )
}

export default EditProfile