import React from 'react';
import { Card, List, ListItem, Typography, Box, Chip } from '@material-ui/core';
import { useCoverCardMediaStyles } from '@mui-treasury/styles/cardMedia/cover';
import { eventDetailCardStyles } from "../../../utils/hooks/useApplyStyles";import moment from 'moment';
import Timer from './Timer'
import LoaderLines from "../../Loader/LoaderLines";
import LoaderBox from "../../Loader/LoaderBox";

export default function SocailShareCard({ event_data }) {
    const styles1 = useCoverCardMediaStyles();
    const styles2 = eventDetailCardStyles();
    const classes = { ...styles1, ...styles2() }
    const now = moment();
    const today_date = moment().subtract(0, "days").format("YYYY-MM-DD");
    return (
        <>
            <Box className={classes.socialShareBox}>
                <Card className={classes.edetailcInfo}>
                    {event_data && event_data.isLoading &&
                        <>
                            <LoaderBox />
                            <LoaderLines />
                            <LoaderLines />
                            <LoaderLines />
                            <LoaderLines />
                            <LoaderLines />
                            <LoaderLines />
                            <LoaderLines />
                            <LoaderLines />
                            <LoaderLines />
                            <LoaderLines />
                        </>
                    }
                    <Box className={classes.bannerCardWrap}>
                        <div className={classes.cardWrap} style={{ paddingTop: 0 }}>
                            {event_data && event_data.data && event_data.data.data[0] && today_date && now && (event_data.data.data[0].start_date == today_date || now.isBefore(event_data.data.data[0].start_date, "day") || now.isBetween(event_data.data.data[0].on_demand_start_date, event_data.data.data[0].on_demand_end_date)) &&
                                <div className={classes.priceText}>
                                    {event_data && event_data.data && event_data.data.data[0] && today_date && now && (event_data.data.data[0].start_date == today_date || now.isBefore(event_data.data.data[0].start_date, "day")) &&
                                        <Typography variant="body1" component="p" gutterBottom>
                                            Attend this event
                                        </Typography>
                                    }
                                    <Typography variant="h4" component="p" gutterBottom>
                                        {!!(event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].price_type && event_data.data.data[0].price_type === "FREE") ? "Free Event" : ""}
                                        {!!(event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].price_type && event_data.data.data[0].price_type === "PAID" && !event_data.data.data[0].price) ? "Paid Event" : ""}
                                        {!!(event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].price_type && event_data.data.data[0].price_type === "PAID" && event_data.data.data[0].price) ? "Price: $" + event_data.data.data[0].price : ""}
                                    </Typography>
                                </div>
                            }
                            {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].start_date && today_date && now && (event_data.data.data[0].start_date == today_date || now.isBefore(event_data.data.data[0].start_date, "day")) &&
                                <div className={classes.edetailTimer}>
                                    <Timer start_date={event_data.data.data[0].start_date} />
                                </div>
                            }
                            <div className={classes.edetailFormats}>
                                {event_data &&
                                    event_data.data &&
                                    event_data.data.data[0] &&
                                    event_data.data.data[0].event_format &&
                                    <Box className='mb-3'>
                                        <Typography variant="h5" component="p">
                                            Format
                                        </Typography>
                                        <Typography variant="p" component="p" className={classes.paraCards}>
                                            {event_data.data.data[0].event_format}
                                        </Typography>
                                    </Box>
                                }
                                {event_data &&
                                    event_data.data &&
                                    event_data.data.data[0] &&
                                    event_data.data.data[0].eventCat &&
                                    event_data.data.data[0].eventCat.length !== 0 &&
                                    <div>
                                        <Typography variant="h5" component="p">
                                            Event Topics
                                        </Typography>
                                        <Typography variant="p" component="p" className={classes.paraCards}>
                                            {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].eventCat && event_data.data.data[0].eventCat.map((cat, i) => [
                                                i > 0 && ", ",
                                                cat.catName && cat.catName.name ? cat.catName.name : ""

                                            ])}
                                        </Typography>
                                    </div>
                                }
                            </div>

                            {event_data &&
                                event_data.data &&
                                event_data.data.data[0] &&
                                event_data.data.data[0].eventTag &&
                                event_data.data.data[0].eventTag.length !== 0 &&
                                <div className={classes.tagsSideMain}>
                                    <Typography variant="h5" component="p">
                                        Tags
                                    </Typography>
                                    <List component="ul" aria-label="Tags" className={classes.edetailtags}>
                                        {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].eventTag && event_data.data.data[0].eventTag.map(tag =>
                                            <ListItem>
                                                {tag.tagName && tag.tagName.name &&
                                                    <Chip color="primary" variant="outlined" label={tag.tagName.name} size="small" id={"event-tag-" + tag.tagName.name.toLowerCase().replace(/ /g, '-')} />
                                                }
                                            </ListItem>
                                        )}
                                    </List>
                                </div>
                            }
                        </div>
                    </Box>
                </Card>
            </Box>
        </>
    );
};