import React, { useEffect, useState } from 'react';
// import ReactDOM from 'react-dom';
import moment from 'moment';


function Timer({ start_date }) {
	const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	// useEffect(() => {
	// 	setInterval(() => {
	// 		const now = moment();
	// 		console.log("now", now)
	// 		const then = moment('2020-10-15 12:12:12', 'YYYY-MM-DD hh:mm:ss');
	// 		console.log("then", then)
	// 		const countdown = moment(then - now);
	// 		console.log("countdown", countdown)
	// 		setDays(countdown.format('D'));
	// 		setHours(countdown.format('HH'));
	// 		setMinutes(countdown.format('mm'));
	// 		setSeconds(countdown.format('ss'));
	// 	}, 5000);
	// }, []);

	useEffect(() => {
		setInterval(() => {
			const now = moment().toDate();
			const time = Date.parse(start_date) - Date.parse(now);
			const seconds = Math.floor((time / 1000) % 60);
			const minutes = Math.floor((time / 1000 / 60) % 60);
			const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
			const days = Math.floor(time / (1000 * 60 * 60 * 24));
			setDays(days < 0 ? "0" : days);
			setHours(hours < 0 ? hours * -1 : hours);
			setMinutes(minutes < 0 ? minutes * -1 : minutes);
			setSeconds(seconds < 0 ? seconds * -1 : seconds);
		}, 1000);
	}, []);


	return (

		<div className="timer-container">
			<div className="timer">
				<p className="number">{days} <span>D</span></p>

			</div>
			{/* <div className="semicolon">:</div> */}
			<div className="timer">
				<p className="number">{hours} <span>H</span></p>

			</div>
			{/* <div className="semicolon">:</div> */}
			<div className="timer">
				<p className="number">{minutes} <span>M</span></p>

			</div>
			{/* <div className="semicolon">:</div> */}
			<div className="timer">
				<p className="number">{seconds} <span>S</span></p>

			</div>
		</div>
	);
}


export default Timer