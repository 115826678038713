import React, { useState, useRef } from "react";
import Cookies from 'universal-cookie';
import { Box, Button, Container, Typography,LinearProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { onboardingStyles } from '../../../utils/hooks/useApplyStyles';
import {Async, FieldFeedback, FieldFeedbacks, FormWithConstraints} from 'react-form-with-constraints-material-ui';

const LoginForm = () => {
    const styles = onboardingStyles();
    const classes = styles();

    const [loginResponse, setloginResponse] = useState({
        status: "",
        msg: "",
        token: "",
        email: "",
        error: "",
        isLoading: false,
    });
    const cookies = new Cookies();

    const form = useRef(null);

    //validates fields
    async function handleChange({ target }) {
        await form.current.validateFields(target);
        setloginResponse({ ...loginResponse, status: "", msg: "", error: ""});
    }
    
    async function handleSubmit(e) {
        e.preventDefault();

        //setloginResponse({ ...loginResponse, status: "", msg: "", error: ""});

        // Validates the non-dirty fields and returns Promise<Field[]>
        await form.current.validateForm();
        
        if (form.current.isValid()) {

            setloginResponse({ ...loginResponse, isLoading: true });

            var OnboardingUsers = `${global.API.rootPath}${global.config.endpoints.onboarding_login}`;

            //var bearerToken = `${global.config.onboarding_token}`;

            //HEADERS
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            //POST DATA
            var urlencoded = new URLSearchParams();
            urlencoded.append("username", e.target.elements.email.value);
            urlencoded.append("password", e.target.elements.password.value);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
                //mode: 'no-cors'
            };

            fetch(OnboardingUsers, requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                setloginResponse({ ...loginResponse, status: data.status, msg: data.msg, token: data.token, email: e.target.elements.email.value, isLoading: false });
            })
            .catch(error => {
                setloginResponse({ ...loginResponse, status: false, error: true, isLoading: false });
                console.log('error', error)
            });

        }
        // else if(!captcha) {
        //     setVoteResponse({ ...voteResponse, status: false, msg: "Captcha Failed" });
        // }
        else {
            console.log('The form is invalid');
        }
    }
    if(loginResponse && loginResponse.status && loginResponse.msg === "Logged in Successfully." && loginResponse.token) {
        let expires = new Date();
        //expire after 2 days from now
        expires.setDate(expires.getDate() + 2);
        // cookies.set('onBoardingEmail', loginResponse.email, { path: '/', expires });
        cookies.set('onBoardingToken', loginResponse.token, { path: '/',expires });
        window.location.href = "/onboarding-user-dashboard";

    }
    
    return (
        <Box className={classes.obLogin}>
            <Container maxWidth="lg">
                <Box className={classes.obLoginBox}>
                    <Typography variant="h2">Login</Typography>
                    <FormWithConstraints ref={form} onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.obLoginForm}>
                        <input name="email" required minLength={3} id="email" className={classes.input} placeholder='Email address' onChange={handleChange} />
                        <FieldFeedbacks for="email">
                            <FieldFeedback when={value => value.length === 0}>Please complete this required field.</FieldFeedback>
                        </FieldFeedbacks>
                        <input type="password" required minLength={3} id="password" name="password" className={classes.input} placeholder="Password" onChange={handleChange} style={{marginTop: 15}} />
                        <FieldFeedbacks for="password">
                            <FieldFeedback when={value => value.length === 0}>Please complete this required field.</FieldFeedback>
                        </FieldFeedbacks>
                        {loginResponse && !loginResponse.status && loginResponse.msg && 
                            <span class="error">{loginResponse.msg}</span>
                        }
                        <Box className={classes.forgotPassword}>
                            <Link to="/onboarding-forgot-password">Forgot Password?</Link>
                        </Box>
                        <div style={{ minHeight: 5, }}>
                            {loginResponse && loginResponse.isLoading &&
                                <LinearProgress />
                            }
                        </div>
                        <Button type="submit" variant='contained' color="primary">Login</Button>
                    </FormWithConstraints>
                    <Box className={classes.alreadyAccount}>
                        <Typography variant='body1'>Don’t have an account?</Typography>
                        <Link to="/onboarding-signup">Create Account</Link>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default LoginForm