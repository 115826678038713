import React, { useState } from "react";
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Box, Typography, Button } from '@material-ui/core';
import MarketplaceFilters from './MarketFilters'

const drawerWidth = '100%';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButtonWrap: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '& button': {
      [theme.breakpoints.down('sm')] : {
        paddingTop: 0
      }
    }
  },
  // necessary for content to be below app bar
  drawerPaper: {
    width: drawerWidth,
    position: 'relative',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function FiltersDrawer({eventstatus, setEventStatus, postFilters, setPostFilters, allCats, setAllCats, allTags, setAllTags, searchTags, setSearchTags }) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <MarketplaceFilters
        eventstatus={eventstatus} setEventStatus={setEventStatus}
        postFilters={postFilters} setPostFilters={setPostFilters}
        allCats={allCats} setAllCats={setAllCats}
        allTags={allTags} setAllTags={setAllTags}
        searchTags={searchTags} setSearchTags={setSearchTags}
      />
    </div>
  );

  const container = document.body;

  return (
    <div className={classes.root}>
      <Box className={classes.menuButtonWrap}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <FilterListIcon />
        </IconButton>
        <Typography variant="h6" component="p"> Filters</Typography>
      </Box>
      
      <div>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Box p={2}>
              <Button
                // color="primary"
                aria-label="Close drawer"
                edge="end"
                onClick={handleDrawerToggle}
                style={{ position: "absolute", right: 5 }}
              >
                <CloseIcon />
              </Button>

              {drawer}
            </Box>
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </div>
    </div>
  );
}

FiltersDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};