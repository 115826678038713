import React from 'react'
import { Box} from '@material-ui/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { eventDetailStyles } from '../../../utils/hooks/useApplyStyles';

var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    centerPadding: '40px',
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
};
const EventGallery = ({images}) => {
    console.log(images)
    const styles = eventDetailStyles();
    const classes = styles();
    return (
                        

        <Box className={classes.edetailgallery}>
            <Slider {...settings}>
                {images.map(gallary =>
                    <div className={classes.galleryItem}>
                        <img src={gallary.image} alt="gallery" />
                    </div>
                )}
            </Slider>
        </Box>
    )
}

export default EventGallery