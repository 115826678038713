import { Box, Button, InputBase, Menu, MenuItem, Typography } from '@material-ui/core';
import React from 'react'
import { Link } from "react-router-dom";
import { dashboardStyles, navbarStyles } from '../../utils/hooks/useApplyStyles';
import Magnifer from '../../assets/images/magnifer.svg'
import { useRoundInputBaseStyles } from '@mui-treasury/styles/inputBase/round';
import { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx'
import Fade from '@material-ui/core/Fade';

const Filter = ({eventFilters,setEventFilters}) => {
    const styles1 = useRoundInputBaseStyles();
    const styles2 = navbarStyles();
    const styles3 = dashboardStyles();
    const classes = { ...styles1, ...styles2(), ...styles3() }
    const [searchToggleMob, setSearchToggleMob] = useState(false);

    const searchToggle = () => {
        setSearchToggleMob(!searchToggleMob);
    }

    // Mode Dropdown
    const [modeAnchorEl, setModeAnchorEl] = useState(null);
    const modeOpen = Boolean(modeAnchorEl);
    const handleModeClick = (event) => {
        setModeAnchorEl(event.currentTarget);
    };
    const handleModeClose = (f) => {        
        const { mode } = f.currentTarget.dataset;
        setEventFilters({ ...eventFilters, eventMode: mode ? mode : "" })
        setModeAnchorEl(null);
    };

    // Format Dropdown
    const [formatAnchorEl, setFormatAnchorEl] = useState(null);
    const formatOpen = Boolean(formatAnchorEl);
    const handleFormatClick = (event) => {
        setFormatAnchorEl(event.currentTarget);
    };
    const handleFormatClose = (f) => {        
        const { format } = f.currentTarget.dataset;
        setEventFilters({ ...eventFilters, formats: format ? format : "" })
        setFormatAnchorEl(null);
    };

    // Status Dropdown (Publish/Draft)
    const [statusAnchorEl, setStatusAnchorEl] = useState(null);
    const statusOpen = Boolean(statusAnchorEl);
    const handleStatusClick = (event) => {
        setStatusAnchorEl(event.currentTarget);
    };
    const handleStatusClose = (e) => {        
        const { status } = e.currentTarget.dataset;
        setEventFilters({ ...eventFilters, eventStatus: status ? status : "" })
        setStatusAnchorEl(null);
    };

    const formats_Arr = [
        'Conference',
        'Trade Show',
        'Single Employer Career Fair',
        'Multi Employer Job Fair',
        'Open Day',
        'Education Fair',
        'Exhibition Fair',
        'Product Launch',
        'Motor Show',
        'Housing Fair',
        'Onboarding Fair',
        'Other',
    ];

    return (
        <div className={classes.filter}>
            <Typography variant='h1'>Event Listing</Typography>
            <div className={classes.filterItems}>

                <div className={`searchFilter ${classes.search}`}>
                    <Box pb={1} />
                    <Button className={classes.searchIconMobile} onClick={searchToggle}>{<img src={Magnifer} alt="Search here" />}</Button>
                    <InputBase
                        classes={classes}
                        className={clsx(classes.searchBarMobile, `${searchToggleMob ? classes.searchBarToggle : ''}`)}
                        color={'secondary'}
                        startAdornment={<img src={Magnifer} alt="Search here"/>}
                        placeholder={'Search'}
                        value={eventFilters.search}
                        onChange={(p) => setEventFilters({ ...eventFilters, search: p.target.value })}
                    />
                    <Box pb={1} />
                </div>

                <div className={classes.filterMenu}>
                    <Button
                        id="mode"
                        aria-controls={modeOpen ? 'mode-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={modeOpen ? 'true' : undefined}
                        onClick={handleModeClick}
                        endIcon={<ExpandMoreIcon />}
                    >
                        {eventFilters && eventFilters.eventMode ? eventFilters.eventMode : "Select Mode"}
                    </Button>
                    <Menu
                        id="mode-menu"
                        MenuListProps={{
                        'aria-labelledby': 'mode',
                        }}
                        anchorEl={modeAnchorEl}
                        open={modeOpen}
                        onClose={handleModeClose}
                        TransitionComponent={Fade}
                        className={classes.dropdownStyles}
                    >
                        <MenuItem onClick={handleModeClose} data-mode={""} >All</MenuItem>
                        <MenuItem onClick={handleModeClose} data-mode={"VIRTUAL"}   >Virtual</MenuItem>
                        <MenuItem onClick={handleModeClose} data-mode={"HYBRID"}   >Hybrid</MenuItem>
                        <MenuItem onClick={handleModeClose} data-mode={"PHYSICAL"}   >In-Person</MenuItem>
                    </Menu>
                </div>

                <div className={classes.filterMenu}>
                    <Button
                        id="format"
                        aria-controls={formatOpen ? 'format-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={formatOpen ? 'true' : undefined}
                        onClick={handleFormatClick}
                        endIcon={<ExpandMoreIcon />}
                    >
                        {eventFilters && eventFilters.formats ? eventFilters.formats : "All formats"}
                    </Button>
                    <Menu
                        id="format-menu"
                        MenuListProps={{
                        'aria-labelledby': 'format',
                        }}
                        anchorEl={formatAnchorEl}
                        open={formatOpen}
                        onClose={handleFormatClose}
                        TransitionComponent={Fade}
                        className={classes.dropdownStyles}
                    >
                        {formats_Arr && formats_Arr.map(format =>
                            <MenuItem onClick={handleFormatClose} data-format={format} >{format}</MenuItem>
                        )}
                    </Menu>
                </div>

                <div className={classes.filterMenu}>
                    <Button
                        id="event-status"
                        aria-controls={statusOpen ? 'event-status-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={statusOpen ? 'true' : undefined}
                        onClick={handleStatusClick}
                        endIcon={<ExpandMoreIcon />}
                    >
                        {eventFilters && eventFilters.eventStatus && eventFilters.eventStatus === "1" ? "Published" : eventFilters.eventStatus === "0" ? "Draft" : "Select Status"}
                    </Button>
                    <Menu
                        id="event-status-menu"
                        MenuListProps={{
                        'aria-labelledby': 'event-status',
                        }}
                        anchorEl={statusAnchorEl}
                        open={statusOpen}
                        onClose={handleStatusClose}
                        TransitionComponent={Fade}
                        className={classes.dropdownStyles}
                    >
                        <MenuItem onClick={handleStatusClose} data-status={"all"} >All</MenuItem>
                        <MenuItem onClick={handleStatusClose} data-status={"1"}   >Published</MenuItem>
                        <MenuItem onClick={handleStatusClose} data-status={"0"}   >Draft</MenuItem>
                    </Menu>
                </div>
                <Link to="/onboarding-new-event">
                    <Button variant="contained" color="primary" className={classes.addEventBtn}>Add new event</Button>
                </Link>
                
            </div>
        </div>
    )
}

export default Filter