import React from 'react';
import cx from 'clsx';
import { Box, Container, Grid, RadioGroup, Typography, Radio, Button, InputBase, Link, ButtonGroup } from '@material-ui/core';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import { useBorderedInputBaseStyles } from '@mui-treasury/styles/inputBase/bordered';
import { forgotPassStyles } from '../../utils/hooks/useApplyStyles'


export default function CodeSentCard() {
    const styles3 = useBorderedInputBaseStyles();
    const styles1 = useFadedShadowStyles();
    const styles2 = forgotPassStyles();
    const classes = { ...styles1, ...styles3, ...styles2() }
    const preventDefault = (event) => event.preventDefault();
    return (
        <Container maxWidth="lg">
            <Card className={cx(classes.card, styles1.root)}>
                <CardContent>
                    <Typography className={classes.heading}>Code Sent</Typography>
                    <Typography varient="body1" gutterBottom>We sent a text message to <span style={{ fontWeight: 'bold' }}>+1 123 456 789</span> with your verification code. Enter it below to reset your password.</Typography>
                    <Typography className={classes.subheading}>Enter 6-Digit Code</Typography>

                    <InputBase
                        classes={classes}
                        className={classes.filterSearchBox}
                        placeholder={'code example:123456'}
                        style={{ marginBottom: '1.5rem' }}
                    />
                    <div  className={classes.actionCode}>
                        <Link href="#" onClick={preventDefault} varient="body1" color="inherit">
                            Send Me Again
                        </Link>

                        <div >
                            <Button variant="outlined" color="primary"  style={{ marginRight: 5 }} >
                                Cancel
                            </Button>
                            <Button variant="containedPrimary" color="primary" >
                                verify
                            </Button>
                        </div>

                    </div>


                </CardContent>




            </Card>

        </Container>

    );
};

