import React from 'react';
import cx from 'clsx';
import { Container, Typography, } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import { useBorderedInputBaseStyles } from '@mui-treasury/styles/inputBase/bordered';
import { forgotPassStyles } from '../../utils/hooks/useApplyStyles'


export default function EmailSentCard() {
    const styles3 = useBorderedInputBaseStyles();
    const styles1 = useFadedShadowStyles();
    const styles2 = forgotPassStyles();
    const classes = { ...styles1, ...styles3, ...styles2() }

    return (
        <Container maxWidth="lg">
            <Card className={cx(classes.card, styles1.root)}>
                <CardContent>
                    <Typography className={classes.heading}>Email Sent</Typography>
                    <Typography varient="body1"  gutterBottom>An email with instructions on how toreset your password has been sent to <Typography color='primary' component='span'> user@email.com</Typography>. check your spam or junk folder if you don’t see email in your inbox.</Typography>

                    <Typography varient="body1" gutterBottom>if you no longer have access to this email account, please contact us.</Typography>

                </CardContent>
            </Card>

        </Container>

    );
};

