import React from 'react';
import { Link } from "react-router-dom";
import { Grid, Card, Container, Typography, Box } from '@material-ui/core';
import { cardsStyles, lookingUpStyles } from "../../../utils/hooks/useApplyStyles";
import EventFormatCardSingle from '../../Marketplace/Cards/EventFormatCardSingle'
import 'react-multi-carousel/lib/styles.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import conference from '../../../assets/format-icons/Virtual Conference – 1.svg';
import summit from '../../../assets/format-icons/Virtual summit.svg';
import trade_Show from '../../../assets/format-icons/Virtual Trade Show – 1.svg';
import open_Day from '../../../assets/format-icons/Virtual Open Day – 1.svg';
import education_Fair from '../../../assets/format-icons/Virtual Education Fair – 1.svg';
import exhibition_Fair from '../../../assets/format-icons/Virtual Exhibition Fair – 1 1.svg';
import product_Launch from '../../../assets/format-icons/Virtual Product Launch – 1.svg';
import motor_Show from '../../../assets/format-icons/Virtual Motor Show – 1.svg';
import housing_Fair from '../../../assets/format-icons/Virtual Housing Fair – 1.svg';
import multi_Employer from '../../../assets/format-icons/multiple employer job fair.svg';
import single_Employer from '../../../assets/format-icons/single employer career fair.svg';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 3000, min: 1280 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 1279, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1023, min: 768 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
    initialSlide:0,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1024,
            settings: 'unslick'
        }
    ]
};

export default function EventFormat() {

    const styles1 = cardsStyles();
    const styles2 = lookingUpStyles();
    const classes = { ...styles1(), ...styles2() }

    const formats_Arr = [
        { name: 'Multi-Employer Job Fair', image: multi_Employer },
        { name: 'Single-Employer Career Fair', image: single_Employer },
        { name: 'Conference', image: conference },
        { name: 'Summit', image: summit },
        { name: 'Trade Show', image: trade_Show },
        { name: 'Open Day', image: open_Day },
        { name: 'Education Fair', image: education_Fair },
        { name: 'Exhibition Fair', image: exhibition_Fair },
        { name: 'Product Launch', image: product_Launch },
        { name: 'Motor Show', image: motor_Show },
        { name: 'Housing Fair', image: housing_Fair },

    ];

    return (
        <>
            <Box component="div" className={`${classes.homesectionGap} ${classes.eventFormat}`} style={{ paddingBottom: 0 }}>
                <Container maxWidth="false" disableGutters={true}>
                    <Box component="div" m={1} mb={3} className={classes.viewMoreBox}>
                        <Typography variant="h2" className={classes.viewmoreTitle}>
                            Event Format
                        </Typography>
                    </Box>
                    <Grid className={classes.homeCardWrap}>
                        <Slider {...settings} className="leftArrowsSlick themeslickArrow">
                            {formats_Arr.map((format) => {
                                return (
                                    <>

                                        <Card className={classes.eventFormatSingles}>
                                            <Link id={"event-format-" + format.name.toLowerCase().replace(/ /g, '-')} to={"/marketplace/?&formats=" + format.name.replace(/ /g, '+')}>
                                                <EventFormatCardSingle format={format} />
                                            </Link>
                                        </Card>

                                    </>
                                );
                            })}
                        </Slider>

                    </Grid>
                </Container>
            </Box>
        </>
    );
};