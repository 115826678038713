import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core';
import dummy from '../../../assets/dummy-org.png'
import gamification from '../../../assets/includes-icons/gamification.png'
import matchmaking from '../../../assets/includes-icons/matchmaking.png'
import networking from '../../../assets/includes-icons/networking.png'
import sessions from '../../../assets/includes-icons/sessions.png'
import webinar from '../../../assets/includes-icons/webinar.png'
import { eventDetailStyles } from '../../../utils/hooks/useApplyStyles';

const EventIncludes = ({EventIncludes}) => {
    const styles = eventDetailStyles();
    const classes = styles();

    return (
        <Box className={classes.edetaileincl}>
            <Typography variant="h4" component="h2" className={classes.edetailTitle}>
                This event includes:
            </Typography>
            <Grid container spacing={2}>
                {EventIncludes && EventIncludes.map(includes =>
                    <Grid item xs={6} sm={4} md="auto">
                        <Box className={classes.edetailinclItem}>
                            <Box className={classes.edetailinclIcon}>
                                <img src={includes &&
                                    includes.name === "webinars"       ?  webinar      :
                                    includes.name === "networking"     ?  networking   :
                                    includes.name === "live QA Session"?  sessions     :
                                    includes.name === "gamification"   ?  gamification :
                                    includes.name === "1:1matchmaking" ?  matchmaking  : 
                                    dummy}
                                    alt={includes.name}
                                />
                            </Box>
                            <Typography variant="body1">{includes.name}</Typography>
                        </Box>                    
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default EventIncludes