import React, { useState, useRef } from "react";
import { Box, Container, Typography, Button, LinearProgress } from '@material-ui/core';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import {Async, FieldFeedback, FieldFeedbacks, FormWithConstraints} from 'react-form-with-constraints-material-ui';
import { useBorderedInputBaseStyles } from '@mui-treasury/styles/inputBase/bordered';
import { onboardingStyles } from '../../utils/hooks/useApplyStyles'


export default function ForgotPasswordCard() {
    const styles3 = useBorderedInputBaseStyles();
    const styles1 = useFadedShadowStyles();
    const styles2 = onboardingStyles();
    const classes = { ...styles1,  ...styles3, ...styles2() }

    const form = useRef(null);

    const [forgotResponse, setForgotResponse] = useState({
        status: "",
        msg: "",
        error: "",
        isLoading: false,
    });

    //validates fields
    async function handleChange({ target }) {
        await form.current.validateFields(target);
    }

    async function handleSubmit(e) {
        e.preventDefault();

        setForgotResponse({ ...forgotResponse, status: "", msg: "", error: "" });

        // Validates the non-dirty fields and returns Promise<Field[]>
        await form.current.validateForm();
        
        if (form.current.isValid()) {

            setForgotResponse({ ...forgotResponse, isLoading: true });            

            var ForgotPassword = `${global.API.rootPath}${global.config.endpoints.onboarding_forgot}`;

            //var bearerToken = `${global.config.onboarding_token}`;
            
            //HEADERS
            var usersHeaders = new Headers();
            //usersHeaders.append("Authorization", `Bearer ${bearerToken}`);
            usersHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            //POST DATA
            var urlencoded = new URLSearchParams();
            urlencoded.append("email", e.target.elements.email.value);

            var requestOptions = {
                method: 'POST',
                headers: usersHeaders,
                body: urlencoded,
                redirect: 'follow',
            };

            fetch(ForgotPassword, requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                setForgotResponse({ ...forgotResponse, status: data.status, msg: data.msg, isLoading: false });
            })
            .catch(error => {
                setForgotResponse({ ...forgotResponse, status: false, error: true, isLoading: false });
                console.log('error', error)
            });
        }
        else {
            console.log('The form is invalid');
        }
    }

    return (
        <div className={classes.obLogin}>
            <Container maxWidth="lg">
                <Box className={classes.obLoginBox}>
                    {forgotResponse && forgotResponse.status && forgotResponse.msg === "New password generated." ?
                        <>
                        <Typography variant="h2">Email Sent</Typography>
                        <Typography varient="body1" component="p" gutterBottom>Thanks for submitting your email address. We've sent you an email with instructions on how to reset your password. If you don't receive it, please check your spam folder.</Typography>
                        </>
                    :
                        <>
                        <Typography variant="h2">Forgot Password</Typography>
                        <Typography variant="body1" component="p" gutterBottom>If you've forgotten your password, enter your email address and click the 'Forgot Password’ link below. You'll receive an email with a link to reset your password.</Typography>
                        <FormWithConstraints ref={form} onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.obLoginForm}>
                            <input name="email" required minLength={3} id="email" className={classes.input} placeholder='Email Address*' onChange={handleChange} style={{marginTop: 15,}}/>
                            {forgotResponse && !forgotResponse.status && forgotResponse.msg && 
                                <span class="error">{forgotResponse.msg}</span>
                            }
                            <FieldFeedbacks for="email">
                                <FieldFeedback when={value => value.length === 0}>Please complete this required field.</FieldFeedback>
                                {/* <FieldFeedback when={value => !/\S+@\S+/.test(value)}>Invalid email address.</FieldFeedback> */}
                            </FieldFeedbacks>
                            <div style={{ minHeight: 15, marginTop: 15, }}>
                                {forgotResponse && forgotResponse.isLoading &&
                                    <LinearProgress />
                                }
                            </div>
                            <Button type="submit" variant="containedPrimary" color="primary" style={{ width: '100%', marginBottom: '1rem' }}>
                                email me
                            </Button>
                        </FormWithConstraints>
                        </>
                    }
                </Box>
            </Container>
        </div>
    );
};

