import { Box, Typography, Grid } from '@material-ui/core';
import React from 'react'
import { awardWinnerCardStyles } from '../../utils/hooks/useApplyStyles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

//import winnerImage from '../../assets/images/box.png'
import dummyOrg from '../../assets/dummy-org.png'
import winTagIcon from '../../assets/images/winnertag-icon.svg'

const AwardWinnerCard = () => {
    const styles = awardWinnerCardStyles();
    const classes = styles();
    return (
        <Grid container spacing={3} justifyContent="center">
            <Grid item lg={4} xs={12}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew/7753/content/images/1670585563t-mobile-career-fest-png1670585563.PNG" alt="T-Mobile CareerFest" />
                            </Box>
                            <Box className={classes.awWinOrg}>
                                <Box className={classes.awWinOrgTitle}>
                                    <Box className={classes.awWinIcon}>
                                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675680680tmobile-png1675680680.png" alt="T-Mobile CareerFest" />
                                    </Box>
                                    <Typography variant="body1">T-Mobile</Typography>
                                </Box>
                                <a href='/eventeer-awards-2023/90/nominee/7753' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Box className={classes.winnering}>
                                <img src={winTagIcon} alt="WinnerIn" />
                                <Typography variant="span">Winner In</Typography>
                            </Box>
                            <Typography variant="body1">Best Out of the Box Event</Typography>
                            <Typography variant="h2">T-Mobile CareerFest</Typography>
                        </Box>
                    </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew/5921/uploads/vjf/content/misc/1629442285iStock-1292003010.jpg" alt="The virtual Student and NRN Careers Fair" />
                            </Box>
                            <Box className={classes.awWinOrg}>
                                <Box className={classes.awWinOrgTitle}>
                                    <Box className={classes.awWinIcon}>
                                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675686083nrn-png1675686083.png" alt="The virtual Student and NRN Careers Fair" />
                                    </Box>
                                    <Typography variant="body1">RCNI</Typography>
                                </Box>
                                <a href='/eventeer-awards-2023/73/nominee/7913' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Box className={classes.winnering}>
                                <img src={winTagIcon} alt="WinnerIn" />
                                <Typography variant="span">Winner In</Typography>
                            </Box>
                            <Typography variant="body1">Best Multi-Employer Job Fair</Typography>
                            <Typography variant="h2">The virtual Student and NRN Careers Fair</Typography>
                        </Box>
                    </Box>
            </Grid>	
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/7527/content/images/1673272307lobby-png1673272307.PNG" alt="Chemileys2022" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675688485hayleys-png1675688485.png" alt="Chemileys2022" />
                                </Box>
                                <Typography variant="body1">Hayleys Fabric PLC</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/69/nominee/7527' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Virtual Conference</Typography>
                        <Typography variant="h2">Chemileys2022</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/7853/content/images/1670671589ph-digicon-2022-png1670671589.PNG" alt="PH Digicon 2022" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675686668pldt-png1675686668.png" alt="PH Digicon 2022" />
                                </Box>
                                <Typography variant="body1">PLDT</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/82/nominee/8851' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Telecom Event</Typography>
                        <Typography variant="h2">PH Digicon 2022</Typography>
                    </Box>
                </Box>
            </Grid>	
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/7715/content/images/1671100510uc-benefits-professionals-symposium-png1671100510.PNG" alt="UC Benefits Professionals Symposium" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675680147university-california-png1675680147.png" alt="UC Benefits Professionals Symposium" />
                                </Box>
                                <Typography variant="body1">University of California</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/80/nominee/7715' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best University Event</Typography>
                        <Typography variant="h2">UC Benefits Professionals Symposium</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/7791/content/images/1667888528isa-rsquo-s-automation-leadership-conference-alc-png1667888528.PNG" alt="ISA Automation & Leadership Conference" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675680629isa-png1675680629.png" alt="ISA Automation & Leadership Conference" />
                                </Box>
                                <Typography variant="body1">International Society of Automation</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/89/nominee/7791' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best in Class Hybrid Event</Typography>
                        <Typography variant="h2">ISA Automation & Leadership Conference</Typography>
                    </Box>
                </Box>
            </Grid>
            
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8172/content/images/1662478735gender-and-health-impacts-of-structural-sexism-gender-norms-relational-power-dynamics-and-gender-inequities-png1662478735.PNG" alt="Gender and Health: Impacts of Structural Sexism, Gender Norms, Relational Power Dynamics, and Gender Inequities" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675680972nih-png1675680972.png" alt="Gender and Health: Impacts of Structural Sexism, Gender Norms, Relational Power Dynamics, and Gender Inequities" />
                                </Box>
                                <Typography variant="body1">National Institutes of Health (NIH)</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/76/nominee/8172' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Virtual Education Fair</Typography>
                        <Typography variant="h2">Gender and Health: Impacts of Structural Sexism, Gender Norms, Relational Power Dynamics, and Gender Inequities</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/7582/content/images/1674636804enterance-png1674636804.PNG" alt="Retail Leadership Experience 2022" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675681113go-west-png1675681113.png" alt="Retail Leadership Experience 2022" />
                                </Box>
                                <Typography variant="body1">Go West Creative</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/81/nominee/7582' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Healthcare Event</Typography>
                        <Typography variant="h2">Retail Leadership Experience 2022</Typography>
                    </Box>
                </Box>
            </Grid>	
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/1391/content/images/1670839916congreso-internacional-de-recursos-humanos-digital-amedirh-2022-png1670839916.PNG" alt="Congreso Internacional de Recursos Humanos Digital Amedirh 2022" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675681380congreso-internacional-png1675681380.png" alt="Congreso Internacional de Recursos Humanos Digital Amedirh 2022" />
                                </Box>
                                <Typography variant="body1">Asociacion Mexicana en Dirección de Recursos Humanos (AMEDIRH)</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/98/nominee/1391' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Virtual Benefits Fair</Typography>
                        <Typography variant="h2">Congreso Internacional de Recursos Humanos Digital Amedirh 2022</Typography>
                    </Box>
                </Box>
            </Grid>		
            
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/6651/content/images/1673448552lobby-png1673448552.png" alt="Practice Opportunity '22" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675683616cmcc-png1675683616.png" alt="Practice Opportunity '22" />
                                </Box>
                                <Typography variant="body1">Canadian Memorial Chiropractic College</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/78/nominee/6651' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Non-profit Event</Typography>
                        <Typography variant="h2">Practice Opportunity '22</Typography>
                    </Box>
                </Box>
            </Grid>	
            
            
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/3385/content/images/1672340760lobby-png1672340760.PNG" alt="University of Bedfordshire - Open days" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675685113university-bedforshire-png1675685113.png" alt="University of Bedfordshire - Open days" />
                                </Box>
                                <Typography variant="body1">University of Bedfordshire</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/75/nominee/3385' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Virtual Open Day</Typography>
                        <Typography variant="h2">University of Bedfordshire - Open days</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8371/content/images/1670416090northwell-change-agent-community-virtual-networking-event-png1670416090.PNG" alt="Northwell Change Agent Community Virtual Networking Event" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675683731northwell-png1675683731.png" alt="Northwell Change Agent Community Virtual Networking Event" />
                                </Box>
                                <Typography variant="body1">Northwell Health</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/88/nominee/8371' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Use of Gamification</Typography>
                        <Typography variant="h2">Northwell Change Agent Community Virtual Networking Event</Typography>
                    </Box>
                </Box>
            </Grid>	
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/7791/content/images/1667888528isa-rsquo-s-automation-leadership-conference-alc-png1667888528.PNG" alt="ISA Automation & Leadership Conference" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675685241isa-png1675685241.png" alt="ISA Automation & Leadership Conference" />
                                </Box>
                                <Typography variant="body1">International Society of Automation	</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/92/nominee/7791' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Use of Mobile App</Typography>
                        <Typography variant="h2">ISA Automation & Leadership Conference</Typography>
                    </Box>
                </Box>
            </Grid>	
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/6980/content/images/1672398958lobby-png1672398958.PNG" alt="Harbor Wholesale Foods 2022" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675685341harbor-png1675685341.png" alt="Harbor Wholesale Foods 2022" />
                                </Box>
                                <Typography variant="body1">Harbor Foods Group Inc</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/87/nominee/6980' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best High Volume Event</Typography>
                        <Typography variant="h2">Harbor Wholesale Foods 2022</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>	
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1671095897national-autism-conference-2022-png1671095897.PNG" alt="National Autism Conference 2022" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675685439proof-png1675685439.png" alt="National Autism Conference 2022" />
                                </Box>
                                <Typography variant="body1">Proof Experiences Inc.</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/83/nominee/8757' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Agency Event</Typography>
                        <Typography variant="h2">National Autism Conference 2022</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/6980/content/images/1672398958lobby-png1672398958.PNG" alt="Harbor Wholesale Foods 2022" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675685341harbor-png1675685341.png" alt="Harbor Wholesale Foods 2022" />
                                </Box>
                                <Typography variant="body1">Harbor Wholesale Foods</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/77/nominee/6980' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Virtual Food Show</Typography>
                        <Typography variant="h2">Harbor Wholesale Foods 2022</Typography>
                    </Box>
                </Box>
            </Grid>            
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/4075/content/images/1671007344black-enterprise-diversity-equity-amp-inclusion-summit-png1671007344.PNG" alt="Black Enterprise Diversity, Equity & Inclusion Summit" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675686536black-enterprise-png1675686536.png" alt="Black Enterprise Diversity, Equity & Inclusion Summit" />
                                </Box>
                                <Typography variant="body1">Black Enterprise</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/91/nominee/4075' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Diversity and Inclusion Event</Typography>
                        <Typography variant="h2">Black Enterprise Diversity, Equity & Inclusion Summit</Typography>
                    </Box>
                </Box>
            </Grid>            
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/6726/content/images/1670841088nnlm-virtual-symposium-png1670841088.PNG" alt="NNLM Virtual Symposium" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675686833nih-png1675686833.png" alt="NNLM Virtual Symposium" />
                                </Box>
                                <Typography variant="body1">University of Maryland, Baltimore</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/93/nominee/6726' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Research Event</Typography>
                        <Typography variant="h2">NNLM Virtual Symposium</Typography>
                    </Box>
                </Box>
            </Grid>		
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/5666/content/images/1670600092west-yorkshire-and-nhs-recruitment-fair-png1670600092.PNG" alt="West Yorkshire Mental Health NHS Collaborative Recruitment Day" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675688024nhs-png1675688024.png" alt="West Yorkshire Mental Health NHS Collaborative Recruitment Day" />
                                </Box>
                                <Typography variant="body1">SWYT</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/74/nominee/5666' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Single-Employer Career Fair</Typography>
                        <Typography variant="h2">West Yorkshire Mental Health NHS Collaborative Recruitment Day</Typography>
                    </Box>
                </Box>
            </Grid>	
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8259/content/images/1672414186entrance-png1672414186.PNG" alt="Canon Robotics Whiz Expo 2022" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675688625canon-png1675688625.png" alt="Canon Robotics Whiz Expo 2022" />
                                </Box>
                                <Typography variant="body1">Canon U.S.A., Inc.</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/94/nominee/8259' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Emerging Tech Event</Typography>
                        <Typography variant="h2">Canon Robotics Whiz Expo 2022</Typography>
                    </Box>
                </Box>
            </Grid>		
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/7987/content/images/1673273204lobby-png1673273204.PNG" alt="BHM Fall 2022 Health Summit" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8980/content/images/1675700338bhm-blkhistorymnth-logo-1-png1675700338.png" alt="BHM Fall 2022 Health Summit" />
                                </Box>
                                <Typography variant="body1">Black Health Matters</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/97/nominee/7987' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Health, Wellness & Fitness Event</Typography>
                        <Typography variant="h2">BHM Fall 2022 Health Summit</Typography>
                    </Box>
                </Box>
            </Grid>	
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/5289/content/images/1672399337entrance-png1672399337.png" alt="Welcome to Cleveland Clinic" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675688868cleveland-png1675688868.png" alt="Welcome to Cleveland Clinic" />
                                </Box>
                                <Typography variant="body1">Cleveland Clinic</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/95/nominee/5289' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Virtual Onboarding Fair</Typography>
                        <Typography variant="h2">Welcome to Cleveland Clinic</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8178/content/images/1672415099entrance-png1672415099.PNG" alt="BeWitching Bash 2022" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675689040bewitching-png1675689040.png" alt="BeWitching Bash 2022" />
                                </Box>
                                <Typography variant="body1">Ritzy Parties</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/86/nominee/8178' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best 3D Virtual Designs</Typography>
                        <Typography variant="h2">BeWitching Bash 2022</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/7927/content/images/1670757053fijatec-virtual-png1670757053.PNG" alt="Fijatec Virtual" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675681587fijatec-png1675681587.png" alt="Fijatec Virtual" />
                                </Box>
                                <Typography variant="body1">Fijatec</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/72/nominee/7927' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Virtual Trade Show</Typography>
                        <Typography variant="h2">Fijatec Virtual</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8371/content/images/1670416090northwell-change-agent-community-virtual-networking-event-png1670416090.PNG" alt="Northwell Change Agent Community Virtual Networking Event" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675683731northwell-png1675683731.png" alt="Northwell Change Agent Community Virtual Networking Event" />
                                </Box>
                                <Typography variant="body1">Northwell Health</Typography>
                            </Box>
                            <a href='/eventeer-awards-2023/84/nominee/8371' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Use of Networking Technology</Typography>
                        <Typography variant="h2">Northwell Change Agent Community Virtual Networking Event</Typography>
                    </Box>
                </Box>
            </Grid>	
        </Grid>
    )
}

export default AwardWinnerCard