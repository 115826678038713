import React from 'react'
import ScrollToTop from '../../../components/ScrollToTop'
import NavigationBar from '../../Header/Navbar'
import SignUpForm from './SignUpForm'


const OnBoardingSignUp = () => {
    return (
        <div className="application">
            <ScrollToTop />
            <NavigationBar />
            <SignUpForm />
        </div>
    )
}

export default OnBoardingSignUp