
import React from "react";



export default function LoaderCard() {

    return (
        <>
            <div className="card">
                <div className="shimmerBG media"></div>
                <div className="p32">
                    <div className="shimmerBG titleLine"></div>
                    <div className="shimmerBG titleLine end"></div>
                    <div className="shimmerBG contentLine mT24"></div>
                    <div className="shimmerBG contentLine"></div>
                    <div className="shimmerBG contentLine"></div>
                    <div className="shimmerBG contentLine"></div>
                    <div className="shimmerBG contentLine end"></div>
                </div>
            </div>


        </>
    );
};
