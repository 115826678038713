import { MuiThemeProvider, createMuiTheme} from "@material-ui/core/styles";

const BreakPoint = createMuiTheme({
  
  overrides: {

    breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1200,
          xl: 1920
        },
      },
  },
});

export default BreakPoint;