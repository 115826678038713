
import { Button, TableCell, TableRow, Menu, MenuItem } from '@material-ui/core';
import React from 'react'
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import { dashboardStyles } from '../../utils/hooks/useApplyStyles';
//import dummyImage from '../../assets/images/event-dummy.png'
import editIcon from '../../assets/images/edit-icon.svg'
import eyeIcon from '../../assets/images/icon-eye.svg'
import dotsIcon from '../../assets/images/menu-dots.svg'

const Listing = ({events,setPublishEventResponse,publishEventResponse}) => {

    const styles = dashboardStyles();
    const classes = styles();

    // const [dotsEl, setDotsEl] = useState(null);

    // const dotsHandler = (event) => {
    //     setDotsEl(event.currentTarget);
    // };
    // const dotsCloseHandler = () => {
    //     setDotsEl(null);
    // };

    const cookies = new Cookies();


    const handlePublish = (event_id, event_title, event_mode, event_type, publish) => {
        //setDotsEl(null);

        setPublishEventResponse({ ...publishEventResponse, status: "", msg: "", error: "", isLoading: true });

        var OnboardingCreateEvent = `${global.API.rootPath}${global.config.endpoints.onboarding_update_event}`;

        //var bearerToken = `${global.config.onboarding_token}`;
        
        //HEADERS
        var usersHeaders = new Headers();
        //usersHeaders.append("Authorization", `Bearer ${bearerToken}`);
        usersHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        //POST DATA
        var urlencoded = new URLSearchParams();        
        urlencoded.append("token", cookies.get('onBoardingToken'));
        urlencoded.append("event_id", event_id);
        urlencoded.append("event_title", event_title);
        urlencoded.append("event_mode", event_mode);
        urlencoded.append("event_type", event_type);
        
        if(publish === "publish")
            urlencoded.append("status", "1");
        else {
            urlencoded.append("status", "0");
        }

        var requestOptions = {
            method: 'POST',
            headers: usersHeaders,
            body: urlencoded,
            redirect: 'follow',
            //mode: 'no-cors'
        };

        fetch(OnboardingCreateEvent, requestOptions)
        .then(response => response.text())
        .then(result => {
            const data = JSON.parse(result);
            setPublishEventResponse({ ...publishEventResponse, status: data.status, msg: data.msg, isLoading: false });
            window.location.reload();
        })
        .catch(error => {
            setPublishEventResponse({ ...publishEventResponse, status: false, error: true, isLoading: false });
            console.log('error', error)
        });
        

    };

    const IsolatedMenu = props => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        return(
            <React.Fragment>
                <Button
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={event => setAnchorEl(event.currentTarget)}
                >
                    <img src={dotsIcon} alt="dots" />
                </Button>
                <Menu
                    elevation={0}
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    className={classes.dropdownStyles}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem
                        disabled={props && props.event && props.event.application && props.event.application.is_published_on_discover && props.event.application.is_published_on_discover === "1" ? true : false}
                        onClick={(e) => {
                            setAnchorEl(null);
                            handlePublish(
                                props && props.event && props.event.app_id ? props.event.app_id : "",
                                props && props.event && props.event.app_title ? props.event.app_title : "",
                                props && props.event && props.event.event_mode ? props.event.event_mode : "",
                                props && props.event && props.event.event_type ? props.event.event_type : "",
                                "publish"
                            )                            
                        }}
                    >Publish</MenuItem>
                    <MenuItem
                        disabled={props && props.event && props.event.application && props.event.application.is_published_on_discover && props.event.application.is_published_on_discover === "1" ? false : true}
                        onClick={(e) => {
                            setAnchorEl(null);
                            handlePublish(
                                props && props.event && props.event.app_id ? props.event.app_id : "",
                                props && props.event && props.event.app_title ? props.event.app_title : "",
                                props && props.event && props.event.event_mode ? props.event_mode : "",
                                props && props.event && props.event.event_type ? props.event.event_type : "",
                                "draft"
                            )                            
                        }}
                    >Draft</MenuItem>
                </Menu>      
            </React.Fragment>
        )
    }

   return (
    <>
        {events.map((event) =>
            <TableRow>
                <TableCell>
                    <div className={classes.eListingTitle}>
                        <span>{event.app_title}</span>
                    </div>
                </TableCell>
                <TableCell>{event.event_mode}</TableCell>
                <TableCell>{event.event_format}</TableCell>
                <TableCell>{event.start_date} - {event.end_date}</TableCell>
                <TableCell>
                    <div className={event.application && event.application.is_published_on_discover && event.application.is_published_on_discover === "1" ? "chip green" : "chip orange"}>
                        {event.application && event.application.is_published_on_discover && event.application.is_published_on_discover === "1" ? "Published" : "Draft"}
                    </div>
                </TableCell>
                <TableCell align="right">
                    <div className={classes.eListingAction}>
                        
                        {event.application && event.application.is_published_on_discover && event.application.is_published_on_discover === "1" &&
                            <div className={classes.eListingDropdown}>
                                <Link
                                    target="_blank"
                                    to={"/events/" + event.app_id + "/" + event.app_title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')} >              
                                    <Button aria-controls="view" aria-haspopup="true">
                                        <img src={eyeIcon} alt="view" />
                                    </Button>
                                </Link>
                            </div>
                        }
                        <div className={classes.eListingDropdown}>
                            <Link to={"/onboarding-edit-event/" + event.app_id} >
                                <Button aria-controls="edit" aria-haspopup="true">
                                    <img src={editIcon} alt="edit" />
                                </Button>
                            </Link>
                        </div>
                        <div className={classes.eListingDropdown}>
            
                            <IsolatedMenu event={event}/>
                            {/* <Menu
                                id={"menu-" + event && event.app_id}
                                anchorEl={dotsEl}
                                keepMounted
                                open={Boolean(dotsEl)}
                                onClose={dotsCloseHandler}
                                className={classes.dropdownStyles}
                            >
                                <MenuItem
                                    //disabled={event.application && event.application.is_published_on_discover && event.application.is_published_on_discover === "1" ? false : true}
                                    onClick={(e) => {
                                        handlePublish(
                                            event && event.app_id ? event.app_id : "",
                                            event && event.app_title ? event.app_title : "",
                                            event && event.event_mode ? event.event_mode : "",
                                            event && event.event_type ? event.event_type : "",
                                            "publish"
                                        )                            
                                    }}
                                >x {event && event.app_id}</MenuItem>
                                <MenuItem
                                    //disabled={event.application && event.application.is_published_on_discover && event.application.is_published_on_discover === "1" ? true : false}
                                    onClick={(e) => {
                                        handlePublish(
                                            event && event.app_id ? event.app_id : "",
                                            event && event.app_title ? event.app_title : "",
                                            event && event.event_mode ? event.event_mode : "",
                                            event && event.event_type ? event.event_type : "",
                                            "draft"
                                        )                            
                                    }}
                                >Draft {event && event.app_id}</MenuItem>
                            </Menu> */}
                        </div>
                    </div>
                </TableCell>
            </TableRow>
        )}
    </>
    )
}

export default Listing