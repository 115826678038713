import { Backdrop, Box, Button, Fade, Modal, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react'
import { modalStyles } from '../../../utils/hooks/useApplyStyles';
import NavigationBar from '../../Header/Navbar';
import DiscoverFooter from '../../Footer/Footer'
import BannerWinnerPage from '../../Banners/BannerWinnerPage2024';
import AwardWinners from './AwardWinners';
import OldWinners from './OldWinners';
const WinnerPage = () => {
    const submitVotesRef = useRef(null);
    const submitVotes = () => submitVotesRef.current.scrollIntoView(true);


    const styles3 = modalStyles();
    const classes = { ...styles3() }
    return (
        <div className="application">
            <NavigationBar submitVotes={submitVotes} />
            <BannerWinnerPage />
            <AwardWinners />
            <OldWinners />
            <DiscoverFooter />
        </div>
    )
}

export default WinnerPage