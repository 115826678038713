import { Box, Container, Grid } from '@material-ui/core'
import React from 'react'
import { awardWinnerCardStyles } from '../../../utils/hooks/useApplyStyles';
import AwardWinnerCard from './AwardWinnerCard'

const AwardWinners = () => {
    const styles = awardWinnerCardStyles();
    const classes = styles();
    return (
        <Box className={classes.awwinnerMai}>
            <Container maxWidth="lg">
                    <AwardWinnerCard />
            </Container>
        </Box>
    )
}

export default AwardWinners