import React from 'react';
import { Link } from "react-router-dom";
import { Grid, Card, Container, Typography, Box, CardMedia, CardActionArea } from '@material-ui/core';
import { cardsStyles, lookingUpStyles } from "../../../utils/hooks/useApplyStyles";
import 'react-multi-carousel/lib/styles.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import exploreProducts from '../../../assets/popular-tags-home/product-resized-mini.png';
// import networkPeople from '../../../assets/popular-tags-home/network-resized-mini.png';
// import topicsInterest from '../../../assets/popular-tags-home/topics-resized-mini.png';
// import jobs from '../../../assets/popular-tags-home/jobs-resized-1-mini.png';
// import research from '../../../assets/popular-tags-home/university-resized-mini.png';
import business from '../../../assets/popular-tags-home/Business Enthusiasts Looking For Connections.png';
import professionals from '../../../assets/popular-tags-home/Professionals Searching For Job Opportunities.png';
import students from '../../../assets/popular-tags-home/Students Searching For Educational Opportunities.png';
import national from '../../../assets/popular-tags-home/National and International Health Awareness Events.png';
import tech from '../../../assets/popular-tags-home/Events for Tech Enthusiasts.png';
import brands from '../../../assets/popular-tags-home/Brands-Searching-For-Visibility-And-Awareness.png';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 3000, min: 1280 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 1279, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1023, min: 768 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    draggable: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    initialSlide: 0,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
    responsive: [
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1.4,
                slidesToScroll: 1.4
            }
        }
    ]
};

export default function PopularTags() {

    const styles1 = cardsStyles();
    const styles2 = lookingUpStyles();
    const classes = { ...styles1(), ...styles2() }

    return (
        <>
            <Box className={classes.lookingUp}>
                <Container maxWidth="lg">
                    <Box className={classes.themeHeader} mb={3} textAlign="center">
                        <Typography variant="h2" className={classes.viewmoreTitle}>
                            Find Events That Match Your Need
                        </Typography>
                        <Typography variant="body1">Browse listings for events that help you learn, network & grow.</Typography>
                    </Box>
                    <Grid className={classes.homeCardWrap}>
                        <Slider {...settings} className="eventfindCarousel">
                            <Card className={classes.popularTagSingle}>
                                <CardActionArea className={classes.popupTagsCard}>
                                    <Link to={"/marketplace/?&event-status=all&formats=Conference,Summit,Trade+Show,Product+Launch"} class="popular-tag" id="poupular-tag-business-enthusiasts" title={"Business Enthusiasts Looking For Connections"} className={classes.popularTagsSlide}>
                                        <CardMedia
                                            component="img"
                                            alt="Business Enthusiasts Looking For Connections"
                                            image={business}
                                        />
                                        <Typography gutterBottom variant="body1" component="span">
                                            Business Enthusiasts Looking For Connections
                                        </Typography>
                                    </Link>
                                </CardActionArea>
                            </Card>
                            <Card className={classes.popularTagSingle}>
                                <CardActionArea className={classes.popupTagsCard}>
                                    <Link to={"/marketplace/?&formats=Multi-Employer+Job+Fair,Single-Employer+Career+Fair"} id="poupular-tag-looking-for-job" title={"Professionals Searching For Job Opportunities"} className={classes.popularTagsSlide}>
                                        <CardMedia
                                            component="img"
                                            alt="Professionals Searching For Job Opportunities"
                                            image={professionals}
                                        />
                                        <Typography gutterBottom variant="body1" component="span">
                                            Professionals Searching For Job Opportunities
                                        </Typography>
                                    </Link>
                                </CardActionArea>
                            </Card>
                            <Card className={classes.popularTagSingle}>
                                <CardActionArea className={classes.popupTagsCard}>
                                    <Link to={"/marketplace/?&formats=Education+Fair,Open+Day"} id="poupular-tag-educational-opportunities" title={"Students Searching For Educational Opportunities"} className={classes.popularTagsSlide}>
                                        <CardMedia
                                            component="img"
                                            alt="Students Searching For Educational Opportunities"
                                            image={students}
                                        />
                                        <Typography gutterBottom variant="body1" component="span">
                                            Students Searching For Educational Opportunities
                                        </Typography>
                                    </Link>
                                </CardActionArea>
                            </Card>
                            <Card className={classes.popularTagSingle}>
                                <CardActionArea className={classes.popupTagsCard}>
                                    <Link to={"/marketplace/?&event-status=all&topics=Health+and+Wellness"} id="poupular-tag-health-awareness" title={"National and International Health Awareness Events"} className={classes.popularTagsSlide}>
                                        <CardMedia
                                            component="img"
                                            alt="National and International Health Awareness Events"
                                            image={national}
                                        />
                                        <Typography gutterBottom variant="body1" component="span">
                                            National and International Health Awareness Events
                                        </Typography>
                                    </Link>
                                </CardActionArea>
                            </Card>


                            <Card className={classes.popularTagSingle}>
                                <CardActionArea className={classes.popupTagsCard}>
                                    <Link to={"/marketplace/?&event-status=all&topics=Science+and+Technology"} id="events-for-tech-enthusiasts" title={"Events for Tech Enthusiasts"} className={classes.popularTagsSlide}>
                                        <CardMedia
                                            component="img"
                                            alt="Events for Tech Enthusiasts"
                                            image={tech}
                                        />
                                        <Typography gutterBottom variant="body1" component="span">
                                            Events for Tech Enthusiasts
                                        </Typography>
                                    </Link>
                                </CardActionArea>
                            </Card>
                            <Card className={classes.popularTagSingle}>
                                <CardActionArea className={classes.popupTagsCard}>
                                    <Link to={"/marketplace/?&event-status=all&formats=Conference,Summit,Trade+Show,Open+Day,Exhibition+Fair,Product+Launch"} id="poupular-tag-brands-searching" title={"Brands Searching For Visibility And Awareness"} className={classes.popularTagsSlide}>
                                        <CardMedia
                                            component="img"
                                            alt="Brands Searching For Visibility And Awareness"
                                            image={brands}
                                        />
                                        <Typography gutterBottom variant="body1" component="span">
                                            Brands Searching For Visibility And Awareness
                                        </Typography>
                                    </Link>
                                </CardActionArea>
                            </Card>

                        </Slider>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};