import axios from "axios";
import React, { useEffect, useState } from "react";
//import {Helmet} from "react-helmet";
import { Container, Box } from '@material-ui/core'
import { cardsStyles } from "../../utils/hooks/useApplyStyles";
import CollectionBanner from '../Banners/CollectionsBanner'
import NavigationBar from '../Header/Navbar'
import CollectionCardSection from './CollectionSections/CollectionCardSection'
import ScrollToTop from '../../components/ScrollToTop';
import DiscoverFooter from '../Footer/Footer'

export default function Collection() {

  window.dataLayer.push({
    event: 'pageview',
    page: {
      url: window.location.href,
      title: "All Collections"
    }
  });

  const GET_MORECOLLECTIONS_API = `${global.API.rootPath}${global.config.endpoints.collections_endpoint}?access_key=${global.config.access_key}&is_eventeer_award=0&limit=50`;

//   if (collection &&
//     collection.data &&
//     collection.data.total_count &&
//     collection.data.total_count !== 0 &&
//     collection.data.data[0] &&
//     collection.data.data[0].name) {
//       if(collection.data.data[0].status &&
//           (collection.data.data[0].status === "0" || !collection.data.data[0].event_id)) {
//             window.location = "/";
//       } else {
//         let fetched_slug = collection.data.data[0].slug ? collection.data.data[0].slug : collection.data.data[0].name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
//         if (collection_slug !== fetched_slug) {
//           window.location = fetched_slug;
//         }
//       }
//   }

  const [moreCollection, setMoreCollection] = useState({
    data: null,
    isLoading: false,
    error: "",
  });
  useEffect(() => {
    setMoreCollection({ ...moreCollection, isLoading: true });
    axios.get(GET_MORECOLLECTIONS_API).then((response) => {
      setMoreCollection({ ...moreCollection, data: response.data, isLoading: false });
    }).catch((err) => setMoreCollection({ ...moreCollection, error: err, isLoading: false }));
  }, []);
  const styles = cardsStyles();
  const classes = styles();
  return (
    <>
      <div className="application">
        <ScrollToTop />
        <NavigationBar />
        <CollectionBanner/>
        <Box variant="div" my={5} pt={5}>
          <Container maxWidth="lg">
            <CollectionCardSection moreCollection={moreCollection} />
          </Container>
        </Box>
        
        <DiscoverFooter />
      </div>
    </>
  )
};