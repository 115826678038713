import React from 'react';
import {Grid,Card} from '@material-ui/core';
import {cardsStyles} from "../../../utils/hooks/useApplyStyles";
import CardContent from './CardContent'


export default function BoxCards(props) {

    const styles = cardsStyles();
    const classes = styles();

    const { events } = props;

    return (
    <>
    <Grid container spacing={3}>
        {events.data.map((event) => {
            return (
            <>
                {/* Card will be in loop in order to generate multiple cards */}
                <Grid item lg={4} mg={6} sm={12} xs={12}>
                <Card event_id={event.id}  className={classes.card}>
                    <CardContent event_data={event}/>
                </Card>    
                </Grid>          
            </>
            );
        })}
    </Grid>
    </>
    );
};