import React from 'react';
import cx from 'clsx';
import { Box, Container, Grid, RadioGroup, Typography, Radio, Button, InputBase, TextField, Checkbox } from '@material-ui/core';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import { useBorderedInputBaseStyles } from '@mui-treasury/styles/inputBase/bordered';
import { forgotPassStyles } from '../../utils/hooks/useApplyStyles'


export default function CreateAccountCard() {
    const styles3 = useBorderedInputBaseStyles();
    const styles1 = useFadedShadowStyles();
    const styles2 = forgotPassStyles();
    const classes = { ...styles1, ...styles3, ...styles2() }

    return (
        <Container maxWidth="lg">
            <Card className={cx(classes.cardWide, styles1.root)}>
                <CardContent>
                    <Typography className={classes.heading}>Create an account</Typography>

                    <form noValidate autoComplete="off">
                        <InputBase
                            classes={classes}
                            className={classes.filterSearchBox}
                            placeholder={'name@example.com'}
                            style={{ marginBottom: '1.5rem' }}
                            type='email'
                        />
                        <InputBase
                            classes={classes}
                            className={classes.filterSearchBox}
                            placeholder={'First Name'}
                            style={{ marginBottom: '1.5rem' }}
                            type='text'
                        />

                        <InputBase
                            classes={classes}
                            className={classes.filterSearchBox}
                            placeholder={'Last Name'}
                            style={{ marginBottom: '1.5rem' }}
                            type='text'
                        />

                        <InputBase
                            classes={classes}
                            className={classes.filterSearchBox}
                            placeholder={'Set Password'}
                            style={{ marginBottom: '1.5rem' }}
                            type='password'
                        />

                        <InputBase
                            classes={classes}
                            className={classes.filterSearchBox}
                            placeholder={'Confirm Password'}
                            style={{ marginBottom: '1.5rem' }}
                            type='password'
                        />
                        <FormControlLabel control={<Checkbox />} label="I accept the vFairs Discover Terms Of Service , Community Guidelines and have read the Privacy Policy." />

                    </form>
                    <Button variant="containedPrimary" color="primary" style={{ width: '100%', marginBottom: '1rem' ,  marginTop: '1.5rem'}}>
                    create Account
                    </Button>

                </CardContent>




            </Card>

        </Container>

    );
};

