import React, { useState,useEffect } from 'react'
import axios from "axios";
import { dashboardStyles } from '../../utils/hooks/useApplyStyles';
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useParams } from "react-router-dom";
import CreateEImg from '../../assets/images/create-event.svg'
import CompleteImg from '../../assets/images/success.png'
import { Box, Button, Fade, Grid, Modal, Typography,LinearProgress } from '@material-ui/core';
import EditEventInfo from './EditEventInfo';
import AboutEvent from './AboutEvent';
import Organisers from './Organisers';
//import ExhibitorsAndSponsors from './ExhibitorsAndSponsors';
//import Webinar from './Webinar';
import Backdrop from '@material-ui/core/Backdrop';

const EditAnEvent = () => {
    const styles = dashboardStyles();
    const classes = styles();

    const { event_id } = useParams();

    const GET_EVENT_API = `${global.API.rootPath}${global.config.endpoints.onboarding_get_event}?&event_id=${event_id}&get_event_cat=true&get_event_tag=true`;

    const [getEvent, setGetEvent] = useState({
        data: null,
        isLoading: false,
        error: "",
    });
    
    const [event, setEvent] = useState({
        title:  "",        
        startDate: "",
        endDate: "",
        time: "",
        timeZone: "",
        mode: "",
        location: "",
        url: "",
        categories: "",
        tags: "",
        description : "",
        type: "",
        eventHave: "",
        coverImage: "",
        galleryImages: "",
        priceType: "",
        price: "",
        sponsorship: "",
        organiserImage: "",
        organiserName: "",
        organiserURL: "",
        organiserDesc: "",
        publishStatus: "",
    });

    useEffect(() => {
        setGetEvent({ ...getEvent, isLoading: true });
        axios.get(GET_EVENT_API).then((response) => {
          setGetEvent({ ...getEvent, data: response.data, isLoading: false });
        }).catch((err) => setGetEvent({ ...getEvent, error: err, isLoading: false }));
    }, []);

    const getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = () => {
            const base64data = reader.result;   
            resolve(base64data);
          }
        });
      }
    
    const [coverImageBase64, setCoverImageBase64] = useState("");
    useEffect(() => {
        getBase64FromUrl(getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].cover_image).then((a) => setCoverImageBase64(a))
    }, [getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].cover_image]);

    const [organiserLogoBase64, setOrganiserLogoBase64] = useState("");
    useEffect(() => {
        getBase64FromUrl(getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].organiser_logo).then((b) => setOrganiserLogoBase64(b ))
    }, [getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].organiser_logo]);

    useEffect(() => {
        setEvent({ ...event,
            title: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].app_title ? getEvent.data.data[0].app_title : "",
            startDate: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].start_date ? getEvent.data.data[0].start_date : "",
            endDate: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].end_date ? getEvent.data.data[0].end_date : "",
            time: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].start_time ? getEvent.data.data[0].start_time : "",
            timeZone: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].time_zone ? getEvent.data.data[0].time_zone : "",
            mode: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].event_mode ? getEvent.data.data[0].event_mode : "",
            location: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].location ? getEvent.data.data[0].location : "",
            url: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].url ? getEvent.data.data[0].url : "",
            categories: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].eventCat && getEvent.data.data[0].eventCat[0] && getEvent.data.data[0].eventCat[0].category_id ? getEvent.data.data[0].eventCat[0].category_id : "",
            tags: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].eventTag && getEvent.data.data[0].eventTag.map(tag => tag.tag_id),
            description : getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].about_us ? getEvent.data.data[0].about_us : "",
            type: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].event_format ? getEvent.data.data[0].event_format : "",
            eventHave: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].include.map(include => include.name),
            coverImage: coverImageBase64 ? coverImageBase64 : "",
            //galleryImages: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].event_mode,
            priceType: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].price_type ? getEvent.data.data[0].price_type : "",
            price: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].price ? getEvent.data.data[0].price : "",
            sponsorship: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].open_for_sponsorship ? getEvent.data.data[0].open_for_sponsorship : "0",
            organiserImage: organiserLogoBase64 ? organiserLogoBase64 : "",
            organiserName: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].organiser ? getEvent.data.data[0].organiser : "",
            organiserURL: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].organiser_url ? getEvent.data.data[0].organiser_url : "",
            organiserDesc: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].organiser_description ? getEvent.data.data[0].organiser_description : "",
            publishStatus: getEvent && getEvent.data && getEvent.data.data && getEvent.data.data[0] && getEvent.data.data[0].application && getEvent.data.data[0].application.is_published_on_discover,
        });
    }, [getEvent,coverImageBase64,organiserLogoBase64]);

    const CAT_BASE_URL = `${global.API.rootPath}${global.config.endpoints.categories_endpoint}?access_key=${global.config.access_key}&page=1&limit=50&sort_by_created_at=asc&get_tags_data=true`;
    const TAGS_BASE_URL = `${global.API.rootPath}${global.config.endpoints.tags_endpoint}?access_key=${global.config.access_key}&page=1&limit=50&sort_by_created_at=asc&get_category_data=true`;
    
    const [allCats, setAllCats] = useState(null);
    const [allTags, setAllTags] = useState(null);
    const [searchTags, setSearchTags] = useState("");
    
    useEffect(() => {
        axios.get(CAT_BASE_URL).then((cats) => {
          setAllCats(cats.data)
        }).catch((err) => alert("Error cats"));
    }, []);
    

    useEffect(() => {
        if (!allCats) {
          return
        }
        let callApi = false;
        if (searchTags && searchTags.length > 2) callApi = true
        else if (!searchTags || searchTags.length == 0) callApi = true
    
        if (callApi) {
          let tag_cat= allCats.data && allCats.data.length > 0 ? allCats.data.filter(cat=> event.categories.includes(cat.name)).map(cat=>cat.id) : [];
          axios.get(TAGS_BASE_URL + `${searchTags ? "&search_by_tag_name=" + searchTags.replace(/[']/g, '') : ""}${tag_cat && tag_cat.length != 0 ? "&category_id=" + tag_cat.toString() : ""}`).then((get_tags) => {
            setAllTags(get_tags.data);
          }).catch((err) => alert("Error tags"));
        }
      }, [searchTags, event.categories, allCats]);
    
    const [error, setError] = useState("");

    const [publishEventResponse, setPublishEventResponse] = useState({
        status: "",
        msg: "",
        error: "",
        isLoading: false,
    });
    
    const [openPage, setOpenPage] = useState(1);
    const [popupOpen, setPopUpOpen] = useState(false);
    const [publish, setpublish] = useState("");

    const cookies = new Cookies();

    const handlePublish = (publish) => {
        
        setPublishEventResponse({ ...publishEventResponse, status: "", msg: "", error: "", isLoading: true });

        var OnboardingCreateEvent = `${global.API.rootPath}${global.config.endpoints.onboarding_update_event}`;

        //var bearerToken = `${global.config.onboarding_token}`;
        
        //HEADERS
        var usersHeaders = new Headers();
        //usersHeaders.append("Authorization", `Bearer ${bearerToken}`);
        usersHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        //POST DATA
        var urlencoded = new URLSearchParams();        
        urlencoded.append("token", cookies.get('onBoardingToken'));
        urlencoded.append("event_id", event_id);        
        urlencoded.append("event_title", event.title);
        urlencoded.append("event_start_date", event.startDate);
        urlencoded.append("event_end_date", event.endDate);
        urlencoded.append("event_start_time", event.time);
        urlencoded.append("time_zone", event.timeZone);
        urlencoded.append("event_mode", event.mode);
        urlencoded.append("location", event.location);
        urlencoded.append("event_url", event.url);
        urlencoded.append("category_id", event.categories);
        urlencoded.append("event_tags", event.tags);
        urlencoded.append("event_description", event.description);        
        urlencoded.append("event_type", event.type);
        urlencoded.append("event_have", event.eventHave);
        urlencoded.append("cover_image", event.coverImage);
        urlencoded.append("event_images", event.galleryImages);
        urlencoded.append("price_type", event.priceType); 
        urlencoded.append("price", event.price);
        urlencoded.append("open_for_sponsorship", event.sponsorship);
        urlencoded.append("organiser_logo", event.organiserImage);
        urlencoded.append("organiser_name", event.organiserName);
        urlencoded.append("organiser_url", event.organiserURL);
        urlencoded.append("organiser_description", event.organiserDesc);
        if(publish === "publish") {
            urlencoded.append("status", "1");
            setEvent({ ...event, publishStatus: "1"})
        }           
        else if (publish === "draft") {
            urlencoded.append("status", "0");
            setEvent({ ...event, publishStatus: "0"})
        } else {
            urlencoded.append("status", event.publishStatus);
        }

        var requestOptions = {
            method: 'POST',
            headers: usersHeaders,
            body: urlencoded,
            redirect: 'follow',
            //mode: 'no-cors'
        };

        fetch(OnboardingCreateEvent, requestOptions)
        .then(response => response.text())
        .then(result => {
            const data = JSON.parse(result);
            setPublishEventResponse({ ...publishEventResponse, status: data.status, msg: data.msg, isLoading: false });
        })
        .catch(error => {
            setPublishEventResponse({ ...publishEventResponse, status: false, error: true, isLoading: false });
            console.log('error', error)
        });
        
    };

    useEffect(() => {        
        if((publishEventResponse && publishEventResponse.status && publishEventResponse.msg === "Event Updated Successfully.")) {
            setPopUpOpen(true);
        }
        else if(publishEventResponse && publishEventResponse.status && publishEventResponse.msg === "Event Drafted Successfully.") {
            setPopUpOpen(true);
        }
    }, [publishEventResponse]);

    const closeSharePop = () => {
        setPublishEventResponse({ ...publishEventResponse, status: "", msg: "", error: "" });
        setPopUpOpen(false);
        //window.location.href = "/onboarding-user-dashboard";
    };


    return (
        <>
            <Box className={classes.ctEvent}>
                <Box className={classes.ctEventHead}>
                    <img src={CreateEImg} alt="CT Event" />
                    <Typography variant='h1'>Edit Event</Typography>
                    <div style={{ minHeight: 5, }}>
                        {(getEvent && getEvent.isLoading) || (publishEventResponse && publishEventResponse.isLoading) &&
                            <LinearProgress />
                        }
                    </div>
                </Box>
                <Grid container spacing={3}>
                    <Grid item md={5}>
                        <Box className={classes.ctEventIntro}>
                            <Box className={`${classes.ctEventList} ${openPage && openPage === 1 ? "current"  : " "}`} onClick={(e) => {setOpenPage(1)}}>
                                <Typography variant="span">1</Typography>
                                <Box>
                                    <Typography variant="h2">Event Info</Typography>
                                    <Typography variant="body1">Enter information about your event.</Typography>
                                </Box>
                            </Box>
                            <Box
                                className={`${classes.ctEventList} ${openPage && openPage === 2 ? "current"  : " "}`}
                                onClick={(e) => {                                    
                                    if(event && event.title) {
                                        setOpenPage(2)
                                    } else {
                                        setError(true);
                                    }
                                }}                                
                                >
                                <Typography variant="span">2</Typography>
                                <Box>
                                    <Typography variant="h2">About Event</Typography>
                                    <Typography variant="body1">Provide a detailed description of the purpose of your event, including any activities, and what attendees can expect to gain.</Typography>
                                </Box>
                            </Box>
                            <Box
                                className={`${classes.ctEventList} ${openPage && openPage === 3 ? "current"  : " "}`}
                                onClick={(e) => {
                                    if(event && event.title) {
                                        setOpenPage(3)
                                    } else {
                                        setError(true);
                                    }                                    
                                }}
                            >
                                <Typography variant="span">3</Typography>
                                <Box>
                                    <Typography variant="h2">Organiser Info</Typography>
                                    <Typography variant="body1">Enter an about section of the event host.</Typography>
                                </Box>
                            </Box>
                            {/* <Box className={`${classes.ctEventList} ${openPage && openPage === "exhibitors" ? "current"  : " "}`} onClick={(e) => {setOpenPage("exhibitors")}}>
                                <Typography variant="span">4</Typography>
                                <Box>
                                    <Typography variant="h2">Exhibitors & Sponsors</Typography>
                                    <Typography variant="body1">Add information about any exhibitors or sponsors for your event, including their names, logos, and descriptions.</Typography>
                                </Box>
                            </Box>
                            <Box className={`${classes.ctEventList} ${openPage && openPage === "webinars" ? "current"  : " "}`} onClick={(e) => {setOpenPage("webinars")}}>
                                <Typography variant="span">5</Typography>
                                <Box>
                                    <Typography variant="h2">Webinars & Speakers</Typography>
                                    <Typography variant="body1">Add details about any webinars or keynote speakers that will be featured at your event, including their names, topics, and bios.</Typography>
                                </Box>
                            </Box> */}
                        </Box>
                    </Grid>
                    <Grid item md={7}>
                        <form noValidate autoComplete="off">
                            {event && event.title && openPage && 
                                openPage === 2 ? <AboutEvent event={event} setEvent={setEvent}/> :
                                openPage === 3 ? <Organisers event={event} setEvent={setEvent} /> :
                                //openPage === "exhibitors" ? <ExhibitorsAndSponsors event={event} setEvent={setEvent} /> :
                               //openPage === "webinars" ? <Webinar event={event} setEvent={setEvent} /> : 
                                <EditEventInfo event={event} setEvent={setEvent} allCats={allCats} setAllCats={setAllCats} allTags={allTags} setAllTags={setAllTags} searchTags={searchTags} setSearchTags={setSearchTags} error={error} setError={setError}/>
                            } 
                        </form>                        
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={popupOpen}
                            onClose={closeSharePop}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={popupOpen}>
                                <Box component="div" className={classes.modalBox}>
                                    <Box className={classes.modalBoxContent}>
                                        <img src={CompleteImg} alt="modal" />
                                        <Typography variant='h2'>{publish && publish === "publish" ? "Successful!" : "Event Updated!"}</Typography>
                                        <Typography variant='body1'>{publish && publish === "publish" ? "Your event is published now successfully." : "Your event is updated successfully."}</Typography>
                                        <Link to="/onboarding-new-event">
                                            <Button variant='contained' color="primary">Add More Event</Button>
                                        </Link>
                                        {event && event.title && (event.publishStatus === "1" || publish === "publish") && 
                                            <Link to={"/events/" + event_id + "/" + event.title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')}>
                                                <Button variant='outlined' color="primary">View published Event</Button>
                                            </Link>
                                        }
                                        <Button color='primary' onClick={closeSharePop}>Close</Button>
                                    </Box>
                                </Box>
                            </Fade>
                        </Modal>
                    </Grid>
                </Grid>
            </Box>
            <div style={{ minHeight: 5, }}>
                {(getEvent && getEvent.isLoading) || (publishEventResponse && publishEventResponse.isLoading) &&
                    <LinearProgress />
                }
            </div>
            <Box className={classes.ctEventFooter}>
                <Box className={classes.ctEventFWrap}>                    
                    <Box className={classes.etEventSave}>
                        
                        {event && event.title && 
                            <>
                            {event && event.publishStatus === "1" && 
                                <Button variant="outlined" color="primary"
                                onClick={(e) => {
                                    handlePublish("draft")
                                }}
                                >draft</Button>
                            }                            
                            <Button variant="contained" color="primary"
                                onClick={(e) => {
                                    handlePublish()                                 
                                }}
                            >Update</Button>
                            {event && event.publishStatus === "0" && 
                                <Button variant="contained" color="primary"
                                    onClick={(e) => {
                                        handlePublish("publish")
                                        setpublish("publish");                               
                                    }}
                                >Publish</Button>
                            }
                            </>                                             
                        }                        
                    </Box>
                    <Box className={classes.etEventSave}>
                        {openPage && openPage > 1 && 
                            <Button
                                color='primary'
                                variant="outlined"
                                onClick={(e) => {setOpenPage(openPage - 1)}}                            
                            >previous step</Button>
                        }
                        {event && event.title && openPage && openPage < 3 && 
                            <Button
                                color='primary'
                                variant="contained"
                                onClick={(e) => {setOpenPage(openPage + 1)}}
                            >next step</Button>
                        }
                    </Box>

                </Box>
            </Box>
        </>
    )
}

export default EditAnEvent