import { createMuiTheme} from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import colors from '../Colors'
import BreakPoint from '../index'
// import avenirLTProBlack from '../Fonts'
// import AvenirLTPro from '../../../styles/fonts/AvenirLTPro-Book.woff2';
// import AvenirLTProBlack from '../../../styles/fonts/AvenirLTPro-Black.woff2';
import TTNormsProRegular from '../../../styles/fonts/TTNorms-Pro/TT_Norms_Pro_Regular.woff';
import TTNormsProBold from '../../../styles/fonts/TTNorms-Pro/TTNorms-Pro-Bold.woff';

const TTNorms_Pro_Regular = {
  fontFamily: 'TTNorms-Pro-Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
    local('TTNorms_Pro_Regular'),
    local('TTNorms-Pro-Regular'),
    url(${TTNormsProRegular}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const TTNorms_Pro_Bold = {
  fontFamily: 'TTNorms-Pro-Bold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('TTNorms_Pro_Bold'),
    local('TTNorms-Pro-Bold'),
    url(${TTNormsProBold}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

// const Avenir = {
//   fontFamily: 'AvenirLTPro-Book',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 400,
//   src: `
//     local('Avenir'),
//     local('AvenirLTPro-Book'),
//     url(${AvenirLTPro}) format('woff2')
//   `,
//   unicodeRange:
//     'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// };

// const AvenirBold = {
//   fontFamily: 'AvenirLTPro-Black',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 700,
//   src: `
//     local('Avenir'),
//     local('AvenirLTPro-Black'),
//     url(${AvenirLTProBlack}) format('woff2')
//   `,
//   unicodeRange:
//     'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// };


const theme = createMuiTheme({

  palette: {
    primary: {
      main: colors.vfairs.main,
      light: colors.vfairs.light,
      contrastText: colors.vfairs.contrastText,
      mainOrange: colors.vfairs.orange,
    },
    secondary: {
      main:  colors.vfairs.secondary,
    },
    success:{
      main: colors.success.main,
    },
    error:{
      main: colors.alert.main,
    },

  },
  typography: {
    useNextVariants: true,
    fontFamily: 'TTNorms-Pro-Regular',
},
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1920
    },
  },

  props: {
    MuiInputLabel: {
      shrink: false,
    },
    MuiCheckbox:{
      disableRipple:true,
    },
    MuiRadio:{
      disableRipple:true,
    },
    MuiDrawer:{
      disableBackdropTransition:true,
    },
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [TTNormsProRegular],
      },
    },
    MuiButton: {
      containedPrimary: {
        fontSize: [13],
        padding:'8px 20px',
        color: colors.vfairs.contrastText,
        background: 'linear-gradient(90deg, rgba(242,101,48,1) 0%, rgba(239,67,115,1) 100%)',
        borderRadius: 20,
        fontWeight:'bold',
        '&:hover': {
          opacity:'0.9',
         },
         '&:disabled':{
           color: '#fff !important'
         }
      },
      containedSecondary: {
        fontSize: [13],
        padding:'8px 20px',
        color: colors.vfairs.contrastTextDark,
        backgroundColor: colors.vfairs.secondary,
        // boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        borderRadius: 20,
        fontWeight:'bold'
      },
      // root:{
      //   textTransform:'capitalize',
      // },
      outlined: {
        fontSize: [13],
        padding:'8px 20px',
        borderRadius: 20,
        fontWeight: 'bold',
      },
      outlinedSecondary: {
        fontSize: [13],
        padding:'8px 20px',
        color: colors.vfairs.contrastTextDark,
        borderRadius: 20,
        background: 'transparent',
        border: '1px solid #CCD5DC',
        fontWeight:'bold'
      },
     
    },
    
    MuiRadio: {
      
      colorSecondary:{
        '&$checked':{
          color: colors.vfairs.main,
        }
      }
    },
    MuiChip:{
      outlined :{
        border:'1px solid',
        borderColor: colors.vfairs.main,
        color:  colors.vfairs.main,
        textTransform:'Capitalize',
        fontSize: [10],
        fontWeight:[600],
        letterSpacing:0.5,
      },
      outlinedSecondary:{
        border:'1px solid',
        borderColor: colors.vfairs.main,
        color:  colors.vfairs.main,
        textTransform:'Capitalize',
        fontSize: [10],
        fontWeight:[600],
        letterSpacing:0.5,
      },
      colorSecondary :{
        backgroundColor: colors.vfairs.white,
        color: colors.vfairs.main,
        textTransform:'Capitalize',
        fontSize: [10],
        fontWeight:[700],
        letterSpacing:0.5,
      },
      colorPrimary :{
        backgroundColor: colors.vfairs.main,
        textTransform:'Capitalize',
        fontSize: [10],
        fontWeight:[600],
        letterSpacing:0.5,
      },
      labelSmall:{
        paddingLeft:[12],
        paddingRight:[12],
      },
    },
    MuiAppBar:{
      colorPrimary:{
        // backgroundColor: colors.gray.light100, 
        backgroundColor: "#ffffff", 
      },
    },
    RoundInputBase:{
      focused:{
        boxShadow:0,
      },

    },
    MuiListItemIcon:{
      root:{
        minWidth: 38,
      },
    },
    TextInfoContent:{
      heading:{
        fontSize: [16],
        lineHeight: [1.5],
      },
    },
    MuiInputLabel:{
      animated:{
        fontSize: [12],
        color:'#000000',
        fontWeight:'normal',
      },

    },
   
    MuiStepIcon:{
      root:{
        fontSize: 28,
      },
      completed:{
        color:'#1D9D48 !important',
      },
      text:{
        fontSize: 12,
      },
    },
    CategoryMenu:{
      title:{
        fontWeight:'bold',
        color: colors.vfairs.main,
        fontFamily: 'TTNorms-Pro-Regular',
      },
      item:{
        lineHeight:1.5,
        fontFamily: 'TTNorms-Pro-Regular',
        color: '#3f4850',
        fontSize: 14,
        '&:hover':{
          color: colors.vfairs.main,
        },
       '& > a':{
          color: colors.vfairs.secondary,
       },
      },
    },
    MuiStepper: {
      root: {
        background:'transparent',
        padding:0,
      },
    },
    MuiPaper: {
      root: {
       boxShadow:'0 !important',
      },
    },
    MuiCard: {
      root: {
        // backgroundColor: colors.vfairs.light,
        // color: colors.vfairs.contrastText,
      },
    },

    MuiListItemAvatar: {
      root:{
        minWidth:40,
      },
    
  },
  MuiBottomNavigation:{
    root:{
      backgroundColor: colors.vfairs.main,
      color: colors.vfairs.contrastText,
    },
  },
  MuiBottomNavigationAction:{
    root:{
      color: colors.vfairs.contrastText,
      '&$selected': {
        color: colors.vfairs.contrastText,
      },
    },
  
  },

  MuiDrawer: {
    paper: {
      // backgroundColor: colors.vfairs.drawer,
      // color: colors.vfairs.contrastText,
    },
  },
  MuiDrawer:{
    paperAnchorDockedLeft:{
      borderRight: 0,
    },
},

  MuiTypography: {
    fontFamily: 'TTNorms-Pro-Bold',
    h1: {
      fontSize: [36],
       fontWeight: 'bold',
       fontFamily: 'TTNorms-Pro-Bold',
      },
    h2: {
      fontSize: [32],
       fontWeight: 'bold',
       fontFamily: 'TTNorms-Pro-Bold',
      },
    h3: {
      fontSize: [28],
       fontWeight: 'bold',
       fontFamily: 'TTNorms-Pro-Bold',
      },
    h4: {
      fontSize: [24],
       fontWeight: 'bold',
       fontFamily: 'TTNorms-Pro-Bold',
       [BreakPoint.breakpoints.down('sm')] :{
         fontSize: 20
       }
      },
    h5: {
     fontSize: [20],
      fontWeight: 'bold',
      fontFamily: 'TTNorms-Pro-Bold',
     },
      h6: {
      fontSize: [16],
      fontWeight: 'bold',
      fontFamily: 'TTNorms-Pro-Bold',
      },
   body1: {
     fontSize: [14]
   },
    body2: {
     fontSize: [12]
   },
  
 },
 MuiMenuItem: {
  root: {
   minHeight:'auto',
    },
  },

  ReadyEmailSubscribe:{
    input:{
      padding:'1rem 1rem',
      fontSize:14,
      '&:hover': {
        boxShadow: 'none !important',
      },
      '&:focus': {
        boxShadow: 'none !important',
      },
    },
    
  },
  MuiBreadcrumbs:{
    li:{
      fontSize: 18,
      color: '#505B63'
    },
    separator: {
      fontSize: 26
    }
  },
  MuiInputBase: {
    input:{
      '&:placeholder':{
        color: '#6D7A84',
        opacity: 1
      },
      "&::-webkit-input-placeholder": {
        color: "#6D7A84 !important",
        opacity: 1
      },
      '&::-webkit-input-placeholder': { /* WebKit browsers */
        color: '#6D7A84',
        opacity: 1
      },
      
      '&::-moz-placeholder': { /* Mozilla Firefox 19+ */
        color: '#6D7A84',
        opacity: 1
      },
      
      '&:-ms-input-placeholder': { /* Internet Explorer 10+ */
       color: '#6D7A84',
       opacity: 1
      } 
    }
  },



  },
 

});

export default theme;