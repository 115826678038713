import { Box, Container, Typography } from '@material-ui/core';
import React from 'react'
import { winnerBannerStyles } from '../../utils/hooks/useApplyStyles';

import winnerLogo from '../../assets/images/winner-logo.svg'
import bCircle from '../../assets/images/circle-arrow-bottom.svg'

const BannerWinnerPage = () => {
    const styles = winnerBannerStyles();
    const classes = styles();
    return (
        <Box className={classes.banner}>
            <Container maxWidth="lg">
                <Box className={classes.bannerContent}>
                    <img src={winnerLogo} alt="winner logo" />
                    <Typography variant="h1">Honoring Creativity, Innovation & Success in Events</Typography>
                    <Typography variant="p" component="p" style={{color: '#fff', fontSize: 20}}>
                        Congratulations to all winners of the Eventeer Awards 2023 hosted by vFairs!
                    </Typography>
                    <Typography variant="p" component="p" style={{color: '#fff', fontSize: 20}}>
                        This awards program recognized the best virtual, hybrid, and in-person events hosted in 2022 on the vFairs platform. Winners were announced in a live awards ceremony on February 7th, 2023. We would like to thank everyone who celebrated with us.
                    </Typography>
                    <Typography variant="p" component="p" style={{color: '#fff', fontSize: 20}}>
                        We can’t wait to see you next year!
                    </Typography>
                    {/* <a href='#'> <img src={bCircle} alt="See Winners" /></a> */}
                </Box>
            </Container>
        </Box>
    )
}

export default BannerWinnerPage