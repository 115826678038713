
import React from "react";



export default function LoaderBox() {

    return (
        <>
        <div className="loadlines">
            <photo className="shine"></photo>
            {/* <photo className="shine"></photo> */}
            </div>
        </>

    );
};

