import { Box, Button, Container, Typography } from '@material-ui/core'
import React from 'react'
import { oldWinnerStyles } from '../../utils/hooks/useApplyStyles';

const OldWinners = () => {
    const styles = oldWinnerStyles();
    const classes = styles();
    // const winnersClicked = () => {
    //     const url = 'https://eventeerawards.vfairs.com/winners-2022/';
    //     window.open(url, '_blank');
    // }
    return (
        <Container maxWidth="lg">
            <Box className={classes.lWinner}>
                <Box className={classes.lWinnerText}>
                    <Typography variant="h2">
                    Meet the Winners of Eventeer Awards 2023
                    </Typography>
                    <Typography variant="body1">
                    2022 was an amazing year for the event organizers as they thrived with new event trends and futuristic ideas. Meet the winners of Eventeer Awards 2023 who aced a tough competition with hundreds of events among 25 different categories.
                    </Typography>
                    {/* <Button
                        variant="containedPrimary"
                        color="primary"
                        onClick={winnersClicked}
                    >
                        See 2022 Winners
                    </Button> */}
                </Box>
                <Box className={classes.awLogoRow}>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675688485hayleys-png1675688485.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675680147university-california-png1675680147.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675680680tmobile-png1675680680.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675680972nih-png1675680972.png" alt="logo" />
                    </Box>  
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675681113go-west-png1675681113.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675681380congreso-internacional-png1675681380.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675681587fijatec-png1675681587.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675683616cmcc-png1675683616.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675683731northwell-png1675683731.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675685113university-bedforshire-png1675685113.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675685341harbor-png1675685341.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675685241isa-png1675685241.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675685439proof-png1675685439.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675686083nrn-png1675686083.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675686536black-enterprise-png1675686536.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675686668pldt-png1675686668.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675686833nih-png1675686833.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675688024nhs-png1675688024.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675688625canon-png1675688625.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675688868cleveland-png1675688868.png" alt="logo" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675689040bewitching-png1675689040.png" alt="logo" />
                    </Box>
                    
                    
                   
                </Box>
            </Box>
        </Container>
    )
}

export default OldWinners