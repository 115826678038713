const colors= {
  
  gray:{
      main:'#e5e5e5',
      light: '#ff7961',
      light100: '#f1f3f5',
      dark: '#8f8f8f',
      contrastText: '#ffffff',
  },
  
  lightmode:{
      main:'#FFFFFF',
      light:'rgba(255, 255, 255, 0.9)',
      drawer:'rgba(255, 255, 255, 0.95)',
      // contrastText: 'rgba(0, 0, 0, 0.96)',
      contrastText: '#474748',
  },
  
  success:{
      main:'#1D9D48',
      dark:'#388e3c',
      light:'#81c784',  
      contrastText: '#ffffff',      
  },
  alert:{
      main:'#EF4876',
      contrastText: '#ffffff',     
  },
 
  vfairs:{
    main:'#F96634',
    secondary:'#EF4876',
    contrastText: '#ffffff',     
    contrastTextDark: '#000000', 
    lightPink:'#fef7f5',
    white: '#ffffff',     
    orange: '#FF762C'
  },
};
export default  colors;