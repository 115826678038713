import React from 'react';
import { Grid, Card, Container, Typography, Box } from '@material-ui/core';
import { cardsStyles, lookingUpStyles } from "../../../utils/hooks/useApplyStyles";
import CardContent from '../../Marketplace/Cards/CardContent'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import 'react-multi-carousel/lib/styles.css';
import { useBorderedInputBaseStyles } from '@mui-treasury/styles/inputBase/bordered';
//import Search from '@material-ui/icons/Search';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LoaderCard from "../../Loader/LoaderCard";
//import SearchIcon from '@material-ui/icons/Search';


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 3000, min: 1280 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 1279, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1023, min: 768 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};


var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    draggable: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
    useTransform: false,

    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                swipeToSlide: true,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                swipeToSlide: true,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                swipeToSlide: true,

            }
        }
    ]
};
export default function HybridEventCards({ events }) {
    const styles1 = cardsStyles();
    const styles2 = useBorderedInputBaseStyles();
    const styles3 = lookingUpStyles();
    const classes = { ...styles1(), ...styles2, ...styles3() }

    return (
        <>
            <Box component="div" className={`${classes.homeSection} ${classes.hybridMain}`} style={{ overflow: 'hidden' }}>
                <Container maxWidth="lg" className={classes.sidedContainer}>
                    <Box className={`${classes.hybridbg} ${classes.homesectionGap}`}>
                        <Box className={`${classes.themeHeader} ${classes.themeHeaderWhite}`} mb={3} textAlign="left">
                            <Typography variant="h2" className={classes.viewmoreTitle}>
                                free events
                            </Typography>
                            <Typography variant="body1">Discover enriching free events, and join the experience your way.</Typography>
                            {/* <Box component="div" mt={2} className={`${classes.navBox} ${classes.themeSearchColor}`} >
                                <InputBase
                                    className={classes.homeCardSearchBox}
                                    placeholder={'Choose Event Location'}
                                    onChange={(l) => setEventsFilters({ ...eventsFilters, search_location: l.target.value.toLowerCase() })}
                                    startAdornment={<SearchIcon />}
                                />
                            </Box> */}
                        </Box>
                    
                        {events && events.data && events.data.current_page_count === 0 &&
                            <div>
                                <h4 style={{color: '#fff'}}>No events found for your search</h4>
                                <p style={{color: '#fff'}}>Unfortunately we can't match an event to your criteria. Try changing some filters to get more results.</p>
                            </div>
                        }
                        {events && events.isLoading ?
                            <Grid container spacing={3} className={classes.homeCardWrap}>
                                <Grid item lg={3} sm={6} xs={12} >
                                    <LoaderCard />
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12} >
                                    <LoaderCard />
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12} >
                                    <LoaderCard />
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12} >
                                    <LoaderCard />
                                </Grid>
                            </Grid>
                            :
                            <Grid className={classes.homeCardWrap}>
                                <Slider {...settings} className="leftArrowsSlick themeslickArrow">
                                    {events && events.data && events.data.data && events.data.data.map((event) => {
                                        return (
                                            <>
                                                <Card className={classes.homeSingles}>
                                                    <CardContent event_data={event} />
                                                </Card>
                                            </>
                                        );
                                    })}
                                </Slider>
                            </Grid>
                        }
                    </Box>
                </Container>
                <Container maxWidth="lg" style={{ position: 'relative' }}>
                    <Box component="div" m={1} className={`${classes.viewMoreBox} ${classes.hybridmore}`}>
                        <Link id="view-more-hybrid-events" to={"/marketplace/?&event-status=all&price-type=FREE"} >View More <ArrowForwardIcon /> </Link>
                    </Box>
                </Container>
            </Box>
        </>
    );
};
