import React, { useState, useEffect } from "react";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { accordianAgendaStyles, Accordion, AccordionSummary, AccordionDetails, TimeChip, eventDetailStyles } from "../../../utils/hooks/useApplyStyles";
import AgendaSpeakerStatic from "./AgendaSpeakerStatic";
import useIsInViewport from '../../../components/useIsInViewport';

export default function AgendaAccordian({ speakers, agendaRef, setPageJumps }) {
    const [expanded, setExpanded] = useState('panel-0');

    const agendaInViewport = useIsInViewport(agendaRef, '-50%');
    if (agendaInViewport && agendaInViewport === true) {
        setPageJumps("active-agenda");
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const styles = accordianAgendaStyles();
    const styles1 = eventDetailStyles();
    const classes = { ...styles(), ...styles1() }

    const [speakersLimit, setSpeakersLimit] = useState({});

    useEffect(() => {
        let limits = null;
        if (speakers && speakers.length > 0)
            speakers.forEach(speaker => speaker.webinar.forEach(webinar => limits = { ...limits, [webinar.id]: 4 }))
        setSpeakersLimit(limits);
    }, [speakers])

    return (
        <div ref={agendaRef} className={`${classes.main} ${classes.edsectionGap}`}>
            <Typography variant="h3" component="h2" gutterBottom className={`${classes.edetailTitle} ${classes.edetailTitlembtt}`}>
                Agenda
            </Typography>
            <Box mb={3}>

                {speakers && speakers.map((speakersgroup, i) =>
                    speakersgroup && speakersgroup.agenda && speakersgroup.agenda != 'null'
                        ?
                        <Accordion square expanded={expanded === 'panel-' + i} onChange={handleChange('panel-' + i)}>
                            <AccordionSummary aria-controls={'panel-' + i + '-content'} id={'panel-' + i + '-header'} expandIcon={<ExpandMoreIcon />} >
                                <Typography>{speakersgroup.agenda}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {speakersgroup && speakersgroup.webinar.map(webinar =>
                                        <>
                                            <Grid Item md={2} xs={12} >
                                                <TimeChip
                                                    className={classes.agendaTime}
                                                    icon={<ScheduleIcon />}
                                                    label={webinar.start_time ? moment(webinar.start_time).format("MMM D, YYYY HH:mm") : ""}
                                                />

                                            </Grid>
                                            <Grid Item md={10} xs={12} className={classes.agendaBoxGrid}>
                                                <Box mb={3} className={classes.agendaInfoWrap}>
                                                    {webinar.title &&
                                                        <Typography variant="h4" component="p" gutterBottom className={classes.agendaInfoWrapTitle} >
                                                            <div dangerouslySetInnerHTML={{ __html: webinar.title.replace(/\<a /g, '<a rel="nofollow"') }}></div>
                                                        </Typography>
                                                    }
                                                    {webinar.description &&
                                                        <Typography variant="body1" gutterBottom>
                                                            {!!(webinar && webinar.description) ? <div dangerouslySetInnerHTML={{ __html: webinar.description.replace(/\<a /g, '<a rel="nofollow"') }}></div> : ""}
                                                        </Typography>
                                                    }
                                                    <Grid container spacing={1} >

                                                        {webinar.speakers_json && webinar.id
                                                            && JSON.parse(webinar.speakers_json) &&
                                                            JSON.parse(webinar.speakers_json).slice(0, speakersLimit[webinar.id]).map((speaker) =>
                                                                <>
                                                                    <Grid item md={6}>
                                                                        <Box mt={0}>
                                                                            <AgendaSpeakerStatic speaker={speaker} />
                                                                        </Box>
                                                                    </Grid>
                                                                </>
                                                            )

                                                        }
                                                        <Grid item xs={12} justifyContent="center" style={{ display: 'flex' }}>
                                                            {webinar.speakers_json && webinar.id && webinar.speakers_json && JSON.parse(webinar.speakers_json) && JSON.parse(webinar.speakers_json).length > speakersLimit[webinar.id] &&
                                                                <Button
                                                                    variant="text"
                                                                    color="primary"
                                                                    className={classes.viewAgendsSpeaker}
                                                                    onClick={() => setSpeakersLimit({ ...speakersLimit, [webinar.id]: speakersLimit[webinar.id] + 4 })}
                                                                    endIcon={<ExpandMoreIcon />}
                                                                >
                                                                    Show More Speakers
                                                                </Button>
                                                            }
                                                        </Grid>

                                                    </Grid>

                                                </Box>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        :
                        <Grid container>
                            {speakersgroup && speakersgroup.webinar.map(webinar =>
                                <>
                                    <Grid Item md={2} xs={12}>
                                        <TimeChip
                                            icon={<ScheduleIcon />}
                                            label={webinar.start_time ? moment(webinar.start_time).format("MMM D, YYYY HH:mm") : ""}
                                        />

                                    </Grid>
                                    <Grid Item md={10} xs={12}>
                                        <Box mb={3} className={classes.agendaInfoWrap}>
                                            {webinar.title &&
                                                <Typography variant="h4" component="p" gutterBottom className={classes.agendaInfoWrapTitle}>
                                                    <div dangerouslySetInnerHTML={{ __html: webinar.title.replace(/\<a /g, '<a rel="nofollow"') }}></div>
                                                </Typography>
                                            }
                                            {webinar.description &&
                                                <Typography variant="body1" gutterBottom>
                                                    {!!(webinar && webinar.description) ? <div dangerouslySetInnerHTML={{ __html: webinar.description.replace(/\<a /g, '<a rel="nofollow"') }}></div> : ""}
                                                </Typography>
                                            }
                                            <Grid container spacing={1} >

                                                {webinar.speakers_json && webinar.id
                                                    && JSON.parse(webinar.speakers_json) &&
                                                    JSON.parse(webinar.speakers_json).slice(0, speakersLimit[webinar.id]).map((speaker) =>
                                                        <>
                                                            <Grid item md={6}>
                                                                <Box mt={0}>
                                                                    <AgendaSpeakerStatic speaker={speaker} />
                                                                </Box>
                                                            </Grid>
                                                        </>
                                                    )

                                                }
                                                <Grid item xs={12} justifyContent="center" style={{ display: 'flex' }}>
                                                    {webinar.speakers_json && webinar.id && webinar.speakers_json && JSON.parse(webinar.speakers_json) && JSON.parse(webinar.speakers_json).length > speakersLimit[webinar.id] &&
                                                        <Button
                                                            variant="text"
                                                            color="primary"
                                                            className={classes.viewAgendsSpeaker}
                                                            onClick={() => setSpeakersLimit({ ...speakersLimit, [webinar.id]: speakersLimit[webinar.id] + 4 })}
                                                            endIcon={<ExpandMoreIcon />}
                                                        >
                                                            Show More Speakers
                                                        </Button>
                                                    }
                                                </Grid>

                                            </Grid>

                                        </Box>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                )}
            </Box>
        </div>
    );
}