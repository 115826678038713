import { AppBar, Button, Container, IconButton, Menu, MenuItem, Toolbar } from '@material-ui/core'
import React,{useEffect,useState} from 'react'
import axios from "axios";
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
// import Logo from '../../assets/logo.svg';
import Logo from '../assets/logo.svg';
import ProfileImg from '../assets/images/profile1.svg'
//import EventDetailSearchbar from '../containers/EventDetail/EventDetailSearchbar';
//import SearchbarHeader from '../containers/Header/Searchbar';
import { dashboardStyles, navbarStyles } from '../utils/hooks/useApplyStyles';
import MoreIcon from '@material-ui/icons/MoreVert';
//import Search from './Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ProfileImage from '../assets/images/profile-image.png'


const Header = () => {
    const styles = navbarStyles();
    const styles1 = dashboardStyles();
    const classes = { ...styles(), ...styles1() }

    const getProfileAPI = `${global.API.rootPath}${global.config.endpoints.onboarding_get_profile}`;

    const [profileData, setProfileData] = useState({
        data: null,
        isLoading: false,
        error: "",
    });

    const cookies = new Cookies();

    let token = cookies.get('onBoardingToken');

    useEffect(() => {
        setProfileData({ ...profileData, isLoading: true });
        axios.get(getProfileAPI + `?token=${token}`).then((profile) => {
            setProfileData({...profileData, data: profile.data});
        }).catch((err) => setProfileData({ ...profileData, error: err, isLoading: false }));;
    }, []);
   // const [anchorEl, setAnchorEl] = useState(null);
    const [anchorProfileEl, setAnchorProfileEl] = useState(null);

    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    const handleLogOut = () => {
        setAnchorProfileEl(null);
        cookies.remove('onBoardingToken');
        window.location.href = "/onboarding-login";
    };

    const handleProfileClick = (event) => {
        setAnchorProfileEl(event.currentTarget);
    };

    const handleProfileClose = () => {
        setAnchorProfileEl(null);
    };
    return (
        <AppBar position="static" className={classes.header}>
            <Container maxWidth="xl">
                <Toolbar className={classes.innerGutterHeader}>
                    <Link to="/">
                        <img
                            src={Logo}
                            alt="Discover by vFairs"
                            className={classes.logoImg}
                        />
                    </Link>
                    <div className={classes.grow} />
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        {/* <div className={classes.notification}>
                            <Button aria-controls="bell-dropdown" aria-haspopup="true" onClick={handleClick}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.0006 9.7041V9C19.0006 5.13401 15.8665 2 12.0006 2C8.13456 2 5.00055 5.13401 5.00055 9V9.7041C5.00055 10.5491 4.75043 11.3752 4.28172 12.0783L3.13311 13.8012C2.08398 15.3749 2.88491 17.5139 4.70962 18.0116C9.48307 19.3134 14.518 19.3134 19.2915 18.0116C21.1162 17.5139 21.9171 15.3749 20.868 13.8012L19.7194 12.0783C19.2507 11.3752 19.0006 10.5491 19.0006 9.7041Z" stroke="#1C274C" stroke-width="1.5" />
                                    <path d="M7.5 19C8.15503 20.7478 9.92246 22 12 22C14.0775 22 15.845 20.7478 16.5 19" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
                                    <path d="M12 6V10" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                            </Button>
                            <Menu
                                id="bell-dropdown"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                className={classes.dropdownStyles}
                            >
                                <MenuItem onClick={handleClose}>Notification A</MenuItem>
                                <MenuItem onClick={handleClose}>Notification B</MenuItem>
                                <MenuItem onClick={handleClose}>Notification C</MenuItem>
                            </Menu>
                        </div> */}
                        {profileData && profileData.data && profileData.data.status && !profileData.loading &&
                            <div className={classes.profile}>
                                <Button aria-controls="bell-dropdown" aria-haspopup="true" onClick={handleProfileClick} endIcon={<ExpandMoreIcon />}>
                                    <img src={profileData.data.data && profileData.data.data.user_image ? profileData.data.data.user_image
                                        : ProfileImg} alt="profile pic" />
                                    <span className={classes.profileUName}>{profileData.data.data && profileData.data.data.full_name ? profileData.data.data.full_name : "Your Name" }</span>
                                </Button>
                                <Menu
                                    id="bell-dropdown"
                                    anchorEl={anchorProfileEl}
                                    keepMounted
                                    open={Boolean(anchorProfileEl)}
                                    onClose={handleProfileClose}
                                    className={classes.dropdownStyles}

                                >
                                    <Link to="/onboarding-profile"><MenuItem onClick={handleProfileClose}>Profile</MenuItem></Link>
                                    <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                                </Menu>
                            </div>
                        }
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-haspopup="true"
                            color="primary"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Header