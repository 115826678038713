import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Typography, Box, Slide } from '@material-ui/core';
import { lookingUpStyles, topicCardSingleStyles } from "../../../utils/hooks/useApplyStyles";
import TopicCardSingle from '../../Marketplace/Cards/TopicCardSingle'
import LoaderBox from "../../Loader/LoaderBox";
import LazyLoad from 'react-lazyload';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EventFormat from "./EventFormat";

var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    draggable: false,
    slidesToShow: 5.5,
    slidesToScroll: 5.5,
    initialSlide: 0,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
    useTransform: false,
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                swipeToSlide: true,
            }
        },
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                swipeToSlide: true,
            }
        },
        {
            breakpoint: 1024,
            settings: 'unslick'
        }
    ]
};


export default function BrowseByTopicSec() {

    const styles1 = topicCardSingleStyles();
    const styles2 = lookingUpStyles();
    const classes = { ...styles1(), ...styles2() }
    const CAT_BASE_URL = `${global.API.rootPath}${global.config.endpoints.categories_endpoint}?access_key=${global.config.access_key}&page=1&limit=50&sort_by_created_at=asc`;

    const [allCats, setAllCats] = useState(null);

    const [post, setPost] = useState({
        data: null,
        isLoading: false,
        error: "",
    });

    useEffect(() => {
        axios.get(CAT_BASE_URL).then((cats) => {
            setAllCats(cats.data);
        }).catch((err) => setPost({ ...post, error: err, isLoading: false }));;
    }, []);
    return (
        <>
            <Box className={`${classes.topicSection} ${classes.homesectionGap}`}>
                <Container maxWidth="lg" className={classes.sidedContainer}>
                    <Box className={classes.eventTopics}>
                        <Box className={classes.eventTopicBox}>
                            <Typography variant="h2">
                                Discover More Events By Interest
                            </Typography>
                            <Typography variant="body1">
                                Find events near you that are of interest. Browse education and career opportunities, exchange information and learn from experts in various fields, discover new products and services, and much more. These world-class summits, exhibitions, and job fairs let you explore and connect to like-minded people worldwide and give you a chance of getting amazing personal and professional benefits.
                            </Typography>
                        </Box>
                        <Box className={classes.topicList}>
                            <Slider {...settings} className="leftArrowsSlick">
                                {allCats &&
                                    allCats.total_count &&
                                    allCats.total_count > 0 &&
                                    allCats.data.map(cat =>
                                        <TopicCardSingle cat={cat} />
                                    )
                                }
                            </Slider>
                        </Box>
                    </Box>
                    <EventFormat />
                </Container>
            </Box>
        </>
    );
};
