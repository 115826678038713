import React from "react";
import { Button, Typography, Chip, Avatar } from "@material-ui/core";
import { cardsStyles } from "../../../utils/hooks/useApplyStyles";

export default function SelectedFilters({postFilters, setPostFilters,eventstatus, setEventStatus }) {
    const styles = cardsStyles();
    const classes = styles();

    return (
        <div className={classes.filtersChipTop}>
            <Typography variant="h4" component="p">Selected Filters</Typography>
            <div className={classes.filterChips}>
                
                {postFilters.startDate &&
                    <Chip
                        avatar={<Avatar>From Date:</Avatar>}
                        variant="outlined"
                        size="small"
                        label={postFilters.startDate}
                        color="secondary"
                        onDelete={() => setPostFilters({
                            ...postFilters,
                            startDate: "",
                        })}
                    />
                }
                {postFilters.endDate &&
                    <Chip
                        avatar={<Avatar>To Date:</Avatar>}
                        variant="outlined"
                        size="small"
                        label={postFilters.endDate}
                        color="secondary"
                        onDelete={() => setPostFilters({
                            ...postFilters,
                            endDate: "",
                        })}
                    />
                }
                {postFilters.eventStatus &&
                    <Chip
                    avatar={<Avatar>Status:</Avatar>}
                    variant="outlined"
                    size="small"
                    label={postFilters.eventStatus}
                    color="secondary"
                    onDelete={() => {
                        setEventStatus({ ...eventstatus, eventStatusValue: "all"})
                        setPostFilters({ ...postFilters, eventStatus: "all" })
                    }}                    
                    />
                }
                {postFilters.OpenForSponsorship &&
                    <Chip
                    avatar={<Avatar>Open for Sponsorship:</Avatar>}
                    variant="outlined"
                    size="small"
                    label={postFilters.OpenForSponsorship === "1" ? "Yes" : ""}
                    color="secondary"
                    onDelete={() => {
                        setPostFilters({ ...postFilters, OpenForSponsorship: "" })
                    }}                    
                    />
                }
                {postFilters.featured &&
                    <Chip
                    avatar={<Avatar>Featured:</Avatar>}
                    variant="outlined"
                    size="small"
                    label={postFilters.featured === "1" ? "Yes" : ""}
                    color="secondary"
                    onDelete={() => {
                        setPostFilters({ ...postFilters, featured: "" })
                    }}                    
                    />
                }
                {postFilters.price &&
                    <Chip
                        avatar={<Avatar>Price:</Avatar>}
                        variant="outlined"
                        size="small"
                        label={postFilters.price}
                        color="secondary"
                        onDelete={() => setPostFilters({
                            ...postFilters,
                            price: "",
                            // from_price: "",
                            // to_price: "",
                        })}
                    />
                }
                {/* {postFilters.from_price &&
                    <Chip
                        avatar={<Avatar>From Price:</Avatar>}
                        variant="outlined"
                        size="small"
                        label={postFilters.from_price}
                        color="secondary"
                        onDelete={() => setPostFilters({
                            ...postFilters,
                            from_price: "",
                        })}
                    />
                }
                {postFilters.to_price &&
                    <Chip
                        avatar={<Avatar>To Price:</Avatar>}
                        variant="outlined"
                        size="small"
                        label={postFilters.to_price}
                        color="secondary"
                        onDelete={() => setPostFilters({
                            ...postFilters,
                            to_price: "",
                        })}
                    />
                } */}
                {postFilters.eventMode &&
                    <Chip
                        avatar={<Avatar>Event Mode:</Avatar>}
                        variant="outlined"
                        size="small"
                        label={postFilters.eventMode}
                        color="secondary"
                        onDelete={() => setPostFilters({
                            ...postFilters,
                            eventMode: "",
                        })}
                    />
                }
                
                {postFilters.categories && postFilters.categories.length !== 0 && postFilters.categories.map((cat) =>
                    <Chip
                        avatar={<Avatar>Topic:</Avatar>}
                        variant="outlined"
                        size="small"
                        label={cat.replace(/-/g, ' ').replace('and', '&')}
                        color="secondary"
                        onDelete={() => setPostFilters({
                            ...postFilters,
                            categories: postFilters.categories.filter(item => item !== cat),
                        })}
                    />
                )}
                {postFilters.tags && postFilters.tags.length !== 0  && postFilters.tags.map((tag) =>
                    <Chip
                        avatar={<Avatar>Tag:</Avatar>}
                        variant="outlined"
                        size="small"
                        label={tag}
                        color="secondary"
                        onDelete={() => setPostFilters({
                            ...postFilters,
                            tags: postFilters.tags.filter(item => item !== tag),
                        })}
                    />
                )}
                {postFilters.formats && postFilters.formats.length !== 0 && postFilters.formats.map((format) =>
                    <Chip
                        avatar={<Avatar>Format:</Avatar>}
                        variant="outlined"
                        size="small"
                        label={format}
                        color="secondary"
                        onDelete={() => setPostFilters({
                            ...postFilters,
                            formats: postFilters.formats.filter(item => item !== format)
                        })}
                    />
                )}
                <Button
                    className={classes.clearFilters}
                    onClick={() => {
                        setEventStatus({ ...eventstatus, eventStatusValue: "all"})
                        setPostFilters({
                            ...postFilters,
                            startDate: "",
                            endDate: "",
                            eventStatus: "all",
                            price: "",
                            from_price: "",
                            to_price: "",
                            eventMode: "",
                            tags: "",
                            categories: "",
                            formats: "",
                            OpenForSponsorship : "",
                            featured : "",   
                        })
                    }}
                    >
                    Clear All
                </Button>
            </div>
        </div>
    );
}