import React from 'react';
import cx from 'clsx';
import { Box, Container, Grid, RadioGroup, Typography, Radio, Button, InputBase, TextField, Checkbox, Divider } from '@material-ui/core';
import { Link } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import { useBorderedInputBaseStyles } from '@mui-treasury/styles/inputBase/bordered';
import { forgotPassStyles } from '../../utils/hooks/useApplyStyles'
import { SocialLink } from '@mui-treasury/components/socialLink'

export default function CreateAccountOneCard() {
    const styles3 = useBorderedInputBaseStyles();
    const styles1 = useFadedShadowStyles();
    const styles2 = forgotPassStyles();
    const classes = { ...styles1, ...styles3, ...styles2() }

    return (
        <Container maxWidth="lg">
            <Card className={cx(classes.cardWide, styles1.root)}>
                <CardContent>
                    <Typography className={classes.heading}>Create an account</Typography>

                    <form noValidate autoComplete="off">
                        <InputBase
                            classes={classes}
                            className={classes.filterSearchBox}
                            placeholder={'Email Address'}
                            style={{ marginBottom: '1.5rem' }}
                            type='email'
                        />
                        <Button variant="containedPrimary" color="primary" style={{ width: '100%', marginBottom: '1rem' }}>
                        continue
                    </Button>

                    </form>
                     <Divider/>
                     <Button variant="outlinedSecondary" color="secondary"  className={cx(classes.buttonLink, classes.buttonGoogle)}>
                     sign up with google
                    </Button>
                    <Button variant="outlinedSecondary" color="secondary"  className={cx(classes.buttonLink, classes.buttonfb)}>
                    sign up with facebook
                    </Button>
                    <Divider/>
                   
                   <Link to="/login/" color="primary" variant='h6'  className={classes.linkText} >Login to vFairs Discover</Link>



                </CardContent>




            </Card>

        </Container>

    );
};

