import React from 'react'
import { Container, Box, Grid } from '@material-ui/core'
import MoreCollectionCardContent from '../MoreCollectionCardContent'
import { cardsStyles } from "../../../utils/hooks/useApplyStyles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoaderCard from "../../Loader/LoaderCard";


export default function CollectionCardSection({ moreCollection }) {
    const styles = cardsStyles();
    const classes = styles();
    return (
        <>
            <Box variant="div" mb={5} className="moreCollection">
                <Container maxWidth="lg">
                    <Grid container spacing={1} className={classes.homeCardWrap}>
                        {moreCollection && moreCollection.isLoading ?
                            <>                        
                                <Grid item lg={3} sm={6} xs={12} >
                                    <LoaderCard />
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12} >
                                    <LoaderCard />
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12} >
                                    <LoaderCard />
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12} >
                                    <LoaderCard />
                                </Grid>
                            </>
                            : 
                            <>
                                {moreCollection &&
                                    moreCollection.data &&
                                    moreCollection.data.total_count &&
                                    moreCollection.data.total_count > 0 &&
                                    moreCollection.data.data &&
                                    moreCollection.data.data.map(collection =>
                                        collection.status &&
                                        collection.status === "1" &&
                                        collection.is_private &&
                                        collection.is_private === "0" &&
                                        collection.event_id &&
                                        <Grid item lg={3} sm={6} xs={12} >
                                            <MoreCollectionCardContent collection={collection} />
                                        </Grid>
                                    )
                                }
                            </>
                        }
                    </Grid>
                </Container>
            </Box>
        </>
    )
};