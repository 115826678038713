import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { cardsStyles } from '../../utils/hooks/useApplyStyles';
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';
import Placeholder2 from '../../assets/images/Event-Collections-2.png';

export default function MoreCollectionCardContent({ collection }) {
    const styles = cardsStyles();
    const classes = styles();
    return (
        <>

            <Box component="div" className={classes.moreCollectionCard}>
                <Link title={collection.name ? collection.name : ""} id={"collection-" + collection.id} to={"/collections/" + collection.id + "/" + collection.slug}>
                    <Box className={`collectionWrap ${classes.moreCollectionThumbnail}`}>
                        <LazyLoad height={400}>
                            <img alt={collection.name} src={collection.thumbnail ? collection.thumbnail : collection.cover_image ? collection.cover_image : Placeholder2} />
                        </LazyLoad>
                    </Box>
                    <Box className={classes.moreCollectionContent}>
                        <Typography variant='h4' component="p">
                            {collection.name}
                        </Typography>                        
                        <Typography variant='body1'>
                            {collection.tag_line}
                        </Typography>                        
                        <Typography variant='span'>
                            {collection.event_id ? collection.event_id.split(",").length + " Events" : "0 Events"}
                        </Typography>
                    </Box>
                </Link>
            </Box>

        </>
    );
};