import React, { useState,useEffect } from 'react'
import axios from "axios";
import { dashboardStyles } from '../../utils/hooks/useApplyStyles';
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import CreateEImg from '../../assets/images/create-event.svg'
import CompleteImg from '../../assets/images/success.png'
import { Box, Button, Fade, Grid, Modal, Typography } from '@material-ui/core';
import CreateEventInfo from './CreateEventInfo';
import AboutEvent from './AboutEvent';
import Organisers from './Organisers';
//import ExhibitorsAndSponsors from './ExhibitorsAndSponsors';
//import Webinar from './Webinar';
import Backdrop from '@material-ui/core/Backdrop';

const CreateAnEvent = () => {
    const styles = dashboardStyles();
    const classes = styles();

    const [openPage, setOpenPage] = useState(1);

    const cookies = new Cookies();

    const CAT_BASE_URL = `${global.API.rootPath}${global.config.endpoints.categories_endpoint}?access_key=${global.config.access_key}&page=1&limit=50&sort_by_created_at=asc&get_tags_data=true`;
    const TAGS_BASE_URL = `${global.API.rootPath}${global.config.endpoints.tags_endpoint}?access_key=${global.config.access_key}&page=1&limit=50&sort_by_created_at=asc&get_category_data=true`;

    const [allCats, setAllCats] = useState(null);
    const [allTags, setAllTags] = useState(null);
    const [searchTags, setSearchTags] = useState("");
    
    useEffect(() => {
        axios.get(CAT_BASE_URL).then((cats) => {
          setAllCats(cats.data)
        }).catch((err) => alert("Error cats"));
    }, []);

    const [event, setEvent] = useState({
        title: "",        
        startDate: "",
        endDate: "",
        time: "",
        timeZone: "",
        mode: "VIRTUAL",
        location: "",
        url: "",
        categories: "",
        tags: [],
        description : "",
        type: "Other",
        eventHave: [],
        coverImage: "",
        galleryImages: "",
        priceType: "",
        price: "",
        sponsorship: false,
        organiserImage: "",
        organiserName: "",
        organiserURL: "",
        organiserDesc: "",
        // perYear: "",
        // attendees: "",
    });

    useEffect(() => {
        if (!allCats) {
          return
        }
        let callApi = false;
        if (searchTags && searchTags.length > 2) callApi = true
        else if (!searchTags || searchTags.length == 0) callApi = true
    
        if (callApi) {
          let tag_cat= allCats.data && allCats.data.length > 0 ? allCats.data.filter(cat=> event.categories.includes(cat.name)).map(cat=>cat.id) : [];
          axios.get(TAGS_BASE_URL + `${searchTags ? "&search_by_tag_name=" + searchTags.replace(/[']/g, '') : ""}${tag_cat && tag_cat.length != 0 ? "&category_id=" + tag_cat.toString() : ""}`).then((get_tags) => {
            setAllTags(get_tags.data);
          }).catch((err) => alert("Error tags"));
        }
      }, [searchTags, event.categories, allCats]);
    
    const [error, setError] = useState("");

    const [publishEventResponse, setPublishEventResponse] = useState({
        status: "",
        msg: "",
        error: "",
        isLoading: false,
    });
    
    const handlePublish = (publish) => {
        
        setPublishEventResponse({ ...publishEventResponse, status: "", msg: "", error: "" });

        var OnboardingCreateEvent = `${global.API.rootPath}${global.config.endpoints.onboarding_create_event}`;

        //var bearerToken = `${global.config.onboarding_token}`;
        
        //HEADERS
        var usersHeaders = new Headers();
        //usersHeaders.append("Authorization", `Bearer ${bearerToken}`);
        usersHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        //POST DATA
        var urlencoded = new URLSearchParams();        
        urlencoded.append("token", cookies.get('onBoardingToken'));
        urlencoded.append("event_title", event.title);
        urlencoded.append("event_start_date", event.startDate);
        urlencoded.append("event_end_date", event.endDate);
        urlencoded.append("event_start_time", event.time);
        urlencoded.append("time_zone", event.timeZone);
        urlencoded.append("event_mode", event.mode);
        urlencoded.append("location", event.location);
        urlencoded.append("event_url", event.url);
        urlencoded.append("category_id", event.categories);
        urlencoded.append("event_tags", event.tags);
        urlencoded.append("event_description", event.description);        
        urlencoded.append("event_type", event.type);
        urlencoded.append("event_have", event.eventHave);
        urlencoded.append("cover_image", event.coverImage);
        urlencoded.append("event_images", event.galleryImages);
        urlencoded.append("price_type", event.priceType); 
        urlencoded.append("price", event.price);
        urlencoded.append("open_for_sponsorship", event.sponsorship);
        urlencoded.append("organiser_logo", event.organiserImage);
        urlencoded.append("organiser_name", event.organiserName);
        urlencoded.append("organiser_url", event.organiserURL);
        urlencoded.append("organiser_description", event.organiserDesc);
        if(publish === "publish")
            urlencoded.append("status", "1");
        else {
            urlencoded.append("status", "0");
        }

        var requestOptions = {
            method: 'POST',
            headers: usersHeaders,
            body: urlencoded,
            redirect: 'follow',
            //mode: 'no-cors'
        };

        fetch(OnboardingCreateEvent, requestOptions)
        .then(response => response.text())
        .then(result => {
            const data = JSON.parse(result);
            setPublishEventResponse({ ...publishEventResponse, status: data.status, msg: data.msg });            
        })
        .catch(error => {
            setPublishEventResponse({ ...publishEventResponse, status: false, error: true });
            console.log('error', error)
        });
        
    };

    const [popupOpen, setPopUpOpen] = useState(false);

    useEffect(() => {
        if((publishEventResponse && publishEventResponse.status && publishEventResponse.msg === "Event Created Successfully.")) {
            setPopUpOpen(true);
        }
        else if(publishEventResponse && publishEventResponse.status && publishEventResponse.msg === "Event Drafted Successfully.") {
            window.location.href = "/onboarding-user-dashboard";
        }
      }, [publishEventResponse]);

    const closeSharePop = () => {
        setPublishEventResponse({ ...publishEventResponse, status: "", msg: "", error: "" });
        setPopUpOpen(false);
        window.location.href = "/onboarding-user-dashboard";
    };
    function refreshPage() {
        window.location.reload(false);
    }

    return (
        <>
            <Box className={classes.ctEvent}>
                <Box className={classes.ctEventHead}>
                    <img src={CreateEImg} alt="CT Event" />
                    <Typography variant='h1'>Create New Event</Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item md={5}>
                        <Box className={classes.ctEventIntro}>
                            <Box className={`${classes.ctEventList} ${openPage && openPage === 1 ? "current"  : " "}`} onClick={(e) => {setOpenPage(1)}}>
                                <Typography variant="span">1</Typography>
                                <Box>
                                    <Typography variant="h2">Event Info</Typography>
                                    <Typography variant="body1">Enter information about your event.</Typography>
                                </Box>
                            </Box>
                            <Box
                                className={`${classes.ctEventList} ${openPage && openPage === 2 ? "current"  : " "}`}
                                onClick={(e) => {                                    
                                    if(event && event.title) {
                                        setOpenPage(2)
                                    } else {
                                        setError(true);
                                    }
                                }}                                
                                >
                                <Typography variant="span">2</Typography>
                                <Box>
                                    <Typography variant="h2">About Event</Typography>
                                    <Typography variant="body1">Provide a detailed description of the purpose of your event, including any activities, and what attendees can expect to gain.</Typography>
                                </Box>
                            </Box>
                            <Box
                                className={`${classes.ctEventList} ${openPage && openPage === 3 ? "current"  : " "}`}
                                onClick={(e) => {
                                    if(event && event.title) {
                                        setOpenPage(3)
                                    } else {
                                        setError(true);
                                    }                                    
                                }}
                            >
                                <Typography variant="span">3</Typography>
                                <Box>
                                    <Typography variant="h2">Organiser Info</Typography>
                                    <Typography variant="body1">Enter an about section of the event host.</Typography>
                                </Box>
                            </Box>
                            {/* <Box className={`${classes.ctEventList} ${openPage && openPage === "exhibitors" ? "current"  : " "}`} onClick={(e) => {setOpenPage("exhibitors")}}>
                                <Typography variant="span">4</Typography>
                                <Box>
                                    <Typography variant="h2">Exhibitors & Sponsors</Typography>
                                    <Typography variant="body1">Add information about any exhibitors or sponsors for your event, including their names, logos, and descriptions.</Typography>
                                </Box>
                            </Box>
                            <Box className={`${classes.ctEventList} ${openPage && openPage === "webinars" ? "current"  : " "}`} onClick={(e) => {setOpenPage("webinars")}}>
                                <Typography variant="span">5</Typography>
                                <Box>
                                    <Typography variant="h2">Webinars & Speakers</Typography>
                                    <Typography variant="body1">Add details about any webinars or keynote speakers that will be featured at your event, including their names, topics, and bios.</Typography>
                                </Box>
                            </Box> */}
                        </Box>
                    </Grid>
                    <Grid item md={7}>
                        <form noValidate autoComplete="off">
                            {event && event.title && openPage && 
                                openPage === 2 ? <AboutEvent event={event} setEvent={setEvent}/> :
                                openPage === 3 ? <Organisers event={event} setEvent={setEvent} /> :
                                //openPage === "exhibitors" ? <ExhibitorsAndSponsors event={event} setEvent={setEvent} /> :
                               //openPage === "webinars" ? <Webinar event={event} setEvent={setEvent} /> : 
                                <CreateEventInfo event={event} setEvent={setEvent} allCats={allCats} setAllCats={setAllCats} allTags={allTags} setAllTags={setAllTags} searchTags={searchTags} setSearchTags={setSearchTags} error={error} setError={setError}/>
                            }
    
                            
                        </form>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={popupOpen}
                            onClose={closeSharePop}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={popupOpen}>
                                <Box component="div" className={classes.modalBox}>
                                    <Box className={classes.modalBoxContent}>
                                        <img src={CompleteImg} alt="modal" />
                                        <Typography variant='h2'>Successful!</Typography>
                                        <Typography variant='body1'>Your event is published now successfully.</Typography>
                                        <Button variant='contained' color="primary" onClick={refreshPage}>Add More Event</Button>
                                        <Link to="/onboarding-user-dashboard">
                                            <Button variant='outlined' color="primary">Your Events</Button>
                                        </Link>
                                        {/* <Button variant='outlined' color="primary">View published Event (TODO)</Button> */}
                                        <Button color='primary' onClick={closeSharePop}>Close</Button>
                                    </Box>
                                </Box>
                            </Fade>
                        </Modal>
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.ctEventFooter}>
                <Box className={classes.ctEventFWrap}>
                    <Box className={classes.etEventSave}>
                        {event && event.title && 
                            <>
                            <Button variant="outlined" color="primary"
                            onClick={(e) => {
                                handlePublish()                                 
                            }}
                            >draft & exit</Button>
                            <Button variant="contained" color="primary"
                                onClick={(e) => {
                                    handlePublish("publish")                                 
                                }}
                            >Publish</Button>
                            </>                      
                        }
                        
                    </Box>
                    <Box className={classes.etEventSave}>
                        {openPage && openPage > 1 && 
                            <Button
                                color='primary'
                                variant="outlined"
                                onClick={(e) => {setOpenPage(openPage - 1)}}                            
                            >previous step</Button>
                        }
                        {event && event.title && openPage && openPage < 3 && 
                            <Button
                                color='primary'
                                variant="contained"
                                onClick={(e) => {setOpenPage(openPage + 1)}}
                            >next step</Button>
                        }
                    </Box>

                </Box>
            </Box>
        </>
    )
}

export default CreateAnEvent