import { Box, Container, Typography } from '@material-ui/core';
import React from 'react'
import { homeBannerStyles } from '../../utils/hooks/useApplyStyles';

const BannerNominee = () => {
    const styles = homeBannerStyles();
    const classes = styles();
    return (
        <Box className={`${classes.bannerNominee}`}>
            <Container maxWidth="lg">
                <Typography variant="h1">
                    Meet All Category Nominees
                </Typography>
            </Container>
        </Box>
    )
}

export default BannerNominee