import React from 'react';
import {CardMedia,Box,Typography} from '@material-ui/core';
import { useCoverCardMediaStyles } from '@mui-treasury/styles/cardMedia/cover';
import {bannerStyles} from "../../utils/hooks/useApplyStyles";


const ResultsPageBanner = (searchTerm) => {
  const styles1 = useCoverCardMediaStyles();
  const styles2 = bannerStyles();
  const classes = {...styles1, ...styles2()}
  const { searched } = searchTerm || {};

  return (
    <Box position={'relative'} width={'100%'} height={'100%'} py={9}>
      <div className={classes.imageArea}>
      <CardMedia
        component={'img'} // add this line to use <img />
        image={"https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1632516870filters-bg-png1632516870.png"}
        classes={classes}
      />
      </div>
      
      <Box className={classes.miniBannerText}>
      <Typography variant="h2" component="h1">
        {searched && searched !== null && searched !== "" && searched !== undefined ? `Results for “${searched}”` : "Find Virtual & Hybrid Events Around the World"}
        </Typography>
        
      </Box>
    </Box>
  );
};

export default ResultsPageBanner;