import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { eventDetailStyles, speakerCardStyles } from "../../../utils/hooks/useApplyStyles";
import { useDynamicAvatarStyles } from '@mui-treasury/styles/avatar/dynamic';
import 'react-multi-carousel/lib/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SingleSpeaker from './SingleSpeaker'
import useIsInViewport from '../../../components/useIsInViewport';

export default function SpeakersCard({ speakers, speakersRef, setPageJumps }) {
    const styles1 = useDynamicAvatarStyles();
    const styles2 = speakerCardStyles();
    const styles3 = eventDetailStyles();
    const classes = { ...styles1, ...styles2(), ...styles3() }
    const speakersInViewport = useIsInViewport(speakersRef, '-50%');
    if (speakersInViewport && speakersInViewport === true) {
        setPageJumps("active-speakers");
    }
    return (
        <div ref={speakersRef} className={`${classes.main} ${classes.edsectionGap}`}>
            <Typography variant="h3" component="h2" gutterBottom className={`${classes.edetailTitle} ${classes.edetailTitlembtt}`} >
                Meet our Speakers
            </Typography>
            <Grid container spacing={3} className={classes.speakersContainer}>
                {speakers && speakers.map((speakersgroup) =>
                    <>
                        {speakersgroup && speakersgroup.webinar.map(speakers_json =>
                            speakers_json && speakers_json.speakers_json && speakers_json.speakers_json !== "null" && JSON.parse(speakers_json.speakers_json).map((speaker) =>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box className={classes.boxCardWrap}>
                                        <SingleSpeaker speaker={speaker} />
                                    </Box>
                                </Grid>
                            )
                        )}
                    </>
                )}
            </Grid>
        </div>
    );
};