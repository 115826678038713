import { Box, Container, Typography, Button } from '@material-ui/core'
import React from 'react'
import { eventeerStyles } from '../../../../utils/hooks/useApplyStyles';

const MoreEvents = () => {
    const style = eventeerStyles();
    const classes = style();
    return (
        <Box className={classes.moreEvents}>
            <Container maxLength="lg">
                <Box className={classes.EventeerWrap} style={{background: "#F96634",}}>
                    <Box className={classes.moreEventsHead}>
                        <Typography variant="h2" align='center' className={classes.moreTitle}>Want to know more about vFairs?</Typography>
                        <Typography variant="body1">
                        Check out our offerings and events hosted by various clients
                        </Typography>
                        <Box className={classes.moreEventsBtns}>
                            <Button variant="outlined" color="primary" href="/" >Explore Events</Button>
                            <Button variant="outlined" color="primary" href="https://www.vfairs.com/contact-us/?utm_source=website&utm_medium=referral&utm_campaign=vfairs-discover-nav" target="_blank">Host an Event</Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default MoreEvents