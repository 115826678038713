import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { eventeerStyles } from '../../../utils/hooks/useApplyStyles';
import eveenteerplaceholder from '../../../assets/images/eveenteer-category-placeholder.png';
import LoaderCard from "../../Loader/LoaderCard";
import { Link } from "react-router-dom";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    draggable: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
    useTransform: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                swipeToSlide: true,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                swipeToSlide: true,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                swipeToSlide: true,

            }
        }
    ]
};


const DiscoverCategoriesSlider = ({ heading }) => {
    //const collectionsBaseURL = `${global.API.rootPath}${global.config.endpoints.collections_endpoint}?access_key=${global.config.access_key}&is_eventeer_award=1&limit=200`;
    const [collections, setCollections] = useState({
        data: null,
        isLoading: false,
        error: "",
    });
    //fetch collections
    useEffect(() => {
        setCollections({ ...collections, isLoading: true });
        axios.get('../../../eventeer-22/eveenteer-22-cats.json',
        {
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            setCollections({ ...collections, data: response.data, isLoading: false });
        }).catch((err) => setCollections({ ...collections, error: err, isLoading: false }));
    }, []);

    const style = eventeerStyles();
    const classes = style();
    return (
        <Box className={classes.eventCategories} id="moreCategoriesSlider">
            <Container maxWidth="lg">
                {heading &&
                    <Typography variant="h2" align='center' className={classes.eventeerTitle}>Discover Voting Categories</Typography>
                }
                {collections && collections.isLoading ?
                    <Grid container spacing={2}>
                        <Grid item lg={3} md={4} sm={6}>
                            <LoaderCard />
                        </Grid>
                        <Grid item lg={3} md={4} sm={6}>
                            <LoaderCard />
                        </Grid>
                        <Grid item lg={3} md={4} sm={6}>
                            <LoaderCard />
                        </Grid>
                        <Grid item lg={3} md={4} sm={6}>
                            <LoaderCard />
                        </Grid>
                    </Grid>
                    :
                    <Slider {...settings} className="cardthemeSlider">
                        {collections.data &&
                            collections.isLoading === false &&
                            collections.data.total_count &&
                            collections.data.total_count > 0 &&
                            collections.data.data &&
                            collections.data.data.map(collection =>
                                collection.status &&
                                collection.status === "1" &&
                                collection.is_private &&
                                collection.is_private === "0" &&
                                <div>
                                    <Box className={classes.categoryBox}>
                                        <Box className={classes.categoryImage}>
                                            <img src={collection.thumbnail ? collection.thumbnail : collection.cover_image ? collection.cover_image : eveenteerplaceholder} alt={collection.name} />
                                        </Box>
                                        <Box className={classes.categorybtn}>
                                            <Link title={collection.name} to={"/eventeer-awards-2023/" + collection.id + "/" + collection.slug}>{collection.name}</Link>
                                        </Box>
                                        <Box className={classes.categorymore}>
                                            <div>
                                                <Typography variant="h5" component="h2">{collection.name}</Typography>
                                                <Typography variant="body1">
                                                    {collection.tag_line}
                                                </Typography>
                                            </div>
                                            <Box className={classes.categoryreadmore}>
                                                <Link title={collection.name} to={"/eventeer-awards-2023/" + collection.id + "/" + collection.slug}>See Nominees <ArrowForwardIcon /></Link>
                                            </Box>
                                        </Box>
                                    </Box>
                                </div>
                            )
                        }
                    </Slider>
                }
            </Container>
        </Box>
    )
}

export default DiscoverCategoriesSlider