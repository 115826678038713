import React from 'react';
import { Box,Typography } from '@material-ui/core';
import { useCoverCardMediaStyles } from '@mui-treasury/styles/cardMedia/cover';
import { eventDetailStyles } from "../../../utils/hooks/useApplyStyles";
import useIsInViewport from '../../../components/useIsInViewport';

export default function EventContent({ content,descRef,setPageJumps }) {
    const styles1 = useCoverCardMediaStyles();
    const styles2 = eventDetailStyles();
    const classes = { ...styles1, ...styles2() }
    const descRefInViewport = useIsInViewport(descRef, '-50%'); //-450px is center

    if(descRefInViewport && descRefInViewport === true) {
        setPageJumps("active-description");
    }

    return (
        <>
            <div ref={descRef} className={classes.main}>
                <Typography variant="h3" component="h2" gutterBottom className={classes.edetailTitle}>
                    About Event
                </Typography>
                <Box pr={5} className={classes.main} >
                    <div className={classes.mainWrapContent} dangerouslySetInnerHTML={{ __html: content.replace(/\<a /g, '<a rel="nofollow"' ) }} />
                </Box>
            </div>
        </>

    );
};
