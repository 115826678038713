import React from 'react';
import {Helmet} from "react-helmet";
import NavigationBar from "../Header/Navbar";
import ForgotPasswordCard from '../OnBoarding/ForgotPassword'
import DiscoverFooter from '../Footer/Footer'
import EmailSentCard from './EmailSent'
import CodeSentCard from './CodeSent'
import { forgotPassStyles } from '../../utils/hooks/useApplyStyles'
import TellUsCard from './TellUsIntrest'
import CreateAccountCard from './CreateAccount'
import CreateAccountOneCard from './CreateAccountOne'
import LoginCard from './LogintoAccount'


export default function LoginMain() {
    const styles = forgotPassStyles();
    const classes = styles();

    return (
        <div className="application">
            <Helmet>
              <title>Log in | vFairs Discover</title>
              <meta name="description" content="Log in to discover exciting virtual and hybrid events to attend, host, and sponsor." />
              {/* <meta property="og:image" content="" /> */}
              <meta property="url" content={window.location.href} />
              <meta property="title" content="Log in | vFairs Discover" />
              <meta property="og:title" content="Log in | vFairs Discover" /> 
              {/* <meta property="image" content="" />   
              <meta property="og:image" content="" />
              <meta content="image/*" property="og:image:type" />*/}
              <meta property="og:locale" content="en_US" />
              <meta property="og:type" content="website" />              
              {/* <meta property="og:quote" content={quote} />
              <meta property="quote" content={quote} />
              <meta property="og:hashtag" content={hashtag} /> */}
              <meta property="og:url" content={window.location.href} />
              <meta property="og:site_name" content="vFairs Discover" />
              <meta property="og:description" content="Log in to discover exciting virtual and hybrid events to attend, host, and sponsor."  />   
            </Helmet>
            <NavigationBar />
            <div className={classes.loginWrap}>
                {/* <CodeSentCard/>
                <EmailSentCard/>
                <ForgotPasswordCard/>
                <TellUsCard/>
                <CreateAccountCard/>
                <CreateAccountOneCard /> */}
                <LoginCard />
            </div>
            <DiscoverFooter />
        </div>

    );
}