import React from "react";
import moment from 'moment';
import clsx from 'clsx';
import { Link } from "react-router-dom";
import { Box, Chip, CardMedia, Typography, Divider } from '@material-ui/core';
import { Row } from '@mui-treasury/components/flex';
import { Info, InfoSubtitle, InfoTitle } from '@mui-treasury/components/info';
import { useCoverCardMediaStyles } from '@mui-treasury/styles/cardMedia/cover';
import { cardsStyles, GreenChip, RedChip, ArchiveChip } from "../../../utils/hooks/useApplyStyles";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import TagsCardContent from './TagsCardContent';
import LazyLoad from 'react-lazyload';
import Placeholder1 from '../../../assets/images/Event-Card-Placeholder-1.png';
import sponDollar from '../../../assets/images/sponsorshipBadge.svg'

//import Share from '../../../assets/images/share.svg';
//import Follow from '../../../assets/images/follow.svg';


export default function CardContent({ event_data }) {
  const styles = cardsStyles();
  const classes = styles();
  const mediaStyles = useCoverCardMediaStyles();
  const now = moment();
  const today_date = moment().subtract(0, "days").format("YYYY-MM-DD");

  return (
    <>
      {event_data && event_data.app_id && event_data.app_title &&
        <>
          <Box className={classes.main} minHeight={150} position={'relative'}>
            <Link className="event-banner"
              target={event_data.application &&
                event_data.application.is_published_on_discover &&
                event_data.application.is_published_on_discover === "1" ? "_self" : "_blank"}
              to={event_data.application &&
                event_data.application.is_published_on_discover &&
                event_data.application.is_published_on_discover === "1" ? "/events/" + event_data.app_id + "/" + (event_data.slug ? event_data.slug : event_data.app_title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')) : event_data.application.url ? "//" + event_data.application.url : ""} >
              {event_data &&
                <LazyLoad height={150}>
                  <CardMedia
                    image={event_data.cover_thumbnail ? event_data.cover_thumbnail ? event_data.cover_image : event_data.cover_image : Placeholder1}
                    classes={mediaStyles}
                  />
                </LazyLoad>
              }

              <div className={classes.contentMain}>
                {event_data && event_data.open_for_sponsorship && event_data.open_for_sponsorship === "1" && event_data.start_date && now && now.isBefore(event_data.start_date, "day") &&
                  <div className={classes.sponshopshipOepn}>
                    <img src={sponDollar} alt="Spin Dollar" />
                    <span>Open for Sponsorship</span>
                  </div>
                }
                {event_data && event_data.is_featured && event_data.is_featured === "1" && event_data.featured_start_date && event_data.featured_end_date && today_date && now && (event_data.featured_start_date === today_date || now.isBetween(event_data.featured_start_date, event_data.featured_end_date, 'day', '[]')) &&
                  <div className={classes.content}>
                    <Chip color="primary" label="featured" size="small" />
                  </div>
                }
                {event_data && event_data.event_mode &&
                  <div className={classes.hybridContent}>
                    <Chip color="secondary" size="small" label={event_data.event_mode} />
                  </div>
                }
              </div>

            </Link>
            {/* <div className={classes.shareNfollow}>
            <a href="#"> <img src={Share} /> </a>
            <a href="#"> <img src={Follow} /> </a>
          </div> */}
          </Box>

          <Row
            className={classes.boxContent}
            m={0}
            p={2}
            pt={2}
            gap={1}
            bgcolor={'common.white'}
            flexDirection={'column'}
          >
            <Link className={classes.boxTitle + " event-name"} title={event_data.app_title.replace(/<[^>]*(>|$)|&amp;|&gt;/g, '&')}
              target={event_data.application &&
                event_data.application.is_published_on_discover &&
                event_data.application.is_published_on_discover === "1" ? "_self" : "_blank"}
              to={event_data.application &&
                event_data.application.is_published_on_discover &&
                event_data.application.is_published_on_discover === "1" ? "/events/" + event_data.app_id + "/" + (event_data.slug ? event_data.slug : event_data.app_title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')) : event_data.application.url ? "//" + event_data.application.url : ""} >
              {event_data.app_title.replace(/<[^>]*(>|$)|&amp;|&gt;/g, '&')}
            </Link>

            <Row flexDirection={'row'} py={1} >
              {event_data && event_data.start_date &&
                <Typography variant="body1" component="span" color="primary" className={classes.dateText}>
                  {moment(event_data.start_date).format("MMM D, YYYY")}
                </Typography>
              }
              {event_data && event_data.start_date && today_date && now && (event_data.start_date === today_date || now.isBetween(event_data.start_date, event_data.end_date, 'day', '[]')) &&
                <GreenChip color="primary" label="Live" size="small" style={{ marginLeft: 10, marginTop: -3 }} />
              }
              {event_data && event_data.start_date && now && now.isBefore(event_data.start_date, "day") &&
                <Chip color="primary" variant="outlined" label="Upcoming" size="small" style={{ marginLeft: 10, marginTop: -3, borderRadius: '4px' }} />
              }
              {event_data && event_data.on_demand_start_date && event_data.on_demand_end_date && now && now.isBetween(event_data.on_demand_start_date, event_data.on_demand_end_date) &&
                <RedChip label="On-Demand" size="small" style={{ marginLeft: 10, marginTop: -3 }} />
              }
              {event_data && event_data.start_date && now && now.isAfter(event_data.start_date, "day") && !now.isBetween(event_data.on_demand_start_date, event_data.on_demand_end_date) && !now.isBetween(event_data.start_date, event_data.end_date, 'day', '[]') &&
                <ArchiveChip label="Archived" size="small" style={{ marginLeft: 10, marginTop: -3 }} />
              }
            </Row>

            {event_data && event_data.start_time &&
              <Row>
                <Typography variant="body1" component="span" className={classes.timeText}>
                  {moment(event_data.start_time, ["HH:mm"]).format("HH:mm")}{event_data && event_data.time_zone ? ", " + event_data.time_zone : ""}
                </Typography>
              </Row>
            }

            <Info position={'start'} className={classes.organizers}>
              {event_data && event_data.organiser && <InfoTitle variant="body1" style={{ marginBottom: 10, }}>organizer: {event_data.organiser}</InfoTitle>}
              <Row flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <InfoSubtitle variant="body1" component="span">
                  {!!(event_data && event_data.price_type && event_data.price_type === "FREE") ? "Free Event" : ""}
                  {!!(event_data && event_data.price_type && event_data.price_type === "PAID" && !event_data.price) ? "Paid Event" : ""}
                  {!!(event_data && event_data.price_type && event_data.price_type === "PAID" && event_data.price) ? "Price: $" + event_data.price : ""}
                </InfoSubtitle>
                {event_data && event_data.event_mode && event_data.event_mode === "HYBRID" && event_data.location &&
                  <InfoSubtitle variant="body2" component="span" className={classes.locText}>
                    <LocationOnOutlinedIcon />
                    {event_data.location}
                  </InfoSubtitle>
                }
              </Row>
            </Info>
            {event_data && event_data.eventTag && event_data.eventTag.length !== 0 &&
              <Divider />
            }
            <Row mt={2} className={clsx(classes.bottomChips, 'bottom_chips')}>
              {event_data && event_data.eventTag && event_data.eventTag.length !== 0 &&
                <TagsCardContent tags_data={event_data.eventTag} />
              }
            </Row>
          </Row>
        </>
      }
    </>
  );
};