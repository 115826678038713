import React from 'react'
import ScrollToTop from '../../components/ScrollToTop'
import BannerNominee from '../Banners/BannerNominee'
import DiscoverFooter from '../Footer/Footer'
import NavigationBar from '../Header/Navbar'
import NomineeWrap from './NomineeSections/NomineeWrap'

const Nominee = () => {
    return (
        <div className="application">
            <ScrollToTop />
            <NavigationBar />
            <BannerNominee />
            <NomineeWrap />
            <DiscoverFooter />
        </div>
    )
}

export default Nominee