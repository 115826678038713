import React from 'react';
import cx from 'clsx';
import { Box, Container, Grid, RadioGroup, Typography, Radio, Button,InputBase,FormGroup, Checkbox } from '@material-ui/core';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import { useBorderedInputBaseStyles } from '@mui-treasury/styles/inputBase/bordered';
import { forgotPassStyles } from '../../utils/hooks/useApplyStyles'


export default function TellUsCard() {
    const styles3 = useBorderedInputBaseStyles();
    const styles1 = useFadedShadowStyles();
    const styles2 = forgotPassStyles();
    const classes = { ...styles1,  ...styles3, ...styles2() }

    return (
        <Container maxWidth="lg">
            <Card className={cx(classes.cardWide, styles1.root)} >
                <CardContent>
                    <Typography className={classes.heading}>Tell us what you love</Typography>

                    <Typography className={classes.subheading}>select interested event topics.</Typography>

                    <FormGroup
                        aria-label="forgotstatus"
                        name="forgot-group"
                    >
                        <FormControlLabel  control={<Checkbox />} label="Business" />
                        <FormControlLabel control={<Checkbox />} label="Fashion & Wellness" />
                        <FormControlLabel control={<Checkbox />} label="Health, Medical & Fitness" />
                        <FormControlLabel  control={<Checkbox />} label="Education" />
                        <FormControlLabel  control={<Checkbox />} label="Science & Technology" />
                        <FormControlLabel  control={<Checkbox />} label="Email" />
                        <FormControlLabel  control={<Checkbox />} label="Email" />
                    </FormGroup>

                    <Typography className={classes.subheading}>select interested event modes.</Typography>

                    <FormGroup
                        aria-label="forgotstatus"
                        name="forgot-group"
                    >
                        <FormControlLabel  control={<Checkbox />} label="Virtual Event" />
                        <FormControlLabel  control={<Checkbox />} label="Hybrid Event" />
                      
                    </FormGroup>

                    <Button variant="containedPrimary" color="primary" style={{ width: '100%', marginBottom: '1rem', marginTop: '1rem' }}>
                    finish
                    </Button>

                </CardContent>




            </Card>

        </Container>

    );
};

