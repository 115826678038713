
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import InputBase from '@material-ui/core/InputBase';
import { Box, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useRoundInputBaseStyles } from '@mui-treasury/styles/inputBase/round';
import { navbarStyles } from "../../utils/hooks/useApplyStyles";
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper';


const EventDetailSearchbar = () => {
    const styles1 = useRoundInputBaseStyles();
    const styles2 = navbarStyles();
    const classes = { ...styles1, ...styles2() }
    const [searchToggleMob, setSearchToggleMob] = useState(false);
    const history = useHistory();

    const [searchFilters, setSearchFilters] = useState({
        inputSearch: "",
    })

    const searchToggle = () => {
        setSearchToggleMob(!searchToggleMob);
    }

    return (
        <div className={classes.search}>
            <Box pb={1} />
                <Box
                    component="form"
                    className={classes.searchBar}
                    onSubmit={(e) => {
                        e.preventDefault();
                        history.push("/marketplace/?&search=" + searchFilters.inputSearch);
                    }}
                >
                    <Button className={classes.searchIconMobile} onClick={searchToggle}>{<SearchIcon />}</Button>
                    <InputBase
                        classes={classes}
                        className={clsx(classes.searchBarMobile, `${searchToggleMob ? classes.searchBarToggle : ''}`)}
                        color={'secondary'}
                        startAdornment={<SearchIcon />}
                        placeholder={'Search Here...'}
                        value={searchFilters.inputSearch}
                        onChange={(e) => setSearchFilters({
                            ...searchFilters,
                            inputSearch: e.target.value,
                        })}     
                        // endAdornment={<ClearOutlinedIcon size="small" onClick={() => setSearchFilters({ ...searchFilters, inputSearch: "" })} />}
                        
                    />
                      <Button
                        type="submit"
                        aria-label="search"
                        id= "search-events"
                        variant="containedPrimary"
                        color="primary"
                        className={classes.searchBtnR}
                        disabled={!searchFilters.inputSearch}
                    >
                        Search
                    </Button>  
                </Box>
                 
            <Box pb={1} />
        </div>
    );
};

export default EventDetailSearchbar;