import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from 'universal-cookie';
//import { onboardingStyles } from '../../../utils/hooks/useApplyStyles';
import { CreateEventNavbar } from '../../Header/CreateEventNavbar'
//import CreateAnEventSteps from './CreateAnEventSteps';
import CreateAnEventStep1 from './CreateAnEventStep1';
import CreateAnEventStep2 from './CreateAnEventStep2'
import CreateAnEventStep3 from './CreateAnEventStep3'
import CreateAnEventStep4 from './CreateAnEventStep4'

const CreateAnEvent = () => {

    const search = useLocation().search;
    const getToken = new URLSearchParams(search).get('access_token');
    const cookies = new Cookies();
    if(getToken) {
        let expires = new Date();
        //expire after 2 days from now
        expires.setDate(expires.getDate() + 2);
        cookies.set('onBoardingToken', getToken, { path: '/',expires });
    }

    const [step, setStep] = useState({
        stepNo: 1,
    });

    const [event, setEvent] = useState({
        mode: "VIRTUAL",
        type: "Other",
        title: "",
        url: "",
        eventHave: [],
        organiserName: "",
        organiserURL: "",
        perYear: "1-10",
        attendees: "1-100",
    });

    const [createEventResponse, setCreateEventResponse] = useState({
        status: "",
        msg: "",
        error: "",
    });

    const handleFinal = () => {
        
        setCreateEventResponse({ ...createEventResponse, status: "", msg: "", error: "" });

        var OnboardingCreateEvent = `${global.API.rootPath}${global.config.endpoints.onboarding_create_event}`;

        //var bearerToken = `${global.config.onboarding_token}`;
        
        //HEADERS
        var usersHeaders = new Headers();
        //usersHeaders.append("Authorization", `Bearer ${bearerToken}`);
        usersHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        //POST DATA
        var urlencoded = new URLSearchParams();        
        urlencoded.append("token", cookies.get('onBoardingToken'));  //TODO
        urlencoded.append("event_mode", event.mode);
        urlencoded.append("event_type", event.type);
        urlencoded.append("event_title", event.title);
        urlencoded.append("event_url", event.url);
        urlencoded.append("event_have", event.eventHave); //TODO
        urlencoded.append("organiser_name", event.organiserName);
        urlencoded.append("organiser_url", event.organiserURL);
        urlencoded.append("events_per_year", event.perYear);
        urlencoded.append("event_attendees", event.attendees);
        urlencoded.append("status", "0");

        var requestOptions = {
            method: 'POST',
            headers: usersHeaders,
            body: urlencoded,
            redirect: 'follow',
            //mode: 'no-cors'
        };

        fetch(OnboardingCreateEvent, requestOptions)
        .then(response => response.text())
        .then(result => {
            const data = JSON.parse(result);
            console.log(data)            
            setCreateEventResponse({ ...createEventResponse, status: data.status, msg: data.msg });            
        })
        .catch(error => {
            setCreateEventResponse({ ...createEventResponse, status: false, error: true });
            console.log('error', error)
        });
        
    };
    if(createEventResponse && createEventResponse.status) {
        window.location.href = "/onboarding-user-dashboard?event-created=1";
    }


    return (
        <div className="application">
            <CreateEventNavbar step={step}/>
            {step && step.stepNo &&
            step.stepNo === 2 ? <CreateAnEventStep2 setStep={setStep} step={step} event={event} setEvent={setEvent} /> :
            step.stepNo === 3 ? <CreateAnEventStep3 setStep={setStep} step={step} event={event} setEvent={setEvent} /> : 
            step.stepNo === 4 ? <CreateAnEventStep4 setStep={setStep} step={step} event={event} setEvent={setEvent} handleFinal={handleFinal}/> :
                <CreateAnEventStep1 setStep={setStep} step={step} event={event} setEvent={setEvent} />
            }
        </div>
    )
}

export default CreateAnEvent