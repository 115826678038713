import React from 'react';
import NavigationBar from "../Header/Navbar";
import ForgotPasswordCard from './ForgotPassword'
//import EmailSentCard from './EmailSent'
//import CodeSentCard from './CodeSent'

export default function ForgetPasswordMain() {

    return (
        <div className="application">
            <NavigationBar />            
            <ForgotPasswordCard/>
            {/* <CodeSentCard/> */}
            {/* <EmailSentCard/> */}
        </div>
    );
}