import { Box, TextField, FormControl, Input, Typography,InputLabel,IconButton } from '@material-ui/core';
import React from 'react'
import { dashboardStyles } from '../../utils/hooks/useApplyStyles';
import UploadIcon from '../../assets/images/upload.svg'
import AddIcon from '@material-ui/icons/Add';
//import AddIcon from '@material-ui/icons/Add';

const Organisers = ({event,setEvent}) => {
    const styles = dashboardStyles();
    const classes = styles();

    async function handleOrganiserImageSubmit(e) {
        e.preventDefault();

        let file = e.target.files[0];

        if(file){
            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = (e) => {
                setEvent({ ...event, organiserImage: e.target.result })
            }
        }
    }

    return (
        <Box className={classes.ctExnSpon}>
            <Box className={classes.ctEventExUpload}>
                <Box className={classes.ctEventETTitle}>
                    <Typography variant='h2'>Organiser</Typography>
                </Box>
                <FormControl className={classes.eventInput}>
                    <InputLabel shrink>Organiser Logo</InputLabel>
                    <Box className={classes.ctEventUpload}>
                        {event && event.organiserImage ? 
                            <Box className={classes.imageBox}>
                            <img src={event && event.organiserImage} alt="Organiser Logo" />
                            </Box>
                            :
                            <>
                            <img src={UploadIcon} alt="Upload Cover"/>
                            <Typography variant="body1">
                                Browse and chose the file you want to upload from your computer
                            </Typography>
                            </>
                        }
                        <Box className={classes.uploadbtn}>
                            <input accept="image/*" id="icon-button-file" type="file" onChange={handleOrganiserImageSubmit} />
                            <label htmlFor="icon-button-file">
                                <IconButton color="primary" variant="contained" aria-label="upload picture" component="span">
                                    <AddIcon />
                                </IconButton>
                            </label>
                        </Box>
                    </Box>
                </FormControl>
                <FormControl className={classes.eventInput}>
                    <Input id="name" disableUnderline placeholder='Organiser name'
                        value={event && event.organiserName ? event.organiserName : ""}
                        onChange={(e) => {
                            setEvent({ ...event, organiserName: e.target.value })
                        }}                    
                    />
                </FormControl>                
                <FormControl className={classes.eventInput}>
                    <Input id="description" disableUnderline placeholder='Organiser URL'
                        value={event && event.organiserURL ? event.organiserURL : ""}
                        onChange={(e) => {
                            setEvent({ ...event, organiserURL: e.target.value })
                        }} 
                    />
                </FormControl>
                <FormControl className={classes.eventInput}>
                    <TextField id="description" multiline rows={10} disableUnderline placeholder='Write Organiser Description...' variant="outlined"
                        value={event && event.organiserDesc ? event.organiserDesc : ""}
                        onChange={(e) => {
                            setEvent({ ...event, organiserDesc: e.target.value })
                        }}                    
                    />
                </FormControl>
            </Box>

        </Box>
    )
}

export default Organisers