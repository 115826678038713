import React, { useRef,useState }  from 'react'
import BannerEventeerNomination2024 from '../../Banners/BannerEventeerNomination2024'
import DiscoverFooter from '../../Footer/Footer'
import NavigationBar from '../../Header/Navbar'
//import DiscoverCategoriesSlider from './EventeerSection/DiscoverCategoriesSlider'
import { eventeerStyles,modalStyles } from '../../../utils/hooks/useApplyStyles';
import { Box, Grid, Typography, Button, Modal,Fade, Backdrop } from '@material-ui/core';
import DiscoverCategories from './EventeerSection/DiscoverCategories'
import Honor from './EventeerSection/Honor'
//import NominationForm from './EventeerSection/NominationForm'
import QnA from './EventeerSection/QnA'
import WinnerGet from './EventeerSection/WinnerGet'
import MoreEvents from './EventeerSection/MoreEvents'
import { useLocation } from "react-router-dom";
import likeThumbs from '../../../assets/images/likethumb.png';
import LazyLoad from 'react-lazyload';


const EventeerAwardsNomination = () => {
    const submitNominationRef = useRef(null);
    const submitNomination = () => submitNominationRef.current.scrollIntoView(true);

    const search = useLocation().search;
    const getvoteQuery = new URLSearchParams(search).get('vote');

    const [open, setOpen] = useState(getvoteQuery && getvoteQuery === 'confirmed' ? true : false);
    
    const handleClose = () => {
        setOpen(false);
        submitNomination();
    };
    const styles1 = eventeerStyles();
    const styles3 = modalStyles();
    const classes = { ...styles3(), ...styles1() }

    return (
        <div className="application">
            <NavigationBar submitNomination={submitNomination} />
            <BannerEventeerNomination2024 submitNomination={submitNomination} />
            <Honor />
            <Grid item xs={12} lg={12} align='center' className={classes.eventeerVideo}>
                <Typography variant="h2" align='center' className={classes.eventeerTitle}>Recognizing Event Brilliance With Your Moments and Our Awards</Typography>
                <iframe src="https://www.youtube.com/embed/rZCzo51PAJY?si=rv_xLH3Set6UjWGa" title="Recognizing Event Brilliance With Your Moments and Our Awards" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Grid>
            {/* <DiscoverCategoriesSlider /> */}
            {/* <DiscoverCategories submitNominationRef={submitNominationRef}/> */}
            {/* <NominationForm submitNominationRef={submitNominationRef}/> */}
            <WinnerGet />
            <MoreEvents />
            <QnA />
            <DiscoverFooter />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={`${classes.modalBox} ${classes.likeModalBox}`}>
                        <LazyLoad height={300}>
                            <img src={likeThumbs} alt="likes" />
                        </LazyLoad>                        
                        <Box className={classes.voteContents}>
                            <Typography variant='h2'>Your vote has been confirmed!</Typography>
                            <Typography variant='body1'>Explore more categories and nominees to vote your favorite one.</Typography>
                            <div className={classes.voteContentBtn}>
                                <Button variant="contained" color="primary" onClick={handleClose}>Explore More</Button>
                            </div>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default EventeerAwardsNomination