import React, {useState } from "react";
import { collectionBannerStyles } from "../../utils/hooks/useApplyStyles";
import { Box, Typography, Container, Breadcrumbs, Link, TextField, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import shareIcon from '../../assets/images/share-icon.svg';
import collectionBG from '../../assets/images/collectionbg.png';
import LoaderLines from "../Loader/LoaderLines";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, TwitterShareButton,
TwitterIcon, WhatsappShareButton, WhatsappIcon, LinkedinShareButton, LinkedinIcon } from "react-share";
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        background: '#FFFFFF',
        borderRadius: 12,
        padding: theme.spacing(3),
        width: 512,

    },
}));


export default function CollectionBanner ({collection}) {
    const styles2 = collectionBannerStyles();
    const classes = { ...styles2(), ...useStyles() }
    const [open, setOpen] = useState(false);
    const openSharePop = () => {
        setOpen(true);
    };
    const closeSharePop = () => {
        setOpen(false);
    };
    const [copy, setCopy] = useState("Copy Link");
    const copyPop = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopy("Copied");
    };

    return (
        <>
            <Box variant="div" className={classes.collectionBanner} style={{ backgroundImage: `url(${collection && collection.data && collection.data.data[0] && collection.data.data[0].cover_image ? collection.data.data[0].cover_image : collectionBG})`}}>
                <Container maxWidth="lg">
                    <Box variant='div' className={classes.breadcrumbMain} pt={4}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            <Link color="inherit" href="/">
                                Home
                            </Link>
                            <Link color="inherit" href="/collections">
                                Collections
                            </Link>
                            {collection && collection.data && collection.data.data[0] && collection.data.data[0].name &&
                                <Typography color="textPrimary">{collection.data.data[0].name}</Typography>
                            }
                        </Breadcrumbs>
                    </Box>
                    <Box variant="div" className={classes.collectionBannerContent}>
                        {collection && collection.isLoading &&
                            <>
                                <LoaderLines />
                                <LoaderLines />
                            </>
                        }
                        {collection && collection.data && collection.data.data[0] && collection.data.data[0].name &&
                            <Typography variant='h1'>{collection.data.data[0].name}</Typography>
                        }
                        {collection && collection.data && collection.data.data[0] && collection.data.data[0].tag_line &&
                            <Typography variant='body1'>{collection.data.data[0].tag_line}</Typography>
                        }
                        <ul className={classes.collectionBannerAction}>
                            {collection && collection.data && collection.data.data[0] && collection.data.data[0].event_id &&
                                <>
                                    <li><span>{collection.data.data[0].event_id.split(",").length} Events</span></li>
                                    <li><img src={shareIcon} onClick={openSharePop} alt="share" /> </li>
                                    <li> <span onClick={openSharePop} style={{ cursor:"pointer"}}>  Share </span> </li>
                                </>
                            }
                        </ul>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={open}
                            onClose={closeSharePop}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={open}>                                
                                <Box component="div" className={classes.shareModal}>
                                    <Box className={classes.shareModalTitle}>
                                        <Typography align='center' variant='h5'>share collections</Typography>
                                        <IconButton aria-label="delete" className={classes.margin} onClick={closeSharePop} size="small">
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    </Box>
                                    <Box className={classes.eventLinkShare}>
                                        <form className={classes.root} noValidate autoComplete="off">
                                            <TextField
                                                id="shareCollection"
                                                variant="filled"
                                                defaultValue={window.location.href}
                                                InputProps={{
                                                    readOnly: true,
                                                    disableUnderline: true
                                                }}
                                            />
                                            <Button variant="contained" disableElevation onClick={copyPop}>{copy}</Button>
                                        </form>
                                    </Box>
                                    {collection && collection.data && collection.data.data[0] && collection.data.data[0].name &&
                                        <ul className={classes.shareLinks}>
                                            <li>
                                                <FacebookShareButton
                                                url={window.location.href}
                                                quote={collection.data.data[0].name}
                                                hashtag="#vfairs"
                                                id="social-facebook"
                                                >
                                                <FacebookIcon size={36} round={true} />
                                                </FacebookShareButton>
                                            </li>
                                            <li>
                                                <TwitterShareButton
                                                    url={window.location.href}
                                                    title={collection.data.data[0].name}
                                                    hashtag="#vfairs"
                                                    id="social-twitter"
                                                    >
                                                    <TwitterIcon size={36} round={true} />
                                                </TwitterShareButton>
                                            </li>
                                            <li>
                                                <WhatsappShareButton
                                                    url={window.location.href}
                                                    title={collection.data.data[0].name}
                                                    separator=":"
                                                    id="social-whatsapp"
                                                    >
                                                    <WhatsappIcon size={36} round={true} />
                                                </WhatsappShareButton>
                                            </li>
                                            <li>
                                                <LinkedinShareButton
                                                    url={window.location.href}
                                                    title={collection.data.data[0].name}
                                                    id="social-linkedin"
                                                    >
                                                    <LinkedinIcon size={36} round={true} />
                                                </LinkedinShareButton>
                                            </li>
                                            <li>
                                                <EmailShareButton
                                                    url={window.location.href}
                                                    subject={collection.data.data[0].name}
                                                    body={"#vfairs" + window.location.href}
                                                    separator=": "
                                                    id="social-email"
                                                    title="Email Link">
                                                    <EmailIcon size={36} round={true} />
                                                </EmailShareButton>
                                            </li>
                                        </ul>
                                    }
                                </Box>
                            </Fade>
                        </Modal>
                    </Box>
                </Container>
            </Box>
        </>
    )
};