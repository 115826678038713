import { Box, Button, Container, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import React from 'react'
import { lookingUpStyles, onboardingStyles } from '../../../utils/hooks/useApplyStyles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import dImage from '../../../assets/images/step1.png'
import vEvent from '../../../assets/images/virtual event.svg'
import hybrid from '../../../assets/images/Hybrid Event.svg'
import physicalEvent from '../../../assets/images/in person event.svg'


const CreateAnEventStep1 = ({setStep,step,event,setEvent}) => {
    const styles = onboardingStyles();
    const styles1 = lookingUpStyles();
    const classes = { ...styles(), ...styles1() }
    return (
        <Box className={classes.eventSteps} style={{ overflow: 'hidden' }}>
            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item lg={8}>
                        <Box className={classes.eventStepsC}>
                            <Box className={classes.eventStepGuide}>
                                <Typography variant='span' className='quarter'>1</Typography>
                                <Typography variant='body1'>select event mode</Typography>
                            </Box>
                            <Typography className={classes.eventStepTitle} variant="h2">What type of event are you publishing on Discover?</Typography>
                            <Box className={classes.eventSelection}>
                                <Typography variant="span" className={classes.eventSelectionLabel}>Choose the event mode that you would like to create.</Typography>
                                <RadioGroup
                                    name="event-mode"
                                >
                                    <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="VIRTUAL"
                                        onChange={(e) => {
                                            setEvent({ ...event, mode: e.target.value })
                                        }}
                                        checked={event.mode === 'VIRTUAL'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={vEvent} alt="Virtual Event" />
                                            <Box>
                                                <Typography variant="h2">Virtual Event</Typography>
                                                <Typography variant="body1">Showcase your virtual event experiences to attract more attendees.</Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>} />
                                    <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="HYBRID"
                                        onChange={(e) => {
                                            setEvent({ ...event, mode: e.target.value })
                                        }}
                                        checked={event.mode === 'HYBRID'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={hybrid} alt="Hybrid Event" />
                                            <Box>
                                                <Typography variant="h2">Hybrid Event</Typography>
                                                <Typography variant="body1">Bring your events mixing in-person & virtual attendance to the limelight.</Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>} />
                                    <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="PHYSICAL"
                                        onChange={(e) => {
                                            setEvent({ ...event, mode: e.target.value })
                                        }}
                                        checked={event.mode === 'PHYSICAL'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={physicalEvent} alt="In-Person Event" />
                                            <Box>
                                                <Typography variant="h2">In-Person Event</Typography>
                                                <Typography variant="body1">Increase the visibility of your in-person event. </Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>} />
                                </RadioGroup>
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setStep({...step, stepNo: 2,})}
                            >Continue</Button>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box className={classes.stepOverview}>
                            <Box className={classes.stepOverviewContent}>
                                <img src={dImage} alt="vfairs discover" />
                                <Typography variant='h2'>What is vFairs Discover?</Typography>
                                <Typography variant="body1">
                                vFairs Discover is an online event marketplace that helps you attract new audiences and sponsors through multiple features. List your event with an exceptional title and description and categorize it for easy search.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default CreateAnEventStep1