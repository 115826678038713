import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Grid } from '@material-ui/core'
import CardContent from '../../Marketplace/Cards/CardContent'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoaderCard from "../../Loader/LoaderCard";

export default function CollectionEventsSection({eventIds}) {
    const GET_EVENT_API = `${global.API.rootPath}${global.config.endpoints.events_endpoint}?access_key=${global.config.access_key}&page=1&limit=1&get_event_tag=true`;
    const [collectionEvents, setCollectionEvents] = useState({
        data: {},
        isLoading: false,
    });
      
    useEffect(() => {
        setCollectionEvents({ ...collectionEvents, isLoading: true });
        axios.all(eventIds.map((ids) => axios.get(GET_EVENT_API+ `&event_id=${ids}`))).then(
            (data) => setCollectionEvents({ ...collectionEvents, data: data, isLoading: false }),
        ); 
    },[]);


    return (
        <>
            <Grid container spacing={3} justifyContent="center">
                {collectionEvents && collectionEvents.isLoading &&
                    <Grid container spacing={3}>
                        <Grid item lg={3} sm={6} xs={12} >
                            <LoaderCard />
                        </Grid>
                        <Grid item lg={3} sm={6} xs={12} >
                            <LoaderCard />
                        </Grid>
                        <Grid item lg={3} sm={6} xs={12} >
                            <LoaderCard />
                        </Grid>
                        <Grid item lg={3} sm={6} xs={12} >
                            <LoaderCard />
                        </Grid>
                    </Grid>
                } 
                {collectionEvents && collectionEvents.data && collectionEvents.data.length>0 && collectionEvents.data.map((event) =>
                        event && event.data && event.data.data && event.data.data[0] && event.data.data[0].app_id && event.data.data[0].app_title &&
                        <Grid item md={3} lg={3} sm={6} xs={12}>
                            <CardContent event_data={event.data.data[0]} />
                        </Grid>
                )}
            </Grid>
        </>
    )
};