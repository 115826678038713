import React, { useState, useEffect } from 'react';
import { Grid, Container, Box, Chip, Button, Typography, IconButton } from '@material-ui/core';
// import { eventDetailStyles, GreenChip } from "../../../utils/hooks/useApplyStyles";
import { eventDetailStyles, GreenChip, WhiteBtn, RedChip, ArchiveChip } from "../../../utils/hooks/useApplyStyles";
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton } from "react-share";
import { Link } from "react-router-dom";
import SocailShareCard from './SocialShareCard'
//import SignUpCard from './SignupCard'
import EventContent from './EventContent'
import ExhibitorCard from './ExhibitorCard'
import SpeakersCard from './SpeakersCard'
import Sponsors from './Sponsors'
import AgendaAccordian from './Agenda'
import LoaderLines from "../../Loader/LoaderLines";
import LoaderBox from "../../Loader/LoaderBox";
import moment from 'moment';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
//import LinkIcon from '@material-ui/icons/Link';
import sponsorshipBadge from '../../../assets/images/sponsorshipbadge-color.svg'
import OrganizeImage from '../../../assets/dummy-org.png';

// Social media buttons
import facebookbtn from "../../../assets/images/Facebook.png"
import twitterbtn from '../../../assets/images/Twitter.png';
import linkedinbtn from '../../../assets/images/LinkedIn.png';
import emailbtn from '../../../assets/images/Email.png';
import whatsappbtn from '../../../assets/images/whatsapp.png';
import linktbtn from '../../../assets/images/copy.png';
import EventGallery from './EventGallery';
import EventIncludes from './EventIncludes';

export default function ContentBox({ event_data, pageJumps, setPageJumps, executeDescScroll, executeSponsorsScroll, executeAgendaScroll, executeSpeakersScroll, executeExhibitorsScroll, descRef, sponsorsRef, agendaRef, speakersRef, exhibitorsRef, mapScroll }) {
    const styles = eventDetailStyles();
    const classes = styles();
    let exhibitor = false;


    const now = moment();
    const today_date = moment().subtract(0, "days").format("YYYY-MM-DD");

    const location = !!(event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].location) ? event_data.data.data[0].location : "";
    const start_date = !!(event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].start_date) ? moment(event_data.data.data[0].start_date).format("YYYYMMDD") : "";
    const start_time = !!(event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].start_time) ? moment(event_data.data.data[0].start_time, ["HHmm"]).format("HHmm") : "";
    const time_zone = !!(event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].time_zone) ? event_data.data.data[0].time_zone : "";



    const [visible, setVisible] = useState(false);
    const handleScroll = () => {
        if (window.pageYOffset > 900) {
            setVisible(true)
        }
        else {
            setVisible(false)
        }
    };
    const [open, setOpen] = useState(false);
    const openSharePop = () => {
        setOpen(true);
    };
    const closeSharePop = () => {
        setOpen(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [copy, setCopy] = useState("Copy Link");
    const copyPop = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopy("Copied");
    };

    return (
        <>
            <Box position={'relative'} >
                <Container maxWidth="lg" >
                    <Box className={classes.edetailBox}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Box className={classes.edetailContent}>
                                    {event_data && event_data.isLoading &&
                                        <>
                                            <LoaderBox />
                                            <LoaderLines />
                                            <LoaderLines />
                                            <LoaderLines />
                                            <LoaderLines />
                                        </>
                                    }
                                    <div className={classes.bannerEventEventStatus} style={{ display: 'flex', alignItems: 'center',}}>
                                        {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].event_mode &&
                                            <Chip color="primary" size="small" label={event_data.data.data[0].event_mode} />
                                        }
                                        {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].is_featured && event_data.data.data[0].is_featured === 1 &&
                                            <GreenChip color="secondary" label="featured" size="small" style={{ marginLeft: 5, }} />
                                        }
                                        {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].app_title && event_data.data.data[0].open_for_sponsorship && event_data.data.data[0].open_for_sponsorship === "1" && event_data.data.data[0].start_date && now && now.isBefore(event_data.data.data[0].start_date, "day") &&
                                            <div className={classes.sponsorshipApply}>
                                                <img src={sponsorshipBadge} alt="Sponsorship Badge" />
                                                <span>Open for Sponsorship</span>
                                                <Button onClick={openSharePop} color="primary">Apply</Button>
                                                <Modal
                                                    aria-labelledby="transition-modal-title"
                                                    aria-describedby="transition-modal-description"
                                                    className={classes.modal}
                                                    open={open}
                                                    onClose={closeSharePop}
                                                    closeAfterTransition
                                                    BackdropComponent={Backdrop}
                                                    BackdropProps={{
                                                        timeout: 500,
                                                    }}
                                                >
                                                    <Fade in={open}>
                                                        <Box component="div" className={classes.shareModal}>
                                                            <Box className={classes.shareModalTitle}>
                                                                <IconButton aria-label="delete" className={classes.margin} onClick={closeSharePop} size="small">
                                                                    <CloseIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Box>
                                                            <iframe src={"https://docs.google.com/forms/d/e/1FAIpQLSeiDqQpZrmuXG0ONZ3dSteIk2HvXS_bFwEFFNnkjB2nEuY91Q/viewform?usp=pp_url&entry.1988510466=" + event_data.data.data[0].app_title + "&entry.525347083=" + window.location.href + "&embedded=true"} width="640" height="620" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                                                        </Box>
                                                    </Fade>
                                                </Modal>
                                            </div>
                                        }
                                    </div>

                                    {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].app_title &&
                                        <Typography variant="h1" component="h1" gutterBottom style={{ marginTop: 15, }}>
                                            {event_data.data.data[0].app_title.replace(/<[^>]*(>|$)|&amp;|&gt;/g, '&')}
                                        </Typography>
                                    }
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="h2" component="p" color='primary' gutterBottom className={classes.detailPageBannerDate}>
                                            {!!(event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].start_date) ? moment(event_data.data.data[0].start_date).format("MMMM D, YYYY") : ""}{!!(event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].start_time) ? ", " + moment(event_data.data.data[0].start_time, ["HH:mm"]).format("HH:mm") : ""} {!!(event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].time_zone) ? event_data.data.data[0].time_zone : ""}
                                        </Typography>
                                        {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].start_date && today_date && now && (event_data.data.data[0].start_date === today_date || now.isBetween(event_data.data.data[0].start_date, event_data.data.data[0].end_date, 'day', '[]')) &&
                                            <GreenChip color="primary" label="Live" size="small" style={{ marginLeft: 10, marginBottom: '0.7em', }} />
                                        }
                                        {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].start_date && now && now.isBefore(event_data.data.data[0].start_date, "day") &&
                                            <Chip color="primary" variant="outlined" label="Upcoming" size="small" style={{ marginLeft: 10, marginBottom: '0.7em', borderRadius: '4px' }} />
                                        }
                                        {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].on_demand_start_date && event_data.data.data[0].on_demand_end_date && now && now.isBetween(event_data.data.data[0].on_demand_start_date, event_data.data.data[0].on_demand_end_date) &&
                                            <RedChip label="On-Demand" size="small" style={{ marginLeft: 10, marginBottom: '0.7em', }} />
                                        }
                                        {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].start_date && now && now.isAfter(event_data.data.data[0].start_date, "day") && !now.isBetween(event_data.data.data[0].on_demand_start_date, event_data.data.data[0].on_demand_end_date) && !now.isBetween(event_data.data.data[0].start_date, event_data.data.data[0].end_date, 'day', '[]') &&
                                            <ArchiveChip label="Archived" size="small" style={{ marginLeft: 10, marginBottom: '0.7em', }} />
                                        }
                                    </div>

                                    {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].event_mode && event_data.data.data[0].event_mode === "HYBRID" && event_data.data.data[0].location &&
                                        <div className={classes.mapText }>
                                            <RoomOutlinedIcon />
                                            <Typography variant="p" component="p" gutterBottom>
                                                {event_data.data.data[0].location}
                                            </Typography>
                                            <Typography variant="a" component="a" gutterBottom onClick={mapScroll}>
                                                View Map
                                            </Typography>
                                        </div>
                                    }
                                    <div className={classes.regAddBtn}>
                                        {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].application && event_data.data.data[0].application.url && event_data.data.data[0].is_url_visible_when_archieved && event_data.data.data[0].start_date && today_date && now && (event_data.data.data[0].is_url_visible_when_archieved === "1" || event_data.data.data[0].start_date == today_date || now.isBefore(event_data.data.data[0].start_date, "day") || now.isBetween(event_data.data.data[0].on_demand_start_date, event_data.data.data[0].on_demand_end_date)) &&
                                            <div className={classes.edetailregbtn}>
                                                <Button
                                                    className={classes.buyTicketBtn}
                                                    variant="containedPrimary"
                                                    color="primary"
                                                    target="_blank"
                                                    href={event_data.data.data[0].app_id &&
                                                        event_data.data.data[0].app_id === "8259" ? "https://canonrobotics.vfairs.com/en/canon-usa" :
                                                        event_data.data.data[0].app_id === "8716" ? "https://efinancialcareers_fair_march.vfairs.com/en/registration?utm_campaign=JS_GLOBAL_MAR_2023_OTHER&utm_source=GLOBAL_OTHER_ENG&utm_medium=DA_VCE_OTHER_ENG" :
                                                        event_data.data.data[0].app_id === "9186" ? "https://recruiterhub.efinancialcareers.com/Global-Fintech-Fair2023_EMEA-US_VFAIRS_ENG.html" :
                                                        event_data.data.data[0].app_id === "9345" ? "https://recruiterhub.efinancialcareers.com/Financial-Services-Tech-VCE-Staffing_VFAIRS.html" :
                                                        event_data.data.data[0].app_id === "8927" ? "https://www.filmscapechicago.com/" :
                                                        "//" + event_data.data.data[0].application.url}
                                                    id="event-link"
                                                >
                                                    {!!(event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].main_button_label) && today_date && now && (event_data.data.data[0].start_date == today_date || now.isBefore(event_data.data.data[0].start_date, "day")) ? event_data.data.data[0].main_button_label : "Visit Website"}
                                                </Button>
                                            </div>
                                        }
                                        <div className={classes.edetailaddtocalbtn}>
                                            {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].start_date && today_date && now && (event_data.data.data[0].start_date === today_date || now.isBefore(event_data.data.data[0].start_date, "day")) &&
                                                <WhiteBtn
                                                    variant="contained"
                                                    color="default"
                                                    target="_blank"
                                                    rel="nofollow"
                                                    id="add-to-calendar"
                                                    startIcon={<EventAvailableIcon />}
                                                    href={"https://www.google.com/calendar/render?action=TEMPLATE&text=" + event_data.data.data[0].app_title + "&dates=" + start_date + "T" + start_time + "00Z/" + start_date + "T" + start_time + "00Z&ctz=" + time_zone + "&details=" + event_data.data.data[0].app_title + "&location=" + location + "&sf=true&output=xml"}
                                                >
                                                    add to calendar
                                                </WhiteBtn>

                                            }
                                        </div>
                                    </div>

                                    <div className={classes.socialSharecBox}>
                                        {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].id && event_data.data.data[0].app_title &&
                                            <Typography variant="h5" component="p">
                                                Social Share
                                            </Typography>
                                        }
                                        <div className={classes.socialLinksM}>
                                            {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].id && event_data.data.data[0].app_title &&
                                                <>
                                                    <FacebookShareButton
                                                        url={window.location.href}
                                                        quote={event_data.data.data[0].app_title.replace(/<[^>]*(>|$)|&amp;|&gt;/g, '&')}
                                                        hashtag="#vfairs"
                                                        id="social-facebook"
                                                        className={classes.socialLinks}>
                                                        <img src={facebookbtn} alt="facebook" />
                                                    </FacebookShareButton>
                                                    <TwitterShareButton
                                                        url={window.location.href}
                                                        title={event_data.data.data[0].app_title.replace(/<[^>]*(>|$)|&amp;|&gt;/g, '&')}
                                                        hashtag="#vfairs"
                                                        id="social-twitter"
                                                        className={classes.socialLinks}
                                                    >
                                                        <img src={twitterbtn} alt="twitter" />
                                                    </TwitterShareButton>
                                                    <LinkedinShareButton
                                                        url={window.location.href}
                                                        title={event_data.data.data[0].app_title.replace(/<[^>]*(>|$)|&amp;|&gt;/g, '&')}
                                                        className={classes.socialLinks}
                                                        id="social-linkedin"
                                                    >
                                                        <img src={linkedinbtn} alt="facebook" />
                                                    </LinkedinShareButton>
                                                    <EmailShareButton
                                                        url={window.location.href}
                                                        subject={event_data.data.data[0].app_title.replace(/<[^>]*(>|$)|&amp;|&gt;/g, '&')}
                                                        body={"#vfairs" + window.location.href}
                                                        separator=": "
                                                        id="social-email"
                                                        className={classes.socialLinks}>
                                                        <img src={emailbtn} alt="facebook" />
                                                    </EmailShareButton>
                                                    <WhatsappShareButton
                                                        url={window.location.href}
                                                        title={event_data.data.data[0].app_title.replace(/<[^>]*(>|$)|&amp;|&gt;/g, '&')}
                                                        separator=": "
                                                        className={classes.socialLinks}
                                                        id="social-whatsapp"
                                                    >
                                                        <img src={whatsappbtn} alt="facebook" />
                                                    </WhatsappShareButton>

                                                    <Button onClick={copyPop} color="primary" style={{display: 'contents'}}>
                                                        <img src={linktbtn} alt="Copy Link" />
                                                    </Button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <SocailShareCard event_data={event_data} />
                            </Grid>
                        </Grid>

                    </Box>
                    <Box className={classes.edetailOrgImg}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={7} md={8}>
                                <Box className={classes.eventImg}>
                                    {event_data && event_data.isLoading &&
                                        <>
                                            <LoaderBox />
                                            <LoaderBox />
                                        </>
                                    }
                                    {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].cover_image &&
                                        <img src={event_data.data.data[0].cover_image} alt="Event Banner" style={{ maxWidth: '100%' }} />
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={5} md={4}>
                                {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].organiser &&
                                    <div className={classes.organizedBy}>
                                        <div className={classes.orgTitleby}>
                                            <Typography variant="h5" component="p">
                                                Organized By:
                                            </Typography>
                                            <div className={classes.organizeConTitle}>
                                                {event_data.data.data[0].organiser_logo &&
                                                    <div className={classes.organizedTitleIcon}>
                                                        <img src={event_data.data.data[0].organiser_logo ? event_data.data.data[0].organiser_logo : OrganizeImage} alt="Organizer Logo" />
                                                    </div>
                                                }
                                                <span>{event_data.data.data[0].organiser}</span>
                                            </div>
                                        </div>


                                        <div className={classes.organizeContents}>

                                            {event_data.data.data[0].organiser_description &&
                                                <Typography variant='body1'>
                                                    {event_data.data.data[0].organiser_description}
                                                </Typography>
                                            }
                                        </div>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </Box>


                    <Box className={classes.mainColWrap}>

                        {event_data && event_data.data && event_data.data.data[0] &&
                            <div class={visible ? classes.fixedTabsDetail : ''}>
                                <Container disableGutters={visible ? false : true}>
                                    {event_data && event_data.data && event_data.data.data[0] &&
                                        <ul className={classes.detailNavigation}>
                                            {event_data.data.data[0].about_us &&
                                                <li>
                                                    <Link to="#" onClick={() => executeDescScroll()} className={pageJumps && pageJumps === "active-description" ? 'active' : ''} >
                                                        About Event
                                                    </Link></li>
                                            }
                                            {event_data.data.data[0].sponsor_cat_group &&
                                                event_data.data.data[0].sponsor_cat_group.length != 0 &&
                                                <li><Link to="#" onClick={() => executeSponsorsScroll()} className={pageJumps && pageJumps === "active-sponsors" ? 'active' : ''}>sponsors</Link></li>
                                            }
                                            {event_data.data.data[0].exhibitors &&
                                                event_data.data.data[0].exhibitors.length != 0 &&
                                                event_data.data.data[0].exhibitors.filter(element => element.booths && element.booths.booth_logo_url && element.booths.description_ar).length > 0 &&
                                                <li><Link to="#" onClick={() => executeExhibitorsScroll()} className={pageJumps && pageJumps === "active-exhibitors" ? 'active' : ''}>exhibitors</Link></li>
                                            }
                                            {event_data.data.data[0].speaker &&
                                                event_data.data.data[0].speaker.length != 0 &&
                                                event_data.data.data[0].speaker.filter(element => element.webinar.length).length > 0 &&
                                                <>
                                                    <li><Link to="#" onClick={() => executeAgendaScroll()} className={pageJumps && pageJumps === "active-agenda" ? 'active' : ''}>agenda</Link></li>
                                                    {event_data.data.data[0].speaker.filter(element => element.webinar.filter(webinar => webinar.speakers_json && String(webinar.speakers_json).length > 4).length).length > 0
                                                        && <li><Link to="#" onClick={() => executeSpeakersScroll()} className={pageJumps && pageJumps === "active-speakers" ? 'active' : ''}>speakers</Link></li>}
                                                </>
                                            }                                          

                                        </ul>
                                    }
                                </Container>
                            </div>
                        }
                        {event_data && event_data.isLoading &&
                            <>
                                <LoaderLines />
                                <LoaderLines />
                                <LoaderLines />
                                <LoaderLines />
                                <LoaderLines />
                                <LoaderLines />
                                <LoaderLines />
                                <LoaderLines />
                                <LoaderLines />
                            </>
                        }
                        {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].about_us &&
                            <EventContent descRef={descRef} setPageJumps={setPageJumps} content={event_data.data.data[0].about_us} />
                        }
                    </Box>

                    {/* Event Gallery */}
                    {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].images &&
                        <EventGallery images={event_data.data.data[0].images}/>
                    }
                    {/* This event includes */}
                    {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].include && event_data.data.data[0].include.length != 0 &&
                        <EventIncludes EventIncludes={event_data.data.data[0].include}/>
                    }
                    <Grid container>
                        <Grid item xs={12} >
                            {event_data && event_data.isLoading &&
                                <>
                                    <LoaderBox />
                                    <LoaderLines />
                                    <LoaderLines />
                                    <LoaderLines />
                                    <LoaderLines />
                                    <LoaderBox />
                                    <LoaderBox />
                                    <LoaderLines />
                                    <LoaderLines />
                                </>
                            }
                            {event_data &&
                                event_data.data &&
                                event_data.data.data[0] &&
                                event_data.data.data[0].sponsor_cat_group &&
                                event_data.data.data[0].sponsor_cat_group.length != 0 &&
                                <Sponsors sponsorsRef={sponsorsRef} setPageJumps={setPageJumps} sponsors_group={event_data.data.data[0].sponsor_cat_group} />
                            }
                            {event_data &&
                                event_data.data &&
                                event_data.data.data[0] &&
                                event_data.data.data[0].exhibitors &&
                                event_data.data.data[0].exhibitors.length != 0 &&
                                event_data.data.data[0].exhibitors.filter(element => element.booths && element.booths.booth_logo_url && element.booths.description_ar).length > 0 &&
                                <ExhibitorCard exhibitorsRef={exhibitorsRef} setPageJumps={setPageJumps} exhibitors={event_data.data.data[0].exhibitors} />
                            }
                            {event_data &&
                                event_data.data &&
                                event_data.data.data[0] &&
                                event_data.data.data[0].speaker &&
                                event_data.data.data[0].speaker.length != 0 &&
                                event_data.data.data[0].speaker.filter(element => element.webinar.length).length > 0 &&
                                <div className={classes.edetailagenda}>
                                    <AgendaAccordian agendaRef={agendaRef} setPageJumps={setPageJumps} speakers={event_data.data.data[0].speaker} />
                                    {event_data.data.data[0].speaker.filter(element => element.webinar.filter(webinar => webinar.speakers_json && String(webinar.speakers_json).length > 4).length).length > 0
                                        && <SpeakersCard speakersRef={speakersRef} setPageJumps={setPageJumps} speakers={event_data.data.data[0].speaker} />}
                                </div>
                            }

                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};




