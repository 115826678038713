import { AppBar, Box, Button, Container, Typography } from '@material-ui/core';
import React from 'react'
import { Link } from 'react-router-dom';
import { navbarStyles, onboardingStyles } from '../../utils/hooks/useApplyStyles';
import Logo from '../../assets/logo.svg';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

export const CreateEventNavbar = ({step}) => {
    const styles = onboardingStyles();
    const styles2 = navbarStyles();
    const classes = { ...styles(), ...styles2() }
    return (
        <div className={classes.grow}>
            <AppBar position="static" className={classes.headercTop} elevation={0}>
                <Container maxWidth="lg" style={{ position: "relative" }}>
                    <Link
                        to="/"
                        className={classes.backwebsite}
                    >
                       <ChevronLeft />  Back to Website
                    </Link>
                    <Box className={classes.obNavbarBox}>
                        <Typography variant='body1'>Create an Event with</Typography>
                        <img
                            src={Logo}
                            alt="Discover by vFairs"
                            className={classes.logoImg}
                        />
                    </Box>
                </Container>
                <Box
                    component="span"
                    className={classes.eventProgressBar}
                    style={step && step.stepNo &&
                        step.stepNo === 2 ? {width: "40%"} :
                        step.stepNo === 3 ? {width: "60%"} : 
                        step.stepNo === 4 ? {width: "80%"} :
                        {width: "20%"}
                    }
                />
            </AppBar>
        </div>
    )
}
