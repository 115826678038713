import { Box, Button, Container, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import React from 'react'
import { lookingUpStyles, onboardingStyles } from '../../../utils/hooks/useApplyStyles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


const CreateAnEventDetails2 = ({setStep,step,event,setEvent,handleFinal}) => {
    const styles = onboardingStyles();
    const styles1 = lookingUpStyles();
    const classes = { ...styles(), ...styles1() }
    return (
        <Box className={classes.eventSteps} style={{ overflow: 'hidden' }}>
            <Container maxWidth="lg">
                <Box className={classes.onbaordWrap}>
                    <Box className={classes.eventStepsC}>
                        <Box className={classes.eventStepGuide}>
                            <Typography variant='span' className='quarter'>4</Typography>
                            <Typography variant='body1'>add event details</Typography>
                        </Box>
                        <Typography className={classes.eventStepTitle} variant="h2">More information</Typography>
                        <Box className={classes.eventSelection}>
                            <Typography variant="span" className={classes.eventSelectionLabel}>Please share additional details so we can improve your experience.</Typography>

                            <Box className={classes.aboutEvent}>
                                <Typography className={classes.eventdetailTitle} variant="h2">How many events will you host this year?</Typography>
                            </Box>

                            <RadioGroup
                                defaultValue="1-10"
                                name="yearly-hosted"
                                className={classes.eventCountBoxes}
                            >
                                <FormControlLabel
                                    className={classes.eventModeSelection}
                                    value="1-10"
                                    onChange={(e) => {
                                        setEvent({ ...event, perYear: e.target.value })
                                    }}
                                    checked={event.perYear === '1-10'}
                                    control={<Radio />}
                                    label={<Box className={classes.eventMContent}>
                                        <Typography variant='body1' className={classes.eventDetailcount}>1 - 10</Typography>
                                        <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                    </Box>}
                                />
                                <FormControlLabel
                                    className={classes.eventModeSelection}
                                    value="10-50"
                                    onChange={(e) => {
                                        setEvent({ ...event, perYear: e.target.value })
                                    }}
                                    checked={event.perYear === '10-50'}
                                    control={<Radio />}
                                    label={<Box className={classes.eventMContent}>
                                        <Typography variant='body1' className={classes.eventDetailcount}>10 - 50</Typography>
                                        <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                    </Box>}
                                />
                                <FormControlLabel
                                    className={classes.eventModeSelection}
                                    value="50-100"
                                    onChange={(e) => {
                                        setEvent({ ...event, perYear: e.target.value })
                                    }}
                                    checked={event.perYear === '50-100'}
                                    control={<Radio />}
                                    label={<Box className={classes.eventMContent}>
                                        <Typography variant='body1' className={classes.eventDetailcount}>50 - 100</Typography>
                                        <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                    </Box>}
                                />
                                <FormControlLabel
                                    className={classes.eventModeSelection}
                                    value="100+"
                                    onChange={(e) => {
                                        setEvent({ ...event, perYear: e.target.value })
                                    }}
                                    checked={event.perYear === '100+'}
                                    control={<Radio />}
                                    label={<Box className={classes.eventMContent}>
                                        <Typography variant='body1' className={classes.eventDetailcount}>More than 100</Typography>
                                        <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                    </Box>}
                                />
                            </RadioGroup>


                            <Box className={classes.aboutEvent}>
                                <Typography className={classes.eventdetailTitle} variant="h2">What is the expected attendee turnout?</Typography>
                            </Box>

                            <RadioGroup
                                defaultValue="1-100"
                                name="exptected-attendee"
                                className={classes.eventCountBoxes}
                            >
                                <FormControlLabel
                                    className={classes.eventModeSelection}
                                    value="1-100"
                                    onChange={(e) => {
                                        setEvent({ ...event, attendees: e.target.value })
                                    }}
                                    checked={event.attendees === '1-100'}
                                    control={<Radio />}
                                    label={<Box className={classes.eventMContent}>
                                        <Typography variant='body1' className={classes.eventDetailcount}>1 - 100</Typography>
                                        <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                    </Box>}
                                />
                                <FormControlLabel
                                    className={classes.eventModeSelection}
                                    value="100-250"
                                    onChange={(e) => {
                                        setEvent({ ...event, attendees: e.target.value })
                                    }}
                                    checked={event.attendees === '100-250'}
                                    control={<Radio />}
                                    label={<Box className={classes.eventMContent}>
                                        <Typography variant='body1' className={classes.eventDetailcount}>100 - 250</Typography>
                                        <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                    </Box>}
                                />
                                <FormControlLabel
                                    className={classes.eventModeSelection}
                                    value="250-500"
                                    onChange={(e) => {
                                        setEvent({ ...event, attendees: e.target.value })
                                    }}
                                    checked={event.attendees === '250-500'}
                                    control={<Radio />}
                                    label={<Box className={classes.eventMContent}>
                                        <Typography variant='body1' className={classes.eventDetailcount}>250 - 500</Typography>
                                        <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                    </Box>}
                                />
                                <FormControlLabel
                                    className={classes.eventModeSelection}
                                    value="500+"
                                    onChange={(e) => {
                                        setEvent({ ...event, attendees: e.target.value })
                                    }}
                                    checked={event.attendees === '500+'}
                                    control={<Radio />}
                                    label={<Box className={classes.eventMContent}>
                                        <Typography variant='body1' className={classes.eventDetailcount}>More than 500</Typography>
                                        <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                    </Box>}
                                />
                            </RadioGroup>
                        </Box>
                        <Box className={classes.bbuttons}>                            
                            <Button
                                color="primary"
                                className={classes.backbtn}
                                startIcon={<ChevronLeftIcon />}
                                onClick={() => setStep({...step, stepNo: 3,})}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleFinal}
                            >Continue</Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default CreateAnEventDetails2