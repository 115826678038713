import { Box } from '@material-ui/core';
import React from 'react'
import { dashboardStyles } from '../../utils/hooks/useApplyStyles';
import Header from '../Header'
import Sidebar from '../Sidebar';
import Listing from './Listing';

const Dashboard = (props) => {
    const styles = dashboardStyles();
    const classes = styles();

    return (
        <Box className={classes.main}>
            <Header />
            <div className={classes.wrapper}>
                <Sidebar />
                <main className={classes.content}>
                    <Listing />
                </main>
            </div>
        </Box>
    )
}

export default Dashboard