import { Box, Container, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from "react-router-dom";
import { lookingUpStyles } from '../../../utils/hooks/useApplyStyles'
import LazyLoad from 'react-lazyload';
import featureIcon from '../../../assets/images/vfairs-ico.svg'

const FeaturePoints = () => {
    const styles = lookingUpStyles();
    const classes = styles();
    return (
        <Box className={classes.featurePointsBox}>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Link id="explore-on-demand" to={"/marketplace/?&event-status=on_demand"}>
                            <Box className={classes.featurePoint}>                            
                                <Box className={classes.featurePointIcon}>
                                <LazyLoad height={50}>
                                    <img src={featureIcon} alt="explore on-demand events" />
                                </LazyLoad>                                    
                                </Box>
                                <Typography variant='body1'>
                                    explore on-demand events
                                </Typography>                            
                            </Box>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Link id="top-free-events" to={"/marketplace/?&event-status=all&price-type=FREE"}>
                            <Box className={classes.featurePoint}>
                                <Box className={classes.featurePointIcon}>
                                    <LazyLoad height={50}>
                                        <img src={featureIcon} alt="Top Free events on your single click" />
                                    </LazyLoad>
                                </Box>
                                <Typography variant='body1'>
                                    Top Free events on your single click
                                </Typography>
                            </Box>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Link id="top-free-events" to={"/marketplace/?&event-status=all&event-mode=VIRTUAL"}>
                            <Box className={classes.featurePoint}>
                                <Box className={classes.featurePointIcon}>
                                    <LazyLoad height={50}>
                                        <img src={featureIcon} alt="check out virtual events" />
                                    </LazyLoad>
                                </Box>
                                <Typography variant='body1'>
                                    check out virtual events
                                </Typography>
                            </Box>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default FeaturePoints