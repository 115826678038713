import { Box, Container, Typography, Grid, Button } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
//import { Link } from "react-router-dom";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { homeBannerStyles } from '../../utils/hooks/useApplyStyles';
//import eventeerLogo from '../../assets/images/eventeer-logo.png';
import eventeerLogo from '../../assets/images/Logo_Eventeer-for-header-2.svg';
//import bannerImage from '../../assets/images/submit-nomination.png';
import moment from 'moment';

const BannerEventeerNomination = ({submitNomination}) => {
    const styles = homeBannerStyles();
    const classes = styles();
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        setInterval(() => {
            const now = moment().toDate();
            const time = Date.parse("2024-02-05") - Date.parse(now);
            const seconds = Math.floor((time / 1000) % 60);
            const minutes = Math.floor((time / 1000 / 60) % 60);
            const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
            const days = Math.floor(time / (1000 * 60 * 60 * 24));
            setDays(days < 0 ? "0" : days);
            setHours(hours < 0 ? hours * -1 : hours);
            setMinutes(minutes < 0 ? minutes * -1 : minutes);
            setSeconds(seconds < 0 ? seconds * -1 : seconds);
        }, 1000);
    }, []);
    return (
        <>
        <Box className={`${classes.bannerEventeevs} ${classes.bannerEventeerNom}`}>
            <Container maxWidth="lg">
                <Grid container alignItems="center">
                    <Grid item xs={12} lg={12}>
                        <div className={classes.bannerHomeContentEventeer}>
                            <img src={eventeerLogo} alt="Eventeer 2024 by vFairs" />                            
                            <Typography variant="h1" component="h1" gutterBottom className={`${classes.mainText} ${classes.eventtitle}`}>
                            vFairs Eventeer Awards: Honoring Excellence in Events
                            </Typography>
                            <Box className={classes.bannerEvneerBtns}>
                                {/* <Button variant="contained" color="primary" onClick={submitNomination}>Submit Vote</Button> */}
                                <a href='/eventeer-awards-2024-winners' className={classes.winnersCTA}>See 2024 Winners<ArrowForwardIcon /> </a>
                            </Box>
                        </div>
                    </Grid>
                    {/* <Grid item xs={12} lg={6}>
                        
                    </Grid> */}
                </Grid>
            </Container>
        </Box>
        {/* <Box className={classes.TimerSection}>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" component="h6">
                            Voting is officially closed now! 
                        </Typography>
                        <Typography variant='body1'>
                            The winners will be announced on Feb 5, 2024
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>                              
                        <Grid container spacing={3}>
                            <Grid item xs={6} md={3}>
                                <div className={classes.eventeerCBox}>
                                    <span className="number">{days}</span>
                                    <span>Days</span>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <div className={classes.eventeerCBox}>
                                    <span className="number">{hours}</span>
                                    <span>Hrs</span>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <div className={classes.eventeerCBox}>
                                    <span className="number">{minutes}</span>
                                    <span>Mins</span>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <div className={classes.eventeerCBox}>
                                    <span className="number">{seconds}</span>
                                    <span>Seconds</span>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box> */}
    </>
    )
}

export default BannerEventeerNomination