import React from "react";
import { Container, Typography, Box, Link, Breadcrumbs} from '@material-ui/core';
import { useCoverCardMediaStyles } from '@mui-treasury/styles/cardMedia/cover';
import { bannerStyles } from "../../utils/hooks/useApplyStyles";


export default function BannerEventDetail({ event_data }) {
    const styles1 = useCoverCardMediaStyles();
    const styles2 = bannerStyles();
    const classes = { ...styles1, ...styles2() }

    return (
        <>
            <Box position={'relative'} width={'100%'} height={'100%'}>
                <div className={classes.detailPageImageArea}>
                    <Container maxWidth="lg" >
                        <Breadcrumbs separator="›" aria-label="breadcrumb" style={{ marginBottom: 25, }} className={classes.whiteBreadcrumb}>
                            <Link color="inherit" href="/">
                                Home
                            </Link>
                            <Link color="inherit" href="/marketplace/">
                                Marketplace
                            </Link>
                            {event_data && event_data.data && event_data.data.data[0] && event_data.data.data[0].app_title &&
                                <Typography color="textPrimary">{event_data.data.data[0].app_title.replace(/<[^>]*(>|$)|&amp;|&gt;/g, '&')}</Typography>
                            }
                        </Breadcrumbs>
                    </Container>
                </div>
            </Box>
        </>
    );
};