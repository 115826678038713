import React, { useState } from "react";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Box,
  Typography,
  Checkbox,
  Divider,
  RadioGroup,
  Radio, InputBase, Button
} from "@material-ui/core";
import { cardsStyles } from '../../../utils/hooks/useApplyStyles'
import FilterTags from './FilterTags'
import FiltersDatePicker from './FilterDates'
//import Search from '@material-ui/icons/Search';
import { useBorderedInputBaseStyles } from '@mui-treasury/styles/inputBase/bordered';

export default function MarketplaceFilters({eventstatus, setEventStatus, postFilters, setPostFilters, allCats, setAllCats, allTags, setAllTags, searchTags, setSearchTags }) {

  const styles = cardsStyles();
  const classes = styles();
  const styles1 = useBorderedInputBaseStyles();
  const classes1 = { ...styles1, ...styles() }

  const [formatFilters, setFormatFilters] = useState({
    format_page: 5,
  })

  const formats_Arr = [
    'Multi-Employer Job Fair',
    'Single-Employer Career Fair',
    'Conference',
    'Summit',
    'Trade Show',
    'Open Day',
    'Education Fair',
    'Exhibition Fair',
    'Product Launch',
    'Motor Show',
    'Housing Fair',    
  ];
  
  return (

    <FormGroup container>
      <Typography variant="h6" component="p" gutterBottom>
        Date
      </Typography>

      <FiltersDatePicker postFilters={postFilters} setPostFilters={setPostFilters} setEventStatus={setEventStatus} />

      <Divider className={classes.filterDivider} />

      <Typography variant="h6" component="p" gutterBottom>
        Event Status
      </Typography>

      <RadioGroup
        aria-label="eventstatus"
        name="eventstatus-group"
      >
        <FormControlLabel value="all" control={<Radio />} label="All Events"
          onChange={(e) => {
            setEventStatus({ ...eventstatus, eventStatusValue: e.target.value })
            setPostFilters({ ...postFilters, eventStatus: e.target.value })
          }}
          checked={eventstatus.eventStatusValue === 'all'}
        />
        <FormControlLabel value="live" control={<Radio />} label="Live"
          onChange={(e) => {
            setEventStatus({ ...eventstatus, eventStatusValue: e.target.value })
            setPostFilters({ ...postFilters, eventStatus: e.target.value })
          }}
          checked={eventstatus.eventStatusValue === 'live'}
        />
        <FormControlLabel value="upcoming" control={<Radio />} label="Upcoming"
          onChange={(e) => {
            setEventStatus({ ...eventstatus, eventStatusValue: e.target.value })
            setPostFilters({ ...postFilters, eventStatus: e.target.value })
          }}
          checked={eventstatus.eventStatusValue === 'upcoming'}
        />
        <FormControlLabel value="on_demand" control={<Radio />} label="On-Demand"
          onChange={(e) => {
            setEventStatus({ ...eventstatus, eventStatusValue: e.target.value })
            setPostFilters({ ...postFilters, eventStatus: e.target.value })
          }}
          checked={eventstatus.eventStatusValue === 'on_demand'}
        />
        <FormControlLabel value="archive" control={<Radio />} label="Archived"
          onChange={(e) => {
            setEventStatus({ ...eventstatus, eventStatusValue: e.target.value })
            setPostFilters({ ...postFilters, eventStatus: e.target.value })
          }}
          checked={eventstatus.eventStatusValue === 'archive'}
        />
      </RadioGroup>

      <Divider className={classes.filterDivider} />
        
      <Box className={classes.filterHeadingReset}>
        <Typography variant="h6" component="p" gutterBottom>
          Event Mode
        </Typography>
        {postFilters.eventMode &&
            <Button
            variant="text"
            color="primary"
            onClick={() => setPostFilters({
              ...postFilters,
              eventMode: "",
              location: "",
            })}
            >Reset</Button>
          }
      </Box>
     
      <RadioGroup
        aria-label="eventmode"
        name="eventmode-group"
        onChange={(e) => setPostFilters({
          ...postFilters,
          eventMode: e.target.value,
        })}
      >
        <FormControlLabel value="VIRTUAL" control={<Radio />} label="Virtual Event" checked={postFilters.eventMode === 'VIRTUAL'} />
        <FormControlLabel value="HYBRID" control={<Radio />} label="Hybrid Event" checked={postFilters.eventMode === 'HYBRID'} />

      </RadioGroup>

      <Divider className={classes.filterDivider} />

      <Box className={classes.filterHeadingReset}>
        <Typography variant="h6" component="p" gutterBottom>
        Price
        </Typography>

        {postFilters.price &&
          <Button
            variant="text"
            color="primary"
            onClick={() => setPostFilters({
              ...postFilters,
              price: "",
              from_price: "",
              to_price: "",
            })}
          >Reset</Button>
        }
      </Box>
     
      <RadioGroup
        aria-label="price"
        name="price-group"
        onChange={(e) => setPostFilters({
          ...postFilters,
          price: e.target.value,
        })}

      >
        <FormControlLabel value="FREE" control={<Radio />} label="Free" checked={postFilters.price === 'FREE'} />
        <FormControlLabel value="PAID" control={<Radio />} label="Paid" checked={postFilters.price === 'PAID'} />
      </RadioGroup>

      {postFilters.price && postFilters.price === "PAID" &&
        <>
          <Typography variant="body1" component="p" style={{ fontWeight: 'bold', color: '#6D7A84', }}>
            Price Range($)
          </Typography>
          <Box className={classes.priceBox} >
            <InputBase
              type={"number"}
              className={classes1.filterSearchBox}
              placeholder={'Min.'}
              value={postFilters.from_price}
              onChange={(p) => setPostFilters({ ...postFilters, from_price: p.target.value })}

            />
            <InputBase
              type={"number"}
              className={classes1.filterSearchBox}
              placeholder={'Max.'}
              value={postFilters.to_price}
              onChange={(p) => setPostFilters({ ...postFilters, to_price: p.target.value })}
            />
          </Box>
        </>
      }

      <Divider className={classes.filterDivider} />

      <Box className={classes.filterHeadingReset}>
        <Typography variant="h6" component="p" gutterBottom>
          Sponsorship
        </Typography>
      </Box>
     
      <FormControlLabel
        control={
          <Checkbox
            name="open_for_sponsorship"
            value="1"
            color="primary"
            checked={postFilters.OpenForSponsorship}
            onChange={(e) => setPostFilters({
              ...postFilters,
              OpenForSponsorship: e.target.checked ? "1" : setPostFilters({
                ...postFilters,
                OpenForSponsorship: "",
              }),
            })}
          />
        }
        label="Open for Sponsorship"
      />

      {/* {postFilters.eventMode && postFilters.eventMode === "HYBRID" && (
        <InputBase
          className={classes1.filterSearchBox}
          placeholder={'Choose Event Location'}
          onChange={(l) => setPostFilters({ ...postFilters, location: l.target.value.toLowerCase() })}
          value={postFilters.location}
          startAdornment={<Search />}
        />
      )} */}
      
      <Divider className={classes.filterDivider} />

      <Typography variant="h6" component="p" gutterBottom>
        Featured
      </Typography>

      <FormControlLabel
        control={
          <Checkbox
            name="featured"
            value="1"
            color="primary"
            checked={postFilters.featured}
            onChange={(e) => setPostFilters({
              ...postFilters,
              featured: e.target.checked ? "1" : setPostFilters({
                ...postFilters,
                featured: "",
              }),
            })}
          />
        }
        label="Featured"
      />
      
      <Divider className={classes.filterDivider} />

      {allCats && allCats.total_count && allCats.total_count > 0 && (
        <Typography variant="h6" component="p" gutterBottom>
          Topics
        </Typography>
      )}

      {allCats &&
        allCats.total_count &&
        allCats.total_count > 0 &&
        allCats.data.map(cat =>
          <FormControlLabel
            control={
              <Checkbox
                name={cat.name}
                color="primary"
                checked={postFilters.categories.includes(cat.name)}
                onChange={(e) => setPostFilters({
                  ...postFilters,
                  categories: e.target.checked ?
                    [...postFilters.categories, cat.name] :
                    postFilters.categories.filter(item => item !== cat.name),
                })}
              />
            }
            label={cat.name}
          />
        )
      }

      <Divider className={classes.filterDivider} />

      <FilterTags
        postFilters={postFilters} setPostFilters={setPostFilters}
        allCats={allCats} setAllCats={setAllCats}
        allTags={allTags} setAllTags={setAllTags}
        searchTags={searchTags} setSearchTags={setSearchTags}
      />

      <Divider className={classes.filterDivider} />

      <Typography variant="h6" component="p" gutterBottom>
        Formats
      </Typography>

      {formats_Arr && formats_Arr.slice(0, formatFilters.format_page).map(format =>
        <FormControlLabel
          control={
            <Checkbox
              name={format}
              color="primary"
              checked={postFilters.formats.includes(format)}
              onChange={(e) => setPostFilters({
                ...postFilters,
                formats: e.target.checked ?
                  [...postFilters.formats, e.target.name] :
                  postFilters.formats.filter(item => item !== e.target.name)
              })}
            />
          }
          label={format}
        />
      )}
      
      <Box mt={4} textAlign={"center"} className={classes.filderButton}>
        {!!formats_Arr && formats_Arr.length > formatFilters.format_page && (
          <Button
            variant="text"
            color="primary"
            onClick={() => setFormatFilters({
              ...formatFilters,
              format_page: formatFilters.format_page + 5,
            })}
          >
            
            More
          </Button>
          )
        }
        {!!formats_Arr &&
          formatFilters.format_page > 5 &&(
            <Button
              variant="text"
              color="primary"
              onClick={() => setFormatFilters({
                ...formatFilters,
                format_page: formatFilters.format_page - 5,
              })}
            >
              Less
            </Button>
            )
          }
          </Box>
        
    </FormGroup>
  );
}