import React from "react";
import ScrollToTop from '../../components/ScrollToTop'
//import BannerNomineeDetail from '../Banners/BannerNomineeDetail'
import DiscoverFooter from '../Footer/Footer'
import NavigationBar from '../Header/Navbar'
import NomineeBox from './action/NomineeBox'

const NomineeDetail = () => {

    return (
        <div className="application">
            <ScrollToTop />
            <NavigationBar />            
            {/* <BannerNomineeDetail/>*/}
            <NomineeBox/>
            <DiscoverFooter />
        </div>
    )
}

export default NomineeDetail