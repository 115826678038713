import React, { useEffect, useState } from "react";
import axios from "axios";
import NavigationBar from "../Header/Navbar";
//import HomeBanner from '../Banners/BannerHomePage'
import BannerHome from '../Banners/BannerHome'
import DiscoverFooter from '../Footer/Footer'
// import TopEventCards from './HomePageSections/TopEvents'
import UpCommingEventCards from './HomePageSections/UpCommingEvents'
//import HybridEventCards from './HomePageSections/HybridEvents'
import FreeEventCards from './HomePageSections/FreeEvents'
//import RecentlyAddedCards from './HomePageSections/RecentlyAdded'
import BrowseByTopicSec from './HomePageSections/BrowseByTopic'
//import EventFormat from './HomePageSections/EventFormat'
import moment from 'moment'
//import { Helmet } from "react-helmet";
import { LinearProgress } from "@material-ui/core";
import PopularTags from "./HomePageSections/PopularTags";
import ScrollToTop from '.././../components/ScrollToTop';
import CollectionsCards from "./HomePageSections/CollectionsCards";
import FeatureEvents from "./HomePageSections/FeatureEvents";
import FeaturePoints from "./HomePageSections/FeaturePoints";
import FeatureCTASec from '../Footer/FeatureCTASec'

export default function HomeMain() {
    window.dataLayer.push({
        event: 'pageview',
        page: {
            url: window.location.href,
            title: "Home"
        }
    });
    const eventsBaseURL = `${global.API.rootPath}${global.config.endpoints.events_endpoint}?access_key=${global.config.access_key}&page=1&limit=12&get_event_tag=true`;
    const featuredEeventsBaseURL = `${global.API.rootPath}${global.config.endpoints.featured_events_endpoint}?access_key=${global.config.access_key}&page=1&limit=12&get_event_tag=true`;

    const [eventsFilters, setEventsFilters] = useState({
        price_type: "",
        featured_type: "all-featured-events",
        upcoming_type: "all-upcoming",
    })

    const [featuredpost, setFeaturedPost] = useState({
        data: null,
        isLoading: false,
        error: "",
    });
    //fetch feature events for <FeatureEvents>
    useEffect(() => {
        setFeaturedPost({ ...featuredpost, isLoading: true });
        
        axios.get(featuredEeventsBaseURL + `${eventsFilters.featured_type === "free-featured-events" ? '&price_type=FREE' : ''}${eventsFilters.featured_type === "paid-featured-events" ? '&price_type=PAID' : ''}`).then((response) => {
            setFeaturedPost({ ...featuredpost, data: response.data, isLoading: false });
        }).catch((err) => setFeaturedPost({ ...featuredpost, error: err, isLoading: false }));
    }, [eventsFilters.featured_type]);

    const [upcomingpost, setUpcomingPost] = useState({
        data: null,
        isLoading: false,
        error: "",
    });
    //fetch Upcoming events for <UpCommingEventCards>
    useEffect(() => {
        setUpcomingPost({ ...upcomingpost, isLoading: true });
        let startDate = moment().add(1, 'days').format("YYYY-MM-DD");
        let endDate = "";
        if (eventsFilters.upcoming_type && eventsFilters.upcoming_type === "this-week") {
            //from today to this week end
            endDate = moment().endOf('isoWeek').format("YYYY-MM-DD");
        }
        else if (eventsFilters.upcoming_type && eventsFilters.upcoming_type === "next-week") {
            //from next weekday to weekend
            startDate = moment().add(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD");
            endDate = moment().add(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD")
        }
        else if (eventsFilters.upcoming_type && eventsFilters.upcoming_type === "this-month") {
            //from today to this month end
            endDate = moment().endOf('month').format("YYYY-MM-DD");
        }
        axios.get(eventsBaseURL + `${eventsFilters.upcoming_type === "recently-added" ? "&sort_by_created_at=desc" : "&sort_by_event_start_date=asc" }${startDate ? "&from_date=" + startDate : ""}${endDate ? "&to_date=" + endDate : ""}`).then((response) => {
            setUpcomingPost({ ...upcomingpost, data: response.data, isLoading: false });
        }).catch((err) => setUpcomingPost({ ...upcomingpost, error: err, isLoading: false }));
    }, [eventsFilters.upcoming_type]);

    const [freeEvents, setFreeEvents] = useState({
        data: null,
        isLoading: false,
        error: "",
    });
    //fetch free events for <FreeEventCards>
    useEffect(() => {
        setFreeEvents({ ...freeEvents, isLoading: true });
        axios.get(eventsBaseURL + `&price_type=FREE&sort_by_event_start_date=desc`).then((response) => {
            setFreeEvents({ ...freeEvents, data: response.data, isLoading: false });
        }).catch((err) => setFreeEvents({ ...freeEvents, error: err, isLoading: false }));
    }, []);

    return (

        <div className="application">
            <ScrollToTop />
            <NavigationBar />
            <BannerHome />
            {(featuredpost.isLoading || upcomingpost.isLoading || freeEvents.isLoading) &&
                <div>
                    <LinearProgress />
                </div>
            }
            <PopularTags />
            <FeatureEvents events={featuredpost} eventsFilters={eventsFilters} setEventsFilters={setEventsFilters} />
            <BrowseByTopicSec />
            <FeaturePoints />
            <UpCommingEventCards events={upcomingpost} eventsFilters={eventsFilters} setEventsFilters={setEventsFilters} />
            {/* <RecentlyAddedCards events={recentpost} /> */}
            {/* <HybridEventCards events={hybridpost} eventsFilters={eventsFilters} setEventsFilters={setEventsFilters} /> */}
            <FreeEventCards events={freeEvents} />
            <CollectionsCards />
            {/* <EventFormat /> */}
            <FeatureCTASec />
            <DiscoverFooter />
        </div>
    );
}