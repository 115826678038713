import React, { useEffect, useState } from "react";
import { CategoryProvider, CategoryItem } from '@mui-treasury/components/menu/category';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Box, Grid, IconButton, Typography, Button, Container } from '@material-ui/core';
import { useFirebaseBtnStyles } from '@mui-treasury/styles/button/firebase';
import NewsLetter from './SubscribeSec'
import { footerStyles } from '../../utils/hooks/useApplyStyles'
import badge1 from '../../assets/images/badge1.svg'
import badgeCA from '../../assets/images/CA_Badge.png'
import badge3 from '../../assets/images/EventMarketing_Leader_Small-Business_Leader.svg'
import badge4 from '../../assets/images/EventNetworkingandMatchmaking_BestRelationship_Total.svg'
import badge5 from '../../assets/images/EventManagementPlatforms_Leader_Leader.svg'
import badge6 from '../../assets/images/EventManagementPlatforms_MomentumLeader_Leader.svg'
import badge7 from '../../assets/images/VirtualEventPlatforms_BestUsability_Enterprise_Total.svg'
import LazyLoad from 'react-lazyload';
import CopyRight from './CopyRight';
import LocationsFooter from './LocationsSec';
import ConnectSecFooter from './ConnectSecFooter';


export default function DiscoverFooter() {

    const styles1 = useFirebaseBtnStyles();
    const styles2 = footerStyles();
    const classes = { ...styles1, ...styles2() }

    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };
    // Set the top cordinate to 0
    // make scrolling smooth
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
    }, []);
    return (
        <div className="footer_wrap">
            <div >
                {isVisible &&
                    <div onClick={scrollToTop} style={{ textAlign: 'center' }} className={classes.scrolltioIconc}>
                        <IconButton aria-label="Scroll to top" >
                            <ArrowUpwardIcon fontSize="small" />
                        </IconButton>
                    </div>}
            </div>

            <NewsLetter />
            <Box className={classes.basicBadges}>
                <Container maxWidth="false" disableGutters="false">
                    <Grid container>
                        <Grid item xs={12} sm={4}>
                            <Box className={classes.logoInfo}>
                                <LazyLoad height={160}>
                                    <Box
                                        component={'img'}
                                        width={160}
                                        src={'https://www.vfairs.com/wp-content/themes/vfairs/assets/img/footer/logo-footer.svg'}
                                        alt="footerlogo"
                                    />
                                </LazyLoad>
                                <Typography variant="body1">Highest rated hybrid, in-person and <br /> virtual events platform</Typography>
                                <Button variant="containedPrimary" color="primary" href="https://www.vfairs.com/contact-us/" target="_blank">
                                    Talk to us
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <ul className={classes.fbadges}>
                                <li>
                                    <a rel="nofollow, noreferrer" href="https://www.capterra.com/p/149104/vFairs/" target="_blank">
                                        <LazyLoad height={130}>
                                            <Box
                                                component={'img'}
                                                src={badge1}
                                                width={138}
                                            />
                                        </LazyLoad>
                                    </a>
                                </li>
                                <li>
                                    <LazyLoad height={130}>
                                        <Box
                                            component={'img'}
                                            src={badgeCA}
                                            width={138}
                                        />
                                    </LazyLoad>
                                </li>
                                <li>
                                    <LazyLoad height={100} style={{display: 'inline-block', paddingTop: 7}}>
                                        <Box
                                            component={'img'}
                                            src={'https://b.sf-syn.com/badge_img/2981082/customers-love-us-white?&amp;variant_id=sf&amp;r=https://www.vfairs.com/'}
                                            width={100}
                                        />
                                    </LazyLoad>
                                </li>
                                <li>
                                    <a rel="nofollow, noreferrer" href="https://www.g2.com/products/vfairs/reviews?focus=true&order=most_helpful" target="_blank">
                                        <LazyLoad height={130}>
                                            <Box
                                                component={'img'}
                                                src={badge3}
                                                width={100}
                                            />
                                        </LazyLoad>
                                    </a>
                                </li>
                                <li>
                                    <a rel="nofollow, noreferrer" href="https://www.g2.com/products/vfairs/reviews?focus=true&order=most_helpful" target="_blank">
                                        <LazyLoad height={130}>
                                            <Box
                                                component={'img'}
                                                src={badge4}
                                                width={100}
                                            />
                                        </LazyLoad>
                                    </a>
                                </li>
                                <li>
                                    <a rel="nofollow, noreferrer" href="https://www.g2.com/products/vfairs/reviews?focus=true&order=most_helpful" target="_blank">
                                        <LazyLoad height={130}>
                                            <Box
                                                component={'img'}
                                                src={badge5}
                                                width={100}
                                            />
                                        </LazyLoad>
                                    </a>
                                </li>
                                <li>
                                    <a rel="nofollow, noreferrer" href="https://www.g2.com/products/vfairs/reviews?focus=true&order=most_helpful" target="_blank">
                                        <LazyLoad height={130}>
                                            <Box
                                                component={'img'}
                                                src={badge6}
                                                width={100}
                                            />
                                        </LazyLoad>
                                    </a>
                                </li>
                                <li>
                                    <a rel="nofollow, noreferrer" href="https://www.g2.com/products/vfairs/reviews?focus=true&order=most_helpful" target="_blank">
                                        <LazyLoad height={130}>
                                            <Box
                                                component={'img'}
                                                src={badge7}
                                                width={100}
                                            />
                                        </LazyLoad>
                                    </a>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box pt={3} pb={3}>
                <Container maxWidth="false" disableGutters="false">
                    <Box className={classes.fLinkInner}>
                        <Grid container spacing={4} className={classes.footerColumns}>
                            <Grid item xs={12} md={2} sm={6} className={classes.mobJustify}>
                                <CategoryProvider >
                                    <Typography variant="h6" component="p" className={classes.footerColumnsHeading}>Company</Typography>
                                    <CategoryItem href="https://www.vfairs.com/why-vfairs/" target="_blank">Why vFairs</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/features/" target="_blank">Features</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/customers/" target="_blank">Customers</CategoryItem>
                                    <CategoryItem href="https://vfairs.breezy.hr/" target="_blank">Careers</CategoryItem>                                    
                                    <CategoryItem href="https://www.vfairs.com/partner-affiliate-program/" target="_blank">Partner/Reseller Program</CategoryItem>                                    
                                    <CategoryItem href="https://www.vfairs.com/av-production-partners/" target="_blank">A/V Production Partners</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/referral-program/" target="_blank">Customer Referral Program</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/green-and-global/" target="_blank">Green & Global Program</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/rfp/" target="_blank">Submit a RFP</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/meet-vfairs/" target="_blank">Meet vFairs</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/pricing/" target="_blank">vFairs Pricing</CategoryItem>

                                    <Typography variant="h6" component="p" className={classes.footerColumnsHeading}>Industries</Typography>
                                    <CategoryItem href="https://www.vfairs.com/industries/university/" target="_blank">University</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/industries/health/" target="_blank">Healthcare & Pharma</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/industries/information-technology/" target="_blank">Information Technology</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/industries/retail/" target="_blank">Retail</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/industries/financial-services/" target="_blank">Financial Services</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/industries/event/" target="_blank">Event Services</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/industries/telecom/" target="_blank">Telecommunication</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/industries/nonprofit/" target="_blank">Nonprofit</CategoryItem>
                                </CategoryProvider>
                            </Grid>
                            
                            <Grid item xs={12} md={2} sm={6} className={classes.mobJustify}>
                                <CategoryProvider >
                                <Typography variant="h6" component="p" className={classes.footerColumnsHeading}>Experiences</Typography>                                
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/in-person-events/" target="_blank">In-Person Event</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/hybrid-event/" target="_blank">Hybrid Event Platform</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-events/" target="_blank">Virtual Events</CategoryItem>

                                    <Typography variant="h6" component="p" className={classes.footerColumnsHeading}>Features</Typography>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/event-registration-software/" target="_blank">Event Registration</CategoryItem> 
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/event-ticketing-software/" target="_blank">Event Ticketing</CategoryItem> 
                                    <CategoryItem href="https://www.vfairs.com/features/virtual-environment/" target="_blank">Virtual Environment</CategoryItem>                                    
                                    <CategoryItem href="https://www.vfairs.com/features/virtual-exhibit-hall/" target="_blank">Virtual Exhibit Hall</CategoryItem>
                                    
                                    <CategoryItem href="https://www.vfairs.com/features/ai-powered-events/" target="_blank">AI Powered Events</CategoryItem>                                                                      
                                    <CategoryItem href="https://www.vfairs.com/features/webinar/" target="_blank">Webinar</CategoryItem>                                    
                                    <CategoryItem href="https://www.vfairs.com/features/virtual-networking-chat/" target="_blank">Chat and Networking</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/features/event-gamification/" target="_blank">Event Gamification</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/features/event-sponsorships/" target="_blank">Event Sponsorships</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/features/event-builder/" target="_blank">Event Builder</CategoryItem>                                    
                                    <CategoryItem href="https://www.vfairs.com/features/custom-virtual-events/" target="_blank">Specialized Features</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/features/accessibility/" target="_blank">Accessibility Features</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/features/mobile-event-app/" target="_blank">Mobile Event App</CategoryItem>
                                </CategoryProvider>
                            </Grid>
                            <Grid item xs={12} md={2} sm={6} className={classes.mobJustify}>
                                <CategoryProvider>
                                    <Typography variant="h6" component="p" className={classes.footerColumnsHeading}>Trade Shows & Conferences</Typography>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-trade-shows/" target="_blank">Virtual Trade Show Platform</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-exhibition-fair/" target="_blank">Virtual Conference Platform</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-exhibition-fair/" target="_blank">Virtual Exhibition Platform</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-product-launch/" target="_blank">Virtual Product Launch Platform</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-food-show/" target="_blank">Virtual Food Show Platform</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-property-show/" target="_blank">Virtual Property Show Platform</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-summit/" target="_blank">Virtual Summit Platform</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/hybrid-trade-show/" target="_blank">Hybrid Trade Show</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/in-person-trade-show/" target="_blank">In Person Trade Show</CategoryItem>


                                    <Typography variant="h6" component="p" className={classes.footerColumnsHeading}>Recruitment & HR</Typography>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-career-fair/" target="_blank">Virtual Career Fair Platform</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-job-fair/" target="_blank">Virtual Job Fair Platform</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-diversity-hiring-fair/" target="_blank">Virtual Diversity Hiring Fair Platform</CategoryItem>
                                </CategoryProvider>
                            </Grid>
                            <Grid item xs={12} md={2} sm={6} className={classes.mobJustify}>
                                <CategoryProvider>
                                    <Typography variant="h6" component="p" className={classes.footerColumnsHeading}>Education</Typography>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-university-job-fair/" target="_blank">Virtual University Job Fair Platform</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-open-day/" target="_blank">Virtual Open Day Platform</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-education-fair/" target="_blank">Virtual Education Fair Platform</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-graduation-ceremony/" target="_blank">Virtual Graduation Platform</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/alumni-networking-event/" target="_blank">Virtual Alumni Networking Platform</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-housing-fair/" target="_blank">Virtual Housing Fair Platform</CategoryItem>

                                    <Typography variant="h6" component="p" className={classes.footerColumnsHeading}>Internal Events</Typography>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-onboarding-fair/" target="_blank">Virtual Onboarding Fair</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/event-management-platform/virtual-benefits-fair/" target="_blank">Virtual Benefits Fair Platform</CategoryItem>
                                </CategoryProvider>
                            </Grid>
                            <Grid item xs={12} md={2} sm={6} className={classes.mobJustify}>
                                <CategoryProvider>
                                    <Typography variant="h6" component="p" className={classes.footerColumnsHeading}>Value Adds</Typography>
                                    <CategoryItem href="https://www.vfairs.com/features/virtual-event-marketing/" target="_blank">Virtual Event Marketing</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/features/customer-support/" target="_blank">Customer Support</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/features/mobile-event-app/" target="_blank">Mobile Event App</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/features/integrations/" target="_blank">Integrations</CategoryItem>                                    
                                    <CategoryItem href="https://www.vfairs.com/features/event-report/" target="_blank">Event Report</CategoryItem>

                                    <Typography variant="h6" component="p" className={classes.footerColumnsHeading}>Resources</Typography>
                                    <CategoryItem href="https://www.vfairs.com/resources/guides/" target="_blank">Guides</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/resources/product-updates/" target="_blank">Product Updates</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/resources/news/" target="_blank">News</CategoryItem>
                                    <CategoryItem href="https://www.vfairs.com/resources/blogposts/" target="_blank">Blog</CategoryItem>                                    
                                    <CategoryItem href="https://www.vfairs.com/resources/case-studies/" target="_blank">Case Studies</CategoryItem>
                                    
                                </CategoryProvider>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
            <Box className={classes.fwrapBox}>
                {/* Section Connect */}
                <ConnectSecFooter />
                {/* Section Locations */}
                {/* <LocationsFooter /> */}
            </Box>

            {/* Section CopyRight */}
            <CopyRight />
        </div>
    );
};