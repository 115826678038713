import { useEffect,useState } from "react";
import Cookies from 'universal-cookie';
import MarkePlacetMain from '../containers/Marketplace/MarketplaceMain';
import EventDetailtMain from '../containers/EventDetail/EventDetail';
import HomeMain from '../containers/HomePage/HomeMain';
import { ThemeProvider } from '@material-ui/core';
import theme from '../components/theme/basetheme/GradientvFairs';
import LoginMain from '../containers/Login/LoginPage';
import CreateAccountMain from '../containers/Login/CreateAccountPage';
import ForgetPasswordMain from '../containers/OnBoarding/ForgotPasswordPage';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import LoaderCard from "../containers/Loader/LoaderCard";
import Collection from "../containers/Collection/Collection";
import CollectionDetail from "../containers/Collection/CollectionDetail";
//import EventeerAwards from "../containers/Eventeer/EventeerAwards";
import Nominee from "../containers/Nominee/Index";
import Nominee2024 from "../containers/Eventeer2024/Nominee/Index";
import NomineeDetail from "../containers/NomineeDetail/Index";
import NomineeDetail2024 from "../containers/Eventeer2024/NomineeDetail/Index";
//import EventeerAwardsNomination from "../containers/EventeerNomination/EventeerAwardsNomination";
import EventeerAwardsNomination2024 from "../containers/Eventeer2024/EventeerNomination/EventeerAwardsNomination2024";
import WinnerPage from "../containers/WinnerPage/Index";
import WinnerPage2024 from "../containers/Eventeer2024//WinnerPage/Index";
import Dashboard from "../Dashboard/EventListing/Index";
import NewEvent from "../Dashboard/NewEvent/Index";
import EditEvent from "../Dashboard/EditEvent/Index";
import OnBoardingLogin from "../containers/OnBoarding/Login/OnBoardingLogin";
import OnBoardingSignUp from "../containers/OnBoarding/SignUp/OnBoardingSignUp";
import CreateAnEvent from "../containers/OnBoarding/CreateAnEvent/CreateAnEvent";
import EditProfile from "../Dashboard/EditProfile/Index";


export default function MarketPlace() {
  
  const cookies = new Cookies();

  const [verification, setVerification] = useState({
    status: "",
    msg: "",
    isLoading: true,
    error: "",
  });

  useEffect(() => {
    let token;
    if(cookies && cookies.get('onBoardingToken')){
        token = cookies.get('onBoardingToken');
    
        const BASE_URL = `${global.API.rootPath}${global.config.endpoints.onboarding_login}`;
    
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("access_token", token);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(BASE_URL, requestOptions)
            .then(response => response.text())
            .then(result => {
              const data = JSON.parse(result);
              setVerification({ ...verification, status: data.status, msg: data.msg, isLoading: false })
            })
            .catch(error => {
              alert(error)
              //const data = JSON.parse(error);
              //setVerification({ ...verification, status: false, error:  data.error, isLoading: false })
            });
    } else {
          setVerification({ ...verification, status:  false, isLoading: false })
    }
  }, []);
  
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/login">
              <LoginMain />
          </Route>
          <Route path="/join">
            <CreateAccountMain />
          </Route>            
          <Route path="/marketplace">
            <MarkePlacetMain />
          </Route>
          <Route path="/eventeer-awards-2023/:collection_id/nominee/:event_id">
            <NomineeDetail />
          </Route>
          <Route path="/eventeer-awards-2024/:collection_id/nominee/:event_id">
            <NomineeDetail2024 />
          </Route>
          <Route path="/events/:id/:event_slug">
            <EventDetailtMain />
          </Route>
          <Redirect from="/events/:id" to="/marketplace" />          
          <Route path="/collections/:id/:collection_slug">
            <CollectionDetail />
          </Route>
          <Route path="/collections">
            <Collection />
          </Route>
          <Route path="/eventeer-awards-2023-winners">
            <WinnerPage />
          </Route>
          <Route path="/eventeer-awards-2024-winners">
            <WinnerPage2024 />
          </Route>
          <Route path="/eventeer-awards-2023/:id/:nominee_slug">
            <Nominee />
          </Route>
          <Route path="/eventeer-awards-2024/:id/:nominee_slug">
            <Nominee2024 />
          </Route>
          <Route path="/eventeer-awards-2023">
            <Redirect from="/eventeer-awards-2023" to="/eventeer-awards-2024/" />
          </Route>
          <Route path="/eventeer-awards-2023-nomination">
          <Redirect from="/eventeer-awards-2023-nomination" to="/eventeer-awards-2024/" />
          </Route>
          <Route path="/eventeer-awards-2024">
            <EventeerAwardsNomination2024 />
          </Route>
          <Redirect from="/collections/:id" to="/marketplace" />
          <Route path="/create-an-event">
            <CreateAnEvent />
          </Route>
          <Route path="/onboarding-login">
            <OnBoardingLogin />
          </Route>
          <Route path="/onboarding-signup">
            <OnBoardingSignUp />
          </Route>
          <Route path="/onboarding-forgot-password">
            <ForgetPasswordMain />
          </Route> 
          <Route path="/onboarding-user-dashboard">
            {verification && verification.isLoading ?
              <>
                <LoaderCard />
                <LoaderCard />
              </>
              : verification && !verification.isLoading && verification.status && verification.msg === "Logged in Successfully."
              ? <Dashboard />
              : <Redirect from="/onboarding-user-dashboard" to="/onboarding-login" />
            }
          </Route>
          <Route path="/onboarding-profile">
            {verification && verification.isLoading ?
              <>
                <LoaderCard />
                <LoaderCard />
              </>
              : verification && !verification.isLoading && verification.status && verification.msg === "Logged in Successfully."
              ? <EditProfile />
              : <Redirect from="/onboarding-profile" to="/onboarding-login" />
            }            
          </Route>
          <Route path="/onboarding-new-event">
            {verification && verification.isLoading ?
              <>
                <LoaderCard />
                <LoaderCard />
              </>
              : verification && !verification.isLoading && verification.status && verification.msg === "Logged in Successfully."
              ? <NewEvent />
              : <Redirect from="/onboarding-new-event" to="/onboarding-login" />
            }
          </Route>
          <Route path="/onboarding-edit-event/:event_id">
            {verification && verification.isLoading ?
              <>
                <LoaderCard />
                <LoaderCard />
              </>
              : verification && !verification.isLoading && verification.status && verification.msg === "Logged in Successfully."
              ? <EditEvent />
              : <Redirect from="/onboarding-edit-event/:event_id" to="/onboarding-login" />
            }
          </Route>
          <Route path="/">
            <HomeMain />
          </Route>          
        </Switch>
      </Router>
    </ThemeProvider>
  );
}