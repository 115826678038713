import React from 'react';
import {Grid,Card} from '@material-ui/core';
import {cardsStyles} from "../../../utils/hooks/useApplyStyles";
import CardContent from './CardContent'


export default function ListCards(props) {

   const styles = cardsStyles();
   const classes = styles();

   const { events } = props;

   return (
    <>
      <Grid className={classes.listCardWrap}>
      {events.data.map((event) => {
         return (
            <>
               <Card className={classes.cardList}>
                  <CardContent event_data={event}/>
               </Card>
            </>
         );
      })}
      </Grid>
    </>
  );
};

