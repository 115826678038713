import React, { useState } from "react";
import clsx from 'clsx';
import { Card } from '@material-ui/core';
import { useN01TextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/n01';
import { useWideCardMediaStyles } from '@mui-treasury/styles/cardMedia/wide';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import { exhibitorCardStyles } from "../../../utils/hooks/useApplyStyles";
import 'react-multi-carousel/lib/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';

export default function ExhibitorCard({ exhibitor, handleClose, open }) {
    const styles1 = useWideCardMediaStyles();
    const styles2 = useFadedShadowStyles();
    const styles3 = useN01TextInfoContentStyles();
    const styles4 = exhibitorCardStyles();
    const classes = { ...styles1, ...styles2, ...styles3, ...styles4() }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500,}}
        > 
            <Fade in={open}>
                <div className={clsx(classes.paper, classes.exhibitormModal)}>
                <button className={classes.closeModalCustom} onClick={handleClose}><CloseIcon /></button>
                    {exhibitor && exhibitor.booths && exhibitor.booths.booth_logo_url &&
                        <img classes={styles1}
                        className={classes.exhibitImageMain} src={"https://vepimg.b8cdn.com" + exhibitor.booths.booth_logo_url} />
                    }
                    {/* {!!exhibitor && exhibitor.first_name &&
                        <h2 id="transition-modal-title">{exhibitor.first_name}</h2>
                    } */}
                    {!!(exhibitor && exhibitor.booths && exhibitor.booths.description_ar) ? <p id="transition-modal-description" className={classes.desriptionImageBoothDetail} dangerouslySetInnerHTML={{ __html: exhibitor.booths.description_ar.replace(/\<a /g, '<a rel="nofollow"' ) }}></p> : ""}
                </div>
            </Fade>
        </Modal>
    );
};