import React from "react";
import { collectionBannerStyles } from "../../utils/hooks/useApplyStyles";
import { Box, Typography, Container, Breadcrumbs, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import collectionBG from '../../assets/images/collectionheader.png';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        background: '#FFFFFF',
        borderRadius: 12,
        padding: theme.spacing(3),
        width: 512,

    },
}));


export default function CollectionBanner ({collections}) {
    const styles2 = collectionBannerStyles();
    const classes = { ...styles2(), ...useStyles() }

    return (
        <>
            <Box variant="div" className={classes.collectionBanner} style={{ backgroundImage: `url(${collectionBG})`, boxShadow: "none"}}>
                <Container maxWidth="lg">
                    <Box variant='div' className={classes.breadcrumbCollection} pt={4}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            <Link color="inherit" href="/">
                                Home
                            </Link>                            
                                <Typography color="textPrimary">Collections</Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box variant="div" className={classes.allCollectionBannerContent}>
                            <Typography variant='h1'>Discover Curated Collections that bring Events to Life</Typography>
                            <Typography variant='body1'>Explore our thoughtfully curated collections, showcasing a diverse range of events and experiences. From unforgettable conferences to inspiring workshops, find the perfect gathering that matches your interests and passions. Unleash your eventful journey with our handpicked selection.</Typography>
                    </Box>
                </Container>
            </Box>
        </>
    )
};