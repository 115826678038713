import { Box, Button, Typography, Container, Breadcrumbs, IconButton } from '@material-ui/core';
import { useParams } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState,useRef } from "react";
import { Link } from "react-router-dom";
import { nomineeDetail, collectionBannerStyles, homeBannerStyles  } from '../../../../utils/hooks/useApplyStyles'
//import Modal from '@material-ui/core/Modal';
//import Backdrop from '@material-ui/core/Backdrop';
//import Fade from '@material-ui/core/Fade';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import shareIcon from '../../../../assets/images/copy.png';
import LoaderLines from "../../../Loader/LoaderLines";
import LoaderBox from "../../../Loader/LoaderBox";
//import Reaptcha from 'reaptcha';
//import {Async,FieldFeedback, FieldFeedbacks, FormWithConstraints} from 'react-form-with-constraints-material-ui';
//import CloseIcon from '@material-ui/icons/Close';
//import moment from 'moment';

// Social 
import Facebook from '../../../../assets/images/Facebook.png';
import Twitter from '../../../../assets/images/Twitter.png';
import WhatsApp from '../../../../assets/images/whatsapp.png';
import LinkedIn from '../../../../assets/images/LinkedIn.png';
import Email from '../../../../assets/images/Email.png';
import Placeholder from '../../../../assets/images/Event-Card-Placeholder-1.png';
//import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton } from "react-share";

// Vote Auth
//import voteAuth from '../../../../assets/images/vote-auth.png';
//import DiscoverCategoriesSlider from '../../EventeerNomination/EventeerSection/DiscoverCategoriesSlider';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
//import thanks from '../../../../assets/images/thanks.png';
import Winner from '../../../../assets/images/Eventeer-24-Badge-Winner.png';

var settings = {
    dots: true,
    arrows: true,
    infinite: false,
    draggable: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <ArrowForwardIcon />,
    prevArrow: <ArrowBackIcon />,
    useTransform: false,

};

const NomineeBox = () => {
    const styles = nomineeDetail();
    const styles1 = homeBannerStyles();
    const styles2 = collectionBannerStyles();
    const classes = { ...styles(), ...styles1(), ...styles2() }

    const { collection_id, event_id } = useParams();

    //const GET_COLLECTION_API = `${global.API.rootPath}${global.config.endpoints.collections_endpoint}?access_key=${global.config.access_key}&collection_id=${collection_id}`;

    const [collection, setCollection] = useState({
        data: null,
        isLoading: false,
        error: "",
    });
    useEffect(() => {
        setCollection({ ...collection, isLoading: true });
        axios.get(`../../../eventeer-24/eveenteer-24-cat-${collection_id}.json`,
        {
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
        ).then((response) => {
            setCollection({ ...collection, data: response.data, isLoading: false });
        }).catch((err) => setCollection({ ...collection, error: err, isLoading: false }));
    }, []);

    if(collection && collection.data && collection.data.data && collection.data.data[0] && collection.data.total_count > 0) {
        const event_ids = collection.data.data[0].event_id;
        if(!event_ids.match(event_id)){
            window.location.href = "/eventeer-awards-2024/";
        }
    }

    //const GET_EVENT_API = `${global.API.rootPath}${global.config.endpoints.events_endpoint}?access_key=${global.config.access_key}&page=1&limit=1&event_id=${event_id}`;

    const [event, setEvent] = useState({
        data: null,
        isLoading: false,
        error: "",
    });

    useEffect(() => {
        setEvent({ ...event, isLoading: true });
        axios.get(`../../../eventeer-24/eveenteer-24-event-${event_id}.json`,
        {
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            setEvent({ ...event, data: response.data, isLoading: false });
        }).catch((err) => setEvent({ ...event, error: err, isLoading: false }));
    }, []);

    // const [voteResponse, setVoteResponse] = useState({
    //     status: "",
    //     msg: "",
    //     error: "",
    // });

    // const [captcha, setCaptcha] = useState("");
    // function verifyCaptcha() {
    //     setCaptcha("true");
    // }

    //const [open, setOpen] = useState(false);
    // const handleOpen = () => {
    //     setOpen(true);
    //     setVoteResponse({ ...voteResponse, status: "", msg: "", error: "" });
    //     //setCaptcha("");
    // };
    // const handleClose = () => {
    //     setOpen(false);
    // };

    const [copy, setCopy] = useState("Copy Link");

    const copyPop = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopy("Copied");
    };

    //get country by IP
    // const [country, setCountry] = useState("");
    // useEffect(() => {
    //     var ipAPI = 'https://ip.nf/me.json';
    //     fetch(ipAPI, { method: "get" })
    //         .then((response) => response.json())
    //         .then((data) => {
    //             setCountry({ ...country, data: data.ip.country })
    //         })
    //         .catch((error) => {
    //             console.error('Error:', error);
    //         });
    // }, []);

    //const form = useRef(null);

    //validates fields
    // async function handleChange({ target }) {
    //     await form.current.validateFields(target);
    // }

    // async function handleSubmit(e) {
    //     e.preventDefault();

    //     // Validates the non-dirty fields and returns Promise<Field[]>
    //     await form.current.validateForm();
        
    //     if (form.current.isValid()) {
    
    //         var VOTE_API = `${global.API.rootPath}${global.config.endpoints.eventeer_vote}?access_key=${global.API.voteAccessKey}`;

    //         var voteHeaders = new Headers();
    //         voteHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    //         var bodyEncoded = new URLSearchParams();

    //         //POST DATA
    //         bodyEncoded.append("collection_id", collection_id);
    //         var regex = /[.\s]/g; //remove . from event_id
    //         bodyEncoded.append("event_id", event_id.replace(regex, ''));
    //         bodyEncoded.append("location", country && country.data ? country.data : "N/A");
    //         bodyEncoded.append("email", e.target.elements.email.value);
    //         bodyEncoded.append("first_name", e.target.elements.firstName.value);
    //         bodyEncoded.append("last_name", e.target.elements.lastName.value);

    //         var requestOptions = {
    //             method: 'POST',
    //             headers: voteHeaders,
    //             body: bodyEncoded,
    //             redirect: 'follow'
    //         };
    //         fetch(VOTE_API, requestOptions)
    //             .then(response => response.text())
    //             .then(result => {
    //                 const data = JSON.parse(result);
    //                 setVoteResponse({ ...voteResponse, status: data.status, msg: data.msg });
    //                 setVoteResponse({ ...voteResponse, status: data.status, msg: data.msg });
    //             })
    //             .catch(error => {
    //                 setVoteResponse({ ...voteResponse, status: false, error: true });
    //                 console.log('error', error)
    //             });
    //     }
    //     // else if(!captcha) {
    //     //     setVoteResponse({ ...voteResponse, status: false, msg: "Captcha Failed" });
    //     // }
    //     else {
    //         //setVoteResponse({ ...voteResponse, status: false, error: true });
    //         console.log('The form is invalid');
    //     }
    // }
    

    // const [days, setDays] = useState(0);
    // const [hours, setHours] = useState(0);
    // const [minutes, setMinutes] = useState(0);
    // const [seconds, setSeconds] = useState(0);

    // useEffect(() => {
    //     setInterval(() => {
    //         const now = moment().toDate();
    //         const time = Date.parse("2024-01-26") - Date.parse(now);
    //         const seconds = Math.floor((time / 1000) % 60);
    //         const minutes = Math.floor((time / 1000 / 60) % 60);
    //         const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    //         const days = Math.floor(time / (1000 * 60 * 60 * 24));
    //         setDays(days < 0 ? "0" : days);
    //         setHours(hours < 0 ? hours * -1 : hours);
    //         setMinutes(minutes < 0 ? minutes * -1 : minutes);
    //         setSeconds(seconds < 0 ? seconds * -1 : seconds);
    //     }, 1000);
    // }, []);

    const getOffset = (element) => {
        const elementRect = element?.getBoundingClientRect();
        return elementRect?.top;
    }
    const listenToScroll = () => {
        const heightToHide = getOffset(
            document.getElementById("moreCategoriesSlider")
        );
        const windowScrollHeight = document.body.scrollTop || document.documentElement.scrollTop;
        if(windowScrollHeight > heightToHide) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    
    }
    const [isVisible, setIsVisible] = useState(true);
    
    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []);

    return (
        <div className='boxCustom'>
            <Box className={classes.nomineeDBanner2024}>
                <Container maxWidth="lg">
                    <Box variant='div' className={classes.breadcrumbMainDark2024} pt={2}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            <Link color="inherit" to="/eventeer-awards-2024/">
                                Eventeer Awards 2024
                            </Link>
                            {collection && collection.data && collection.data.data && collection.data.data[0] && collection.data.total_count > 0 && !collection.isLoading ?
                                <Link color="inherit" to={"/eventeer-awards-2024/" + collection.data.data[0].id + "/" + collection.data.data[0].slug}>
                                    {collection.data.data[0].name}
                                </Link>
                                :
                                <LoaderLines />
                            }
                            
                            {event && event.data && event.data.data && event.data.data[0] && event.data.total_count > 0 && !event.isLoading ?
                                <Typography color="textPrimary">{event.data.data[0].app_title}</Typography>
                                :
                                <LoaderLines />
                            }
                        </Breadcrumbs>
                    </Box>
                </Container>
            </Box>
            <Box className={classes.nomineeBox}>
                {/* <Box className={classes.nomineeVoting}>
                    <Typography variant="h2">Voting Close: Jan 26, 2024</Typography>
                    <Box className={classes.nomineeVoteTimer}>
                        <p>{days}<span> Days</span></p>
                        <p>{hours}<span> Hrs</span></p>
                        <p>{minutes}<span> Mins</span></p>
                        <p>{seconds}<span> Secs</span></p>
                    </Box>
                </Box> */}
                <Box className={classes.nomineeBoxContent}>
                    <Box className={classes.nomineeSlider}>
                        {event && event.data && event.data.data && event.data.data[0] && event.data.total_count > 0 && !event.isLoading ?
                            event.data.data[0].images ?
                                <Slider {...settings} className="nomineeSlider">
                                    {event.data.data[0].images.map(gallary =>
                                        <Box className={classes.nomineeDetailSlide}>
                                            <img src={gallary.image} alt="nominee" />
                                        </Box>
                                    )}
                                </Slider>
                                : event.data.data[0].cover_image ?
                                    <Box className={classes.nomineeDetailSlide}>
                                        <img src={event.data.data[0].cover_image} alt={event.data.data[0].app_title} />
                                    </Box>
                                    :
                                    <Box className={classes.nomineeDetailSlide}>
                                        <img src={Placeholder} alt={event.data.data[0].app_title} />
                                    </Box>
                            :
                            <>
                                <LoaderBox />
                                <LoaderBox />
                            </>
                        }
                    </Box>
                    <Box className={classes.Nomineecontent}>
                        <Box className={classes.Nomineecontenttitle}>
                            <Typography variant='h1'>
                                {event && event.data && event.data.data && event.data.data[0] && event.data.total_count > 0 && !event.isLoading ?
                                    event.data.data[0].app_title
                                    :
                                    <LoaderLines />
                                }
                            </Typography>
                            {event && event.data && event.data.data && event.data.data[0] && (
                                event.data.data[0].app_id === "9704" ||
                                event.data.data[0].app_id === "9110" ||
                                event.data.data[0].app_id === "9692" ||
                                event.data.data[0].app_id === "8692" ||
                                event.data.data[0].app_id === "8261" ||
                                event.data.data[0].app_id === "10426" ||
                                event.data.data[0].app_id === "9230" ||
                                event.data.data[0].app_id === "9559" ||
                                event.data.data[0].app_id === "8700" ||
                                event.data.data[0].app_id === "9861" ||
                                event.data.data[0].app_id === "10766" ||
                                event.data.data[0].app_id === "10068" ||
                                event.data.data[0].app_id === "10741" ||
                                event.data.data[0].app_id === "9143" ||
                                event.data.data[0].app_id === "7791" ||
                                event.data.data[0].app_id === "10745" ||
                                event.data.data[0].app_id === "10306" ||
                                event.data.data[0].app_id === "5289" ||
                                event.data.data[0].app_id === "8900" ||
                                event.data.data[0].app_id === "8135" ||
                                event.data.data[0].app_id === "9212" ||
                                event.data.data[0].app_id === "10230" ||
                                event.data.data[0].app_id === "7927" ||
                                event.data.data[0].app_id === "11587" ||
                                event.data.data[0].app_id === "9230" ||   
                                event.data.data[0].app_id === "9559" ||   
                                event.data.data[0].app_id === "10601"                
                                )  ? <img className={classes.NomineeWinner} src={Winner} alt="Eventeer Winner" /> : ""
                            }
                        </Box>
                        <Typography variant='body1'>
                            {event && event.data && event.data.data && event.data.data[0] && event.data.total_count > 0 && !event.isLoading ?
                                <div className={classes.mainWrapContent} dangerouslySetInnerHTML={{ __html: event.data.data[0].about_us.replace(/<a /g, '<a rel="nofollow"') }} />
                                :
                                <>
                                    <LoaderLines />
                                    <LoaderLines />
                                </>
                            }
                        </Typography>


                    </Box>
                    <Box className={classes.nomineeDList}>
                        <Box className={classes.nomineeDListBox}>
                            {collection && collection.data && collection.data.data && collection.data.data[0] && collection.data.total_count > 0 && !collection.isLoading ?
                                <>
                                    <Typography variant='span'>
                                        Nominee for:
                                    </Typography>
                                    <Typography variant='body1'>
                                        {collection.data.data[0].name}
                                    </Typography>
                                </>
                                :
                                <LoaderLines />
                            }
                        </Box>
                        <Box className={classes.nomineeDListBox}>

                            {event && event.data && event.data.data && event.data.data[0] && event.data.total_count > 0 && !event.isLoading ?
                                <>
                                    <Typography variant='span'>
                                        Hosted by:
                                    </Typography>
                                    <Typography variant='body1'>
                                        {event.data.data[0].organiser}
                                    </Typography>
                                </>
                                :
                                <LoaderLines />
                            }
                        </Box>

                        <Box className={classes.nomineeDListBox} style={{ marginBottom: 0 }}>
                            {event && event.data && event.data.data && event.data.data[0] && event.data.total_count > 0 && !event.isLoading && event.data.data[0].include && event.data.data[0].include.length !== "0" ?
                                <>
                                    <Typography variant='span'>
                                        Features Used:
                                    </Typography>
                                    <ul>
                                        {event.data.data[0].include && event.data.data[0].include.map(includes => (
                                            <li>{includes.name}</li>
                                        ))}
                                    </ul>
                                </>
                                :
                                <LoaderLines />
                            }
                        </Box>
                    </Box>
                    {isVisible && event && event.data && event.data.data && event.data.data[0] && event.data.total_count > 0 &&
                        <Box className={classes.nomineeshare}>
                            <ul>
                                <li>
                                    <img alt="Copy Link" title="Copy Link" src={shareIcon} style={{ cursor: "pointer" }} onClick={copyPop} />
                                </li>
                                <li>
                                    <FacebookShareButton
                                        url={window.location.href}
                                        quote={event.data.data[0].app_title}
                                        hashtag="#vfairs"
                                        id="social-facebook"
                                    >
                                        <img src={Facebook} alt="Share to Facebook" title="Share to Facebook" />
                                    </FacebookShareButton>
                                </li>
                                <li>
                                    <TwitterShareButton
                                        url={window.location.href}
                                        title={event.data.data[0].app_title}
                                        hashtag="#vfairs"
                                        id="social-twitter"
                                    >
                                        <img src={Twitter} alt="Share to Twitter" title="Share to Twitter" />
                                    </TwitterShareButton>
                                </li>
                                <li>
                                    <WhatsappShareButton
                                        url={window.location.href}
                                        title={event.data.data[0].app_title}
                                        separator=":"
                                        id="social-whatsapp"
                                    >
                                        <img src={WhatsApp} alt="Share to WhatsApp" title="Share to WhatsApp" />
                                    </WhatsappShareButton>
                                </li>
                                <li>
                                    <LinkedinShareButton
                                        url={window.location.href}
                                        title={event.data.data[0].app_title}
                                        id="social-linkedin"
                                    >
                                        <img src={LinkedIn} alt="Share to LinkedIn" title="Share to LinkedIn" />
                                    </LinkedinShareButton>
                                </li>
                                <li>
                                    <EmailShareButton
                                        url={window.location.href}
                                        subject={event.data.data[0].app_title}
                                        body={"#vfairs " + window.location.href}
                                        separator=": "
                                        id="social-email"
                                        title="Email Link">
                                        <img src={Email} alt="Send in email" title="Send in Email" />
                                    </EmailShareButton>
                                </li>
                            </ul>
                        </Box>
                    }

                    <Box className={classes.nomineeDetailAction}>
                        {event && event.data && event.data.data && event.data.data[0] && event.data.total_count > 0 && !event.isLoading ?
                            <>
                                <Link variant="outlined" color="primary" className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary" title="Go to Event Page"
                                    to={"/events/" + event.data.data[0].app_id + "/" + event.data.data[0].slug} >
                                    Go to Event Page
                                </Link>
                                {/* <Button variant="contained" color="primary" onClick={handleOpen}>Vote</Button> */}
                            </>
                            :
                            <LoaderLines/>
                        }                      
                        
                    </Box>
                </Box>
            </Box>

            <Box className={classes.moreCatBox}>
                {/* <Container maxWidth="lg">
                    <Typography variant="h2">More Categories to Vote</Typography>
                </Container> */}
                {/* <DiscoverCategoriesSlider heading={false} /> */}
            </Box>

            <Box className={classes.voteStickybtn}>
                <Button variant="contained" color="primary">Voting Closed</Button>
                {/* <Button variant="contained" color="primary" disabled>Voting starts on December 20th, 2023</Button> */}
            </Box>
            {/* <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                className={classes.modalVoteStyle}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.voteModal}>
                        <IconButton aria-label="delete" className={classes.voteModalClose} onClick={handleClose} size="small">
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                        {voteResponse && voteResponse.status && voteResponse.msg === "Please check your email for verification link!" ?
                                <Box className={classes.voteHeader}>
                                    <img src={thanks} className={classes.voteThanks} alt="Thank you for vote" />
                                    <Box className={classes.voteBox}>
                                        <Typography variant="h2">Please check your inbox.</Typography>
                                        <Typography variant="body1">
                                            We sent a confirmation link via email. Please click it to confirm your vote. You may need to check your spam box.
                                        </Typography>
                                    </Box>
                                </Box>
                            :
                                <Box className={classes.voteHeader}>
                                    <img src={voteAuth} className={classes.voteAuth} alt="Vote" />
                                    <Box className={classes.voteBox}>
                                        <Typography variant="h2">Submit your vote</Typography>
                                        <Typography variant="body1">
                                            Please verify your information to finalize your vote.
                                        </Typography>
                                        <FormWithConstraints ref={form} onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.voteForm}>
                                            {voteResponse && !voteResponse.status && voteResponse.msg && 
                                                <span class="error">{voteResponse.msg}</span>
                                            }
                                            <input type="text" required minLength={3} name="firstName" className={classes.voteInput} placeholder='First Name*' onChange={handleChange} />
                                            <FieldFeedbacks for="firstName">
                                                <FieldFeedback when={value => value.length === 0}>Please complete this required field.</FieldFeedback>
                                            </FieldFeedbacks>
                                            <input type="text" required minLength={3} name="lastName" className={classes.voteInput} placeholder='Last Name*' onChange={handleChange} />
                                            <FieldFeedbacks for="lastName">
                                                <FieldFeedback when={value => value.length === 0}>Please complete this required field.</FieldFeedback>
                                            </FieldFeedbacks>
                                            <input name="email" required minLength={3} id="email" className={classes.voteInput} placeholder='Email Address*' onChange={handleChange} />
                                            <FieldFeedbacks for="email">
                                                <FieldFeedback when={value => value.length === 0}>Please complete this required field.</FieldFeedback>
                                                <FieldFeedback when={value => !/\S+@\S+/.test(value)}>Invalid email address.</FieldFeedback>
                                            </FieldFeedbacks>
                                            <Reaptcha sitekey="6LfY6pwiAAAAAEond0lK4rBcUH2dxIdfvU86ONMM" onVerify={verifyCaptcha} className={classes.voteCaptcha}/>
                                            {voteResponse && voteResponse.msg === "Captcha Failed" && 
                                                <span class="error">Prove you're not a Robot</span>
                                            } 
                                            <Button variant="contained" color="primary" type='submit'>Vote</Button>
                                        </FormWithConstraints >
                                    </Box>
                                </Box>
                            }
                    </Box>
                </Fade>
            </Modal> */}
        </div>
    )
}

export default NomineeBox