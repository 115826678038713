import React from "react";
import { Box, Grid, Typography, Container } from '@material-ui/core';
import { footerStyles } from "../../utils/hooks/useApplyStyles";
import { Link, useLocation } from "react-router-dom";
import logo from '../../assets/logo.svg';
import moment from 'moment';

export default function DiscoverFooter() {
    const styles = footerStyles();
    const classes = styles();
    let location = useLocation();

    return (
        <>
        <Box className={classes.contFou} style={{margin: location && location.pathname && location.pathname.match(/eventeer-awards-2023/) ? '0 auto' : '0 auto 50px'}}>
            <Container maxWidth="false" disableGutters="false">
                <Grid container spacing={2}>
                    <div className={classes.copyRight}>
                        <Typography varient="h6" component="p" >{"Copyright © "+moment().year()+" vFairs"}</Typography>
                        <div className={classes.grow} />
                        <Typography className={classes.copyLink} component="div">
                            <Link target="_blank" to={{ pathname: "https://status.vfairs.com/" }} varient="body1" color="inherit">
                                Status
                            </Link>
                            <Link to={{ pathname: "https://www.vfairs.com/privacy-policy/" }} target="_blank" varient="body1" color="inherit">
                                Privacy Policy
                            </Link>
                            <Link to={{ pathname: "https://security.vfairs.com/" }} target="_blank" varient="body1" color="inherit">
                                Trust Center
                            </Link>
                            <Link to={{ pathname: "https://www.vfairs.com/service-level-agreement/" }} target="_blank" varient="body1" color="inherit">
                                SLA
                            </Link>
                            <Link to={{ pathname: "https://www.vfairs.com/global-data-processing-addendum/" }} target="_blank" varient="body1" color="inherit">
                                Global DPA
                            </Link>
                            <Link to={{ pathname: "https://www.vfairs.com/terms-of-service/" }} target="_blank" varient="body1" color="inherit">
                                Terms Of Service
                            </Link>
                        </Typography>
                    </div>
                </Grid>                
            </Container>            
        </Box>
        {location && location.pathname && location.pathname.match(/eventeer-awards-2023/) &&
            <Box className={classes.poweredBy}>
                <Container maxWidth="false" disableGutters="false" className={classes.poweredBySection}>
                    Powered by 
                    <Link to="/">
                        <img src={logo} alt="Powered by vFairs Discover"></img>
                    </Link>     
                </Container>                
            </Box>
        }
        </>
    );
};