import { AppBar, Box, Button, Modal, Fade, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Typography,LinearProgress } from '@material-ui/core';
import React,{ useState,useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from 'universal-cookie';
import moment from 'moment'
import { dashboardStyles } from '../../utils/hooks/useApplyStyles';
import Backdrop from '@material-ui/core/Backdrop';
import CompleteImg from '../../assets/images/success.png'
import Filter from './Filter'
import EventRows from './EventRows'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Listing = () => {
    const styles = dashboardStyles();
    const classes = styles();
    
    const cookies = new Cookies();
    const BASE_URL = `${global.API.rootPath}${global.config.endpoints.onboarding_get_event}?token=${cookies.get('onBoardingToken')}&limit=1000&sort_by_created_at=desc`;
    const [events, setEvents] = useState({
        data: null,
        isLoading: false,
        error: "",
    });
    
    const history = useHistory();
    
    function setParams({ param, query }) {
        const searchParams = new URLSearchParams();
        searchParams.set(param, query || "");
        if (query) {
        return searchParams.toString();
        }
    }
    const search = useLocation().search;
    const getEventStatus = new URLSearchParams(search).get('event-status');
    const getEventMode = new URLSearchParams(search).get('event-mode');
    const getFormats = new URLSearchParams(search).get('formats');
    const getSearchQuery = new URLSearchParams(search).get('search');
    const getPastEvents = new URLSearchParams(search).get('date');
    const getOnboardingStatus = new URLSearchParams(search).get('event-created');

    const [open, setOpen] = useState(false);
    
    const closeSharePop = () => {
        setOpen(false);
    };

    const [eventFilters, setEventFilters] = useState({
        eventStatus: getEventStatus ? getEventStatus : "",
        eventMode: getEventMode ? getEventMode : "",
        formats: getFormats ? getFormats : "",
        search: getSearchQuery ? getSearchQuery : "",
        pastEvents: getPastEvents ? getPastEvents : "all",
        onboardingStatus: getOnboardingStatus ? getOnboardingStatus : "",
    })
    
    const handleDateChange = (e, newValue) => {   
        setEventFilters({ ...eventFilters, pastEvents: newValue })
    }
    const setEventStatus = setParams({ param: "event-status", query: eventFilters.eventStatus });
    const setEventMode = setParams({ param: "event-mode", query: eventFilters.eventMode });
    const setFormats = setParams({ param: "formats", query: eventFilters.formats && eventFilters.formats.length != 0 ? eventFilters.formats : "" });
    const setSearchQuery = setParams({ param: "search", query: eventFilters.search.replace(/[']/g, '') });    
    const setPastEvents = setParams({ param: "date", query: eventFilters.pastEvents });
    const setOnboardingStatus = setParams({ param: "event-created", query: eventFilters.onboardingStatus });
    
    //from tomorrow to onwards
    let startDate = moment().add(1, 'days').format("YYYY-MM-DD");

    useEffect(() => {
        
        history.push(`?${setOnboardingStatus ? "&" + setOnboardingStatus : ""}${setEventStatus ? "&" + setEventStatus : ""}${setEventMode ? "&" + setEventMode : ""}${setFormats ? "&" + decodeURIComponent(setFormats) : ""}${setSearchQuery ? "&" + setSearchQuery : ""}${setPastEvents ? "&" + setPastEvents : ""}`);

        if(getOnboardingStatus && getOnboardingStatus === "1") {
            setOpen(true);
            setEventFilters({ ...eventFilters, onboardingStatus: "" })
        }

        setEvents({ ...events, isLoading: true });
        axios.get(BASE_URL + `${eventFilters.eventStatus ? "&is_published=" + eventFilters.eventStatus : ""}${eventFilters.search ? "&search=" + eventFilters.search.replace(/[']/g, '') : ""}${eventFilters.eventMode ? "&event_mode=" + eventFilters.eventMode : ""}${eventFilters.formats ? "&event_format=" + eventFilters.formats : ""}${eventFilters.pastEvents && eventFilters.pastEvents === "upcoming" ? "&from_date=" + startDate : "&get_past_events=" + eventFilters.pastEvents}`).then((fetched_events) => {
            setEvents({ ...events, data:  fetched_events.data, isLoading: false });
        }).catch((err) => setEvents({ ...events, error: err, isLoading: false }));;
    }, [eventFilters]);

    const [publishEventResponse, setPublishEventResponse] = useState({
        status: "",
        msg: "",
        error: "",
        isLoading: false,
    });

    return (
        <>
            <Filter eventFilters={eventFilters} setEventFilters={setEventFilters}/>
            
            <div style={{ minHeight: 5, }}>
                {events && events.isLoading &&
                    <LinearProgress />
                }
                {publishEventResponse && publishEventResponse.isLoading &&
                    <LinearProgress />
                }
            </div>

            <Box className={classes.eListing}>
                <AppBar position="static" elevation={0} className={classes.eListingTab}>
                    <Tabs value={eventFilters.pastEvents} onChange={handleDateChange}>
                        <Tab label="All Events" {...a11yProps("all")} value="all"/>
                        <Tab label="Live" {...a11yProps("live")} value="live"/>
                        <Tab label="Upcoming" {...a11yProps("upcoming")} value="upcoming"/>
                        <Tab label="Archive" {...a11yProps("archive")} value="archive" />                      
                    </Tabs>
                </AppBar>
                <TabPanel value={eventFilters.pastEvents} index={eventFilters.pastEvents}>
                    <Table className={classes.eListingTable}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Event Title</TableCell>
                                <TableCell>Mode</TableCell>
                                <TableCell>Format</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell align='right'>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {events &&
                                events.data &&
                                events.data.data &&
                                events.data.status &&
                                events.data.total_count &&
                                events.data.total_count !== 0 &&
                                <EventRows events={events.data.data} publishEventResponse={publishEventResponse} setPublishEventResponse={setPublishEventResponse}/>
                            }
                        </TableBody>
                    </Table>
                </TabPanel>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={closeSharePop}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Box component="div" className={classes.modalBox}>
                            <Box className={classes.modalBoxContent}>
                                <img src={CompleteImg} alt="modal" />
                                <Typography variant='h2'>Successful!</Typography>
                                <Typography variant='body1'>Your first event is created successfully.</Typography>
                                <Button color='primary' onClick={closeSharePop}>Close</Button>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </Box>
        </>


    )
}

export default Listing