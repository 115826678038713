import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { eventDetailStyles } from "../../../utils/hooks/useApplyStyles";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import LazyLoad from 'react-lazyload';
import useIsInViewport from '../../../components/useIsInViewport';

export default function Sponsors({ sponsors_group, sponsorsRef,setPageJumps }) {
    const styles = eventDetailStyles();
    const classes = styles();
    const sponsorsInViewport = useIsInViewport(sponsorsRef, '-50%');
    if(sponsorsInViewport && sponsorsInViewport === true) {
        setPageJumps("active-sponsors");
    }

    return (
        <>
            <Box ref={sponsorsRef} className={`${classes.main} ${classes.edsectionGap}`}>
                <Typography variant="h4" component="h2" gutterBottom className={classes.edetailTitle} >
                    sponsors
                </Typography>
                <Box>
                {sponsors_group && sponsors_group.map(sponsors_cat =>
                    <div>
                    <Typography variant="h6" component="p" color="secondary" gutterBottom className={classes.eventSubTitle} >
                        {!!(sponsors_cat && sponsors_cat.name) ? sponsors_cat.name : "sponsors_cat_name_null" }
                    </Typography>

                    <ImageList className={classes.imageList}>
                        {sponsors_cat && sponsors_cat.sponsors && sponsors_cat.sponsors.map(sponsors =>
                        <ImageListItem>
                            <a href={!!(sponsors && sponsors.link) ? sponsors.link : "" } target={!!(sponsors && sponsors.link) ? "_blank" : "_self" } rel="nofollow noreferrer"  >
                                <LazyLoad height={150}>
                                    <img src={!!(sponsors && sponsors.logo) ? sponsors.logo : "sponsors_logo_null" } alt={!!(sponsors && sponsors.name) ? sponsors.name : "sponsors_name_null" } />
                                </LazyLoad>
                            </a>
                        </ImageListItem>
                         )}
                    </ImageList>
                    </div>
                )}
                </Box>
            </Box>
        </>
    );
};
