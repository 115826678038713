import React from 'react';
import { Grid, Card, Container, Typography, Box } from '@material-ui/core';
import { Link } from "react-router-dom";
import { cardsStyles, eventDetailStyles } from "../../utils/hooks/useApplyStyles";
import CardContent from '../Marketplace/Cards/CardContent'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


export default function SectionMoreCards({ more_events }) {

    const styles = cardsStyles();
    const styles1 = eventDetailStyles();
    const classes = { ...styles(), ...styles1() }

    return (
        <>
            <Box component="div" pt={4} pb={4} className={classes.edetailmoredetail}>
                <Container maxWidth="lg">
                    <Box component="div" m={3} className={classes.viewMoreBox}>
                        <Typography variant="h3" component="p" gutterBottom>
                            More Like This
                        </Typography>
                        <Link to="/marketplace" >View More <ArrowForwardIcon /> </Link>
                    </Box>
                    <Grid container spacing={3} className={classes.moreCardWrap}>
                        {more_events.map((event) =>
                            event.application && event.application.is_published_on_discover && event.application.is_published_on_discover === "1" &&
                            <Grid item lg={3} sm={6} xs={12} >
                                <Card className={classes.cardSingles}>
                                    <CardContent event_data={event} />
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};
