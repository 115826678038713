import React, { useState } from "react";
import clsx from 'clsx';
import { Box, Card, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { useWideCardMediaStyles } from '@mui-treasury/styles/cardMedia/wide';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import { eventDetailStyles, exhibitorCardStyles } from "../../../utils/hooks/useApplyStyles";
import 'react-multi-carousel/lib/styles.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExhibitorModel from './ExhibitorModel';
import LazyLoad from 'react-lazyload';
import useIsInViewport from '../../../components/useIsInViewport';


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 3000, min: 1280 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 1279, min: 992 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 991, min: 768 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    centerPadding: '40px',
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};



export default function ExhibitorCard({ exhibitors, exhibitorsRef, setPageJumps }) {
    const styles1 = useWideCardMediaStyles();
    const styles2 = useFadedShadowStyles();
    const styles4 = exhibitorCardStyles();
    const styles5 = eventDetailStyles();
    const classes = { ...styles1, ...styles2, ...styles4(), ...styles5() }

    const [openModal, setOpenModal] = useState("");

    const toggleModal = (value) => {
        if (value === openModal) setOpenModal("")
        else setOpenModal(value)
    };
    const exhibitorsInViewport = useIsInViewport(exhibitorsRef, '-50%');
    if (exhibitorsInViewport && exhibitorsInViewport === true) {
        setPageJumps("active-exhibitors");
    }
    return (
        <div ref={exhibitorsRef} className={`${classes.main} ${classes.edsectionGap}`}>
            {exhibitors && exhibitors[0] && exhibitors[0].booths &&
                <Typography variant="h3" component="h2" className={classes.edetailTitle}>
                    Exhibitors
                </Typography>
            }
            <Box className={classes.exhibitorWrap}>
                <Slider {...settings} className="cardthemeSlider">

                    {exhibitors && exhibitors.map((exhibitor, i) =>
                        exhibitor && exhibitor.booths && exhibitor.booths.booth_logo_url && exhibitor.booths.description_ar &&
                        <div>
                            <Card className={clsx(classes.root, classes.root, classes.exhibitMainCard)}>
                                {exhibitor && exhibitor.booths && exhibitor.booths.booth_logo_url &&
                                    <Box component="div" classes={styles1} className={classes.exhibitImageMain}>
                                        <LazyLoad height={150}>
                                            <img src={"https://vepimg.b8cdn.com" + exhibitor.booths.booth_logo_url} alt="Exhibitor Logo" />
                                        </LazyLoad>
                                    </Box>
                                }
                                <CardContent className={classes.content}>
                                    <Typography variant="p" component="p" className={classes.styles3}>
                                        {!!(exhibitor && exhibitor.booths && exhibitor.booths.description_ar) ? exhibitor.booths.description_ar.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&rsquo;|&amp;|&gt;/g, ' ') : ""}
                                    </Typography>
                                </CardContent>
                                {!!exhibitor && exhibitor.booths && exhibitor.booths.description_ar && exhibitor.booths.description_ar.length > 150 &&
                                    <div>
                                        <CardActions className={classes.exhiCardAction}>
                                            <Button size="small" color="primary" onClick={() => toggleModal("spearkerModal" + i)}>
                                                View More
                                            </Button>
                                        </CardActions>

                                        {openModal === "spearkerModal" + i && <ExhibitorModel exhibitor={exhibitor} handleClose={() => toggleModal("spearkerModal" + i)} open={openModal === "spearkerModal" + i} />}

                                    </div>
                                }
                            </Card>
                        </div>
                    )}
                </Slider>

            </Box>
        </div>
    );
};