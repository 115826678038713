import { Box, Button, Container, FormControlLabel, Grid, TextField, Typography,Checkbox,FormGroup } from '@material-ui/core';
import React, {useState} from 'react'
import { lookingUpStyles, onboardingStyles } from '../../../utils/hooks/useApplyStyles';
//import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import matchmaking from '../../../assets/images/matchmaking.svg'
import webinar from '../../../assets/images/webinar.svg'
import networking from '../../../assets/images/networking.svg'
import gamification from '../../../assets/images/gamification.svg'
import liveQnA from '../../../assets/images/liveQnA.svg'
// import speakers from '../../../assets/images/speakers.svg'
// import sponsors from '../../../assets/images/sponsors.svg'
// import exhibitors from '../../../assets/images/exhibitors.svg'


const CreateAnEventDetails1 = ({setStep,step,event,setEvent}) => {
    const styles = onboardingStyles();
    const styles1 = lookingUpStyles();
    const classes = { ...styles(), ...styles1() }
    const [error, setError] = useState("");
    const [errorURL, setErrorURL] = useState("");
    const [errorOrganiserURL, setOrganiserURL] = useState("");

    // const isValidUrl = urlString=> {
    //     var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    //   '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    //   '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    //   '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
    //     return !!urlPattern.test(urlString);
    // }
    const isValidUrl = urlString=> {
        let url;
        try { 
            url =new URL(urlString); 
        }
        catch(e){ 
        return false; 
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    const handleContinue = () => {
        if (event && event.url && !isValidUrl(event.url)) {
            setErrorURL(true);
        } else if (event && event.organiserURL && !isValidUrl(event.organiserURL)) {
            setOrganiserURL(true);
        } else if(event && event.title && event.url && event.organiserName && isValidUrl(event.url)) {
            setStep({ stepNo: 4 });
        } else {
            setError(true);
        }
    };
    
    return (
        <Box className={classes.eventSteps} style={{ overflow: 'hidden' }}>
            <Container maxWidth="lg">
                <Box className={classes.onbaordWrap}>
                    <Box className={classes.eventStepsC}>
                        <Box className={classes.eventStepGuide}>
                            <Typography variant='span' className='quarter'>3</Typography>
                            <Typography variant='body1'>add event details</Typography>
                        </Box>
                        <Typography className={classes.eventStepTitle} variant="h2">Let’s create your event</Typography>
                        <Box className={classes.eventSelection}>
                            <Typography variant="span" className={classes.eventSelectionLabel}>Add your all event details that are required in the form below.</Typography>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={5} md={6}>
                                    <Box className={classes.inputGroup}>
                                        <Typography variant='span' className={classes.inputLabel}>Your Event Title*</Typography>
                                        <TextField id="event-title" placeholder='Your Event Title' variant="outlined"
                                            value={event && event.title ? event.title : ""}
                                            onChange={(e) => {
                                                setEvent({ ...event, title: e.target.value })
                                            }}
                                        />
                                        {error &&
                                            <span style={{color:'red'}}>Please complete this required field.</span>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={5} md={6}>
                                    <Box className={`${classes.inputGroup} ${classes.lastInputGroup}`}>
                                        <Typography variant='span' className={classes.inputLabel}>Event URL*</Typography>
                                        <TextField id="event-url" placeholder='URL example: www.eventtech.com' variant="outlined"
                                            value={event && event.url ? event.url : ""}
                                            onChange={(e) => {
                                                setEvent({ ...event, url: e.target.value })
                                            }}
                                        />
                                        {error ? <span style={{color:'red'}}>Please complete this required field.</span> :
                                        errorURL ? <span style={{color:'red'}}>Invalid URL.</span> : ""}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Typography variant='span' className={classes.inputLabel}>What does your event have? (optional)</Typography>
                            <FormGroup
                                name="eventhave"
                                className={classes.eventDoes}
                            >
                                <FormControlLabel
                                    className={classes.eventTypeSelection}
                                    value="1:1matchmaking"
                                    onChange={(e) => setEvent({
                                    ...event,
                                    eventHave: e.target.checked ?
                                        [...event.eventHave, "1:1matchmaking"] :
                                        event.eventHave.filter(item => item !== "1:1matchmaking"),
                                    })}
                                    control={<Box className={classes.eventMContent}>
                                        <img src={matchmaking} alt="Matchmaking" />
                                        <Box>
                                            <Typography variant='span' className={classes.eventFormat}>Matchmaking</Typography>
                                        </Box>
                                        <Box component="span"><Checkbox checked={event.eventHave.includes("1:1matchmaking")} /></Box>
                                    </Box>}
                                    label=""                                    
                                />
                                <FormControlLabel
                                    className={classes.eventTypeSelection}
                                    value="webinars"
                                    onChange={(e) => setEvent({
                                    ...event,
                                    eventHave: e.target.checked ?
                                        [...event.eventHave, "webinars"] :
                                        event.eventHave.filter(item => item !== "webinars"),
                                    })}
                                    control={<Box className={classes.eventMContent}>
                                        <img src={webinar} alt="Webinar" />
                                        <Box>
                                            <Typography variant='span' className={classes.eventFormat}>Webinar</Typography>
                                        </Box>
                                        <Box component="span"><Checkbox checked={event.eventHave.includes("webinars")} /></Box>
                                    </Box>}
                                    label=""
                                />
                                <FormControlLabel
                                    className={classes.eventTypeSelection}
                                    value="networking"
                                    onChange={(e) => setEvent({
                                    ...event,
                                    eventHave: e.target.checked ?
                                        [...event.eventHave, "networking"] :
                                        event.eventHave.filter(item => item !== "networking"),
                                    })}
                                    label=""
                                    control={<Box className={classes.eventMContent}>
                                        <img src={networking} alt="Networking" />
                                        <Box>
                                            <Typography variant='span' className={classes.eventFormat}>Networking</Typography>
                                        </Box>
                                        <Box component="span"><Checkbox checked={event.eventHave.includes("networking")} /></Box>
                                    </Box>}
                                />
                                <FormControlLabel
                                    className={classes.eventTypeSelection}
                                    value="gamification"                                    
                                    onChange={(e) => setEvent({
                                    ...event,
                                    eventHave: e.target.checked ?
                                        [...event.eventHave, "gamification"] :
                                        event.eventHave.filter(item => item !== "gamification"),
                                    })}
                                    label=""
                                    control={<Box className={classes.eventMContent}>
                                        <img src={gamification} alt="Gamification" />
                                        <Box>
                                            <Typography variant='span' className={classes.eventFormat}>Gamification</Typography>
                                        </Box>
                                        <Box component="span"><Checkbox checked={event.eventHave.includes("gamification")} /></Box>
                                    </Box>}
                                />
                                <FormControlLabel
                                    className={classes.eventTypeSelection}
                                    value="live QA Session"                                    
                                    onChange={(e) => setEvent({
                                    ...event,
                                    eventHave: e.target.checked ?
                                        [...event.eventHave, "live QA Session"] :
                                        event.eventHave.filter(item => item !== "live QA Session"),
                                    })}
                                    label=""
                                    control={<Box className={classes.eventMContent}>
                                        <img src={liveQnA} alt="Live Q&A" />
                                        <Box>
                                            <Typography variant='span' className={classes.eventFormat}>Live Q&A</Typography>
                                        </Box>
                                        <Box component="span"><Checkbox checked={event.eventHave.includes("live QA Session")} /></Box>
                                    </Box>}
                                />
                                {/* <FormControlLabel
                                    className={classes.eventTypeSelection}
                                    value="speakers"                                    
                                    onChange={(e) => setEvent({
                                    ...event,
                                    eventHave: e.target.checked ?
                                        [...event.eventHave, "speakers"] :
                                        event.eventHave.filter(item => item !== "speakers"),
                                    })}
                                    label=""
                                    control={<Box className={classes.eventMContent}>
                                        <img src={speakers} alt="Speakers" />
                                        <Box>
                                            <Typography variant='span' className={classes.eventFormat}>Speakers</Typography>
                                        </Box>
                                        <Box component="span"><Checkbox checked={event.eventHave.includes("speakers")} /></Box>
                                    </Box>}
                                /> */}
                                {/* <FormControlLabel
                                    className={classes.eventTypeSelection}
                                    value="sponsors"                                    
                                    onChange={(e) => setEvent({
                                    ...event,
                                    eventHave: e.target.checked ?
                                        [...event.eventHave, "sponsors"] :
                                        event.eventHave.filter(item => item !== "sponsors"),
                                    })}
                                    label=""
                                    control={<Box className={classes.eventMContent}>
                                        <img src={sponsors} alt="Sponsors" />
                                        <Box>
                                            <Typography variant='span' className={classes.eventFormat}>Sponsors</Typography>
                                        </Box>
                                        <Box component="span"><Checkbox checked={event.eventHave.includes("sponsors")} /></Box>
                                    </Box>}
                                /> */}
                                {/* <FormControlLabel
                                    className={classes.eventTypeSelection}
                                    value="exhibitors"                                    
                                    onChange={(e) => setEvent({
                                    ...event,
                                    eventHave: e.target.checked ?
                                        [...event.eventHave, "exhibitors"] :
                                        event.eventHave.filter(item => item !== "exhibitors"),
                                    })}
                                    label=""
                                    control={<Box className={classes.eventMContent}>
                                        <img src={exhibitors} alt="Exhibitors" />
                                        <Box>
                                            <Typography variant='span' className={classes.eventFormat}>Exhibitors</Typography>
                                        </Box>
                                        <Box component="span"><Checkbox checked={event.eventHave.includes("exhibitors")} /></Box>
                                    </Box>}
                                /> */}
                            </FormGroup> 
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={5} md={6}>
                                    <Box className={classes.inputGroup}>
                                        <Typography variant='span' className={classes.inputLabel}>Organiser Name*</Typography>
                                        <TextField id="org-name" placeholder='Enter organiser name' variant="outlined"
                                            value={event && event.organiserName ? event.organiserName : ""}
                                            onChange={(e) => {
                                                setEvent({ ...event, organiserName: e.target.value })
                                            }}                                        
                                        />
                                        {error &&
                                            <span style={{color:'red'}}>Please complete this required field.</span>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={7} md={6}>
                                    <Box className={`${classes.inputGroup} ${classes.lastInputGroup}`}>
                                        <Typography variant='span' className={classes.inputLabel}>Organiser URL (optional)</Typography>
                                        <TextField id="org-url" placeholder='Enter organiser URL' variant="outlined"
                                            value={event && event.organiserURL ? event.organiserURL : ""}
                                            onChange={(e) => {
                                                setEvent({ ...event, organiserURL: e.target.value })
                                            }}
                                        />
                                        {errorOrganiserURL && <span style={{color:'red'}}>Invalid URL.</span>}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className={classes.bbuttons}>
                            <Button
                                color="primary"
                                className={classes.backbtn}
                                startIcon={<ChevronLeftIcon />}
                                onClick={() => setStep({...step, stepNo: 2,})}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleContinue}
                            >Continue</Button>                            
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default CreateAnEventDetails1