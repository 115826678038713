import { Box, Container, Typography, Grid } from '@material-ui/core'
import React from 'react'
import { homeBannerStyles } from '../../utils/hooks/useApplyStyles'
import HomeSearch from '../HomePage/HomePageSections/HomeSearchbar';

import bannerImage from '../../assets/images/banner.png';

const BannerHome = () => {
    const styles = homeBannerStyles();
    const classes = styles();
    return (
        <Box className={classes.bannerHome}>
            <Container maxWidth="lg">
                <Grid container alignItems="center">
                    <Grid item xs={12} md={6}>
                        <div className={classes.bannerMainHomeContent}>
                            <Typography variant="h1" component="h1" gutterBottom className={classes.mainText}>
                                vFairs Discover: Find Top Virtual, Hybrid & In-Person Events
                            </Typography>
                            <HomeSearch />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={classes.bannerImg}>
                            <img src={bannerImage} alt="Discover Events by vFairs" />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default BannerHome