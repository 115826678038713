import { Box, Typography, Grid } from '@material-ui/core';
import React from 'react'
import { awardWinnerCardStyles } from '../../../utils/hooks/useApplyStyles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import winTagIcon from '../../../assets/images/winnertag-icon.svg'

const AwardWinnerCard = () => {
    const styles = awardWinnerCardStyles();
    const classes = styles();
    return (
        <Grid container spacing={3} justifyContent="center">
            <Grid item lg={4} xs={12}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew/9704/content/images/1701931626caribbean-services-exporters-symposium-png1701931626.PNG" alt="Caribbean Services Exporters Symposium" />
                            </Box>
                            <Box className={classes.awWinOrg}>
                                <Box className={classes.awWinOrgTitle}>
                                    <Box className={classes.awWinIcon}>
                                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706884343ttcsi-png1706884343.png" alt="Caribbean Services Exporters Symposium" />
                                    </Box>
                                    <Typography variant="body1">Trinidad and Tobago Coalition of Services</Typography>
                                </Box>
                                <a href='/eventeer-awards-2024/69/nominee/9704' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Box className={classes.winnering}>
                                <img src={winTagIcon} alt="WinnerIn" />
                                <Typography variant="span">Winner In</Typography>
                            </Box>
                            <Typography variant="body1">Best Conference</Typography>
                            <Typography variant="h2">Caribbean Services Exporters Symposium</Typography>
                        </Box>
                    </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew/9110/content/images/1702570352exterior-png1702570352.png" alt="VBuildFair" />
                            </Box>
                            <Box className={classes.awWinOrg}>
                                <Box className={classes.awWinOrgTitle}>
                                    <Box className={classes.awWinIcon}>
                                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706886167lynx-background-png1706886167.png" alt="VBuildFair" />
                                    </Box>
                                    <Typography variant="body1">Lynx Exhibitions</Typography>
                                </Box>
                                <a href='eventeer-awards-2024/72/nominee/9110' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Box className={classes.winnering}>
                                <img src={winTagIcon} alt="WinnerIn" />
                                <Typography variant="span">Winner In</Typography>
                            </Box>
                            <Typography variant="body1">Best Trade Show</Typography>
                            <Typography variant="h2">VBuildFair</Typography>
                        </Box>
                    </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew/9692/content/images/1702998586acca-eu-vcf-png1702998586.PNG" alt="ACCA EU Virtual Careers Fair" />
                            </Box>
                            <Box className={classes.awWinOrg}>
                                <Box className={classes.awWinOrgTitle}>
                                    <Box className={classes.awWinIcon}>
                                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706884911acca-png1706884911.png" alt="ACCA EU Virtual Careers Fair" />
                                    </Box>
                                    <Typography variant="body1">ACCA</Typography>
                                </Box>
                                <a href='eventeer-awards-2024/73/nominee/9692' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Box className={classes.winnering}>
                                <img src={winTagIcon} alt="WinnerIn" />
                                <Typography variant="span">Winner In</Typography>
                            </Box>
                            <Typography variant="body1">Best Hiring Fair</Typography>
                            <Typography variant="h2">ACCA EU Virtual Careers Fair</Typography>
                        </Box>
                    </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8692/content/images/1677056519nnlm-virtual-symposium-png1677056519.PNG" alt="Health Misinformation" />
                            </Box>
                            <Box className={classes.awWinOrg}>
                                <Box className={classes.awWinOrgTitle}>
                                    <Box className={classes.awWinIcon}>
                                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706886456nlm-png1706886456.png" alt="Health Misinformation" />
                                    </Box>
                                    <Typography variant="body1">Network of the National Library of Medicine</Typography>
                                </Box>
                                <a href='eventeer-awards-2024/76/nominee/8692' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Box className={classes.winnering}>
                                <img src={winTagIcon} alt="WinnerIn" />
                                <Typography variant="span">Winner In</Typography>
                            </Box>
                            <Typography variant="body1">Best Education Fair</Typography>
                            <Typography variant="h2">2023 NNLM Virtual Symposium on Health Misinformation</Typography>
                        </Box>
                    </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8261/content/images/1691948265eco-canada-rsquo-s-virtual-career-fair-png1691948265.PNG" alt="ECO Canada's Virtual Career Fair" />
                            </Box>
                            <Box className={classes.awWinOrg}>
                                <Box className={classes.awWinOrgTitle}>
                                    <Box className={classes.awWinIcon}>
                                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706886631eco-canada-png1706886631.png" alt="ECO Canada's Virtual Career Fair" />
                                    </Box>
                                    <Typography variant="body1">ECO Canada</Typography>
                                </Box>
                                <a href='eventeer-awards-2024/78/nominee/8261' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Box className={classes.winnering}>
                                <img src={winTagIcon} alt="WinnerIn" />
                                <Typography variant="span">Winner In</Typography>
                            </Box>
                            <Typography variant="body1">Best Non-profit Event</Typography>
                            <Typography variant="h2">ECO Canada's Virtual Career Fair</Typography>
                        </Box>
                    </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew/10426/content/images/1701943483tambiz-png1701943483.PNG" alt="8th TamBiz: Next Gen" />
                            </Box>
                            <Box className={classes.awWinOrg}>
                                <Box className={classes.awWinOrgTitle}>
                                    <Box className={classes.awWinIcon}>
                                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706886885feu-png1706886885.png" alt="8th TamBiz: Next Gen" />
                                    </Box>
                                    <Typography variant="body1">Far Eastern University</Typography>
                                </Box>
                                <a href='eventeer-awards-2024/80/nominee/10426' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Box className={classes.winnering}>
                                <img src={winTagIcon} alt="WinnerIn" />
                                <Typography variant="span">Winner In</Typography>
                            </Box>
                            <Typography variant="body1">Best University Event</Typography>
                            <Typography variant="h2">8th TamBiz: Next Gen</Typography>
                        </Box>
                    </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew/9230/content/images/1702810433living-well-with-diabetes-png1702810433.PNG" alt="Living Well with Diabetes" />
                            </Box>
                            <Box className={classes.awWinOrg}>
                                <Box className={classes.awWinOrgTitle}>
                                    <Box className={classes.awWinIcon}>
                                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706893593ndss-png1706893593.png" alt="Living Well with Diabetes" />
                                    </Box>
                                    <Typography variant="body1">National Diabetes Services Scheme</Typography>
                                </Box>
                                <a href='eventeer-awards-2024/81/nominee/9230' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Box className={classes.winnering}>
                                <img src={winTagIcon} alt="WinnerIn" />
                                <Typography variant="span">Winner In</Typography>
                            </Box>
                            <Typography variant="body1">Best Healthcare Event</Typography>
                            <Typography variant="h2">Living Well with Diabetes</Typography>
                        </Box>
                    </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/9559/content/images/1702736426cara-west-2023-png1702736426.PNG" alt="CARA Unite" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1707156193strategic-events-new-png1707156194.png" alt="CARA Unite" />
                                </Box>
                                <Typography variant="body1">Strategic Events</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/83/nominee/9559' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Agency Event</Typography>
                        <Typography variant="h2">CARA West</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8700/content/images/1701678455gm-expo-png1701678455.PNG" alt="GM Expo" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706894294gm-png1706894294.png" alt="GM Expo" />
                                </Box>
                                <Typography variant="body1">General Motors</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/84/nominee/8700' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Networking Event</Typography>
                        <Typography variant="h2">GM Expo</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/9861/content/images/1701244037destination-canada-2023-png1701244037.PNG" alt="Destination Canada 2023" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706902743goc-png1706902743.png" alt="Destination Canada 2023" />
                                </Box>
                                <Typography variant="body1">Government of Canada</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/85/nominee/9861' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Exhibition Experience</Typography>
                        <Typography variant="h2">Destination Canada 2023</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1703000613lundbeck-national-meeting-png1703000613.PNG" alt="Lundbeck National Meeting" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/17069029171-lunch-png1706902917.png" alt="Lundbeck National Meeting" />
                                </Box>
                                <Typography variant="body1">Lundbeck</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/88/nominee/10766' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Use of Gamification</Typography>
                        <Typography variant="h2">Lundbeck National Meeting</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/10068/content/images/1702827597regtech-convention-png1702827598.PNG" alt="Regnology 30th RegTech Convention" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706903336image-6-png1706903336.png" alt="Regnology 30th RegTech Convention" />
                                </Box>
                                <Typography variant="body1">Regnology</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/89/nominee/10068' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Hybrid Event</Typography>
                        <Typography variant="h2">Regnology 30th RegTech Convention</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1702897674steelpointe-yacht-amp-charter-show-png1702897674.PNG" alt="Steelpointe Yacht & Charter Show/Wings, Wheels & Water" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706903563showpiece-shows-png1706903563.png" alt="Steelpointe Yacht & Charter Show/Wings, Wheels & Water" />
                                </Box>
                                <Typography variant="body1">Showpiece Shows</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/90/nominee/10741' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Out of the Box Event</Typography>
                        <Typography variant="h2">Steelpointe Yacht & Charter Show/Wings, Wheels & Water</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/9143/content/images/1700470005entrance-png1700470005.PNG" alt="2nd Annual Break Into Law Conference" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706904552barrier-breakers-png1706904552.png" alt="2nd Annual Break Into Law Conference" />
                                </Box>
                                <Typography variant="body1">Barrier Breakers</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/91/nominee/9143' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Diversity and Inclusion Event</Typography>
                        <Typography variant="h2">2nd Annual Break Into Law Conference</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/7791/content/images/1667888528isa-rsquo-s-automation-leadership-conference-alc-png1667888528.PNG" alt="ISA Automation & Leadership Conference" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706904818isa-png1706904818.png" alt="ISA Automation & Leadership Conference" />
                                </Box>
                                <Typography variant="body1">International Society of Automation</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/92/nominee/7791' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Mobile App Event</Typography>
                        <Typography variant="h2">ISA Automation & Leadership Conference</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1702917861urg-tech-summit-png1702917861.PNG" alt="URG Tech Summit" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706904993united-robotics-png1706904993.png" alt="URG Tech Summit" />
                                </Box>
                                <Typography variant="body1">United Robotics Group</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/94/nominee/10745' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Tech Industry Event</Typography>
                        <Typography variant="h2">URG Tech Summit</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/10306/content/images/1701514694youth-connect-png1701514694.PNG" alt="Youth Connect" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706905123maxwell-leadership-png1706905123.png" alt="Youth Connect" />
                                </Box>
                                <Typography variant="body1">Maxwell Leadership</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/97/nominee/10306' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Training Events</Typography>
                        <Typography variant="h2">Youth Connect</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/5289/content/images/1672399337entrance-png1672399337.png" alt="New Caregiver Experience" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706905277cleveland-clinic-png1706905277.png" alt="New Caregiver Experience" />
                                </Box>
                                <Typography variant="body1">Cleveland Clinic</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/103/nominee/5289' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Internal Event</Typography>
                        <Typography variant="h2">New Caregiver Experience</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8900/content/images/1702827234etap-conference-png1702827234.PNG" alt="ETAP Global Conference" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706905435etap-png1706905435.png" alt="ETAP Global Conference" />
                                </Box>
                                <Typography variant="body1">ETap</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/105/nominee/8900' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Virtual Event</Typography>
                        <Typography variant="h2">ETAP Global Conference</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/7791/content/images/1667888528isa-rsquo-s-automation-leadership-conference-alc-png1667888528.PNG" alt="ISA Automation & Leadership Conference" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706904818isa-png1706904818.png" alt="ISA Automation & Leadership Conference" />
                                </Box>
                                <Typography variant="body1">International Society of Automation</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/107/nominee/7791' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best In-Person Event</Typography>
                        <Typography variant="h2">ISA Automation & Leadership Conference</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8135/content/images/1702966271virtual-careers-fair-university-of-sydney-png1702966271.PNG" alt="2023 Virtual International Careers Fair" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706905709university-of-sydney-png1706905709.png" alt="2023 Virtual International Careers Fair" />
                                </Box>
                                <Typography variant="body1">The University of Sydney</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/109/nominee/8135' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Immersive Event Design</Typography>
                        <Typography variant="h2">2023 Virtual International Careers Fair</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/9212/content/images/1684581364pharmacy-careers-summit-png1684581364.PNG" alt="Pharmacy Careers Summit 2023" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706905867ravens-png1706905867.png" alt="Pharmacy Careers Summit 2023" />
                                </Box>
                                <Typography variant="body1">Raven's Recruitment</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/111/nominee/9212' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Summit</Typography>
                        <Typography variant="h2">Pharmacy Careers Summit 2023</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/10230/content/images/1701451631statewide-pk-12-educational-employment-fair-png1701451631.PNG" alt="Statewide PK-12 Educational Employment Fair" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706905994tcoe-complete-png1706905994.png" alt="Statewide PK-12 Educational Employment Fair" />
                                </Box>
                                <Typography variant="body1">Tulare County Office of Education</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/112/nominee/10230' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Event - North America</Typography>
                        <Typography variant="h2">Statewide PK-12 Educational Employment Fair</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/7927/content/images/1670757053fijatec-virtual-png1670757053.PNG" alt="Fijatec Virtual" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706906110fijatec-png1706906110.png" alt="Fijatec Virtual" />
                                </Box>
                                <Typography variant="body1">Fijatec</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/113/nominee/7927' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Event - Latin America</Typography>
                        <Typography variant="h2">Fijatec Virtual</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1705680247ot-cybersecurity-summit-png1705680247.PNG" alt="OT Cybersecurity Summit" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706904818isa-png1706904818.png" alt="OT Cybersecurity Summit" />
                                </Box>
                                <Typography variant="body1">International Society of Automation</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/114/nominee/11587' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Event - EMEA</Typography>
                        <Typography variant="h2">OT Cybersecurity Summit</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/9230/content/images/1702810433living-well-with-diabetes-png1702810433.PNG" alt="Living Well with Diabetes" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706893593ndss-png1706893593.png" alt="Living Well with Diabetes" />
                                </Box>
                                <Typography variant="body1">National Diabetes Services Scheme</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/115/nominee/9230' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Event - APAC</Typography>
                        <Typography variant="h2">Living Well with Diabetes</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/9559/content/images/1702736426cara-west-2023-png1702736426.PNG" alt="CARA Unite" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1707156193strategic-events-new-png1707156194.png" alt="CARA Unite" />
                                </Box>
                                <Typography variant="body1">Strategic Events</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/93/nominee/9559' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best Research Event</Typography>
                        <Typography variant="h2">CARA West</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/10601/content/images/1701330258nestle-global-pre-onboarding-fair-png1701330258.PNG" alt="Nestle Pre-Boarding Site" />
                        </Box>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706906572nestle-png1706906572.png" alt="Nestle Pre-Boarding Site" />
                                </Box>
                                <Typography variant="body1">Nestlé</Typography>
                            </Box>
                            <a href='eventeer-awards-2024/87/nominee/10601' className={classes.awWinOrgLink}><OpenInNewIcon /></a>
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Box className={classes.winnering}>
                            <img src={winTagIcon} alt="WinnerIn" />
                            <Typography variant="span">Winner In</Typography>
                        </Box>
                        <Typography variant="body1">Best High Volume Event</Typography>
                        <Typography variant="h2">Nestle Pre-Boarding Site</Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default AwardWinnerCard