import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import NavigationBar from "../Header/Navbar";
import DiscoverFooter from '../Footer/Footer'
import SectionMoreCards from "./SectionMore";
import SectionMap from './SectionMap'
import BannerEventDetail from '../Banners/BannerEventDetail'
import ContentBox from './DetailContent/DetailContentBox'
import { useParams } from "react-router-dom";
//import { Helmet } from "react-helmet";
//import moment from 'moment';
//import ScrollToTop from '.././../components/ScrollToTop';
//import Tags from "./DetailContent/Tags";
//import EventTags from "./DetailContent/EventTags";

export default function EventDetailtMain() {

  window.dataLayer.push({
    event: 'pageview',
    page: {
      url: window.location.href,
      title: "Event Detail"
    }
  });

  const [searchFilters, setSearchFilters] = useState({
    searchTerm: null,
  });
  const { id, event_slug } = useParams();

  const GET_EVENT_API = `${global.API.rootPath}${global.config.endpoints.events_endpoint}?access_key=${global.config.access_key}&page=1&limit=1&get_exhibitors_data=true&get_sponsors_data=true&get_event_tag=true&get_speakers_data=true&get_event_cat=true&event_id=${id}`;
  
  const [post, setPost] = useState({
    data: null,
    isLoading: false,
    error: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setPost({ ...post, data: null, isLoading: true });
    axios.get(GET_EVENT_API).then((response) => {
      
      if( response.data &&
          response.data.total_count &&
          response.data.total_count !== 0 &&
          response.data.data[0] &&
          response.data.data[0].app_title &&
          response.data.data[0].application &&
          response.data.data[0].application.is_published_on_discover        
        ) {
            let fetched_slug = response.data && response.data.data[0] && response.data.data[0].slug ? response.data.data[0].slug : response.data.data[0].app_title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
            if(response.data.data[0].app_id === "9855") {
              window.location.href = "http://opengovtransform.com/";
            }            
            else if (response.data.data[0].application.is_published_on_discover === "0") {
              //window.location.href = "/";
            }
            else if(event_slug !== fetched_slug) {
                window.location = fetched_slug;
            }
            else {
              setPost({ ...post, data: response.data, isLoading: false });
            }              
          }
        
    }).catch((err) => setPost({ ...post, error: err, isLoading: false }));
  }, [id]);

  // if (post &&
  //   post.data &&
  //   post.data.total_count &&
  //   post.data.total_count !== 0 &&
  //   post.data.data[0] &&
  //   post.data.data[0].app_title &&
  //   post.data.data[0].application &&
  //   post.data.data[0].application.is_published_on_discover &&
  //   post.data.data[0].application.is_published_on_discover !== "0") {
  //     let fetched_slug = post.data.data[0].slug ? post.data.data[0].slug : post.data.data[0].app_title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  //     if (event_slug !== fetched_slug) {
  //       window.location = fetched_slug;
  //     }
  //   }

  const GET_MORELIKE_API = `${global.API.rootPath}${global.config.endpoints.events_endpoint}?access_key=${global.config.access_key}&page=1&limit=4&sort_by_event_start_date=asc&get_event_tag=true`;

  const [morePost, setMorePost] = useState({
    moreLikeData: null,
    moreIsLoading: false,
    moreLikeError: "",
  });

  useEffect(() => {
    if (post &&
      post.data &&
      post.data.total_count &&
      post.data.total_count !== 0 &&
      post.data.data[0] &&
      post.data.data[0].application &&
      post.data.data[0].application.is_published_on_discover &&
      post.data.data[0].application.is_published_on_discover === "1" &&
      post.data.data[0].eventCat &&
      post.data.data[0].eventCat.length !== 0) {
      var categories = post.data.data[0].eventCat.map(function (val) {
        return val.category_id;
      }).join(',');

      setMorePost({ ...morePost, moreIsLoading: true });
      axios.get(GET_MORELIKE_API + `&category_ids=${categories}`).then((response) => {
        setMorePost({ ...morePost, moreLikeData: response.data, moreIsLoading: false });
      })
        .catch((err) => setMorePost({ ...morePost, moreLikeError: err, moreIsLoading: false }));
    }

  }, [post.data]);

  const mapRef = useRef(null);
  const executeMapScroll = () => mapRef.current.scrollIntoView(true);

  const [pageJumps, setPageJumps] = useState("");

  const descRef = useRef(null);
  function executeDescScroll() {
    setPageJumps("active-description");
    descRef.current.scrollIntoView(true);
  }

  const sponsorsRef = useRef(null);
  function executeSponsorsScroll() {
    setPageJumps("active-sponsors");
    sponsorsRef.current.scrollIntoView(true);
  }

  const agendaRef = useRef(null);
  function executeAgendaScroll() {
    setPageJumps("active-agenda");
    agendaRef.current.scrollIntoView(true);
  }

  const speakersRef = useRef(null);
  function executeSpeakersScroll() {
    setPageJumps("active-speakers");
    speakersRef.current.scrollIntoView(true);
  }

  const exhibitorsRef = useRef(null);
  function executeExhibitorsScroll() {
    setPageJumps("active-exhibitors");
    exhibitorsRef.current.scrollIntoView(true);
  }

  return (
    <div className="application">

      {/* <ScrollToTop /> */}
      <NavigationBar searchFilters={searchFilters} setSearchFilters={setSearchFilters} event_data={post} />
      <BannerEventDetail event_data={post} />
      <ContentBox
        pageJumps={pageJumps} setPageJumps={setPageJumps} executeDescScroll={executeDescScroll} executeSponsorsScroll={executeSponsorsScroll}
        executeAgendaScroll={executeAgendaScroll} executeExhibitorsScroll={executeExhibitorsScroll}
        executeSpeakersScroll={executeSpeakersScroll} descRef={descRef} sponsorsRef={sponsorsRef} agendaRef={agendaRef} speakersRef={speakersRef} exhibitorsRef={exhibitorsRef} mapScroll={executeMapScroll} event_data={post} />
      {post &&
        post.data &&
        post.data.current_page_count &&
        post.data.current_page_count !== 0 &&
        post.data.data &&
        post.data.data[0] &&
        post.data.data[0].event_mode &&
        post.data.data[0].event_mode === "HYBRID" &&
        post.data.data[0].location &&
        post.data.data[0].longitude &&
        post.data.data[0].latitude && (
          <SectionMap event_data={post.data.data[0]} refMapProp={mapRef} />
        )
      }

      {/* Event Tags */}
      {/* <EventTags /> */}

      {morePost &&
        morePost.moreLikeData &&
        morePost.moreLikeData.current_page_count &&
        morePost.moreLikeData.current_page_count !== 0 &&
        morePost.moreLikeData.data && (
          <SectionMoreCards more_events={morePost.moreLikeData.data} />
        )
      }
      <DiscoverFooter />
    </div>

  );
}