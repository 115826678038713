import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, InputBase, Button } from '@material-ui/core';
import { useCoverCardMediaStyles } from '@mui-treasury/styles/cardMedia/cover';
import { homeBannerStyles } from "../../../utils/hooks/useApplyStyles";
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';

export default function HomeSearch() {
    const styles1 = useCoverCardMediaStyles();
    const styles2 = homeBannerStyles();
    const classes = { ...styles1, ...styles2() }
    const history = useHistory();

    const [searchFilters, setSearchFilters] = useState({
        inputSearch: "",
    })

    return (
        <>
        <Box className={classes.searchBox}>

            <Paper
                component="form"
                className={classes.searchBar}
                onSubmit={(e) => {
                    e.preventDefault();
                    history.push("/marketplace/?&search=" + searchFilters.inputSearch);
                }}
            >
                <IconButton className={classes.iconButton} aria-label="menu">
                    <SearchIcon />
                </IconButton>
                <InputBase
                    className={classes.input}
                    placeholder="Search Your Favorite Events"
                    inputProps={{ 'aria-label': 'Search Your Favorite Events' }}
                    value={searchFilters.inputSearch}
                    onChange={(e) => setSearchFilters({
                        ...searchFilters,
                        inputSearch: e.target.value,
                    })}
                />
                <Button
                    type="submit"
                    aria-label="search"
                    id="search-events-discover"
                    variant="containedPrimary"
                    color="primary"
                    disabled={!searchFilters.inputSearch}
                >
                    discover
                </Button>

            </Paper>
        </Box>
                            
        </>
        );
};
