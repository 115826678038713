import { Box, Button, Container, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import React from 'react'
import { lookingUpStyles, onboardingStyles } from '../../../utils/hooks/useApplyStyles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import singleEmployer from '../../../assets/images/single-employer-career-fair.svg'
import onboardingfair from '../../../assets/images/onboarding-fari.svg'
import conference from '../../../assets/images/conference.svg'
import educationfair from '../../../assets/images/education-fair.svg'
import motorshow from '../../../assets/images/motor-show.svg'
import openday from '../../../assets/images/open-day.svg'
import exhibitionfair from '../../../assets/images/exhibition-fair.svg'
//import benefitsfair from '../../../assets/images/benfits-fair.svg'
import multismployer from '../../../assets/images/multi-employer.svg'
import tradeshow from '../../../assets/images/trade-show.svg'
import housingfair from '../../../assets/images/housing-fair.svg'
import product_launch from '../../../assets/images/product-launch.svg'
import others from '../../../assets/images/others.svg'


const CreateAnEventStep2 = ({setStep,step,event,setEvent}) => {
    const styles = onboardingStyles();
    const styles1 = lookingUpStyles();
    const classes = { ...styles(), ...styles1() }
    return (
        <Box className={classes.eventSteps} style={{ overflow: 'hidden' }}>
            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item lg={8}>
                        <Box className={classes.eventStepsC}>
                            <Box className={classes.eventStepGuide}>
                                <Typography variant='span' className='quarter'>2</Typography>
                                <Typography variant='body1'>select event format</Typography>
                            </Box>
                            <Typography className={classes.eventStepTitle} variant="h2">Choose your event type:</Typography>
                            <Box className={classes.eventSelection}>
                                <Typography variant="span" className={classes.eventSelectionLabel}>Select your event type from the options below.</Typography>
                                <RadioGroup
                                    name="event-format"
                                    className={classes.twoRowContent}
                                >
                                    <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="Conference"
                                        onChange={(e) => {
                                            setEvent({ ...event, type: e.target.value })
                                        }}
                                        checked={event.type === 'Conference'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={conference} alt="conference" />
                                            <Box>
                                                <Typography variant="h2">conference</Typography>
                                                <Typography variant="body1">Create endless opportunities for learning and networking by hosting a winning conference.</Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>}
                                    />
                                    <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="Virtual Trade Show"
                                        onChange={(e) => {
                                            setEvent({ ...event, type: e.target.value })
                                        }}
                                        checked={event.type === 'Virtual Trade Show'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={tradeshow} alt="Trade Show" />
                                            <Box>
                                                <Typography variant="h2">Trade Show</Typography>
                                                <Typography variant="body1">Help businesses connect with global prospects and drive leads with exhibiting products and services.</Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>}
                                    />
                                    <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="Single Employer Career Fair"
                                        onChange={(e) => {
                                            setEvent({ ...event, type: e.target.value })
                                        }}
                                        checked={event.type === 'Single Employer Career Fair'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={singleEmployer} alt="Single-Employer Career Fair" />
                                            <Box>
                                                <Typography variant="h2">Single-Employer Career Fair</Typography>
                                                <Typography variant="body1">Promote employer brands, share open roles, and help recruiters hire top talent worldwide.</Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>}
                                    />
                                    <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="Multi Employer Job Fair"
                                        onChange={(e) => {
                                            setEvent({ ...event, type: e.target.value })
                                        }}
                                        checked={event.type === 'Multi Employer Job Fair'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={multismployer} alt="Multi-Employer Job Fair" />
                                            <Box>
                                                <Typography variant="h2">Multi-Employer Job Fair</Typography>
                                                <Typography variant="body1">Excite job seekers by hosting webinars and accepting real-time resumes and taking interviews with promising candidates from a pool of recruiters.</Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>}
                                    />
                                    <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="Virtual Open Day"
                                        onChange={(e) => {
                                            setEvent({ ...event, type: e.target.value })
                                        }}
                                        checked={event.type === 'Virtual Open Day'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={openday} alt="Open Day" />
                                            <Box>
                                                <Typography variant="h2">Open Day</Typography>
                                                <Typography variant="body1">Attract more admission applications by showcasing everything your school has to offer to students from all over the world.</Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>}
                                    />
                                    <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="Virtual Exhibition Fair"
                                        onChange={(e) => {
                                            setEvent({ ...event, type: e.target.value })
                                        }}
                                        checked={event.type === 'Virtual Exhibition Fair'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={exhibitionfair} alt="exhibition fair" />
                                            <Box>
                                                <Typography variant="h2">exhibition fair</Typography>
                                                <Typography variant="body1">Experience the future of exhibitions with immersive and interactive exhibit halls to drive leads with exhibiting products and services.</Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>}
                                    />
                                    <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="Virtual Onboarding Fair"
                                        onChange={(e) => {
                                            setEvent({ ...event, type: e.target.value })
                                        }}
                                        checked={event.type === 'Virtual Onboarding Fair'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={onboardingfair} alt="Onboarding fair" />
                                            <Box>
                                                <Typography variant="h2">Onboarding Fair</Typography>
                                                <Typography variant="body1">Help new hires explore the organization and different employee programs.</Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>}
                                    />
                                    <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="Virtual Education Fair"
                                        onChange={(e) => {
                                            setEvent({ ...event, type: e.target.value })
                                        }}
                                        checked={event.type === 'Virtual Education Fair'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={educationfair} alt="education fair" />
                                            <Box>
                                                <Typography variant="h2">education fair</Typography>
                                                <Typography variant="body1">Help students explore the different areas involved in the university-level study while understanding the benefits of universities worldwide.</Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>}
                                    />
                                    {/* <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="Virtual Benefits Fair"
                                        onChange={(e) => {
                                            setEvent({ ...event, type: e.target.value })
                                        }}
                                        checked={event.type === 'Virtual Benefits Fair'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={benefitsfair} alt="Benefits fair" />
                                            <Box>
                                                <Typography variant="h2">Benefits Fair</Typography>
                                                <Typography variant="body1">Publicize employee benefits to a remote and in-house workforce while making it accessible and fun.</Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>}
                                    /> */}
                                    <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="Virtual Product Launch"
                                        onChange={(e) => {
                                            setEvent({ ...event, type: e.target.value })
                                        }}
                                        checked={event.type === 'Virtual Product Launch'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={product_launch} alt="Product Launch" />
                                            <Box>
                                                <Typography variant="h2">Product Launch</Typography>
                                                <Typography variant="body1">Host a stunning virtual product launch that wows your audience and prepare your product for record sales.</Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>}
                                    />
                                    <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="Virtual Motor Show"
                                        onChange={(e) => {
                                            setEvent({ ...event, type: e.target.value })
                                        }}
                                        checked={event.type === 'Virtual Motor Show'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={motorshow} alt="Motor Show"/>
                                            <Box>
                                                <Typography variant="h2">Motor Show</Typography>
                                                <Typography variant="body1">Connect globally, monetize participation, engage in real-time conversations, and promote offerings through listings.</Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>}
                                    />                                    
                                    <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="Virtual Housing Fair"
                                        onChange={(e) => {
                                            setEvent({ ...event, type: e.target.value })
                                        }}
                                        checked={event.type === 'Virtual Housing Fair'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={housingfair} alt="Housing Fair" />
                                            <Box>
                                                <Typography variant="h2">Housing Fair</Typography>
                                                <Typography variant="body1">Help students explore available properties conveniently and connect them with the best local property agents.</Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>}
                                    />
                                    <FormControlLabel
                                        className={classes.eventModeSelection}
                                        value="Other"
                                        onChange={(e) => {
                                            setEvent({ ...event, type: e.target.value })
                                        }}
                                        checked={event.type === 'Other'}
                                        control={<Radio />}
                                        label={<Box className={classes.eventMContent}>
                                            <img src={others} alt="Other" />
                                            <Box>
                                                <Typography variant="h2">Other Events</Typography>
                                                <Typography variant="body1">Not seeing your exact event description? You can still showcase your unique, custom event with this category.</Typography>
                                            </Box>
                                            <Box component="span" className={classes.eventCheck}><CheckCircleIcon /></Box>
                                        </Box>}
                                    />
                                </RadioGroup>
                            </Box>
                            <Box className={classes.bbuttons}>
                             <Button
                                    color="primary"
                                    className={classes.backbtn}
                                    startIcon={<ChevronLeftIcon />}
                                    onClick={() => setStep({...step, stepNo: 1,})}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setStep({...step, stepNo: 3,})}
                                >Continue</Button>                                
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={4} className={classes.rOverViewGrid}>
                        <Box className={classes.stepOverview}>
                            <Box className={`${classes.stepContenttwo} ${classes.stepOverviewContent}`}>
                                {/* <img src={seventyfivePercent} alt="vfairs discover" /> */}
                                {event && event.mode &&
                                    event.mode === "HYBRID" ? <Typography variant="body1"><span>86%</span> of B2B organizations report experiencing a beneficial return on investment from their hybrid events.</Typography> :
                                    event.mode === "PHYSICAL" ? <Typography variant="body1"><span>81%</span> of events scheduled for 2022 incorporated an in-person element.</Typography> :
                                    <Typography variant="body1"><span>93.2%</span> of event planners have hosted successful virtual events in terms of attendance rate.</Typography>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default CreateAnEventStep2