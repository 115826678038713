import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Paper, Box, Container, IconButton, Button, Typography, LinearProgress } from "@material-ui/core";
import BoxCards from "./Cards/BoxedCard";
import ListCards from "./Cards/ListCard";
import ReorderIcon from "@material-ui/icons/Reorder";
import BorderAllIcon from "@material-ui/icons/BorderAll";
import { cardsStyles, LtOrangeChip } from "../../utils/hooks/useApplyStyles";
import moment from 'moment'
import FiltersDrawer from './Filters/FiltersDrawer';
import SelectedFilters from './Filters/SelectedFilters';
import nothingFound from '../../assets/images/no-data-found-minfi.png';

const BASE_URL = `${global.API.rootPath}${global.config.endpoints.events_endpoint}?access_key=${global.config.access_key}&page=1&get_event_cat=true&get_event_tag=true&is_featured=0`;
const FEATURED_BASE_URL = `${global.API.rootPath}${global.config.endpoints.featured_events_endpoint}?access_key=${global.config.access_key}&page=1&get_event_cat=true&get_event_tag=true`;
const CAT_BASE_URL = `${global.API.rootPath}${global.config.endpoints.categories_endpoint}?access_key=${global.config.access_key}&page=1&limit=50&sort_by_created_at=asc&get_tags_data=true`;
const TAGS_BASE_URL = `${global.API.rootPath}${global.config.endpoints.tags_endpoint}?access_key=${global.config.access_key}&page=1&limit=50&sort_by_created_at=asc&get_category_data=true`;

export default function CardsWrapper({ searchTerm }) {

  const styles = cardsStyles();
  const classes = styles();

  const [listView, setListView] = useState("grid");
  const [post, setPost] = useState({
    data: null,
    isLoading: false,
    error: "",
  });

  const history = useHistory();
  function setParams({ param, query }) {
    const searchParams = new URLSearchParams();
    searchParams.set(param, query || "");
    if (query) {
      return searchParams.toString();
    }
  }
  const search = useLocation().search;
  const getFromDate = new URLSearchParams(search).get('from_date');
  const getToDate = new URLSearchParams(search).get('to_date');
  const getEventStatus = new URLSearchParams(search).get('event-status');
  const getPriceMode = new URLSearchParams(search).get('price-type');
  const getFromPrice = new URLSearchParams(search).get('from-price');
  const getToPrice = new URLSearchParams(search).get('to-price');
  const getEventMode = new URLSearchParams(search).get('event-mode');
  const getLocation = new URLSearchParams(search).get('location');
  const getCats = new URLSearchParams(search).get('topics');
  const getTags = new URLSearchParams(search).get('tags');
  const getFormats = new URLSearchParams(search).get('formats');
  const getOpenForSponsorship = new URLSearchParams(search).get('open-for-sponsorship');
  const getFeatured = new URLSearchParams(search).get('featured');
  const [eventstatus, setEventStatus] = useState({
    eventStatusValue: getEventStatus ? getEventStatus : 'all',
  });

  const [postFilters, setPostFilters] = useState({
    startDate: getFromDate ? getFromDate : "",
    endDate: getToDate ? getToDate : "",
    eventStatus: getEventStatus ? getEventStatus : "all",
    price: getPriceMode ? getPriceMode : "",
    from_price: getFromPrice ? getFromPrice : "",
    to_price: getToPrice ? getToPrice : "",
    eventMode: getEventMode ? getEventMode : "",
    location: getLocation ? getLocation : "",
    categories: getCats ? getCats.split(',') : [],
    tags: getTags ? getTags.split(',') : [],
    formats: getFormats ? getFormats.split(',') : [],
    OpenForSponsorship: getOpenForSponsorship ? getOpenForSponsorship : "",
    featured: getFeatured ? getFeatured : "",
    limit: 21,
    featureLimit: 6,
  })

  const [allCats, setAllCats] = useState(null);
  const [allTags, setAllTags] = useState(null);
  const [searchTags, setSearchTags] = useState("");

  useEffect(() => {
    axios.get(CAT_BASE_URL).then((cats) => {
      setAllCats(cats.data);
    }).catch((err) => setPost({ ...post, error: err, isLoading: false }));;
  }, []);

  useEffect(() => {
    if (!allCats) {
      return
    }
    let callApi = false;
    if (searchTags && searchTags.length > 2) callApi = true
    else if (!searchTags || searchTags.length == 0) callApi = true

    if (callApi) {
      let tag_cat= allCats.data && allCats.data.length > 0 ? allCats.data.filter(cat=> postFilters.categories.includes(cat.name)).map(cat=>cat.id) : [];
      axios.get(TAGS_BASE_URL + `${searchTags ? "&search_by_tag_name=" + searchTags.replace(/[']/g, '') : ""}${tag_cat && tag_cat.length != 0 ? "&category_id=" + tag_cat.toString() : ""}`).then((get_tags) => {
        setAllTags(get_tags.data);
      }).catch((err) => setPost({ ...post, error: err, isLoading: false }));;
    }
  }, [searchTags, postFilters.categories, allCats]);
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [postFilters.price, postFilters.from_price, postFilters.to_price, postFilters.eventMode, postFilters.categories, postFilters.tags, postFilters.formats]);

  useEffect(() => {
    if (!allCats || !allTags) {
      return
    }
    // if (postFilters.eventStatus && postFilters.eventStatus === "live") {
    //   //todays date only
    //   postFilters.startDate = moment().subtract(0, "days").format("YYYY-MM-DD")
    //   postFilters.endDate = postFilters.startDate;
    // }
    if (postFilters.eventStatus && postFilters.eventStatus === "upcoming") {
      //from tomorrow to onwards
      postFilters.startDate = moment().add(1, 'days').format("YYYY-MM-DD")
      postFilters.endDate = "";
    }
    else if (postFilters.eventStatus && (postFilters.eventStatus === "all" || postFilters.eventStatus === "on_demand" || postFilters.eventStatus === "archive" || postFilters.eventStatus === "live")) {
      postFilters.startDate = "";
      postFilters.endDate = "";
    }
    const setFromDate = setParams({ param: "from-date", query: postFilters.startDate });
    const setToDate = setParams({ param: "to-date", query: postFilters.endDate });
    const setEventStatus = setParams({ param: "event-status", query: postFilters.eventStatus });
    const setPriceMode = setParams({ param: "price-type", query: postFilters.price });
    const setFromPrice = setParams({ param: "from-price", query: postFilters.from_price });
    const setToPrice = setParams({ param: "to-price", query: postFilters.to_price });
    const setEventMode = setParams({ param: "event-mode", query: postFilters.eventMode });
    const setLocation = setParams({ param: "location", query: postFilters.location });
    const setSearchQuery = setParams({ param: "search", query: searchTerm.replace(/[']/g, '') });
    const setCats = setParams({ param: "topics", query: postFilters.categories && postFilters.categories.length != 0 ? postFilters.categories : "" });
    const setTags = setParams({ param: "tags", query: postFilters.tags && postFilters.tags.length != 0 ? postFilters.tags : "" });
    const setFormats = setParams({ param: "formats", query: postFilters.formats && postFilters.formats.length != 0 ? postFilters.formats : "" });
    const setOpenForSponsorship = setParams({ param: "open-for-sponsorship", query: postFilters.OpenForSponsorship });
    const setFeatured = setParams({ param: "featured", query: postFilters.featured })

    history.push(`?${setFromDate ? setFromDate : ""}${setToDate ? "&" + setToDate : ""}${setEventStatus ? "&" + setEventStatus : ""}${setSearchQuery ? "&" + setSearchQuery : ""}${setPriceMode ? "&" + setPriceMode : ""}${setFromPrice && postFilters.price && postFilters.price === "PAID" ? "&" + setFromPrice : ""}${setToPrice && postFilters.price && postFilters.price === "PAID" ? "&" + setToPrice : ""}${setEventMode ? "&" + setEventMode : ""}${setLocation && postFilters.eventMode === "HYBRID" ? "&" + setLocation : ""}${setCats && setCats.length != 0 ? "&" + decodeURIComponent(setCats) : ""}${setTags ? "&" + decodeURIComponent(setTags) : ""}${setFormats ? "&" + decodeURIComponent(setFormats) : ""}${setOpenForSponsorship ?  "&" + setOpenForSponsorship : ""}${setFeatured ?  "&" + setFeatured : ""}`);
    setPost({ ...post, isLoading: true });
    let cat_ids = allCats.data && allCats.data.length > 0 ? allCats.data.filter(cat=> postFilters.categories.includes(cat.name)).map(cat=>cat.id) : [];
    let tag_ids = allTags.data && allTags.data.length > 0 ? allTags.data.filter(tag=> postFilters.tags.includes(tag.name)).map(tag=>tag.id) : [];

    let endpoints = [
      BASE_URL + `&limit=${postFilters.limit}${postFilters.eventStatus === "archive" || postFilters.eventStatus === "all" ? "&sort_by_event_start_date=desc" : "&sort_by_event_start_date=asc"}${postFilters.eventStatus && postFilters.eventStatus === "on_demand" ? "&get_past_events=" + postFilters.eventStatus : postFilters.eventStatus === "archive" ? "&get_past_events=" + postFilters.eventStatus : postFilters.eventStatus === "live" ? "&get_past_events=" + postFilters.eventStatus : ""}${postFilters.startDate ? "&from_date=" + postFilters.startDate : ""}${postFilters.endDate ? "&to_date=" + postFilters.endDate : ""}${cat_ids && cat_ids.length != 0 ? "&category_ids=" + cat_ids.toString() : ""}${postFilters.formats && postFilters.formats.length != 0 ? "&event_format=" + postFilters.formats : ""}${tag_ids && tag_ids.length != 0 ? "&tag_ids=" + tag_ids.toString() : ""}${postFilters.eventMode ? "&event_mode=" + postFilters.eventMode : ""}${searchTerm ? "&search=" + searchTerm.replace(/[']/g, '') : ""}${postFilters.location && postFilters.eventMode && postFilters.eventMode === "HYBRID" ? "&location=" + postFilters.location : ""}${postFilters.price ? "&price_type=" + postFilters.price : ""}${postFilters.from_price && postFilters.price && postFilters.price === "PAID" ? "&from_price=" + postFilters.from_price : ""}${postFilters.to_price && postFilters.price && postFilters.price === "PAID" ? "&to_price=" + postFilters.to_price : ""}${postFilters.OpenForSponsorship ? "&open_for_sponsorship=" + postFilters.OpenForSponsorship : ""}`,
      FEATURED_BASE_URL + `&limit=${postFilters.featureLimit}${postFilters.eventStatus === "archive" || postFilters.eventStatus === "all" ? "&sort_by_event_start_date=desc" : "&sort_by_event_start_date=asc"}${postFilters.eventStatus && postFilters.eventStatus === "on_demand" ? "&get_past_events=" + postFilters.eventStatus : postFilters.eventStatus === "archive" ? "&get_past_events=" + postFilters.eventStatus : postFilters.eventStatus === "live" ? "&get_past_events=" + postFilters.eventStatus : ""}${postFilters.startDate ? "&from_date=" + postFilters.startDate : ""}${postFilters.endDate ? "&to_date=" + postFilters.endDate : ""}${cat_ids && cat_ids.length != 0 ? "&category_ids=" + cat_ids.toString() : ""}${postFilters.formats && postFilters.formats.length != 0 ? "&event_format=" + postFilters.formats : ""}${tag_ids && tag_ids.length != 0 ? "&tag_ids=" + tag_ids.toString() : ""}${postFilters.eventMode ? "&event_mode=" + postFilters.eventMode : ""}${searchTerm ? "&search=" + searchTerm.replace(/[']/g, '') : ""}${postFilters.location && postFilters.eventMode && postFilters.eventMode === "HYBRID" ? "&location=" + postFilters.location : ""}${postFilters.price ? "&price_type=" + postFilters.price : ""}${postFilters.from_price && postFilters.price && postFilters.price === "PAID" ? "&from_price=" + postFilters.from_price : ""}${postFilters.to_price && postFilters.price && postFilters.price === "PAID" ? "&to_price=" + postFilters.to_price : ""}${postFilters.OpenForSponsorship ? "&open_for_sponsorship=" + postFilters.OpenForSponsorship : ""}`
    ];
    
    Promise.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      axios.spread((...allData) => {
        let data = postFilters.featured === "1" ? [...allData[1].data.data] : [...allData[1].data.data, ...allData[0].data.data];
        setPost({
          ...post, data, pageInfo: {
            totalCount:    postFilters.featured === "1" ? allData[1].data.total_count     : parseInt(allData[0].data.total_count)     + parseInt(allData[1].data.total_count),
            currentPageNo: postFilters.featured === "1" ? allData[1].data.current_page_no : parseInt(allData[0].data.current_page_no) + parseInt(allData[1].data.current_page_no),
            totalPages:    postFilters.featured === "1" ? allData[1].data.total_pages     : parseInt(allData[0].data.total_pages)     + parseInt(allData[1].data.total_pages),
          },
          isLoading: false
        });
      })
    );

  }, [postFilters, searchTerm, allCats, allTags]);

  return (
    <>
      <Container className={classes.gridContainer} style={{ paddingLeft: 8, paddingRight: 8, }}>
        <div style={{ minHeight: 10, }}>
          {post.isLoading && (
            <LinearProgress />
          )}
        </div>

        {post.error && <p>{post.error}</p>}
        <section className={classes.mainSection}>

          <div className={classes.filterSidebar} >
            <div className={classes.filterbtns}>
              <Typography variant="h5" component="p" style={{ marginBottom: 10, marginLeft: 5, }}>
                Filters
              </Typography>
            </div>

            <Paper className={classes.filterBox}>
              <Box p={2}>
                <FiltersDrawer
                  eventstatus={eventstatus} setEventStatus={setEventStatus}
                  postFilters={postFilters} setPostFilters={setPostFilters}
                  allCats={allCats} setAllCats={setAllCats}
                  allTags={allTags} setAllTags={setAllTags}
                  searchTags={searchTags} setSearchTags={setSearchTags}
                />
              </Box>
            </Paper>
          </div>

          <div className={classes.listingBox} >
            <div className={classes.filterbtns}>
              <div className={classes.filtersCountnFilter}>
                {post && post.pageInfo && post.pageInfo.totalCount > 0 &&
                  <LtOrangeChip
                    label={post.pageInfo.totalCount + " Events"}
                  />
                }

              </div>

              {!!post &&
                !!post.pageInfo &&
                !!post.pageInfo.totalCount &&
                post.pageInfo.totalCount !== 0 && (
                  <div>
                    <IconButton
                      aria-label="list"
                      onClick={() => setListView("list")}
                    >
                      <ReorderIcon />
                    </IconButton>
                    <IconButton
                      aria-label="grid"
                      onClick={() => setListView("grid")}
                    >
                      <BorderAllIcon />
                    </IconButton>
                  </div>
                )
              }
            </div>
            {postFilters && (postFilters.startDate || postFilters.endDate || postFilters.eventStatus || postFilters.OpenForSponsorship || postFilters.featured || postFilters.price || postFilters.eventMode ||
              postFilters.tags.length != 0 || postFilters.categories.length || postFilters.formats.length != 0) && 
              <SelectedFilters
                eventstatus={eventstatus} setEventStatus={setEventStatus}
                postFilters={postFilters} setPostFilters={setPostFilters}
              />
            }
            
            {post && post.pageInfo && post.pageInfo.totalCount === 0 &&
              <div className={classes.noResults}>
                <img alt="No Events Found" src={nothingFound} />
                <Typography variant="h3">We couldn't find events for your query.</Typography>
                <Typography variant="body1">Sorry about that! Would you like to try a different search?</Typography>
              </div>
            }
            <div className={classes.boxGrid}>
              {!!post &&
                !!post.data &&
                !!post.pageInfo &&
                !!post.pageInfo.totalCount &&
                post.pageInfo.totalCount !== 0 &&
                listView === "list" && (
                  <ListCards events={post} />
                )
              }

              {!!post &&
                !!post.data &&
                !!post.pageInfo &&
                !!post.pageInfo.totalCount &&
                post.pageInfo.totalCount !== 0 &&
                listView === "grid" && (
                  <BoxCards events={post} />
                )
              }
              {!!post &&
                !!post.data &&
                !!post.pageInfo &&
                !!post.pageInfo.totalPages &&
                !!post.pageInfo.currentPageNo &&
                post.pageInfo.totalPages != post.pageInfo.currentPageNo && (
                  <Box mt={4} textAlign={"center"}>
                    <Button
                      variant="containedPrimary"
                      color="primary"
                      onClick={() => setPostFilters({
                        ...postFilters,
                        limit: postFilters.limit + 21,
                        featureLimit: postFilters.featureLimit + 6,
                      })}
                    >
                      {post.isLoading ? 'Loading...' : 'Load More Events'}
                    </Button>
                  </Box>
                )
              }

            </div>
          </div>
        </section>
      </Container>
    </>
  );
}
