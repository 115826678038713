import 'date-fns';
import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { cardsStyles } from "../../../utils/hooks/useApplyStyles";
import moment from 'moment'

export default function FiltersDatePicker({postFilters, setPostFilters, setEventStatus}) {
  const styles = cardsStyles();
  const classes = styles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container className={classes.dateWrap}>
        <InputLabel>From</InputLabel>
        <KeyboardDatePicker
          className={classes.datePicker}
          margin="normal"
          format="dd-MM-yyyy"
          value={postFilters.startDate ? postFilters.startDate : null}
          onChange={ (date)  => {
            setEventStatus("");
            //setPostFilters({ ...postFilters, eventStatus: '', startDate: moment(date).format("YYYY-MM-DD") });
            setPostFilters({ ...postFilters, eventStatus: '', startDate: moment(date).format("YYYY-MM-DD") });
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <InputLabel>To</InputLabel>

        <KeyboardDatePicker
          className={classes.datePicker}
          margin="normal"
          format="dd-MM-yyyy"
          value={postFilters.endDate ? postFilters.endDate : null}
          onChange={(date)  => {
            setEventStatus("");
            setPostFilters({ ...postFilters, eventStatus: '', endDate: moment(date).format("YYYY-MM-DD") });
            
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
