import React, { useEffect, useState, useRef } from "react";
//import axios from "axios";
import { Box, Button, Container, Typography, LinearProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { onboardingStyles } from '../../../utils/hooks/useApplyStyles';
import signupImage from '../../../assets/images/signup-image.png'
import {FieldFeedback, FieldFeedbacks, FormWithConstraints} from 'react-form-with-constraints-material-ui';
import signupDoneImage from '../../../assets/images/signup-done.png'

const SignUpForm = () => {
    const styles = onboardingStyles();
    const classes = styles();

    const [signUpResponse, setSignUpResponse] = useState({
        status: "",
        msg: "",
        error: "",
        isLoading: false,
    });

    const form = useRef(null);

    //get country by IP
    const [country, setCountry] = useState({
        country: "",
        ip_address: "",
    });
    useEffect(() => {
        var ipAPI = 'https://ip.nf/me.json';
        fetch(ipAPI, {method: "get"})
            .then((response) => response.json())
            .then((data) => {
                setCountry({ ...country, country: data.ip.country, ip_address: data.ip.ip })
        });
    }, []);
    //validates fields
    async function handleChange({ target }) {
        await form.current.validateFields(target);
        setSignUpResponse({ ...signUpResponse, status: "", msg: "", error: ""});
    }

    async function handleSubmit(e) {
        e.preventDefault();

        setSignUpResponse({ ...signUpResponse, status: "", msg: "", error: ""});

        // Validates the non-dirty fields and returns Promise<Field[]>
        await form.current.validateForm();
        
        if (form.current.isValid()) {
            
            setSignUpResponse({ ...signUpResponse, isLoading: true });

            var OnboardingUsers = `${global.API.rootPath}${global.config.endpoints.onboarding_users}`;

            //var bearerToken = `${global.config.onboarding_token}`;
            
            //HEADERS
            var usersHeaders = new Headers();
            //usersHeaders.append("Authorization", `Bearer ${bearerToken}`);
            usersHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            //POST DATA
            var urlencoded = new URLSearchParams();
            urlencoded.append("country", country.country);
            urlencoded.append("ip_address", country.ip_address);
            urlencoded.append("email", e.target.elements.email.value);
            urlencoded.append("name", e.target.elements.name.value);

            var requestOptions = {
                method: 'POST',
                headers: usersHeaders,
                body: urlencoded,
                redirect: 'follow',
                //mode: 'no-cors'
            };

            fetch(OnboardingUsers, requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                setSignUpResponse({ ...signUpResponse, status: data.status, msg: data.msg, isLoading: false });
            })
            .catch(error => {
                setSignUpResponse({ ...signUpResponse, status: false, error: true, isLoading: false });
                console.log('error', error)
            });

        }
        else {
            console.log('The form is invalid');
        }
    }
    return (
        <Box className={classes.obLogin}>
            <Container maxWidth="lg">
            <Box className={classes.obLoginBox}>
                {signUpResponse && signUpResponse.status && signUpResponse.msg === "User Registred Successfully!" ?
                    <>
                        <img src={signupDoneImage} alt="signup Done" />
                        <Typography variant="h2">Almost Done!</Typography>
                        <Typography variant="body1" component="p">We have sent email to verify email with an verification link. Check your email and click on the link.</Typography>
                    </>

                :
                    <>
                        <img src={signupImage} alt="SignUp for Onboarding"/>
                        <Typography variant="h2">Sign up</Typography>
                        <Typography variant="body1">Create your first event with discover</Typography>
                        <FormWithConstraints ref={form} onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.obLoginForm}>
                            <input name="name" required minLength={3} id="name" className={classes.input} placeholder='Your Name*' onChange={handleChange}/>
                            <FieldFeedbacks for="name">
                                <FieldFeedback when={value => value.length === 0}>Please complete this required field.</FieldFeedback>
                            </FieldFeedbacks>
                            <input name="email" required minLength={3} id="email" className={classes.input} placeholder='Email Address*' onChange={handleChange} style={{marginTop: 15,}}/>
                            {signUpResponse && !signUpResponse.status && signUpResponse.msg && 
                                <span class="error">{signUpResponse.msg}</span>
                            }
                            <FieldFeedbacks for="email">
                                <FieldFeedback when={value => value.length === 0}>Please complete this required field.</FieldFeedback>
                                {/* <FieldFeedback when={value => !/\S+@\S+/.test(value)}>Invalid email address.</FieldFeedback> */}
                            </FieldFeedbacks>
                            <div style={{ minHeight: 15, marginTop: 15, }}>
                                {signUpResponse && signUpResponse.isLoading &&
                                    <LinearProgress />
                                }
                            </div>
                            <Button type="submit" variant='contained' color="primary">Create</Button>
                        </FormWithConstraints>
                        <Box className={classes.alreadyAccount}>
                            <Typography variant='body1'>Already have an account?</Typography>
                            <Link to="/onboarding-login">Login</Link>
                        </Box>
                    </>
                }
                </Box>
            </Container>
        </Box>
    )
}

export default SignUpForm