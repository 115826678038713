import { Box, FormControl, FormControlLabel, Grid, IconButton, Input, InputLabel, Checkbox, Radio, RadioGroup, FormGroup, Switch, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { dashboardStyles } from '../../utils/hooks/useApplyStyles';
import multismployer from '../../assets/images/multi-employer.svg'
import singlemployer from '../../assets/images/single-employer-career-fair.svg'
import others from '../../assets/images/others.svg'
import conference from '../../assets/images/conference.svg'
import educationfair from '../../assets/images/education-fair.svg'
import exhibitionfair from '../../assets/images/exhibition-fair.svg'
import housingfair from '../../assets/images/housing-fair.svg'
import motorshow from '../../assets/images/motor-show.svg'
import openday from '../../assets/images/open-day.svg'
import product_launch from '../../assets/images/product-launch.svg'
import tradeshow from '../../assets/images/trade-show.svg'
import onboardingfair from '../../assets/images/onboarding-fari.svg'
import matchmaking from '../../assets/images/matchmaking.svg'
import webinar from '../../assets/images/webinar.svg'
import networking from '../../assets/images/networking.svg'
import gamification from '../../assets/images/gamification.svg'
import liveQnA from '../../assets/images/liveQnA.svg'
// import speakers from '../../assets/images/speakers.svg'
// import sponsors from '../../assets/images/sponsors.svg'
// import exhibitors from '../../assets/images/exhibitors.svg'
import UploadIcon from '../../assets/images/upload.svg'
//import { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';

const AboutEvent = ({event,setEvent}) => {
    const styles = dashboardStyles();
    const classes = styles();

    async function handleCoverImageSubmit(e) {
        e.preventDefault();

        let file = e.target.files[0];
        if(file){
            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = (e) => {
                setEvent({ ...event, coverImage: e.target.result })
            }
        }
    }

    return (
        <>
            <FormControl className={classes.eventInput}>
                <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={10}
                    placeholder="Write Event Description..."
                    variant="outlined"
                    value={event && event.description ? event.description : ""}
                    onChange={(e) => {
                        setEvent({ ...event, description: e.target.value })
                    }}
                />
            </FormControl>

            <FormControl className={classes.eventInput}>
                <InputLabel shrink>What does your event type? (format)</InputLabel>
                <RadioGroup className={classes.ctEventHave} name="event-format">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControlLabel
                                value="Single-Employer Career Fair"
                                onChange={(e) => {
                                    setEvent({ ...event, type: e.target.value })
                                }}
                                checked={event.type === 'Single-Employer Career Fair'}
                                control={<Radio />}
                                label={<Box className={classes.ctEventRLabel}> <img src={singlemployer} alt="label" /> <span>Single-Employer Career Fair</span> </Box>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControlLabel
                                value="Multi-Employer Job Fair"
                                onChange={(e) => {
                                    setEvent({ ...event, type: e.target.value })
                                }}
                                checked={event.type === 'Multi-Employer Job Fair'}
                                control={<Radio />}
                                label={<Box className={classes.ctEventRLabel}> <img src={multismployer} alt="label" /> <span>Multi-Employer Job Fair</span> </Box>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                value="Conference"
                                onChange={(e) => {
                                    setEvent({ ...event, type: e.target.value })
                                }}
                                checked={event.type === 'Conference'}
                                control={<Radio />}
                                label={<Box className={classes.ctEventRLabel}> <img src={conference} alt="label" /> <span>Conference</span> </Box>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                value="Trade Show"
                                onChange={(e) => {
                                    setEvent({ ...event, type: e.target.value })
                                }}
                                checked={event.type === 'Trade Show'}
                                control={<Radio />}
                                label={<Box className={classes.ctEventRLabel}> <img src={tradeshow} alt="label" /> <span>Trade Show</span> </Box>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                value="Open Day"
                                onChange={(e) => {
                                    setEvent({ ...event, type: e.target.value })
                                }}
                                checked={event.type === 'Open Day'}
                                control={<Radio />}
                                label={<Box className={classes.ctEventRLabel}> <img src={openday} alt="label" /> <span>Open Day</span> </Box>}
                            />
                        </Grid>                    
                        
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                value="Exhibition Fair"
                                onChange={(e) => {
                                    setEvent({ ...event, type: e.target.value })
                                }}
                                checked={event.type === 'Exhibition Fair'}
                                control={<Radio />}
                                label={<Box className={classes.ctEventRLabel}> <img src={exhibitionfair} alt="label" /> <span>Exhibition Fair</span> </Box>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                value="Onboarding Fair"
                                onChange={(e) => {
                                    setEvent({ ...event, type: e.target.value })
                                }}
                                checked={event.type === 'Onboarding Fair'}
                                control={<Radio />}
                                label={<Box className={classes.ctEventRLabel}> <img src={onboardingfair} alt="label" /> <span>Onboarding Fair</span> </Box>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                value="Education Fair"
                                onChange={(e) => {
                                    setEvent({ ...event, type: e.target.value })
                                }}
                                checked={event.type === 'Education Fair'}
                                control={<Radio />}
                                label={<Box className={classes.ctEventRLabel}> <img src={educationfair} alt="label" /> <span>Education Fair</span> </Box>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                value="Product Launch"
                                onChange={(e) => {
                                    setEvent({ ...event, type: e.target.value })
                                }}
                                checked={event.type === 'Product Launch'}
                                control={<Radio />}
                                label={<Box className={classes.ctEventRLabel}> <img src={product_launch} alt="label" /> <span>Product Launch</span> </Box>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                value="Motor Show"
                                onChange={(e) => {
                                    setEvent({ ...event, type: e.target.value })
                                }}
                                checked={event.type === 'Motor Show'}
                                control={<Radio />}
                                label={<Box className={classes.ctEventRLabel}> <img src={motorshow} alt="label" /> <span>Motor Show</span> </Box>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                value="Housing Fair"
                                onChange={(e) => {
                                    setEvent({ ...event, type: e.target.value })
                                }}
                                checked={event.type === 'Housing Fair'}
                                control={<Radio />}
                                label={<Box className={classes.ctEventRLabel}> <img src={housingfair} alt="label" /> <span>Housing Fair</span> </Box>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControlLabel
                                value="Other"
                                onChange={(e) => {
                                    setEvent({ ...event, type: e.target.value })
                                }}
                                checked={event.type === 'Other'}
                                control={<Radio />}
                                label={<Box className={classes.ctEventRLabel}> <img src={others} alt="label" /> <span>Other Events</span> </Box>}
                            />
                        </Grid>
                    </Grid>
                </RadioGroup>
            </FormControl>
            <FormControl className={classes.eventInput}>
                <InputLabel shrink>What does your event have?</InputLabel>
                <FormGroup
                    name="eventhave"
                    className={classes.eventDoes}
                >
                    <FormControlLabel
                        className={classes.eventTypeSelection}
                        value="1:1matchmaking"
                        onChange={(e) => setEvent({
                        ...event,
                        eventHave: e.target.checked ?
                            [...event.eventHave, "1:1matchmaking"] :
                            event.eventHave.filter(item => item !== "1:1matchmaking"),
                        })}
                        control={<Box className={classes.eventMContent}>
                            <img src={matchmaking} alt="Matchmaking" />
                            <Box>
                                <Typography variant='span' className={classes.eventFormat}>Matchmaking</Typography>
                            </Box>
                            <Box component="span"><Checkbox checked={event.eventHave.includes("1:1matchmaking")} /></Box>
                        </Box>}
                        label=""
                        
                    />
                    <FormControlLabel
                        className={classes.eventTypeSelection}
                        value="webinars"
                        onChange={(e) => setEvent({
                        ...event,
                        eventHave: e.target.checked ?
                            [...event.eventHave, "webinars"] :
                            event.eventHave.filter(item => item !== "webinars"),
                        })}
                        control={<Box className={classes.eventMContent}>
                            <img src={webinar} alt="Webinar" />
                            <Box>
                                <Typography variant='span' className={classes.eventFormat}>Webinar</Typography>
                            </Box>
                            <Box component="span"><Checkbox checked={event.eventHave.includes("webinars")} /></Box>
                        </Box>}
                        label=""
                    />
                    <FormControlLabel
                        className={classes.eventTypeSelection}
                        value="networking"
                        onChange={(e) => setEvent({
                        ...event,
                        eventHave: e.target.checked ?
                            [...event.eventHave, "networking"] :
                            event.eventHave.filter(item => item !== "networking"),
                        })}
                        label=""
                        control={<Box className={classes.eventMContent}>
                            <img src={networking} alt="networking" />
                            <Box>
                                <Typography variant='span' className={classes.eventFormat}>Networking</Typography>
                            </Box>
                            <Box component="span"><Checkbox checked={event.eventHave.includes("networking")} /></Box>
                        </Box>}
                    />
                    <FormControlLabel
                        className={classes.eventTypeSelection}
                        value="gamification"                                    
                        onChange={(e) => setEvent({
                        ...event,
                        eventHave: e.target.checked ?
                            [...event.eventHave, "gamification"] :
                            event.eventHave.filter(item => item !== "gamification"),
                        })}
                        label=""
                        control={<Box className={classes.eventMContent}>
                            <img src={gamification} alt="Gamification" />
                            <Box>
                                <Typography variant='span' className={classes.eventFormat}>Gamification</Typography>
                            </Box>
                            <Box component="span"><Checkbox checked={event.eventHave.includes("gamification")} /></Box>
                        </Box>}
                    />
                    <FormControlLabel
                        className={classes.eventTypeSelection}
                        value="live QA Session"                                    
                        onChange={(e) => setEvent({
                        ...event,
                        eventHave: e.target.checked ?
                            [...event.eventHave, "live QA Session"] :
                            event.eventHave.filter(item => item !== "live QA Session"),
                        })}
                        label=""
                        control={<Box className={classes.eventMContent}>
                            <img src={liveQnA} alt="Live Q&A" />
                            <Box>
                                <Typography variant='span' className={classes.eventFormat}>Live Q&A</Typography>
                            </Box>
                            <Box component="span"><Checkbox checked={event.eventHave.includes("live QA Session")} /></Box>
                        </Box>}
                    />
                    {/* <FormControlLabel
                        className={classes.eventTypeSelection}
                        value="speakers"                        
                        onChange={(e) => setEvent({
                        ...event,
                        eventHave: e.target.checked ?
                            [...event.eventHave, "speakers"] :
                            event.eventHave.filter(item => item !== "speakers"),
                        })}
                        label=""
                        control={<Box className={classes.eventMContent}>
                            <img src={speakers} alt="Speakers" />
                            <Box>
                                <Typography variant='span' className={classes.eventFormat}>Speakers</Typography>
                            </Box>
                            <Box component="span"><Checkbox checked={event.eventHave.includes("speakers")} /></Box>
                        </Box>}
                    /> */}
                    {/* <FormControlLabel
                        className={classes.eventTypeSelection}
                        value="sponsors"                        
                        onChange={(e) => setEvent({
                        ...event,
                        eventHave: e.target.checked ?
                            [...event.eventHave, "sponsors"] :
                            event.eventHave.filter(item => item !== "sponsors"),
                        })}
                        label=""
                        control={<Box className={classes.eventMContent}>
                            <img src={sponsors} alt="Sponsors" />
                            <Box>
                                <Typography variant='span' className={classes.eventFormat}>Sponsors</Typography>
                            </Box>
                            <Box component="span"><Checkbox checked={event.eventHave.includes("sponsors")} /></Box>
                        </Box>}
                    /> */}
                    {/* <FormControlLabel
                        className={classes.eventTypeSelection}
                        value="exhibitors"                        
                        onChange={(e) => setEvent({
                        ...event,
                        eventHave: e.target.checked ?
                            [...event.eventHave, "exhibitors"] :
                            event.eventHave.filter(item => item !== "exhibitors"),
                        })}
                        label=""
                        control={<Box className={classes.eventMContent}>
                            <img src={exhibitors} alt="Exhibitors" />
                            <Box>
                                <Typography variant='span' className={classes.eventFormat}>Exhibitors</Typography>
                            </Box>
                            <Box component="span"><Checkbox checked={event.eventHave.includes("exhibitors")} /></Box>
                        </Box>}
                    /> */}
                </FormGroup>
            </FormControl>

            <FormControl className={classes.eventInput}>
                <InputLabel shrink>Cover Image</InputLabel>
                <Box className={classes.ctEventUpload}>
                    {event && event.coverImage ? 
                        <Box className={classes.imageBox}>
                        <img src={event && event.coverImage} alt="profile pic" />
                        </Box>
                        :
                        <>
                        <img src={UploadIcon} alt="Upload Cover"/>
                        <Typography variant="body1">
                            Browse and chose the file you want to upload from your computer
                        </Typography>
                        </>
                    }
                    <Box className={classes.uploadbtn}>
                        <input accept="image/*" id="icon-button-file" type="file" onChange={handleCoverImageSubmit} />
                        <label htmlFor="icon-button-file">
                            <IconButton color="primary" variant="contained" aria-label="upload picture" component="span">
                                <AddIcon />
                            </IconButton>
                        </label>
                    </Box>
                </Box>
            </FormControl>
            {/* <FormControl className={classes.eventInput}>
                <InputLabel shrink>Event Gallery Images</InputLabel>
                <Box className={classes.ctEventUpload}>
                    <img src={UploadIcon} alt="Upload Gallery"/>
                    <Typography variant="body1">
                    (TODO) Browse and chose the files you want to upload from your computer
                    </Typography>
                    <Box className={classes.uploadbtn}>
                        <input accept="image/*" id="icon-button-file" type="file" multiple />
                        <label htmlFor="icon-button-file">
                            <IconButton color="primary" variant="contained" aria-label="upload picture" component="span">
                                <AddIcon />
                            </IconButton>
                        </label>
                    </Box>
                </Box>
            </FormControl> */}

            <FormControl className={classes.eventInput}>
                <InputLabel shrink>Price Type</InputLabel>
                <Box className={classes.ctEventPriceType}>
                    <RadioGroup className={classes.pTypeCheck} name="price-type">
                        <FormControlLabel
                            value="FREE"
                            control={<Radio />}
                            label="Free"
                            onChange={(e) => {
                                setEvent({ ...event, priceType: e.target.value })
                            }}
                            checked={event.priceType === 'FREE'}
                        />
                        <FormControlLabel
                            value="PAID"
                            control={<Radio />}
                            label="Paid"
                            onChange={(e) => {
                                setEvent({ ...event, priceType: e.target.value })
                            }}
                            checked={event.priceType === 'PAID'}
                        />
                    </RadioGroup>
                    {event && event.priceType && event.priceType === 'PAID' &&
                        <Input id="price" disableUnderline placeholder='Enter event price (Example. $56)' type="number"
                            value={event && event.price ? event.price : ""}
                            onChange={(e) => {
                                setEvent({ ...event, price: e.target.value })
                            }}
                        />
                    }
                </Box>
            </FormControl>

            <FormControl className={classes.eventInput}>
                <Box className={classes.ctEventFeatures}>
                    <FormControlLabel
                        label="Open For Sponsorship"
                        control={
                            <Switch
                                checked={event && event.sponsorship && event.sponsorship === "1" ? true : false}
                                onChange={(e) => {
                                    setEvent({ ...event, [e.target.name]: e.target.checked === true ? "1" : "0"});
                                }}
                                name="sponsorship"
                                color="primary"
                            />
                        }
                    />
                </Box>
            </FormControl>
        </>

    )
}

export default AboutEvent