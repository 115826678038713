// this file contains all global vairables
if(window && window.location && window.location.host){
    let rootPath = "https://api.vfairs.com/v13/"; //Prod API
    let voteAccessKey = "XtTqd6cPkOusoX5kMdJwxPd8GNrocRWNKv6Vq"; //Prod & Pre-prod vote accesskey
    if (window.location.host === "discover.vfairsdev.com") {
        rootPath = "https://discover.vfairsdev.com/api/v13/"; //Pre-Prod API
    } else if(window.location.host === "qc1.vfairsdev.com") {
        rootPath = "https://qa3-discover.vfairsdev.com/api/v13/"; //QA API
        voteAccessKey = "TDFDNjIhGy1kzsaA3FJZJy7NjKFZ4GnOFAtOV"; //QA vote accesskey
    }
    module.exports = global.API = {rootPath, voteAccessKey}
}
module.exports = global.config = {
    access_key: "asdasd",
    onboarding_token: "cTJNSmxLdUxPV3lGWlplNnhYUXVCZ0RZVDJ4U2hiLWEtbjE4QlBVZGc3MHZnZ2NBYUQ3M1JjSTd1N3dCQlZ5OENtVTNt",
    endpoints: {
        events_endpoint: "events",
        featured_events_endpoint: "events/featured",
        collections_endpoint: "collections",
        categories_endpoint: "categories",
        tags_endpoint: "tags",
        eventeer_vote: "eventeer-award-votes",
        onboarding_users: "users",
        onboarding_login: "auth/login",
        onboarding_forgot: "users/forgot-password",
        onboarding_update_profile: "users/update-profile",
        onboarding_get_profile: "users/get-profile",
        onboarding_create_event: "discover-event/create-event",
        onboarding_update_event: "discover-event/update-event",
        onboarding_get_event: "discover-event/events",
        login: "signins",
        register: "signups",
    },
};