import React from 'react';
import { Grid, Card, Container, Typography, Box } from '@material-ui/core';
import { cardsStyles, lookingUpStyles } from "../../../utils/hooks/useApplyStyles";
import CardContent from '../../Marketplace/Cards/CardContent'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import 'react-multi-carousel/lib/styles.css';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LoaderCard from "../../Loader/LoaderCard";
import LazyLoad from 'react-lazyload';
import upcomingBox from '../../../assets/images/upcomingobj.svg';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 3000, min: 1280 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 1279, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1023, min: 768 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    draggable: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
    useTransform: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                swipeToSlide: true,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                swipeToSlide: true,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                swipeToSlide: true,

            }
        }
    ]
};


export default function UpCommingEventCards({ events, eventsFilters, setEventsFilters }) {

    const styles = cardsStyles();
    const styles2 = lookingUpStyles();
    const classes = { ...styles(), ...styles2() }

    return (
        <>
            <Box component="div" className={`${classes.homeSection} ${classes.homesectionGap}`} style={{position: 'relative'}}>
                
                <Box className={classes.upcomingObj}>
                    <LazyLoad height={100}>
                        <img src={upcomingBox} alt="Upcoming BG" />
                    </LazyLoad> 
                </Box>
                <Container maxWidth="lg">
                    <Box className={classes.themeHeader} mb={2} textAlign="center">
                        <Typography variant="h2" className={classes.viewmoreTitle}>
                            Upcoming Events
                        </Typography>
                    </Box>

                    <ul className={classes.eventTypeLinks}>
                        <li>
                            <Link
                                to=""
                                id="all-upcoming-events"
                                className={eventsFilters.upcoming_type === "all-upcoming" ? "active" : ""}
                                onClick={() => setEventsFilters({
                                    ...eventsFilters,
                                    upcoming_type: "all-upcoming",
                                })}
                                gutterBottom
                            >
                                All
                            </Link>
                        </li>
                        <li>
                            <Link
                                to=""
                                id="recently-added-events"
                                className={eventsFilters.upcoming_type === "recently-added" ? "active" : ""}
                                onClick={() => setEventsFilters({
                                    ...eventsFilters,
                                    upcoming_type: "recently-added",
                                })}
                                gutterBottom
                            >
                                Recently Added
                            </Link>
                        </li>
                        <li>
                            <Link
                                to=""
                                id="this-week-events"
                                className={eventsFilters.upcoming_type === "this-week" ? "active" : ""}
                                onClick={() => setEventsFilters({
                                    ...eventsFilters,
                                    upcoming_type: "this-week",
                                })}
                                gutterBottom
                            >
                                This Week
                            </Link>
                        </li>
                        <li>
                            <Link
                                to=""
                                id="coming-week-events"
                                className={eventsFilters.upcoming_type === "next-week" ? "active" : ""}
                                onClick={() => setEventsFilters({
                                    ...eventsFilters,
                                    upcoming_type: "next-week",
                                })}
                                gutterBottom
                            >
                                Coming Week
                            </Link>
                        </li>
                        <li>
                            <Link
                                to=""
                                id="this-month-events"
                                className={eventsFilters.upcoming_type === "this-month" ? "active" : ""}
                                onClick={() => setEventsFilters({
                                    ...eventsFilters,
                                    upcoming_type: "this-month",
                                })}
                                gutterBottom
                            >
                                This Month
                            </Link>
                        </li>
                    </ul>
                    {events && events.data && events.data.current_page_count === 0 &&
                        <Box component="div" m={1} mb={3} className={classes.navBox} >

                            <div>
                                <h4>No events found for your search</h4>
                                <p>Unfortunately we can't match an event to your criteria. Try changing some filters to get more results.</p>
                            </div>

                        </Box>
                    }
                    {events && events.isLoading ?
                        <Grid container spacing={3} className={classes.homeCardWrap}>
                            <Grid item lg={3} sm={6} xs={12} >
                                <LoaderCard />
                            </Grid>
                            <Grid item lg={3} sm={6} xs={12} >
                                <LoaderCard />
                            </Grid>
                            <Grid item lg={3} sm={6} xs={12} >
                                <LoaderCard />
                            </Grid>
                            <Grid item lg={3} sm={6} xs={12} >
                                <LoaderCard />
                            </Grid>
                        </Grid>
                        :
                        <Grid className={classes.homeCardWrap}>
                            <Slider {...settings} className="cardthemeSlider">
                                {events && events.data && events.data.data && events.data.data.map((event) => {
                                    return (
                                        <>
                                            <Card className={classes.homeSingles}>
                                                <CardContent event_data={event} />
                                            </Card>
                                        </>
                                    );
                                })}
                            </Slider>
                        </Grid>
                    }  
                    <Box component="div" mt={3} className={`${classes.viewMoreBox} ${classes.centerViewmore}`} justifyContent="center">
                        <Link id="view-more-upcoming-events" to={"/marketplace/?&event-status=upcoming"} >View More <ArrowForwardIcon /> </Link>
                    </Box>
                </Container>
            </Box>
        </>
    );
};