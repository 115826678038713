import React from 'react';
import { Avatar, Typography, Box } from '@material-ui/core';
import { Row } from '@mui-treasury/components/flex';
import { Info, InfoTitle } from '@mui-treasury/components/info';
import { cardsStyles } from "../../../utils/hooks/useApplyStyles";
import LazyLoad from 'react-lazyload';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export default function EventFormatCardSingle({ format }) {
  const styles = cardsStyles();
  const classes = styles();

  return (
    <>
      <Row
        className={classes.eventFormatBox}
        m={0}
        p={2}
        pt={2}
        gap={1}
        bgcolor={'common.white'}
        flexDirection={'column'}
      >
        <LazyLoad height={100}>
          <Avatar className={styles.avatar} src={format.image} />
        </LazyLoad>

        <Row flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} style={{flex: 1}}>
          <Info position={'center'} className={classes.eventFormateText}>
            <Typography variant='h5' component="p" style={{ marginTop: 16, }}>{format.name}</Typography>
            {/* <InfoSubtitle variant="body1" component="span"> Combine In-person & Virtual Experiences</InfoSubtitle> */}
          </Info>
          <Box className={classes.rightMobileIcon}>
          <ChevronRightIcon />
        </Box>
        </Row>
   
      </Row>
    </>
  );
};