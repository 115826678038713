import React from 'react';
import { Link } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import {
  Info,
  InfoTitle,
} from '@mui-treasury/components/info';
import { useGalaxyInfoStyles } from '@mui-treasury/styles/info/galaxy';
import { useCoverCardMediaStyles } from '@mui-treasury/styles/cardMedia/cover';
import { topicCardSingleStyles } from "../../../utils/hooks/useApplyStyles";
import LazyLoad from 'react-lazyload';
import { Typography } from '@material-ui/core';

export default function TopicCardSingle({cat}) {
  const mediaStyles = useCoverCardMediaStyles({ bgPosition: 'top' });
  const styles = topicCardSingleStyles();
  const classes = styles();
  return (
    <>
      <Link
        id={"topic-"+cat.name.toLowerCase().replace(/ /g, '-')}
        to={"/marketplace/?&topics="+ cat.name.replace(/ /g, '+')} >
        <Card elevation={0}>
          {cat && cat.image &&
            <LazyLoad height={200} className={classes.browseTopicSignle}>
              {/* <CardMedia
                classes={mediaStyles}
                image={cat.image}
              /> */}
              <img src={cat.image} alt={cat.name} />
            </LazyLoad>
          }
          <Box py={2} className={classes.content}>
            <Info useStyles={useGalaxyInfoStyles}>
              {cat && cat.name &&
                <Typography variant="h6" component="p" className={classes.topicTitle}>{cat.name}</Typography>
              }
            </Info>
          </Box>
        </Card>
      </Link>
    </>
  );
};
