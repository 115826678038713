import { Box, Container, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { eventeerStyles } from '../../../../utils/hooks/useApplyStyles'
import LazyLoad from 'react-lazyload';
//import honorAward from '../../../../assets/images/honor-winner.png';
import honorAward2024 from '../../../../assets/images/eventeer2024-2ndsec.png'


const Honor = () => {

    const style = eventeerStyles();
    const classes = style();

    return (
        <Box className={classes.honor}>
            <Container maxWidth="lg">
                <Box className={classes.honorBG}>
                    <Grid container alignItems='center'justifyContent='center' spacing={4}>
                        <Grid item lg={5}>
                            <LazyLoad height={500}>
                                <Box className={classes.honorAwardImage}>
                                    <img src={honorAward2024} alt="Eventeer honour award" />
                                </Box>
                            </LazyLoad>
                        </Grid>
                        <Grid item lg={7}>
                            <Box className={classes.honorText}>
                                <Typography variant="h1" component="h2" className={classes.eventeerTitle}>
                                Embracing 2023: A Year Of Incredible Events
                                </Typography>
                                <Typography variant="body1">
                                Events have the power to serve as catalysts for progress, drive positive business outcomes, give underserved communities new opportunities, and offer pathways to exciting new careers.
                                </Typography>
                                <Typography variant="body1">
                                Throughout the year, vFairs clients have proudly hosted an impressive tally of over 2000 remarkable events, uniting a diverse audience of over 10 million attendees from all corners of the globe. The impact of these events has been nothing short of transformational, shaping diverse industries, strengthening communities, and touching countless lives.
                                </Typography>
                                <Typography variant="body1" style={{fontWeight: 'bold'}}>
                                This calls for a celebration.
                                </Typography>
                                <Typography variant="body1">
                                Introducing Eventeer Awards 2024, which will celebrate excellence in the events hosted on the vFairs platform throughout 2023. We will celebrate the amazing efforts of event organizers and hosts across industries worldwide.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default Honor