import React from 'react';
import Marketplace from "./pages/Marketplace";

function App() {
  return (
    <div>
       <Marketplace/>
    </div>
  );
}

export default App;