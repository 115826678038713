import React from 'react'
import { Container, Box, Typography, Grid } from '@material-ui/core'
import MoreCollectionCardContent from '../MoreCollectionCardContent'
import { cardsStyles } from "../../../utils/hooks/useApplyStyles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LoaderCard from "../../Loader/LoaderCard";


var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    draggable: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
    useTransform: false,

    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                swipeToSlide: true,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                swipeToSlide: true,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                swipeToSlide: true,

            }
        }
    ]
};

export default function CollectionCardSection({ moreCollection }) {
    const styles = cardsStyles();
    const classes = styles();
    return (
        <>
            <Box variant="div" mb={5} className="moreCollection">
                <Container maxWidth="lg">
                    <Box component="div" m={1} className={classes.viewMoreBox}>
                        <Box component="div">
                            <Typography variant="h2" gutterBottom className={classes.viewmoreTitle}>
                                More Collections
                            </Typography>
                        </Box>
                    </Box>

                    {moreCollection && moreCollection.isLoading ?
                        <Grid container spacing={3} className={classes.homeCardWrap}>
                            <Grid item lg={3} sm={6} xs={12} >
                                <LoaderCard />
                            </Grid>
                            <Grid item lg={3} sm={6} xs={12} >
                                <LoaderCard />
                            </Grid>
                            <Grid item lg={3} sm={6} xs={12} >
                                <LoaderCard />
                            </Grid>
                            <Grid item lg={3} sm={6} xs={12} >
                                <LoaderCard />
                            </Grid>
                        </Grid>
                        :
                        <Slider {...settings}>
                            {moreCollection &&
                                moreCollection.data &&
                                moreCollection.data.total_count &&
                                moreCollection.data.total_count > 0 &&
                                moreCollection.data.data &&
                                moreCollection.data.data.map(collection =>
                                    collection.status &&
                                    collection.status === "1" &&
                                    collection.is_private &&
                                    collection.is_private === "0" &&
                                    collection.event_id &&
                                    <MoreCollectionCardContent collection={collection} />

                                )
                            }
                        </Slider>
                    }
                </Container>
            </Box>
        </>
    )
};