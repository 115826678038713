
import React, { useState } from "react";
import InputBase from '@material-ui/core/InputBase';
import {Box, Button} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useRoundInputBaseStyles } from '@mui-treasury/styles/inputBase/round';
import {navbarStyles} from "../../utils/hooks/useApplyStyles";
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import clsx from 'clsx'


const SearchbarHeader = ({searchFilters, setSearchFilters}) => {
  const styles1 = useRoundInputBaseStyles();
  const styles2 = navbarStyles();
  const classes = {...styles1, ...styles2()}
  const [searchToggleMob, setSearchToggleMob] = useState(false);

  const searchToggle = () => {
    setSearchToggleMob(!searchToggleMob);
  }

  return (
    <div className={classes.search}>
      <Box pb={1} />
      <Button className={classes.searchIconMobile} onClick={searchToggle}>{<SearchIcon />}</Button>
      <InputBase
        classes={classes}
        className={clsx(classes.searchBarMobile, `${searchToggleMob ? classes.searchBarToggle : ''}`)} 
        color={'secondary'}
        startAdornment={<SearchIcon />}
        placeholder={'Search Here...'}
        value={searchFilters.searchTerm}
        onChange={(e) => setSearchFilters({ ...searchFilters, searchTerm: e.target.value.toLowerCase() })}       
        endAdornment={<ClearOutlinedIcon size="small" onClick={()=> setSearchFilters({...searchFilters,searchTerm: "" })} />}
      />   
      
        <Box pb={1} />
    </div>
  );
};

export default SearchbarHeader;