import React, { useState } from "react";
import {Box,Chip,InputBase,Typography,Button} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { useBorderedInputBaseStyles } from '@mui-treasury/styles/inputBase/bordered';
import {cardsStyles} from "../../../utils/hooks/useApplyStyles";
import { Row } from '@mui-treasury/components/flex';

export default function FilterTags ({ postFilters, setPostFilters, allTags,  setSearchTags }) {

  const styles1 = useBorderedInputBaseStyles();
  const styles2 = cardsStyles();
  const classes = {...styles1, ...styles2()}
  const [tagsMore, setTagsMore] = useState({
    tag_page: 8,
  })

  return (
    <div>
    {allTags && allTags.total_count && allTags.total_count > 0 && (
      <Typography variant="h6" component="p" gutterBottom>
        Tags
      </Typography>
    )}
    
      <Box pt={1} />
      <InputBase
        className={classes.filterSearchBox}
        placeholder={'Search Tags'}
        onChange={(s) => setSearchTags(s.target.value.toLowerCase())}
        startAdornment={<Search />}

      />

      <Row pt={2} className={classes.filterChips}>
      {!!allTags && allTags.data && allTags.data.length > 0 && allTags.data.slice(0, tagsMore.tag_page).map(tag =>
          <Chip
            variant={postFilters.tags.includes(tag.name) ? "default" : "outlined"}
            color="primary"
            label={tag.name}
            size="small"
            clickable={true}
            onClick={() => setPostFilters({
              ...postFilters,
              tags: postFilters.tags.includes(tag.name) ? postFilters.tags.filter(item => item !== tag.name) : [...postFilters.tags, tag.name],
            })}
          />
        )}
      </Row>
      <Row pt={0} style={{display:"flex", justifyContent:"center"}}>
        
          <Box mt={4} className={classes.filderButton}>
          {!!allTags &&
            allTags.data &&
            allTags.data.length > 0 &&
            allTags.data.length > tagsMore.tag_page &&
            (
              <Button
                variant="text"
                color="primary"
                onClick={() => setTagsMore({
                  ...tagsMore,
                  tag_page: tagsMore.tag_page + 8,
                })}
              >
                More
              </Button>
            )
          }

          {!!allTags &&
            tagsMore.tag_page > 8 && (
              <Button
                variant="text"
                color="primary"
                onClick={() => setTagsMore({
                  ...tagsMore,
                  tag_page: tagsMore.tag_page - 8,
                })}
              >
                Less
              </Button>
            )
          }
          </Box>
        
      </Row>
    </div>
  );
};
