import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import cx from 'clsx';
import { Link } from "react-router-dom";
import { Container, Typography, Button, InputBase, Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import { useBorderedInputBaseStyles } from '@mui-treasury/styles/inputBase/bordered';
import { forgotPassStyles } from '../../utils/hooks/useApplyStyles'

export default function LoginCard() {
    const styles3 = useBorderedInputBaseStyles();
    const styles1 = useFadedShadowStyles();
    const styles2 = forgotPassStyles();
    const classes = { ...styles1, ...styles3, ...styles2() }
    const history = useHistory();
    const LOGIN_URL = `${global.API.rootPath}${global.config.endpoints.login}?access_key=${global.config.access_key}`;
    console.log(LOGIN_URL);

    const [loginAction, setLoginAction] = useState({
        email: "",
        pass: "",
    })
    console.log(loginAction.email);
    console.log(loginAction.pass);

    const login = (event) => {
        event.preventDefault();
        debugger;
        axios.post(LOGIN_URL, {
            email: loginAction.email,
            password: loginAction.pass
        })
            .then(response => setLoginAction(response.data.id));
        // fetch('http://localhost:51282/Api/login/Login', {        
        //     method: 'post',        
        //     headers: {        
        //         'Accept': 'application/json',        
        //         'Content-Type': 'application/json'        
        //     },        
        //     body: JSON.stringify({        
        //         Email: this.state.Email,        
        //         Password: this.state.Password        
        //     })        
        // }).then((Response) => Response.json())        
        //     .then((result) => {        
        //         console.log(result);        
        //         if (result.Status == 'Invalid')        
        //             alert('Invalid User');        
        //         else        
        //             history.push("/Dashboard");        
        //     })        
        //history.push("/marketplace/?&search=" + loginAction.inputSearch);
    }

    return (
        <Container maxWidth="lg">
            <Card className={cx(classes.cardWide, styles1.root)}>
                <CardContent>
                    <Typography className={classes.heading}>login to your account</Typography>
                    <form
                        noValidate
                        autoComplete="off"
                        onSubmit={login}
                    >
                        <InputBase
                            classes={classes}
                            className={classes.filterSearchBox}
                            placeholder={'Email Address'}
                            style={{ marginBottom: '1.5rem' }}
                            type='email'
                            value={loginAction.email}
                            onChange={(e) => setLoginAction({
                                ...loginAction,
                                email: e.target.value,
                            })}
                        />
                        <InputBase
                            classes={classes}
                            className={classes.filterSearchBox}
                            placeholder={'Password'}
                            style={{ marginBottom: '1rem' }}
                            type='password'
                            value={loginAction.pass}
                            onChange={(e) => setLoginAction({
                                ...loginAction,
                                pass: e.target.value,
                            })}
                        />
                        <Link to="/forget-password/" color="primary" variant='body2' className={classes.forgotLink} >forgot password?</Link>
                        <Button variant="containedPrimary" color="primary" style={{ width: '100%', marginBottom: '1rem', marginTop: '1rem' }}>
                            login to your account
                        </Button>
                    </form>
                    <Divider />
                    <Button variant="outlinedSecondary" color="secondary" className={cx(classes.buttonLink, classes.buttonGoogle)}>
                        sign up with google
                    </Button>
                    <Button variant="outlinedSecondary" color="secondary" className={cx(classes.buttonLink, classes.buttonfb)}>
                        sign up with facebook
                    </Button>
                    <Divider />
                    <Typography variant='h6' className={classes.linkText} >Lorem ipsum dolor sit amet, consectetur adipiscing.</Typography>
                    <Link to="/join/" color="primary" variant='h6' className={classes.linkText} >Join vFairs Discover</Link>
                </CardContent>
            </Card>
        </Container>
    );
};