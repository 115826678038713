import React from 'react';
import { Box, Card } from '@material-ui/core';
import { speakerCardStyles } from "../../../utils/hooks/useApplyStyles";
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { Row, Item } from '@mui-treasury/components/flex';
import { Info, InfoTitle, InfoSubtitle } from '@mui-treasury/components/info';
import { useDynamicAvatarStyles } from '@mui-treasury/styles/avatar/dynamic';
import { useNewsInfoStyles } from '@mui-treasury/styles/info/news';
import 'react-multi-carousel/lib/styles.css';
import LazyLoad from 'react-lazyload';

export default function AgendaSpeakerStatic({ speaker }) {

    const styles1 = useDynamicAvatarStyles();
    const styles2 = speakerCardStyles();
    const classes = { ...styles1, ...styles2() }

    return (
        <>

            <Box className={classes.singleSpAgenda}>
                <Card className={classes.agendaCardWrap}>
                    <Row gap={2} alignItems="center">
                        <Item>
                            <LazyLoad height={80}>

                                <Avatar
                                    classes={classes}
                                    src={speaker && speaker.image ? speaker.image : ""}
                                />
                            </LazyLoad>
                        </Item>
                        <Info useStyles={useNewsInfoStyles}>
                            <Typography variant="h4" component="p" className={classes.agendaInfoTitle}>
                                {speaker && speaker.name ? <div dangerouslySetInnerHTML={{ __html: speaker.name }}></div> : ""}
                            </Typography>
                            {speaker && speaker.emp_name ?
                                <InfoSubtitle className={classes.infoSubTitle}> <div dangerouslySetInnerHTML={{ __html: speaker.emp_name.replace(/\<a /g, '<a rel="nofollow"') }}></div> </InfoSubtitle>
                                : ""}

                        </Info>
                    </Row>
                </Card>
            </Box>
        </>
    );
};