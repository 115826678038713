import React from 'react';
import { Container, Typography, Box, Grid } from '@material-ui/core';
import HubspotForm from "react-hubspot-form";
import { footerStyles } from '../../utils/hooks/useApplyStyles'

export default function NewsLetter() {

    const styles = footerStyles();
    const classes = styles();

    return (
        <>
            <Box component="div" textAlign="center" className={classes.newsletters}>
                <Container maxWidth="lg">
                    <Grid container alignItems="center" className={classes.newsGridWrap}>
                        <Grid item md={8}>
                            <Box component="div">
                                <Typography variant="h5" component="p" gutterBottom className={classes.newsLetterTagline}>
                                Looking for the best events from around the world?
                                </Typography>
                                <Typography variant="p" component="p" gutterBottom className={classes.newsLetterSubTagline}>
                                Subscribe to the vFairs Discover Newsletter for monthly updates about events you'll actually want to participate in
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <HubspotForm
                                region="na1"
                                portalId="2978594"
                                formId="f3473776-f559-404f-9d03-8a3266729921"
                                loading={<div></div>}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};