import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, Container, Typography, Box } from '@material-ui/core';
import { cardsStyles, lookingUpStyles } from "../../../utils/hooks/useApplyStyles";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import 'react-multi-carousel/lib/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CollectionCardContent from '../../Collection/CollectionCardContent';
import Slider from "react-slick";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import LoaderCard from "../../Loader/LoaderCard";
import { Link } from "react-router-dom";


var settings = {
    dots: false,
    arrows: true,
    // infinite: false,
    draggable: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    // initialSlide: 2,
    nextArrow: <ExpandMoreIcon />,
    prevArrow: <ExpandLessIcon />,
    useTransform: true,
    vertical: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                swipeToSlide: true,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                swipeToSlide: true,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                swipeToSlide: true,

            }
        }
    ]
};

export default function CollectionsCards() {
    const collectionsBaseURL = `${global.API.rootPath}${global.config.endpoints.collections_endpoint}?access_key=${global.config.access_key}&is_eventeer_award=0&limit=50`;
    const [collections, setCollections] = useState({
        data: null,
        isLoading: false,
        error: "",
    });
    //fetch collections
    useEffect(() => {
        setCollections({ ...collections, isLoading: true });
        axios.get(collectionsBaseURL).then((response) => {
            setCollections({ ...collections, data: response.data, isLoading: false });
        }).catch((err) => setCollections({ ...collections, error: err, isLoading: false }));
    }, []);
    const styles1 = cardsStyles();
    const styles2 = lookingUpStyles();
    const classes = { ...styles1(), ...styles2() }

    return (
        <>
            <Box component="div" className={`${classes.homeSection} ${classes.collectionSectionGap}`}>
                <Container maxWidth="lg">
                    <Grid container alignItems="center">
                        <Grid item lg={4}>
                            <Box component="div">
                                <Typography variant="h2" gutterBottom className={classes.viewmoreTitle}>
                                    Event Collections
                                </Typography>
                                <Typography variant="body1">Our event collections are a group of specially curated listings suited to popular interests. Find top virtual, hybrid, and in-person conferences, trade shows, career fairs, education fairs, and more to learn, connect and grow with the best professional teams.</Typography>
                                <Link id="view-more-hybrid-events" to="/collections">All Collections <ArrowForwardIcon /> </Link>
                            </Box>
                           
                        </Grid>
                        <Grid item lg={8}>
                            <Grid className={classes.homeCardWrap}>
                                <>
                                    <Slider {...settings} className={`vericalSliderControl ${classes.collectionCarousel}`}>
                                        {collections.data &&
                                            collections.data.total_count &&
                                            collections.data.total_count > 0 &&
                                            collections.data.data &&
                                            collections.data.data.map(collection =>
                                                collection.status &&
                                                collection.status === "1" &&
                                                collection.is_private &&
                                                collection.is_private === "0" &&
                                                collection.event_id &&
                                                <CollectionCardContent collection={collection} />
                                            )
                                        }
                                    </Slider>
                                </>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};