import { Box, Button, Divider, FormControl, Hidden, Input, InputLabel, Typography,LinearProgress,Modal,Fade } from '@material-ui/core'
import React, {useRef,useState,useEffect} from 'react'
import Cookies from 'universal-cookie';
import axios from "axios";
import Backdrop from '@material-ui/core/Backdrop';
import ProfileImg from '../../assets/images/profile1.svg'
import { dashboardStyles } from '../../utils/hooks/useApplyStyles';
import PublishIcon from '@material-ui/icons/Publish';
import { FieldFeedback, FieldFeedbacks, FormWithConstraints} from 'react-form-with-constraints-material-ui';
import Banner from '../../assets/images/profile-banner.png'
import CompleteImg from '../../assets/images/success.png'

const Profile = () => {
    const styles = dashboardStyles();
    const classes = styles();

    const cookies = new Cookies();

    let token = cookies.get('onBoardingToken');

    const getProfileAPI = `${global.API.rootPath}${global.config.endpoints.onboarding_get_profile}`;

    const [profileData, setProfileData] = useState({
        data: null,
        isLoading: false,
        error: "",
    });

    const [profileProps, setProfileProps] = useState({
        //profilePic:  "",        
        fullName: "",
        email: "",
        bio: "",
        jobTitle: "",
        company: "",
    });

    useEffect(() => {
        setProfileData({ ...profileData, isLoading: true });
        axios.get(getProfileAPI + `?token=${token}`).then((profile) => {
            setProfileData({...profileData, data: profile.data});
        }).catch((err) => setProfileData({ ...profileData, error: err, isLoading: false }));;
    }, []);

    useEffect(() => {
        setProfileProps({ ...profileProps,
            fullName: profileData && profileData.data && profileData.data.status && profileData.data.data && profileData.data.data.full_name,
            email: profileData && profileData.data && profileData.data.status && profileData.data.data && profileData.data.data.email,
            bio: profileData && profileData.data && profileData.data.status && profileData.data.data && profileData.data.data.bio,
            jobTitle: profileData && profileData.data && profileData.data.status && profileData.data.data && profileData.data.data.job_title,
            company: profileData && profileData.data && profileData.data.status && profileData.data.data && profileData.data.data.company_name,
        });
    }, [profileData]);



    const updateProfile = `${global.API.rootPath}${global.config.endpoints.onboarding_update_profile}`;

    const [profilePicResponse, setProfilePicResponse] = useState({
        status: "",
    });
    const [profilePicBlob, setProfilePicBlob] = useState("");

    async function handleProfilePicSubmit(e) {
        e.preventDefault();

        setProfilePicResponse({ ...profilePicResponse, status: "", });
        let file = e.target.files[0];

        if(file){
            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = (e) => {
                setProfilePicBlob(e.target.result)
            }
        }
    }

    useEffect(() => {
        if(profilePicBlob) {
            //HEADERS
            var usersHeaders = new Headers();
            //usersHeaders.append("Authorization", `Bearer ${bearerToken}`);
            usersHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            //POST DATA
            var urlencoded = new URLSearchParams();
            urlencoded.append("token", token);
            urlencoded.append("profile_image", profilePicBlob);

            var requestOptions = {
                method: 'POST',
                headers: usersHeaders,
                body: urlencoded,
                redirect: 'follow',
            };

            fetch(updateProfile, requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                setProfilePicResponse({ ...profilePicResponse, status: data.status, });
            })
            .catch(error => {
                setProfilePicResponse({ ...profilePicResponse, status: false,  });
                console.log('error', error)
            });
        }
    }, [profilePicBlob]);


    const profileForm = useRef(null);

    const [profileResponse, setProfileResponse] = useState({
        status: "",
        msg: "",
        error: "",
        isLoading: false,
    });

    //validates fields
    // async function handleProfileUpdate({ target }) {
    //     await profileForm.current.validateFields(target);
    // }

    async function handleProfileSubmit(e) {
        e.preventDefault();

        setProfileResponse({ ...profileResponse, status: "", msg: "", error: "", isLoading: true });

        // Validates the non-dirty fields and returns Promise<Field[]>
        //await profileForm.current.validateForm();
        
        //if (profileForm.current.isValid()) {
            
            //var bearerToken = `${global.config.onboarding_token}`;
            
            //HEADERS
            var usersHeaders = new Headers();
            //usersHeaders.append("Authorization", `Bearer ${bearerToken}`);
            usersHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            //POST DATA
            var urlencoded = new URLSearchParams();
            urlencoded.append("token", token);
            urlencoded.append("full_name", profileProps && profileProps.fullName ? profileProps.fullName : "");
            urlencoded.append("bio", profileProps && profileProps.bio ? profileProps.bio : "");
            urlencoded.append("job_title", profileProps && profileProps.jobTitle ? profileProps.jobTitle : "");
            urlencoded.append("company_name", profileProps && profileProps.company ? profileProps.company : "");

            var requestOptions = {
                method: 'POST',
                headers: usersHeaders,
                body: urlencoded,
                redirect: 'follow',
            };

            fetch(updateProfile, requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                setProfileResponse({ ...profileResponse, status: data.status, msg: data.msg, isLoading: false });
            })
            .catch(error => {
                setProfileResponse({ ...profileResponse, status: false, error: true, isLoading: false });
                console.log('error', error)
            });
        //}
        //else {
            //console.log('The form is invalid');
        //}
            
    }
    
    const [passwordResponse, setPasswordResponse] = useState({
        status: "",
        msg: "",
        error: "",
        isLoading: false,
    });


    const passwordForm = useRef(null);

    //validates fields
    async function handlePasswordChange({ target }) {
        await passwordForm.current.validateFields(target);
    }

    async function handlePasswordSubmit(e) {
        e.preventDefault();

        setPasswordResponse({ ...passwordResponse, status: "", msg: "", error: "", isLoading: true });

        // Validates the non-dirty fields and returns Promise<Field[]>
        await passwordForm.current.validateForm();
        
        if (passwordForm.current.isValid()) {            

            //var bearerToken = `${global.config.onboarding_token}`;
            
            //HEADERS
            var usersHeaders = new Headers();
            //usersHeaders.append("Authorization", `Bearer ${bearerToken}`);
            usersHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            //POST DATA
            var urlencoded = new URLSearchParams();
            urlencoded.append("token", token);
            urlencoded.append("old_password", e.target.elements.old_password.value);
            urlencoded.append("new_password", e.target.elements.new_password.value);

            var requestOptions = {
                method: 'POST',
                headers: usersHeaders,
                body: urlencoded,
                redirect: 'follow',
            };

            fetch(updateProfile, requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                setPasswordResponse({ ...passwordResponse, status: data.status, msg: data.msg, isLoading: false });
            })
            .catch(error => {
                setPasswordResponse({ ...passwordResponse, status: false, error: true, isLoading: false });
                console.log('error', error)
            });
        }
        else {
            console.log('The form is invalid');
        }
            
    }

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if(
            (passwordResponse && passwordResponse.status && passwordResponse.msg === "Password updated successfully.") ||
            (profileResponse && profileResponse.status && profileResponse.msg === "Profile Updated Successfully!")
        ) {
            setOpen(true);
        }
      }, [passwordResponse,profileResponse]);

    const closeSharePop = () => {
        setPasswordResponse({ ...passwordResponse, status: "", msg: "", error: "" });
        setProfileResponse({ ...profileResponse, status: "", msg: "", error: "" });
        setOpen(false);
    };

    return (
        <main className={classes.content}>
            <Box className={classes.dashboardTitle}>
                <Typography variant='h1'>Profile</Typography>
                {/* <Button variant="outlined" color="primary">Edit Profile</Button> */}
            </Box>
            <div style={{ minHeight: 5, }}>
                {profileData && profileData.isLoading &&
                    <LinearProgress />
                }
            </div>
            <Box className={classes.profileBanner}>
                <img src={Banner} alt="Banner" />
            </Box>
            
            <Box className={classes.profileMain}>                
                <Box className={classes.profileInnerMain}>                    
                    <Box className={classes.profileUploadMai}>
                        <FormWithConstraints ref={profileForm} onSubmit={handleProfilePicSubmit} noValidate autoComplete="off">
                            <Box className={classes.profileImg}>
                                <img
                                    src={profilePicBlob ? profilePicBlob
                                    : profileData && profileData.data && profileData.data.status && profileData.data.data && profileData.data.data.user_image ? profileData.data.data.user_image
                                    : ProfileImg} alt="profile pic" />
                            </Box>
                            <Box className={classes.profileUpload}>
                                <input
                                    accept="image/*"
                                    className={classes.input}
                                    id="profile_image"
                                    //multiple
                                    type="file"
                                    onChange={handleProfilePicSubmit}
                                />
                                <label htmlFor="profile_image">
                                    <Button variant="contained" color="primary" component="span" type='submit' onChange={handleProfilePicSubmit}>
                                        <Hidden smUp>
                                            <PublishIcon />
                                        </Hidden>
                                        <Hidden smDown>
                                            upload new picture
                                        </Hidden>
                                    </Button>
                                </label>
                            </Box>
                            {/* <Button variant="contained" className={classes.profileDelete} color="primary" onChange={handleProfilePicDelete}>
                                Delete
                            </Button> */}
                        </FormWithConstraints>
                    </Box>

                    <Box className={classes.profileUpdate}>
                        <Typography variant='h2' className={classes.profileTitle}>Profile Details</Typography>
                        <FormWithConstraints ref={profileForm} onSubmit={handleProfileSubmit} noValidate autoComplete="off">
                            <FormControl className={classes.eventInput}>
                                <InputLabel shrink>Full Name</InputLabel>
                                <Input
                                    id="full_name"
                                    name="full_name"
                                    value={profileProps && profileProps.fullName ? profileProps.fullName : ""}
                                    type="text"
                                    disableUnderline
                                    //placeholder='John Doe'
                                    className={classes.textField}
                                    InputLabelProps={{shrink: true,}}
                                    onChange={(e) => {
                                        setProfileProps({ ...profileProps, fullName: e.target.value })
                                    }}
                                />
                            </FormControl>
                            <FormControl className={classes.eventInput}>
                                <InputLabel shrink>Email Address</InputLabel>
                                <Input
                                    id="email"
                                    name="email"
                                    value={profileProps && profileProps.email ? profileProps.email : ""}
                                    type="text"
                                    disableUnderline
                                    disabled
                                    variant="filled"
                                    className={classes.textField}
                                    InputLabelProps={{shrink: true,}}
                                    onChange={(e) => {
                                        setProfileProps({ ...profileProps, email: e.target.value })
                                    }}
                                />
                            </FormControl>
                            <FormControl className={classes.eventInput}>
                                <InputLabel shrink>Your Bio</InputLabel>
                                <Input
                                    id="bio"
                                    name="bio"
                                    value={profileProps && profileProps.bio ? profileProps.bio : ""}
                                    type="text"
                                    disableUnderline
                                    placeholder='Your Bio'
                                    className={classes.textField}
                                    InputLabelProps={{shrink: true,}}
                                    onChange={(e) => {
                                        setProfileProps({ ...profileProps, bio: e.target.value })
                                    }}
                                />
                            </FormControl>
                            <FormControl className={classes.eventInput}>
                                <InputLabel shrink>Job Title</InputLabel>
                                <Input
                                    id="job_title"
                                    name="job_title"
                                    value={profileProps && profileProps.jobTitle ? profileProps.jobTitle : ""}
                                    type="text"
                                    disableUnderline
                                    placeholder='Your Job Title'
                                    className={classes.textField}
                                    InputLabelProps={{shrink: true,}}
                                    onChange={(e) => {
                                        setProfileProps({ ...profileProps, jobTitle: e.target.value })
                                    }}
                                />
                            </FormControl>
                            <FormControl className={classes.eventInput}>
                                <InputLabel shrink>Company Name</InputLabel>
                                <Input
                                    id="company_name"
                                    name="company_name"
                                    value={profileProps && profileProps.company ? profileProps.company : ""}
                                    type="text"
                                    disableUnderline
                                    placeholder='Your Company Name'
                                    className={classes.textField}
                                    InputLabelProps={{shrink: true,}}
                                    onChange={(e) => {
                                        setProfileProps({ ...profileProps, company: e.target.value })
                                    }}
                                />
                            </FormControl>
                            {profileResponse && !profileResponse.status && profileResponse.msg && 
                                <span class="error">{profileResponse.msg}</span>
                            }
                            <div style={{ minHeight: 5, }}>
                                {profileResponse && profileResponse.isLoading &&
                                    <LinearProgress />
                                }
                            </div>
                            <Box className={classes.profilSubmitBtn}>
                                <Button variant="contained" type='submit' color="primary">
                                    Update Profile
                                </Button>
                            </Box>
                        </FormWithConstraints>
                        <Divider className={classes.dividerGap} />
                        <Typography variant='h2' className={classes.profileTitle}>Change Password</Typography>
                        <FormWithConstraints ref={passwordForm} onSubmit={handlePasswordSubmit} noValidate autoComplete="off">
                            <FormControl className={classes.eventInput}>
                                <InputLabel shrink>Old Password</InputLabel>
                                <Input
                                    id="old_password"
                                    name="old_password"
                                    type="password"
                                    disableUnderline
                                    placeholder='Enter Old Password'
                                    className={classes.textField}
                                    InputLabelProps={{shrink: true,}}
                                    onChange={handlePasswordChange}
                                />
                                <FieldFeedbacks for="old_password">
                                    <FieldFeedback when={value => value.length === 0}>Please complete this required field.</FieldFeedback>
                                </FieldFeedbacks>
                            </FormControl>
                            <FormControl className={classes.eventInput}>
                                <InputLabel shrink>New Password</InputLabel>
                                <Input
                                    id="new_password"
                                    name="new_password"
                                    type="password"
                                    disableUnderline
                                    placeholder='Enter New Password'
                                    className={classes.textField}
                                    InputLabelProps={{shrink: true,}}
                                    onChange={handlePasswordChange}
                                />
                                <FieldFeedbacks for="new_password">
                                    <FieldFeedback when={value => value.length === 0}>Please complete this required field.</FieldFeedback>
                                </FieldFeedbacks>
                            </FormControl>
                            {passwordResponse && !passwordResponse.status && passwordResponse.msg && 
                                <span class="error">{passwordResponse.msg}</span>
                            }
                            <div style={{ minHeight: 5, }}>
                                {passwordResponse && passwordResponse.isLoading &&
                                    <LinearProgress />
                                }
                            </div>
                            <Box className={classes.profilSubmitBtn}>
                                <Button variant="contained" type='submit' color="primary">
                                    change
                                </Button>
                            </Box>
                        </FormWithConstraints>
                    </Box>
                </Box>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={closeSharePop}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Box component="div" className={classes.modalBox}>
                            <Box className={classes.modalBoxContent}>
                                <img src={CompleteImg} alt="modal" />
                                <Typography variant='h2'>Done!</Typography>
                                {passwordResponse && passwordResponse.status && passwordResponse.msg === "Password updated successfully."
                                ? <Typography variant='body1'>Password updated successfully.</Typography>
                                : profileResponse && profileResponse.status && profileResponse.msg === "Profile Updated Successfully!"
                                ? <Typography variant='body1'>Profile updated successfully.</Typography>
                                : ""
                                }
                                <Button color='primary' onClick={closeSharePop}>Close</Button>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </Box>
        </main>

    )
}

export default Profile