import React, {useState } from "react";
import { Container, Typography, Box, Button, IconButton } from '@material-ui/core';
import { footerStyles } from '../../utils/hooks/useApplyStyles';
import LazyLoad from 'react-lazyload';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';

export default function FeatureCTA() {

    const styles = footerStyles();
    const classes = styles();
    const [open, setOpen] = useState(false);
    const openFeatureCTAPop = () => {
        setOpen(true);
    };
    const closeFeatureCTAPop = () => {
        setOpen(false);
    };

    return (
        <>
            <Box id="newsletter-subscription" component="div" textAlign="center" className={classes.newslettersMain}>
                <Container maxWidth="lg">
                    <Box className={classes.newslettersInner}>
                        <Box className={classes.newsletterContent}>
                            <Typography variant='h2'>
                                Want to Feature Your Event?
                            </Typography>
                            <Typography variant="body1">
                                Showcase your events with vFairs Discover and attract more attendees.
                            </Typography>
                            <Button variant="contained" onClick={openFeatureCTAPop} >get started</Button>
                        </Box>
                    </Box>
                </Container>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.FeatureCTAModalMain}
                    open={open}
                    onClose={closeFeatureCTAPop}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>                                
                        <Box component="div" className={classes.FeatureCTAModal}>
                                <IconButton aria-label="delete" className={classes.margin} onClick={closeFeatureCTAPop} size="small">
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeajVoIeIYIzK8OZ8h4lgfi94m-fIYdt3GZ2D0iH1EI0-e7EA/viewform?embedded=true" width="640" height="570" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                        </Box>
                    </Fade>
                </Modal>
            </Box>
            
        </>
    );
};