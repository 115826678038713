import { FormControl, Grid, Input, InputLabel, MenuItem, Select, Box, FormControlLabel, Radio, RadioGroup, TextField  } from '@material-ui/core'
import React from 'react'
import { dashboardStyles } from '../../utils/hooks/useApplyStyles';
import moment from 'moment'
import FilterTags from '../NewEvent/FilterTags'
import Autocomplete from "@material-ui/lab/Autocomplete"

//import Search from '@material-ui/icons/Search';
import vEvent from '../../assets/images/virtual event.svg'
import hybrid from '../../assets/images/Hybrid Event.svg'
import physicalEvent from '../../assets/images/in person event.svg'


const timeZonesList = [
    { id: 1, title: "(GMT-10:00) America/Adak (Hawaii-Aleutian Standard Time)" },
    { id: 2, title: "(GMT-10:00) America/Atka (Hawaii-Aleutian Standard Time)" },
    { id: 3, title: "(GMT-9:00) America/Anchorage (Alaska Standard Time)" },
    { id: 4, title: "(GMT-9:00) America/Juneau (Alaska Standard Time)" },
    { id: 5, title: "(GMT-9:00) America/Nome (Alaska Standard Time)" },
    { id: 6, title: "(GMT-9:00) America/Yakutat (Alaska Standard Time)" },
    { id: 7, title: "(GMT-8:00) America/Dawson (Pacific Standard Time)" },
    { id: 8, title: "(GMT-8:00) America/Ensenada (Pacific Standard Time)" },
    { id: 9, title: "(GMT-8:00) America/Los_Angeles (Pacific Standard Time)" },
    { id: 10, title: "(GMT-8:00) America/Tijuana (Pacific Standard Time)" },
    { id: 11, title: "(GMT-8:00) America/Vancouver (Pacific Standard Time)" },
    { id: 12, title: "(GMT-8:00) America/Whitehorse (Pacific Standard Time)" },
    { id: 13, title: "(GMT-8:00) Canada/Pacific (Pacific Standard Time)" },
    { id: 14, title: "(GMT-8:00) Canada/Yukon (Pacific Standard Time)" },
    { id: 15, title: "(GMT-8:00) Mexico/BajaNorte (Pacific Standard Time)" },
    { id: 16, title: "(GMT-7:00) America/Boise (Mountain Standard Time)" },
    { id: 17, title: "(GMT-7:00) America/Cambridge_Bay (Mountain Standard Time)" },
    { id: 18, title: "(GMT-7:00) America/Chihuahua (Mountain Standard Time)" },
    { id: 19, title: "(GMT-7:00) America/Dawson_Creek (Mountain Standard Time)" },
    { id: 20, title: "(GMT-7:00) America/Denver (Mountain Standard Time)" },
    { id: 21, title: "(GMT-7:00) America/Edmonton (Mountain Standard Time)" },
    { id: 22, title: "(GMT-7:00) America/Hermosillo (Mountain Standard Time)" },
    { id: 23, title: "(GMT-7:00) America/Inuvik (Mountain Standard Time)" },
    { id: 24, title: "(GMT-7:00) America/Mazatlan (Mountain Standard Time)" },
    { id: 25, title: "(GMT-7:00) America/Phoenix (Mountain Standard Time)" },
    { id: 26, title: "(GMT-7:00) America/Shiprock (Mountain Standard Time)" },
    { id: 27, title: "(GMT-7:00) America/Yellowknife (Mountain Standard Time)" },
    { id: 28, title: "(GMT-7:00) Canada/Mountain (Mountain Standard Time)" },
    { id: 29, title: "(GMT-7:00) Mexico/BajaSur (Mountain Standard Time)" },
    { id: 30, title: "(GMT-6:00) America/Belize (Central Standard Time)" },
    { id: 31, title: "(GMT-6:00) America/Cancun (Central Standard Time)" },
    { id: 32, title: "(GMT-6:00) America/Chicago (Central Standard Time)" },
    { id: 33, title: "(GMT-6:00) America/Costa_Rica (Central Standard Time)" },
    { id: 34, title: "(GMT-6:00) America/El_Salvador (Central Standard Time)" },
    { id: 35, title: "(GMT-6:00) America/Guatemala (Central Standard Time)" },
    { id: 36, title: "(GMT-6:00) America/Knox_IN (Central Standard Time)" },
    { id: 37, title: "(GMT-6:00) America/Managua (Central Standard Time)" },
    { id: 38, title: "(GMT-6:00) America/Menominee (Central Standard Time)" },
    { id: 39, title: "(GMT-6:00) America/Merida (Central Standard Time)" },
    { id: 40, title: "(GMT-6:00) America/Mexico_City (Central Standard Time)" },
    { id: 41, title: "(GMT-6:00) America/Monterrey (Central Standard Time)" },
    { id: 42, title: "(GMT-6:00) America/Rainy_River (Central Standard Time)" },
    { id: 43, title: "(GMT-6:00) America/Rankin_Inlet (Central Standard Time)" },
    { id: 44, title: "(GMT-6:00) America/Regina (Central Standard Time)" },
    { id: 45, title: "(GMT-6:00) America/Swift_Current (Central Standard Time)" },
    { id: 46, title: "(GMT-6:00) America/Tegucigalpa (Central Standard Time)" },
    { id: 47, title: "(GMT-6:00) America/Winnipeg (Central Standard Time)" },
    { id: 48, title: "(GMT-6:00) Canada/Central (Central Standard Time)" },
    { id: 50, title: "(GMT-6:00) Canada/Saskatchewan (Central Standard Time)" },
    { id: 51, title: "(GMT-6:00) Chile/EasterIsland (Easter Is. Time)" },
    { id: 52, title: "(GMT-6:00) Mexico/General (Central Standard Time)" },
    { id: 53, title: "(GMT-5:00) America/Atikokan (Eastern Standard Time)" },
    { id: 54, title: "(GMT-5:00) America/Bogota (Colombia Time)" },
    { id: 55, title: "(GMT-5:00) America/Cayman (Eastern Standard Time)" },
    { id: 56, title: "(GMT-5:00) America/Coral_Harbour (Eastern Standard Time)" },
    { id: 57, title: "(GMT-5:00) America/Detroit (Eastern Standard Time)" },
    { id: 58, title: "(GMT-5:00) America/Fort_Wayne (Eastern Standard Time)" },
    { id: 59, title: "(GMT-5:00) America/Grand_Turk (Eastern Standard Time)" },
    { id: 60, title: "(GMT-5:00) America/Guayaquil (Ecuador Time)" },
    { id: 61, title: "(GMT-5:00) America/Havana (Cuba Standard Time)" },
    { id: 62, title: "(GMT-5:00) America/Indianapolis (Eastern Standard Time)" },
    { id: 63, title: "(GMT-5:00) America/Iqaluit (Eastern Standard Time)" },
    { id: 64, title: "(GMT-5:00) America/Jamaica (Eastern Standard Time)" },
    { id: 65, title: "(GMT-5:00) America/Lima (Peru Time)" },
    { id: 66, title: "(GMT-5:00) America/Louisville (Eastern Standard Time)" },
    { id: 67, title: "(GMT-5:00) America/Montreal (Eastern Standard Time)" },
    { id: 68, title: "(GMT-5:00) America/Nassau (Eastern Standard Time)" },
    { id: 69, title: "(GMT-5:00) America/New_York (Eastern Standard Time)" },
    { id: 70, title: "(GMT-5:00) America/Nipigon (Eastern Standard Time)" },
    { id: 71, title: "(GMT-5:00) America/Panama (Eastern Standard Time)" },
    { id: 72, title: "(GMT-5:00) America/Pangnirtung (Eastern Standard Time)" },
    { id: 73, title: "(GMT-5:00) America/Port-au-Prince (Eastern Standard Time)" },
    { id: 74, title: "(GMT-5:00) America/Resolute (Eastern Standard Time)" },
    { id: 75, title: "(GMT-5:00) America/Thunder_Bay (Eastern Standard Time)" },
    { id: 76, title: "(GMT-5:00) America/Toronto (Eastern Standard Time)" },
    { id: 77, title: "(GMT-5:00) Canada/Eastern (Eastern Standard Time)" },
    { id: 78, title: "(GMT-4:-30) America/Caracas (Venezuela Time)" },
    { id: 79, title: "(GMT-4:00) America/Anguilla (Atlantic Standard Time)" },
    { id: 80, title: "(GMT-4:00) America/Antigua (Atlantic Standard Time)" },
    { id: 81, title: "(GMT-4:00) America/Aruba (Atlantic Standard Time)" },
    { id: 82, title: "(GMT-4:00) America/Asuncion (Paraguay Time)" },
    { id: 83, title: "(GMT-4:00) America/Barbados (Atlantic Standard Time)" },
    { id: 84, title: "(GMT-4:00) America/Blanc-Sablon (Atlantic Standard Time)" },
    { id: 85, title: "(GMT-4:00) America/Boa_Vista (Amazon Time)" },
    { id: 86, title: "(GMT-4:00) America/Campo_Grande (Amazon Time)" },
    { id: 87, title: "(GMT-4:00) America/Cuiaba (Amazon Time)" },
    { id: 88, title: "(GMT-4:00) America/Curacao (Atlantic Standard Time)" },
    { id: 89, title: "(GMT-4:00) America/Dominica (Atlantic Standard Time)" },
    { id: 90, title: "(GMT-4:00) America/Eirunepe (Amazon Time)" },
    { id: 91, title: "(GMT-4:00) America/Glace_Bay (Atlantic Standard Time)" },
    { id: 92, title: "(GMT-4:00) America/Goose_Bay (Atlantic Standard Time)" },
    { id: 93, title: "(GMT-4:00) America/Grenada (Atlantic Standard Time)" },
    { id: 94, title: "(GMT-4:00) America/Guadeloupe (Atlantic Standard Time)" },
    { id: 95, title: "(GMT-4:00) America/Guyana (Guyana Time)" },
    { id: 96, title: "(GMT-4:00) America/Halifax (Atlantic Standard Time)" },
    { id: 97, title: "(GMT-4:00) America/La_Paz (Bolivia Time)" },
    { id: 98, title: "(GMT-4:00) America/Manaus (Amazon Time)" },
    { id: 99, title: "(GMT-4:00) America/Marigot (Atlantic Standard Time)" },
    { id: 100, title: "(GMT-4:00) America/Martinique (Atlantic Standard Time)" },
    { id: 101, title: "(GMT-4:00) America/Moncton (Atlantic Standard Time)" },
    { id: 102, title: "(GMT-4:00) America/Montserrat (Atlantic Standard Time)" },
    { id: 103, title: "(GMT-4:00) America/Port_of_Spain (Atlantic Standard Time)" },
    { id: 104, title: "(GMT-4:00) America/Porto_Acre (Amazon Time)" },
    { id: 105, title: "(GMT-4:00) America/Porto_Velho (Amazon Time)" },
    { id: 106, title: "(GMT-4:00) America/Puerto_Rico (Atlantic Standard Time)" },
    { id: 107, title: "(GMT-4:00) America/Rio_Branco (Amazon Time)" },
    { id: 108, title: "(GMT-4:00) America/Santiago (Chile Time)" },
    { id: 109, title: "(GMT-4:00) America/Santo_Domingo (Atlantic Standard Time)" },
    { id: 110, title: "(GMT-4:00) America/St_Barthelemy (Atlantic Standard Time)" },
    { id: 111, title: "(GMT-4:00) America/St_Kitts (Atlantic Standard Time)" },
    { id: 112, title: "(GMT-4:00) America/St_Lucia (Atlantic Standard Time)" },
    { id: 113, title: "(GMT-4:00) America/St_Thomas (Atlantic Standard Time)" },
    { id: 114, title: "(GMT-4:00) America/St_Vincent (Atlantic Standard Time)" },
    { id: 115, title: "(GMT-4:00) America/Thule (Atlantic Standard Time)" },
    { id: 116, title: "(GMT-4:00) America/Tortola (Atlantic Standard Time)" },
    { id: 117, title: "(GMT-4:00) America/Virgin (Atlantic Standard Time)" },
    { id: 118, title: "(GMT-4:00) Antarctica/Palmer (Chile Time)" },
    { id: 119, title: "(GMT-4:00) Atlantic/Bermuda (Atlantic Standard Time)" },
    { id: 120, title: "(GMT-4:00) Atlantic/Stanley (Falkland Is. Time)" },
    { id: 121, title: "(GMT-4:00) Brazil/Acre (Amazon Time)" },
    { id: 122, title: "(GMT-4:00) Brazil/West (Amazon Time)" },
    { id: 123, title: "(GMT-4:00) Canada/Atlantic (Atlantic Standard Time)" },
    { id: 124, title: "(GMT-4:00) Chile/Continental (Chile Time)" },
    { id: 125, title: "(GMT-3:30) America/St_Johns (Newfoundland Standard Time)" },
    { id: 126, title: "(GMT-3:30) Canada/Newfoundland (Newfoundland Standard Time)" },
    { id: 127, title: "(GMT-3:00) America/Araguaina (Brasilia Time)" },
    { id: 128, title: "(GMT-3:00) America/Bahia (Brasilia Time)" },
    { id: 129, title: "(GMT-3:00) America/Belem (Brasilia Time)" },
    { id: 130, title: "(GMT-3:00) America/Buenos_Aires (Argentine Time)" },
    { id: 131, title: "(GMT-3:00) America/Catamarca (Argentine Time)" },
    { id: 132, title: "(GMT-3:00) America/Cayenne (French Guiana Time)" },
    { id: 133, title: "(GMT-3:00) America/Cordoba (Argentine Time)" },
    { id: 134, title: "(GMT-3:00) America/Fortaleza (Brasilia Time)" },
    { id: 135, title: "(GMT-3:00) America/Godthab (Western Greenland Time)" },
    { id: 136, title: "(GMT-3:00) America/Jujuy (Argentine Time)" },
    { id: 137, title: "(GMT-3:00) America/Maceio (Brasilia Time)" },
    { id: 138, title: "(GMT-3:00) America/Mendoza (Argentine Time)" },
    { id: 139, title: "(GMT-3:00) America/Miquelon (Pierre &amp; Miquelon Standard Time)" },
    { id: 140, title: "(GMT-3:00) America/Montevideo (Uruguay Time)" },
    { id: 141, title: "(GMT-3:00) America/Paramaribo (Suriname Time)" },
    { id: 142, title: "(GMT-3:00) America/Recife (Brasilia Time)" },
    { id: 143, title: "(GMT-3:00) America/Rosario (Argentine Time)" },
    { id: 144, title: "(GMT-3:00) America/Santarem (Brasilia Time)" },
    { id: 145, title: "(GMT-3:00) America/Sao_Paulo (Brasilia Time)" },
    { id: 146, title: "(GMT-3:00) Antarctica/Rothera (Rothera Time)" },
    { id: 147, title: "(GMT-3:00) Brazil/East (Brasilia Time)" },
    { id: 148, title: "(GMT-2:00) America/Noronha (Fernando de Noronha Time)" },
    { id: 149, title: "(GMT-2:00) Atlantic/South_Georgia (South Georgia Standard Time)" },
    { id: 150, title: "(GMT-2:00) Brazil/DeNoronha (Fernando de Noronha Time)" },
    { id: 151, title: "(GMT-1:00) America/Scoresbysund (Eastern Greenland Time)" },
    { id: 152, title: "(GMT-1:00) Atlantic/Azores (Azores Time)" },
    { id: 153, title: "(GMT-1:00) Atlantic/Cape_Verde (Cape Verde Time)" },
    { id: 154, title: "(GMT+0:00) Africa/Abidjan (Greenwich Mean Time)" },
    { id: 155, title: "(GMT+0:00) Africa/Accra (Ghana Mean Time)" },
    { id: 156, title: "(GMT+0:00) Africa/Bamako (Greenwich Mean Time)" },
    { id: 157, title: "(GMT+0:00) Africa/Banjul (Greenwich Mean Time)" },
    { id: 158, title: "(GMT+0:00) Africa/Bissau (Greenwich Mean Time)" },
    { id: 159, title: "(GMT+0:00) Africa/Casablanca (Western European Time)" },
    { id: 160, title: "(GMT+0:00) Africa/Conakry (Greenwich Mean Time)" },
    { id: 161, title: "(GMT+0:00) Africa/Dakar (Greenwich Mean Time)" },
    { id: 162, title: "(GMT+0:00) Africa/El_Aaiun (Western European Time)" },
    { id: 163, title: "(GMT+0:00) Africa/Freetown (Greenwich Mean Time)" },
    { id: 164, title: "(GMT+0:00) Africa/Lome (Greenwich Mean Time)" },
    { id: 165, title: "(GMT+0:00) Africa/Monrovia (Greenwich Mean Time)" },
    { id: 166, title: "(GMT+0:00) Africa/Nouakchott (Greenwich Mean Time)" },
    { id: 167, title: "(GMT+0:00) Africa/Ouagadougou (Greenwich Mean Time)" },
    { id: 168, title: "(GMT+0:00) Africa/Sao_Tome (Greenwich Mean Time)" },
    { id: 169, title: "(GMT+0:00) Africa/Timbuktu (Greenwich Mean Time)" },
    { id: 170, title: "(GMT+0:00) America/Danmarkshavn (Greenwich Mean Time)" },
    { id: 171, title: "(GMT+0:00) Atlantic/Canary (Western European Time)" },
    { id: 172, title: "(GMT+0:00) Atlantic/Faeroe (Western European Time)" },
    { id: 173, title: "(GMT+0:00) Atlantic/Faroe (Western European Time)" },
    { id: 174, title: "(GMT+0:00) Atlantic/Madeira (Western European Time)" },
    { id: 175, title: "(GMT+0:00) Atlantic/Reykjavik (Greenwich Mean Time)" },
    { id: 176, title: "(GMT+0:00) Atlantic/St_Helena (Greenwich Mean Time)" },
    { id: 177, title: "(GMT+0:00) Europe/Belfast (Greenwich Mean Time)" },
    { id: 178, title: "(GMT+0:00) Europe/Dublin (Greenwich Mean Time)" },
    { id: 179, title: "(GMT+0:00) Europe/Guernsey (Greenwich Mean Time)" },
    { id: 180, title: "(GMT+0:00) Europe/Isle_of_Man (Greenwich Mean Time)" },
    { id: 181, title: "(GMT+0:00) Europe/Jersey (Greenwich Mean Time)" },
    { id: 182, title: "(GMT+0:00) Europe/Lisbon (Western European Time)" },
    { id: 183, title: "(GMT+0:00) Europe/London (Greenwich Mean Time)" },
    { id: 184, title: "(GMT+1:00) Africa/Algiers (Central European Time)" },
    { id: 185, title: "(GMT+1:00) Africa/Bangui (Western African Time)" },
    { id: 186, title: "(GMT+1:00) Africa/Brazzaville (Western African Time)" },
    { id: 187, title: "(GMT+1:00) Africa/Ceuta (Central European Time)" },
    { id: 188, title: "(GMT+1:00) Africa/Douala (Western African Time)" },
    { id: 189, title: "(GMT+1:00) Africa/Kinshasa (Western African Time)" },
    { id: 190, title: "(GMT+1:00) Africa/Lagos (Western African Time)" },
    { id: 191, title: "(GMT+1:00) Africa/Libreville (Western African Time)" },
    { id: 192, title: "(GMT+1:00) Africa/Luanda (Western African Time)" },
    { id: 193, title: "(GMT+1:00) Africa/Malabo (Western African Time)" },
    { id: 194, title: "(GMT+1:00) Africa/Ndjamena (Western African Time)" },
    { id: 195, title: "(GMT+1:00) Africa/Niamey (Western African Time)" },
    { id: 196, title: "(GMT+1:00) Africa/Porto-Novo (Western African Time)" },
    { id: 197, title: "(GMT+1:00) Africa/Tunis (Central European Time)" },
    { id: 198, title: "(GMT+1:00) Africa/Windhoek (Western African Time)" },
    { id: 199, title: "(GMT+1:00) Arctic/Longyearbyen (Central European Time)" },
    { id: 200, title: "(GMT+1:00) Atlantic/Jan_Mayen (Central European Time)" },
    { id: 201, title: "(GMT+1:00) Europe/Amsterdam (Central European Time)" },
    { id: 202, title: "(GMT+1:00) Europe/Andorra (Central European Time)" },
    { id: 203, title: "(GMT+1:00) Europe/Belgrade (Central European Time)" },
    { id: 204, title: "(GMT+1:00) Europe/Berlin (Central European Time)" },
    { id: 205, title: "(GMT+1:00) Europe/Bratislava (Central European Time)" },
    { id: 206, title: "(GMT+1:00) Europe/Brussels (Central European Time)" },
    { id: 207, title: "(GMT+1:00) Europe/Budapest (Central European Time)" },
    { id: 208, title: "(GMT+1:00) Europe/Copenhagen (Central European Time)" },
    { id: 209, title: "(GMT+1:00) Europe/Gibraltar (Central European Time)" },
    { id: 210, title: "(GMT+1:00) Europe/Ljubljana (Central European Time)" },
    { id: 211, title: "(GMT+1:00) Europe/Luxembourg (Central European Time)" },
    { id: 212, title: "(GMT+1:00) Europe/Madrid (Central European Time)" },
    { id: 213, title: "(GMT+1:00) Europe/Malta (Central European Time)" },
    { id: 214, title: "(GMT+1:00) Europe/Monaco (Central European Time)" },
    { id: 215, title: "(GMT+1:00) Europe/Oslo (Central European Time)" },
    { id: 216, title: "(GMT+1:00) Europe/Paris (Central European Time)" },
    { id: 217, title: "(GMT+1:00) Europe/Podgorica (Central European Time)" },
    { id: 218, title: "(GMT+1:00) Europe/Prague (Central European Time)" },
    { id: 219, title: "(GMT+1:00) Europe/Rome (Central European Time)" },
    { id: 220, title: "(GMT+1:00) Europe/San_Marino (Central European Time)" },
    { id: 221, title: "(GMT+1:00) Europe/Sarajevo (Central European Time)" },
    { id: 222, title: "(GMT+1:00) Europe/Skopje (Central European Time)" },
    { id: 223, title: "(GMT+1:00) Europe/Stockholm (Central European Time)" },
    { id: 224, title: "(GMT+1:00) Europe/Tirane (Central European Time)" },
    { id: 225, title: "(GMT+1:00) Europe/Vaduz (Central European Time)" },
    { id: 226, title: "(GMT+1:00) Europe/Vatican (Central European Time)" },
    { id: 227, title: "(GMT+1:00) Europe/Vienna (Central European Time)" },
    { id: 228, title: "(GMT+1:00) Europe/Warsaw (Central European Time)" },
    { id: 229, title: "(GMT+1:00) Europe/Zagreb (Central European Time)" },
    { id: 230, title: "(GMT+1:00) Europe/Zurich (Central European Time)" },
    { id: 231, title: "(GMT+2:00) Africa/Blantyre (Central African Time)" },
    { id: 232, title: "(GMT+2:00) Africa/Bujumbura (Central African Time)" },
    { id: 233, title: "(GMT+2:00) Africa/Cairo (Eastern European Time)" },
    { id: 234, title: "(GMT+2:00) Africa/Gaborone (Central African Time)" },
    { id: 235, title: "(GMT+2:00) Africa/Harare (Central African Time)" },
    { id: 236, title: "(GMT+2:00) Africa/Johannesburg (South Africa Standard Time)" },
    { id: 237, title: "(GMT+2:00) Africa/Kigali (Central African Time)" },
    { id: 238, title: "(GMT+2:00) Africa/Lubumbashi (Central African Time)" },
    { id: 239, title: "(GMT+2:00) Africa/Lusaka (Central African Time)" },
    { id: 240, title: "(GMT+2:00) Africa/Maputo (Central African Time)" },
    { id: 241, title: "(GMT+2:00) Africa/Maseru (South Africa Standard Time)" },
    { id: 242, title: "(GMT+2:00) Africa/Mbabane (South Africa Standard Time)" },
    { id: 243, title: "(GMT+2:00) Africa/Tripoli (Eastern European Time)" },
    { id: 244, title: "(GMT+2:00) Asia/Amman (Eastern European Time)" },
    { id: 245, title: "(GMT+2:00) Asia/Beirut (Eastern European Time)" },
    { id: 246, title: "(GMT+2:00) Asia/Damascus (Eastern European Time)" },
    { id: 247, title: "(GMT+2:00) Asia/Gaza (Eastern European Time)" },
    { id: 248, title: "(GMT+2:00) Asia/Istanbul (Eastern European Time)" },
    { id: 249, title: "(GMT+2:00) Asia/Jerusalem (Israel Standard Time)" },
    { id: 250, title: "(GMT+2:00) Asia/Nicosia (Eastern European Time)" },
    { id: 251, title: "(GMT+2:00) Asia/Tel_Aviv (Israel Standard Time)" },
    { id: 252, title: "(GMT+2:00) Europe/Athens (Eastern European Time)" },
    { id: 253, title: "(GMT+2:00) Europe/Bucharest (Eastern European Time)" },
    { id: 254, title: "(GMT+2:00) Europe/Chisinau (Eastern European Time)" },
    { id: 255, title: "(GMT+2:00) Europe/Helsinki (Eastern European Time)" },
    { id: 256, title: "(GMT+2:00) Europe/Istanbul (Eastern European Time)" },
    { id: 257, title: "(GMT+2:00) Europe/Kaliningrad (Eastern European Time)" },
    { id: 258, title: "(GMT+2:00) Europe/Kiev (Eastern European Time)" },
    { id: 259, title: "(GMT+2:00) Europe/Mariehamn (Eastern European Time)" },
    { id: 260, title: "(GMT+2:00) Europe/Minsk (Eastern European Time)" },
    { id: 261, title: "(GMT+2:00) Europe/Nicosia (Eastern European Time)" },
    { id: 262, title: "(GMT+2:00) Europe/Riga (Eastern European Time)" },
    { id: 263, title: "(GMT+2:00) Europe/Simferopol (Eastern European Time)" },
    { id: 264, title: "(GMT+2:00) Europe/Sofia (Eastern European Time)" },
    { id: 265, title: "(GMT+2:00) Europe/Tallinn (Eastern European Time)" },
    { id: 266, title: "(GMT+2:00) Europe/Tiraspol (Eastern European Time)" },
    { id: 267, title: "(GMT+2:00) Europe/Uzhgorod (Eastern European Time)" },
    { id: 268, title: "(GMT+2:00) Europe/Vilnius (Eastern European Time)" },
    { id: 269, title: "(GMT+2:00) Europe/Zaporozhye (Eastern European Time)" },
    { id: 270, title: "(GMT+3:00) Africa/Addis_Ababa (Eastern African Time)" },
    { id: 271, title: "(GMT+3:00) Africa/Asmara (Eastern African Time)" },
    { id: 272, title: "(GMT+3:00) Africa/Asmera (Eastern African Time)" },
    { id: 273, title: "(GMT+3:00) Africa/Dar_es_Salaam (Eastern African Time)" },
    { id: 274, title: "(GMT+3:00) Africa/Djibouti (Eastern African Time)" },
    { id: 275, title: "(GMT+3:00) Africa/Kampala (Eastern African Time)" },
    { id: 276, title: "(GMT+3:00) Africa/Khartoum (Eastern African Time)" },
    { id: 277, title: "(GMT+3:00) Africa/Mogadishu (Eastern African Time)" },
    { id: 278, title: "(GMT+3:00) Africa/Nairobi (Eastern African Time)" },
    { id: 279, title: "(GMT+3:00) Antarctica/Syowa (Syowa Time)" },
    { id: 280, title: "(GMT+3:00) Asia/Aden (Arabia Standard Time)" },
    { id: 281, title: "(GMT+3:00) Asia/Baghdad (Arabia Standard Time)" },
    { id: 282, title: "(GMT+3:00) Asia/Bahrain (Arabia Standard Time)" },
    { id: 283, title: "(GMT+3:00) Asia/Kuwait (Arabia Standard Time)" },
    { id: 284, title: "(GMT+3:00) Asia/Qatar (Arabia Standard Time)" },
    { id: 285, title: "(GMT+3:00) Europe/Moscow (Moscow Standard Time)" },
    { id: 286, title: "(GMT+3:00) Europe/Volgograd (Volgograd Time)" },
    { id: 287, title: "(GMT+3:00) Indian/Antananarivo (Eastern African Time)" },
    { id: 288, title: "(GMT+3:00) Indian/Comoro (Eastern African Time)" },
    { id: 289, title: "(GMT+3:00) Indian/Mayotte (Eastern African Time)" },
    { id: 290, title: "(GMT+3:30) Asia/Tehran (Iran Standard Time)" },
    { id: 291, title: "(GMT+4:00) Asia/Baku (Azerbaijan Time)" },
    { id: 292, title: "(GMT+4:00) Asia/Dubai (Gulf Standard Time)" },
    { id: 293, title: "(GMT+4:00) Asia/Muscat (Gulf Standard Time)" },
    { id: 294, title: "(GMT+4:00) Asia/Tbilisi (Georgia Time)" },
    { id: 295, title: "(GMT+4:00) Asia/Yerevan (Armenia Time)" },
    { id: 296, title: "(GMT+4:00) Europe/Samara (Samara Time)" },
    { id: 297, title: "(GMT+4:00) Indian/Mahe (Seychelles Time)" },
    { id: 298, title: "(GMT+4:00) Indian/Mauritius (Mauritius Time)" },
    { id: 299, title: "(GMT+4:00) Indian/Reunion (Reunion Time)" },
    { id: 300, title: "(GMT+4:30) Asia/Kabul (Afghanistan Time)" },
    { id: 301, title: "(GMT+5:00) Asia/Aqtau (Aqtau Time)" },
    { id: 302, title: "(GMT+5:00) Asia/Aqtobe (Aqtobe Time)" },
    { id: 303, title: "(GMT+5:00) Asia/Ashgabat (Turkmenistan Time)" },
    { id: 304, title: "(GMT+5:00) Asia/Ashkhabad (Turkmenistan Time)" },
    { id: 305, title: "(GMT+5:00) Asia/Dushanbe (Tajikistan Time)" },
    { id: 306, title: "(GMT+5:00) Asia/Karachi (Pakistan Time)" },
    { id: 307, title: "(GMT+5:00) Asia/Oral (Oral Time)" },
    { id: 308, title: "(GMT+5:00) Asia/Samarkand (Uzbekistan Time)" },
    { id: 309, title: "(GMT+5:00) Asia/Tashkent (Uzbekistan Time)" },
    { id: 310, title: "(GMT+5:00) Asia/Yekaterinburg (Yekaterinburg Time)" },
    { id: 311, title: "(GMT+5:00) Indian/Kerguelen (French Southern &amp; Antarctic Lands Time)" },
    { id: 312, title: "(GMT+5:00) Indian/Maldives (Maldives Time)" },
    { id: 313, title: "(GMT+5:30) Asia/Calcutta (India Standard Time)" },
    { id: 314, title: "(GMT+5:30) Asia/Colombo (India Standard Time)" },
    { id: 315, title: "(GMT+5:30) Asia/Kolkata (India Standard Time)" },
    { id: 316, title: "(GMT+5:45) Asia/Katmandu (Nepal Time)" },
    { id: 317, title: "(GMT+6:00) Antarctica/Mawson (Mawson Time)" },
    { id: 318, title: "(GMT+6:00) Antarctica/Vostok (Vostok Time)" },
    { id: 319, title: "(GMT+6:00) Asia/Almaty (Alma-Ata Time)" },
    { id: 320, title: "(GMT+6:00) Asia/Bishkek (Kirgizstan Time)" },
    { id: 321, title: "(GMT+6:00) Asia/Dacca (Bangladesh Time)" },
    { id: 322, title: "(GMT+6:00) Asia/Dhaka (Bangladesh Time)" },
    { id: 323, title: "(GMT+6:00) Asia/Novosibirsk (Novosibirsk Time)" },
    { id: 324, title: "(GMT+6:00) Asia/Omsk (Omsk Time)" },
    { id: 325, title: "(GMT+6:00) Asia/Qyzylorda (Qyzylorda Time)" },
    { id: 326, title: "(GMT+6:00) Asia/Thimbu (Bhutan Time)" },
    { id: 327, title: "(GMT+6:00) Asia/Thimphu (Bhutan Time)" },
    { id: 328, title: "(GMT+6:00) Indian/Chagos (Indian Ocean Territory Time)" },
    { id: 329, title: "(GMT+6:30) Asia/Rangoon (Myanmar Time)" },
    { id: 330, title: "(GMT+6:30) Indian/Cocos (Cocos Islands Time)" },
    { id: 331, title: "(GMT+7:00) Antarctica/Davis (Davis Time)" },
    { id: 332, title: "(GMT+7:00) Asia/Bangkok (Indochina Time)" },
    { id: 333, title: "(GMT+7:00) Asia/Ho_Chi_Minh (Indochina Time)" },
    { id: 334, title: "(GMT+7:00) Asia/Hovd (Hovd Time)" },
    { id: 335, title: "(GMT+7:00) Asia/Jakarta (West Indonesia Time)" },
    { id: 336, title: "(GMT+7:00) Asia/Krasnoyarsk (Krasnoyarsk Time)" },
    { id: 337, title: "(GMT+7:00) Asia/Phnom_Penh (Indochina Time)" },
    { id: 338, title: "(GMT+7:00) Asia/Pontianak (West Indonesia Time)" },
    { id: 339, title: "(GMT+7:00) Asia/Saigon (Indochina Time)" },
    { id: 340, title: "(GMT+7:00) Asia/Vientiane (Indochina Time)" },
    { id: 341, title: "(GMT+7:00) Indian/Christmas (Christmas Island Time)" },
    { id: 342, title: "(GMT+8:00) Antarctica/Casey (Western Standard Time Australia)" },
    { id: 343, title: "(GMT+8:00) Asia/Brunei (Brunei Time)" },
    { id: 344, title: "(GMT+8:00) Asia/Choibalsan (Choibalsan Time)" },
    { id: 345, title: "(GMT+8:00) Asia/Chongqing (China Standard Time)" },
    { id: 346, title: "(GMT+8:00) Asia/Chungking (China Standard Time)" },
    { id: 347, title: "(GMT+8:00) Asia/Harbin (China Standard Time)" },
    { id: 348, title: "(GMT+8:00) Asia/Hong_Kong (Hong Kong Time)" },
    { id: 349, title: "(GMT+8:00) Asia/Irkutsk (Irkutsk Time)" },
    { id: 350, title: "(GMT+8:00) Asia/Kashgar (China Standard Time)" },
    { id: 351, title: "(GMT+8:00) Asia/Kuala_Lumpur (Malaysia Time)" },
    { id: 352, title: "(GMT+8:00) Asia/Kuching (Malaysia Time)" },
    { id: 353, title: "(GMT+8:00) Asia/Macao (China Standard Time)" },
    { id: 354, title: "(GMT+8:00) Asia/Macau (China Standard Time)" },
    { id: 355, title: "(GMT+8:00) Asia/Makassar (Central Indonesia Time)" },
    { id: 356, title: "(GMT+8:00) Asia/Manila (Philippines Time)" },
    { id: 357, title: "(GMT+8:00) Asia/Shanghai (China Standard Time)" },
    { id: 358, title: "(GMT+8:00) Asia/Singapore (Singapore Time)" },
    { id: 359, title: "(GMT+8:00) Asia/Taipei (China Standard Time)" },
    { id: 360, title: "(GMT+8:00) Asia/Ujung_Pandang (Central Indonesia Time)" },
    { id: 361, title: "(GMT+8:00) Asia/Ulaanbaatar (Ulaanbaatar Time)" },
    { id: 362, title: "(GMT+8:00) Asia/Ulan_Bator (Ulaanbaatar Time)" },
    { id: 363, title: "(GMT+8:00) Asia/Urumqi (China Standard Time)" },
    { id: 364, title: "(GMT+8:00) Australia/Perth (Western Standard Time Australia)" },
    { id: 365, title: "(GMT+8:00) Australia/West (Western Standard Time Australia)" },
    { id: 366, title: "(GMT+8:45) Australia/Eucla (Central Western Standard Time Australia)" },
    { id: 367, title: "(GMT+9:00) Asia/Dili (Timor-Leste Time)" },
    { id: 368, title: "(GMT+9:00) Asia/Jayapura (East Indonesia Time)" },
    { id: 369, title: "(GMT+9:00) Asia/Pyongyang (Korea Standard Time)" },
    { id: 370, title: "(GMT+9:00) Asia/Seoul (Korea Standard Time)" },
    { id: 371, title: "(GMT+9:00) Asia/Tokyo (Japan Standard Time)" },
    { id: 372, title: "(GMT+9:00) Asia/Yakutsk (Yakutsk Time)" },
    { id: 373, title: "(GMT+9:30) Australia/Adelaide (Central Standard Time South Australia)" },
    { id: 374, title: "(GMT+9:30) Australia/Broken_Hill (Central Standard Time South Australia/New South Wales)" },
    { id: 375, title: "(GMT+9:30) Australia/Darwin (Central Standard Time Northern Territory)" },
    { id: 376, title: "(GMT+9:30) Australia/North (Central Standard Time Northern Territory)" },
    { id: 377, title: "(GMT+9:30) Australia/South (Central Standard Time South Australia)" },
    { id: 378, title: "(GMT+9:30) Australia/Yancowinna (Central Standard Time South Australia/New South Wales)" },
    { id: 379, title: "(GMT+10:00) Antarctica/DumontDUrville (Dumont-d'Urville Time)" },
    { id: 380, title: "(GMT+10:00) Asia/Sakhalin (Sakhalin Time)" },
    { id: 381, title: "(GMT+10:00) Asia/Vladivostok (Vladivostok Time)" },
    { id: 382, title: "(GMT+10:00) Australia/ACT (Eastern Standard Time New South Wales)" },
    { id: 383, title: "(GMT+10:00) Australia/Brisbane (Eastern Standard Time Queensland)" },
    { id: 384, title: "(GMT+10:00) Australia/Canberra (Eastern Standard Time New South Wales)" },
    { id: 385, title: "(GMT+10:00) Australia/Currie (Eastern Standard Time New South Wales)" },
    { id: 386, title: "(GMT+10:00) Australia/Hobart (Eastern Standard Time Tasmania)" },
    { id: 387, title: "(GMT+10:00) Australia/Lindeman (Eastern Standard Time Queensland)" },
    { id: 388, title: "(GMT+10:00) Australia/Melbourne (Eastern Standard Time Victoria)" },
    { id: 389, title: "(GMT+10:00) Australia/NSW (Eastern Standard Time New South Wales)" },
    { id: 390, title: "(GMT+10:00) Australia/Queensland (Eastern Standard Time Queensland)" },
    { id: 391, title: "(GMT+10:00) Australia/Sydney (Eastern Standard Time New South Wales)" },
    { id: 392, title: "(GMT+10:00) Australia/Tasmania (Eastern Standard Time Tasmania)" },
    { id: 393, title: "(GMT+10:00) Australia/Victoria (Eastern Standard Time Victoria)" },
    { id: 394, title: "(GMT+10:30) Australia/LHI (Lord Howe Standard Time)" },
    { id: 395, title: "(GMT+10:30) Australia/Lord_Howe (Lord Howe Standard Time)" },
    { id: 396, title: "(GMT+11:00) Asia/Magadan (Magadan Time)" },
    { id: 397, title: "(GMT+12:00) Antarctica/McMurdo (New Zealand Standard Time)" },
    { id: 398, title: "(GMT+12:00) Antarctica/South_Pole (New Zealand Standard Time)" },
    { id: 399, title: "(GMT+12:00) Asia/Anadyr (Anadyr Time)" },
    { id: 400, title: "(GMT+12:00) Asia/Kamchatka (Petropavlovsk-Kamchatski Time)" },
]

const CreateEventInfo = ({event,setEvent,allCats,setAllCats,allTags,setAllTags,searchTags,setSearchTags,error,setError}) => {
    const styles = dashboardStyles();
    const classes = styles();
    const dates = {
        todaysDate:   moment().subtract(0, "days").format("YYYY-MM-DD"),
        tomorrowDate: moment().add(1, 'days').format("YYYY-MM-DD")
    };
    return (
        <>
        
            <FormControl className={classes.eventInput}>
                <InputLabel shrink>Event Name*</InputLabel>
                <Input id="name" disableUnderline placeholder='Event name'
                    value={event && event.title ? event.title : ""}
                    onChange={(e) => {
                        setEvent({ ...event, title: e.target.value })
                        setError(false)
                    }}
                />
                {error && 
                    <span style={{color:'red'}}>Please complete this required field.</span>
                }
            </FormControl>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                    <FormControl className={classes.eventInput}>
                        <InputLabel shrink>Event Start Date</InputLabel>
                        <Input
                            id="date"
                            type="date"
                            disableUnderline
                            placeholder='Select event start date'
                            className={classes.textField}
                            defaultValue={dates.todaysDate}
                            value={event.startDate}
                            onChange={(e) => {
                                setEvent({ ...event, startDate: e.target.value })
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <FormControl className={classes.eventInput}>
                        <InputLabel shrink>Event End Date*</InputLabel>
                        <Input
                            id="date"
                            type="date"
                            disableUnderline
                            placeholder='Select event end date'
                            defaultValue={dates.tomorrowDate}
                            value={event.endDate}
                            onChange={(e) => {
                                setEvent({ ...event, endDate: e.target.value })
                            }}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <FormControl className={classes.eventInput}>
                        <InputLabel shrink>Event Time</InputLabel>
                        <Input
                            id="time"
                            type="time"
                            disableUnderline
                            placeholder='Select event start time'
                            value={event.time}
                            onChange={(e) => {
                                setEvent({ ...event, time: e.target.value })
                            }}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>                
                    <FormControl className={classes.eventInput}>
                        <InputLabel id="demo-simple-select-filled-label" shrink>Time Zone</InputLabel>
                        <Autocomplete
                            onChange={(e, value) => {
                                setEvent({ ...event, timeZone: value.title })
                            }}
                            id="timezone-dropdown"
                            class="class-timezone-dropdown"
                            options={timeZonesList}
                            getOptionLabel={option => option.title}
                            renderInput={(params) => <TextField {...params}  />}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <FormControl className={classes.eventInput}>
                <InputLabel shrink>Event Mode*</InputLabel>
                <RadioGroup className={classes.ctEventHave} name="event-have" value={event && event.mode}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                value="VIRTUAL"
                                onChange={(e) => {
                                    setEvent({ ...event, mode: e.target.value })
                                }}
                                checked={event.mode === 'VIRTUAL'}
                                control={<Radio />}
                                label={<Box className={classes.ctEventRLabel}> <img src={vEvent} alt="label" /> <span>Virtual</span> </Box>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                value="HYBRID"
                                onChange={(e) => {
                                    setEvent({ ...event, mode: e.target.value })
                                }}
                                checked={event.mode === 'HYBRID'}
                                control={<Radio />}
                                label={<Box className={classes.ctEventRLabel}> <img src={hybrid} alt="label" /> <span>Hybrid</span> </Box>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                value="PHYSICAL"
                                onChange={(e) => {
                                    setEvent({ ...event, mode: e.target.value })
                                }}
                                checked={event.mode === 'PHYSICAL'}
                                control={<Radio />}
                                label={<Box className={classes.ctEventRLabel}> <img src={physicalEvent} alt="label" /> <span>In-Person</span> </Box>}
                            />
                        </Grid>
                    </Grid>

                </RadioGroup>
            </FormControl>
            {event && event.mode !== "VIRTUAL" && 
                <FormControl className={classes.eventInput}>
                    <InputLabel shrink>Event Location</InputLabel>
                    <Input id="location" disableUnderline placeholder='10th Street ...' 
                        value={event && event.location ? event.location : ""}
                        onChange={(e) => {
                            setEvent({ ...event, location: e.target.value })
                        }}
                    />
                </FormControl>
            }
            
            <FormControl className={classes.eventInput}>
                <InputLabel shrink>Website URL</InputLabel>
                <Input id="website-url" disableUnderline placeholder='URL example. www.eventtech.cm'
                    value={event && event.url ? event.url : ""}
                    onChange={(e) => {
                        setEvent({ ...event, url: e.target.value })
                    }}
                />
            </FormControl>
            {allCats &&
            allCats.data &&
            allCats.total_count &&
            allCats.total_count > 0 &&
                <FormControl className={classes.eventInput}>
                    <InputLabel id="demo-simple-select-filled-label" shrink>Select Category</InputLabel>
                    <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        value={event && event.categories}
                        onChange={(e) => {
                            setEvent({ ...event, categories: e.target.value })
                        }}
                        >
                        {allCats.data.map(cat =>
                            <MenuItem value={cat.id}>{cat.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            }
            {event &&
                event.categories &&
                allCats &&
                allCats.data &&
                <FilterTags
                    event={event} setEvent={setEvent}
                    allCats={allCats} setAllCats={setAllCats}
                    allTags={allTags} setAllTags={setAllTags}
                    searchTags={searchTags} setSearchTags={setSearchTags}
                />
            }
            
        </>
    )
}

export default CreateEventInfo