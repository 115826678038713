import axios from "axios";
import React, { useEffect, useState } from "react";
//import {Helmet} from "react-helmet";
import { Container, Box, Typography } from '@material-ui/core'
import { cardsStyles } from "../../utils/hooks/useApplyStyles";
import CollectionBanner from '../Banners/CollectionDetailBanner'
import NavigationBar from '../Header/Navbar'
import CollectionCardSection from './CollectionSections/CollectionCardSlider'
import CollectionEventsSection from './CollectionSections/CollectionEventsSection'
import { useParams } from "react-router-dom";
import LoaderLines from "../Loader/LoaderLines";
import ScrollToTop from '../../components/ScrollToTop';
import DiscoverFooter from '../Footer/Footer'

export default function Collection() {

  window.dataLayer.push({
    event: 'pageview',
    page: {
      url: window.location.href,
      title: "Collection Detail"
    }
  });

  const { id, collection_slug } = useParams();
  const GET_COLLECTION_API = `${global.API.rootPath}${global.config.endpoints.collections_endpoint}?access_key=${global.config.access_key}&collection_id=${id}`;
  const GET_MORECOLLECTIONS_API = `${global.API.rootPath}${global.config.endpoints.collections_endpoint}?access_key=${global.config.access_key}&is_eventeer_award=0&limit=50`;
  const [collection, setCollection] = useState({
    data: null,
    isLoading: false,
    error: "",
  });
  useEffect(() => {
    setCollection({ ...collection, isLoading: true });
    axios.get(GET_COLLECTION_API).then((response) => {
      setCollection({ ...collection, data: response.data, isLoading: false });
    }).catch((err) => setCollection({ ...collection, error: err, isLoading: false }));
  }, []);


  if (collection &&
    collection.data &&
    collection.data.total_count &&
    collection.data.total_count !== 0 &&
    collection.data.data[0] &&
    collection.data.data[0].name) {
      if(collection.data.data[0].status &&
          (collection.data.data[0].status === "0" || !collection.data.data[0].event_id)) {
            window.location = "/";
      } else {
        let fetched_slug = collection.data.data[0].slug ? collection.data.data[0].slug : collection.data.data[0].name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        if (collection_slug !== fetched_slug) {
          window.location = fetched_slug;
        }
      }
  }

  if(collection &&
    collection.data &&
    collection.data.data &&
    collection.data.data[0] &&
    collection.data.total_count &&
    collection.data.total_count > 0 &&
    collection.data.data[0].event_id
    ) {
        var eventIds =  collection.data.data[0].event_id.split(",");
        
  }
  const [moreCollection, setMoreCollection] = useState({
    data: null,
    isLoading: false,
    error: "",
  });
  useEffect(() => {
    setMoreCollection({ ...moreCollection, isLoading: true });
    axios.get(GET_MORECOLLECTIONS_API).then((response) => {
      setMoreCollection({ ...moreCollection, data: response.data, isLoading: false });
    }).catch((err) => setMoreCollection({ ...moreCollection, error: err, isLoading: false }));
  }, []);
  const styles = cardsStyles();
  const classes = styles();
  return (
    <>
      <div className="application">
        <ScrollToTop />
        <NavigationBar />
        <CollectionBanner collection={collection} />
        <Box variant="div" my={5} pt={5}>
          <Container maxWidth="lg">
            {eventIds &&
              <CollectionEventsSection eventIds={eventIds} />
            }
          </Container>
        </Box>
        
        <Container maxWidth="lg">
          <Box className={classes.moreAboutCollection}>
              <Typography variant="h2">
                  more about this collection
              </Typography>
              <Typography variant="body1">
                {collection && collection.isLoading ?
                    <>
                        <LoaderLines />
                        <LoaderLines />
                        <LoaderLines />
                    </>
              :
              collection && collection.data && collection.data.data[0] && collection.data.data[0].description ? collection.data.data[0].description : "Nothing here"
              }
              </Typography>
          </Box>
        </Container>
        <CollectionCardSection moreCollection={moreCollection} />
        <DiscoverFooter />
      </div>
    </>
  )
};