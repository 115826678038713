import React from 'react';
import { Chip } from '@material-ui/core';
import 'react-multi-carousel/lib/styles.css';

export default function TagsCardContent({ tags_data } ) {
    return (
        <>
                {tags_data && tags_data.map(tag => tag.tagName && tag.tagName.name && 
                        <Chip color="secondary" variant="outlined" label={tag.tagName.name} size="small" id={"event-tag-"+tag.tagName.name.toLowerCase().replace(/ /g, '-')} />
                )}
        </>
    );
};
