import React, { useState } from "react";
import { Box, Card, Button, Typography } from '@material-ui/core';
import { speakerCardStyles } from "../../../utils/hooks/useApplyStyles";
import Avatar from '@material-ui/core/Avatar';
import { Row, Item } from '@mui-treasury/components/flex';
import { Info, InfoSubtitle } from '@mui-treasury/components/info';
import { useDynamicAvatarStyles } from '@mui-treasury/styles/avatar/dynamic';
import { useNewsInfoStyles } from '@mui-treasury/styles/info/news';
import { SocialLink, SocialProvider } from '@mui-treasury/components/socialLink';
import 'react-multi-carousel/lib/styles.css';
import LazyLoad from 'react-lazyload';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EventNoteIcon from '@material-ui/icons/EventNote';
import CloseIcon from '@material-ui/icons/Close';

export default function SingleSpeaker({ speaker }) {

    const styles1 = useDynamicAvatarStyles();
    const styles2 = speakerCardStyles();
    const classes = { ...styles1, ...styles2() }
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <Box className={classes.singleSpeakerWrap}>
                <Card className={classes.cardWrap}>
                    <Row gap={2}  alignItems="center">
                    <Item>
                        <LazyLoad height={100}>
                            <Avatar
                                classes={classes}
                                src={speaker && speaker.image ? speaker.image : ""}
                            />
                        </LazyLoad>
                    </Item>
                    <Info useStyles={useNewsInfoStyles}>
                        {!!speaker && speaker.name &&
                            <Typography variant="h6" component="p" className={classes.infoTitle}>
                                {speaker && speaker.name ? <div dangerouslySetInnerHTML={{ __html: speaker.name }}></div> : ""}
                            </Typography>
                        }
                        {!!speaker && speaker.emp_name &&
                            <InfoSubtitle className={classes.infoSubTitle}><div dangerouslySetInnerHTML={{ __html: speaker.emp_name.replace(/\<a /g, '<a rel="nofollow"') }}></div></InfoSubtitle>
                        }
                        <div className={classes.socialLinkWrap} >
                            <SocialProvider>
                                {speaker && speaker.linked_in &&
                                    <SocialLink brand={'LinkedIn'} rel="nofollow" target="_blank" href={speaker.linked_in} />
                                }
                                {speaker && speaker.twitter &&
                                    <SocialLink brand={'Twitter'} rel="nofollow" target="_blank" href={speaker.twitter} />
                                }
                            </SocialProvider>
                            {speaker && speaker.bio && (speaker.twitter || speaker.linked_in) &&
                                <Box component="span" className={classes.speakerSeeMoreSpacer}> | </Box>
                            }
                            {speaker && speaker.bio &&
                                <Box component="span" className={classes.speakerSeeMore}>
                                    <Button size="small" color="primary" startIcon={<EventNoteIcon />} onClick={handleOpen}>
                                        View Bio
                                    </Button>
                                </Box>
                            }
                        </div>
                        {speaker && speaker.bio &&
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                className={classes.modalMain}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                            >
                                <Box className={classes.speakerModal}>
                                    <button className={classes.closeModalCustom} onClick={handleClose}><CloseIcon /></button>
                                    <div className={classes.speakerHead}>
                                        <div className={classes.speakerImage}>
                                            <Avatar
                                                classes={classes}
                                                src={speaker && speaker.image ? speaker.image : ""}
                                            />
                                        </div>
                                        {!!speaker && speaker.name &&
                                            <Typography variant="h6"> <strong><div dangerouslySetInnerHTML={{ __html: speaker.name }}></div></strong> </Typography>
                                        }
                                        {!!speaker && speaker.emp_name &&
                                            <Typography variant="body1"><div dangerouslySetInnerHTML={{ __html: speaker.emp_name }}></div></Typography>
                                        }
                                        {speaker && (speaker.linked_in || speaker.twitter) &&
                                            <ul className={classes.speakerSocial}>
                                                {speaker && speaker.linked_in &&
                                                    <li><a href={speaker.linked_in} target="_blank" ><LinkedInIcon /></a></li>
                                                }
                                                {speaker && speaker.twitter &&
                                                    <li><a href={speaker.twitter} target="_blank"><TwitterIcon /></a></li>
                                                }
                                            </ul>
                                        }
                                    </div>
                                    <Typography variant="body1"> <div dangerouslySetInnerHTML={{ __html: speaker.bio.replace(/\<a /g, '<a rel="nofollow"') }}></div></Typography>
                                </Box>
                            </Modal>
                        }
                        </Info>
                    </Row>
                </Card>
            </Box>
        </>
    );
};